import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import { IconType } from "@eman/emankit/Icon";
import Select from "@eman/emankit/Select";

import BaseComponent from "@component/BaseComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import ContractsVM from "@vm/Other/Contracts";
import { lazyInject, TYPES } from "../../../../inversify.config";
import { InputVariant } from "@eman/emankit/Input";

export interface ContractHeaderProps extends PropsFromRouter {
  showAdd?: boolean;
}

@observer
export default class ContractHeader extends BaseComponent<ContractHeaderProps> {
  @lazyInject(TYPES.Contracts)
  vm: ContractsVM;

  componentDidMount(): void {
    this.vm.setContractId(parseInt(this.props.match.params.contract_id, 10), false);
  }

  onContractChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.vm.setContractId(parseInt(`${e.target.value}`, 10));
  };

  onAddClick = () => {
    // tslint:disable-next-line: no-undefined-argument
    this.vm.setContractId(undefined);
  };

  renderContractDropdown = (disabled = false) => {
    if (!this.vm.contracts.length) {
      return null;
    }

    const currentContractId = this.vm.contractId;
    const options = this.vm.contracts.map(contract => {
      this.enums.assignObjectEnum(contract);

      return (
        <option key={contract.id} value={contract.id}>
          {contract.contract_type ? contract.contract_type.name : ""} - {this.locs.formatDate(contract.valid_from)}
        </option>
      );
    });

    if (!currentContractId) {
      options.unshift(<option key="empty" value="empty" disabled={true} />);
    }

    return (
      <Select
        onChange={this.onContractChange}
        disabled={disabled}
        value={currentContractId || "empty"}
        variant={InputVariant.White}>
        {options}
      </Select>
    );
  };

  render() {
    return (
      <>
        <div style={{ minWidth: 400 }} className={this.props.showAdd ? "mr-12" : ""}>
          {this.renderContractDropdown()}
        </div>
        {this.props.showAdd && this.user.allowToObject(UserRightsObjects.CONTRACT, UserRightsOperations.CREATE) && (
          <Button icon={IconType.Plus} variant={ButtonVariant.Active} onClick={this.onAddClick} />
        )}
      </>
    );
  }
}
