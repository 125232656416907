import { Exclude, Transform, Type } from "@eman/class-transformer";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";
import JobPosition from "@model/JobPosition";
import OrganizationStructure from "@model/OrganizationStructure";
import User from "@model/User";
import Contract from "./Contract";

export enum JobTitleState {
  ACTIVE = "active",
  IN_PREPARATION = "in_preparation",
  CLOSED = "closed",
}

export default class JobTitle extends BaseModel {
  @observable user_id: number;
  @observable supervisor_id: number;
  @observable job_position_id?: number;
  @observable contract_id: number;
  @observable organization_structure_id: number;
  @observable primary: boolean;
  @observable reason: string;

  @Exclude({ toPlainOnly: true })
  @observable
  job_position: JobPosition;

  @Exclude({ toPlainOnly: true })
  @observable
  organization_structure: OrganizationStructure;

  @Exclude({ toPlainOnly: true })
  @observable
  state: JobTitleState;

  @Exclude({ toPlainOnly: true })
  @observable
  supervisor: User;

  @Exclude({ toPlainOnly: true })
  @observable
  contract: Contract;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;
}
