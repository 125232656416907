import EditModal from "@component/EditModal";
import OrganizationStructure, { OrganizationStructureType } from "@model/OrganizationStructure";
import OrganizationStructureEditVM from "@vm/Edit/OrganizationStructure";
import { observer } from "mobx-react";

import { ORGANIZATION_STRUCTURE_TYPE_PARAM } from "../../../config";

import { lazyInject, TYPES } from "../../../inversify.config";

import Form from "./Form";

@observer
export default class OrganizationStructureEditModal extends EditModal<OrganizationStructure> {
  prefix = "employer";

  get modelName(): string {
    if (this.getType() === OrganizationStructureType.COMPANY) {
      return "company";
    } else {
      return "organization_unit";
    }
  }

  @lazyInject(TYPES.OrganizationStructureEdit)
  vm: OrganizationStructureEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employer_organization_structures());
  };

  getType(): OrganizationStructureType {
    const queryParams = this.router.getQuery() || {};

    return queryParams[ORGANIZATION_STRUCTURE_TYPE_PARAM] as OrganizationStructureType;
  }

  setIdFromParams() {
    const params = this.props.match.params;

    this.vm.init(params.organization_structure_id, this.getType());
  }

  renderModalBody() {
    return <Form type={this.getType()} entity={this.vm.entity} possibleParents={this.vm.possibleParents} />;
  }
}
