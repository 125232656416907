import { Transform, Type } from "@eman/class-transformer";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";

export default class ForeignPersonalDetail extends BaseModel {
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  residence_permit_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  residence_permit_to: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  work_permit_valid_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  work_permit_valid_to: Date;

  @observable vat_number: string;
}
