import { inject, injectable } from "inversify";
import { action } from "mobx";

import Allocation from "@model/Allocation";
import AllocationForm from "@vm/Form/Allocation";
import CreateViewModel from "@vm/Create/CreateViewModel";
import Router from "@service/Router";

import { ALLOCATION_WORK_TYPE_PARAM } from "../../config";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class AllocationCreateVM extends CreateViewModel<Allocation, Repository<Allocation>> {
  constructor(
    @inject(TYPES.AllocationRepository) repository: Repository<Allocation>,
    @inject(TYPES.Router) private router: Router,
    @inject(TYPES.AllocationForm) public allocationFormVM: AllocationForm
  ) {
    super(Allocation, repository);
  }

  @action
  resetEntityAndErrors() {
    super.resetEntityAndErrors();

    const queryParams = this.router.getQuery() || {};
    this.entity.is_work = queryParams[ALLOCATION_WORK_TYPE_PARAM] !== "false";

    this.allocationFormVM.setEntity(this.entity);
  }
}
