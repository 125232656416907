import { Transform, Type } from "@eman/class-transformer";
import BaseModel from "@model/BaseModel";
import { observable } from "mobx";
import { enumValue } from "@util/Enumerable";
import { Exclude } from "@eman/class-transformer";

export default class School extends BaseModel {
  @observable user_id: string;
  @observable degree: string;
  @observable field: number;
  @observable completed: boolean;
  @observable enumeration_school_id: number;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  date_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  date_to: Date;

  @Exclude()
  @observable
  @enumValue("schools", "enumeration_school_id")
  school: models.IEnumType;
}
