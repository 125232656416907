import BaseModal from "@component/BaseModal";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import React from "react";

export default abstract class CreateModal<TModel extends models.IBase> extends BaseModal<TModel> {
  suffix = "create";

  abstract vm: ViewModel.Create<TModel>;

  componentDidMount(): void {
    this.vm.resetEntityAndErrors();
    this.vm.scroll = false;
  }

  buttons() {
    return (
      <>
        <Button className="mr-12" onClick={this.onReset}>
          {this.locs.tg("form.reset")}
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={this.onSave}>
          {this.locs.tg("form.create")}
        </Button>
      </>
    );
  }

  successMessage(): string {
    return this.locs.tg("form.created");
  }

  onSave = async () => {
    const id = await this.vm.create();

    if (id) {
      EventBus.trigger(SHOW_TOAST, this.successMessage());
      this.onClose(id);
    }

    return status;
  };

  onReset = () => {
    this.vm.resetEntityAndErrors();
  };
}
