import BaseModel from "@model/BaseModel";
import { observable } from "mobx";
import { Type } from "@eman/class-transformer";

export default class BalanceGroup extends BaseModel {
  @observable value: number;
  @observable user_id: number;
  @observable is_work: boolean | null;
  @observable zero_sum_value_ok: boolean | null;

  @Type(() => Date)
  @observable
  valid_from: Date;

  @Type(() => Date)
  @observable
  valid_to: Date;

  @observable
  capacity_id: number;
}
