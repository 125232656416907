import downloadjs from "downloadjs";
import { inject, injectable } from "inversify";
import ListViewModel from "./ListViewModel";

import Export from "@model/Export";

import TYPES from "../../inversify.types";
import EventBus, { IDisposable, REFRESH_NOTIFICATION } from "@util/EventBus";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class ExportListVM
  extends ListViewModel<Export, RepositoryWithDownload<Export>>
  implements ViewModel.WithDownload {
  static defaults = {
    sort: {
      key: "created_at",
      sort: "desc",
    },
    columns: ["human_name", "status", "lines", "created_at", "finished_at", "action"],
  };

  private readonly eventHandlers: IDisposable[] = [];

  constructor(@inject(TYPES.ExportRepository) repository: RepositoryWithDownload<Export>) {
    super(repository);
  }

  init() {
    this.eventHandlers.push(EventBus.on(REFRESH_NOTIFICATION, this.refreshList));
    return super.init();
  }

  dispose() {
    this.eventHandlers.forEach(x => x.dispose());
  }

  refreshList = () => {
    this.fetchList(true, false);
  };

  async downloadFile(id: number, defaultFileName?: string) {
    const { data, fileName } = await this.repository.fileDownload(id);
    downloadjs(data, fileName || defaultFileName || "default_filename");
  }
}
