import { unmanaged } from "inversify";
import { action } from "mobx";

import EventBus, { SCROLL_TO_FIRST_ERROR } from "@util/EventBus";
import ViewModel from "../ViewModel";

/**
 * Create view model.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
export default abstract class CreateViewModel<TModel extends models.IBase, TRepository extends Repository<TModel>>
  extends ViewModel<TModel, TRepository>
  implements ViewModel.Create<TModel> {
  constructor(@unmanaged() protected model: new () => TModel, @unmanaged() repository: TRepository) {
    super(repository);
  }

  /**
   * On component will mount we want to clear things out.
   */
  @action
  resetEntityAndErrors() {
    // TODO why not here setEntity?
    this.entity = new this.model();
  }

  /**
   * Create record through repository.
   *
   * @returns {(Promise<number | undefined>)}
   * @memberof CreateViewModel
   */
  async create(): Promise<number | undefined> {
    this.currentlyFetching = true;
    const response = await this.repository.create(this.entity);

    // On 422 unprocessable entity server returns an object of errors, otherwise (different kind of errors)
    // response.error is string
    if (!response.status && response.errors && response.errors.constructor === Object) {
      this.entity.setErrors(response.errors);
      EventBus.trigger(SCROLL_TO_FIRST_ERROR);
    } else {
      this.entity.clearErrors();
    }

    this.currentlyFetching = false;

    return response.status ? response.original.entity.id : undefined;
  }
}
