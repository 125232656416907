import { injectable } from "inversify";

import School from "@model/School";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class SchoolRepository extends BaseAssociatedRepository<School> {
  constructor() {
    super(School, "users/$ID/schools", "school");
  }
}
