import { observer } from "mobx-react";
import React from "react";

import BaseList from "@component/BaseList";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";

import { lazyInject, TYPES } from "../../../inversify.config";
import Training from "@model/Training";
import TrainingListVM from "@vm/List/Training";
import { AlignType } from "@eman/respectkit/DataTable/DataBody";
import ViewHelpers from "@util/ViewHelpers";

@observer
export default class TrainingList extends BaseList<Training, TrainingListVM, PropsFromRouter> {
  modelName = "training";
  prefix = "employee";
  searchable = false;
  savedSettingsName = "user_training";

  @lazyInject(TYPES.TrainingList)
  vm: TrainingListVM;

  initVM() {
    this.vm.init(this.props.match.params.user_id);
  }

  renderConfirmButton = (id: number, disabled: boolean) => {
    return (
      <Button
        variant={ButtonVariant.Approve}
        key="confirm_"
        onClick={() => this.onConfirmClick(id)}
        disabled={!disabled}
        style={{ width: "100%" }}>
        {this.tg(disabled ? "confirm" : "confirmed")}
      </Button>
    );
  };

  renderRow = (item: Training, column: string): React.ReactNode => {
    switch (column) {
      case "training_type":
        return this.enums.value("training_types", item.enumeration_training_type_id)?.name;
      case "user":
        return item.user.fullName;
      case "supervisor":
        return item.supervisor.fullName;
      case "created_at":
        return this.locs.formatDate(item.created_at);
      case "supervisor_confirmation":
        return item.supervisor_confirmation ? this.locs.formatDate(item.supervisor_confirmation) : this.tg("unconfirmed");
      case "user_confirmation":
        return item.user_confirmation ? this.locs.formatDate(item.user_confirmation) : this.tg("unconfirmed");
      case "confirmation_button":
        return this.renderConfirmButton(item.id!, this.isConfirmationEnabled(item));
      case "url":
        return ViewHelpers.renderUrlButton(this.locs, item.url);
      default:
        return item[column];
    }
  };

  isConfirmationEnabled = (item: Training) => {
    return (
      (this.user.entity.id == item.user_id && !item.user_confirmation) ||
      (this.user.entity.id == item.supervisor.id && !item.supervisor_confirmation)
    );
  };

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_employees_trainings(this.props.match.params.user_id));
  };

  onConfirmClick = (id: number) => {
    void this.vm.confirmTraining(id);
  };

  headerProps() {
    return {
      text: this.tg("title"),
      level: 2,
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.TRAINING, UserRightsOperations.CREATE)) {
          return (
            <Button variant={ButtonVariant.Primary} key="new_training" onClick={this.onAddClick}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "user_confirmation",
        label: this.ta("user_confirmation"),
        type: "date_range",
      },
      {
        id: "supervisor_confirmation",
        label: this.ta("supervisor_confirmation"),
        type: "date_range",
      },
      {
        id: "created_at",
        label: this.ta("created_at"),
        type: "date_range",
      },
    ];

    return {
      filters,
      hideReset: false,
      hideFilterSettings: false,
      hideColumnSettings: false,
    };
  }

  dataProps() {
    const noClickAttributes = {
      width: 100,
      align: AlignType.Center,
      className: "noclick",
      alwaysShow: true,
      nosort: true,
    };

    const columns = [
      this.createField("training_type", {}),
      this.createField("user", { nosort: true }),
      this.createField("supervisor", { nosort: true }),
      this.createField("created_at", { width: 100 }),
      this.createField("supervisor_confirmation", { width: 100 }),
      this.createField("user_confirmation", { width: 100 }),
      this.createField("url", { ...noClickAttributes, alwaysShow: false }),
      this.createField("confirmation_button", noClickAttributes),
    ];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: Training) => {
        this.objectEditOrShowLink("employees_trainings", [this.props.match.params.user_id, item.id]);
      },
    };
  }
}
