import { action } from "mobx";

import Attachment from "@model/Attachment";
import CreateViewModel from "@vm/Create/CreateViewModel";

export default class WithAttachmentsCreateViewModel<
    TModel extends models.IWithAttachments,
    TRepository extends Repository<TModel>
  >
  extends CreateViewModel<TModel, TRepository>
  implements ViewModel.WithAttachments {
  // This is needed for recognize unique files because filename is not unique
  private idCounter = 0;

  get attachments() {
    return this.entity.documents_attributes;
  }

  @action
  onDropFile(files: File[], data?: FormDataArguments, ...others: any) {
    files.forEach((file: File) => {
      this.idCounter++;

      // plainToClass is not possible here because of Blob data !!!
      const attachment: Attachment = Object.assign(new Attachment(), {
        id: this.idCounter,
        attach: file,
        total: file.size,
        name: file.name,
        document_type: file.type,
      });

      this.attachments.push(attachment);
    });
  }

  @action
  removeFile = (id: number) => {
    this.entity.documents_attributes = this.entity.documents_attributes.filter(item => item.id !== id);
  };
}
