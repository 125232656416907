import { Exclude, Type } from "@eman/class-transformer";
import { computed, observable } from "mobx";

import BranchOffice from "@model/BranchOffice";
import Company from "@model/Company";
import Contract from "@model/Contract";
import HealthInsurance from "@model/HealthInsurance";
import JobTitle from "@model/JobTitle";
import OrganizationUnit from "@model/OrganizationUnit";
import Rate from "@model/Rate";
import User from "@model/User";

export default class Employee extends User {
  @observable note: boolean;

  @Exclude({ toPlainOnly: true })
  @Type(() => OrganizationUnit)
  @observable
  organization_unit?: OrganizationUnit;

  @Exclude({ toPlainOnly: true })
  @Type(() => Contract)
  @observable
  contracts: Contract[];

  @Exclude({ toPlainOnly: true })
  @Type(() => Company)
  @observable
  company?: Company;

  @Exclude({ toPlainOnly: true })
  @Type(() => BranchOffice)
  @observable
  branch_office?: BranchOffice;

  @Exclude({ toPlainOnly: true })
  @Type(() => JobTitle)
  @observable
  active_job_titles?: JobTitle[];

  @Exclude({ toPlainOnly: true })
  @Type(() => Rate)
  @observable
  rate?: Rate;

  @Exclude({ toPlainOnly: true })
  @Type(() => HealthInsurance)
  @observable
  active_health_insurance?: HealthInsurance;

  /**
   * Return active contracts for employee list service
   */
  @computed get activeContracts(): Contract[] {
    return this.contracts.filter(x => x.state === "active");
  }
}
