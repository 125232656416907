import { observer } from "mobx-react";
import React from "react";

import TextArea from "@eman/emankit/TextArea";

import BindingElement, { BindingProps } from "./index";

@observer
export default class TextAreaBox extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setValue(e.target.value);
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const { skipDirty, formatValue, parseValue, ...others } = this.props;

    return <TextArea {...others} onChange={this.handleValueChanged} value={this.value || ""} />;
  }
}
