import School from "@model/School";
import SchoolListVM from "@vm/List/School";
import SmallList from "@component/SmallList";
import { lazyInject, TYPES } from "../../../../inversify.config";
import { observer } from "mobx-react";
import ViewHelpers from "@util/ViewHelpers";

export interface SchoolsListProps {
  userId: number;
}

@observer
export default class SchoolsList extends SmallList<School, SchoolListVM, SchoolsListProps> {
  @lazyInject(TYPES.SchoolList)
  vm: SchoolListVM;

  modelName = "school";
  prefix = "employee.personal_information";

  componentDidMount(): void {
    this.vm.setParentId(this.props.userId);
    super.componentDidMount();
  }

  renderRow = (item: School, column: string) => {
    switch (column) {
      case "date_from":
      case "date_to":
        return this.locs.formatDate(item[column]);
      case "completed":
        return ViewHelpers.booleanStringify(this.locs, item[column]);
      case "enumeration_school_id":
        return item.school ? item.school.name : null;
      default:
        return item[column];
    }
  };

  onClick = (item: School): void => {
    this.objectEditOrShowLink("employees_personal_data_schools", [this.props.userId, item.id!]);
  };

  rows() {
    return [
      this.createField("date_from", {}),
      this.createField("date_to", {}),
      this.createField("completed", {}),
      this.createField("degree", {}),
      this.createField("enumeration_school_id", {}),
      this.createField("field", {}),
    ];
  }
}
