import { Exclude } from "@eman/class-transformer";
import { computed, observable } from "mobx";

import BaseUser from "@model/BaseUser";
import Company from "@model/Company";

export default class CurrentUser extends BaseUser {
  @observable announcement_count: number;

  @observable managed_user_ids: number[];

  @observable parent_company?: Company;

  // Values for filters
  @Exclude({ toPlainOnly: true })
  filter_settings: ListSavedSettings;

  @computed
  get has_subordinates(): boolean {
    return this.managed_user_ids.length > 1;
  }

  @computed
  get authenticated(): boolean {
    return this.id !== undefined && this.id > 0;
  }
}
