import { inject, injectable } from "inversify";
import { IReactionDisposer, reaction } from "mobx";

import Contract from "@model/Contract";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import ContractFormVM from "@vm/Form/Contract";
import ContractsVM from "@vm/Other/Contracts";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;

@injectable()
export default class ContractCreateVM
  extends AssociatedCreateViewModel<Contract, AssociatedRepository<Contract>>
  implements ViewModel.WithReactions {
  private contractsReactionDisposer: IReactionDisposer;

  constructor(
    @inject(TYPES.ContractRepository) repository: AssociatedRepository<Contract>,
    @inject(TYPES.ContractForm) public contractFormVM: ContractFormVM,
    @inject(TYPES.Contracts) private contractsVM: ContractsVM
  ) {
    super(Contract, repository);
    this.turnOnReactions();
  }

  resetEntityAndErrors() {
    super.resetEntityAndErrors();
    this.contractFormVM.setEntity(this.entity);

    // First contract of employee has to be primary by default
    this.entity.primary = !this.contractsVM.hasAnyContract;
  }

  setParentId(parentId: number) {
    super.setParentId(parentId);

    this.contractFormVM.setParentId(parentId);
  }

  /**
   * React on contract list change
   */
  turnOnReactions() {
    this.contractsReactionDisposer = reaction(
      () => this.contractsVM.hasAnyContract,
      hasAnyContract => {
        this.entity.primary = !hasAnyContract;
      }
    );
  }

  turnOffReactions() {
    if (this.contractsReactionDisposer) {
      this.contractsReactionDisposer();
    }
  }
}
