import { inject, injectable } from "inversify";
import { computed } from "mobx";

import JobPosition from "@model/JobPosition";
import OrganizationStructure from "@model/OrganizationStructure";
import EditViewModel from "@vm/Edit/EditViewModel";
import OptionsVM from "@vm/Other/Options";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class JobPositionEditVM extends EditViewModel<JobPosition, Repository<JobPosition>> {
  private organizationStructureOptionsVM: OptionsVM<OrganizationStructure>;

  constructor(
    @inject(TYPES.JobPositionRepository) repository: Repository<JobPosition>,
    @inject(TYPES.OrganizationStructureRepository) organizationStructureRepository: Repository<OrganizationStructure>
  ) {
    super(JobPosition, repository);
    this.organizationStructureOptionsVM = new OptionsVM<OrganizationStructure>(organizationStructureRepository, "name");
  }

  @computed
  get availableOrganizationStructures() {
    return this.organizationStructureOptionsVM.selectOptions;
  }
}
