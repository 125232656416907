import { injectable } from "inversify";

import BaseRepository from "@repository/BaseRepository";
import AllocationJobTitle from "@model/AllocationJobTitle";

@injectable()
export default class AllocationJobTitleRepository extends BaseRepository<AllocationJobTitle> {
  constructor() {
    super(AllocationJobTitle, "allocation_job_titles", "allocation_job_title");
  }
}
