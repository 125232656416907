import { observer } from "mobx-react";

import BaseComponent from "@component/BaseComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import AddressList from "./Address/List";
import ContactList from "./Contact/List";

@observer
export default class ContactInformationShow extends BaseComponent<PropsFromRouter> {
  render() {
    return (
      <>
        {this.user.allowToObject(UserRightsObjects.CONTACT, UserRightsOperations.LIST) && (
          <ContactList match={this.props.match} />
        )}
        {this.user.allowToObject(UserRightsObjects.ADDRESS, UserRightsOperations.LIST) && (
          <AddressList match={this.props.match} />
        )}
      </>
    );
  }
}
