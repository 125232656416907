import CreateModal from "@component/CreateModal";
import { observer } from "mobx-react";

import { lazyInject, TYPES } from "../../inversify.config";
import Export from "@model/Export";
import Form from "./Form";
import ExportCreateVM from "@vm/Create/Export";
import EventBus, { SHOW_TOAST } from "@util/EventBus";

@observer
export default class ExportCreateModal extends CreateModal<Export> {
  modelName = "export";

  @lazyInject(TYPES.ExportCreate)
  vm: ExportCreateVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.exports_uri());
  };

  get changesPrompt(): boolean {
    return this.vm.entity.changes > 0;
  }

  onSave = async () => {
    const response = await this.vm.repository.startExportReport(
      this.vm.entity.export_type,
      this.vm.entity.offset,
      this.vm.entity.name
    );

    if (response.status) {
      EventBus.trigger(SHOW_TOAST, this.tg("export.success"));
      this.vm.resetEntityAndErrors();
      this.router.pageLink(this.uriHelper.exports_uri());
    }

    this.vm.entity.setErrors(response.errors!);

    return "";
  };

  renderModalBody() {
    return (
      <>
        <Form entity={this.vm.entity} vm={this.vm.exportFormVM} />
      </>
    );
  }
}
