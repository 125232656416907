import Note from "@model/Note";

import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";
import { inject, injectable } from "inversify";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class NoteEditVM extends AssociatedEditViewModel<Note, AssociatedRepository<Note>> {
  constructor(@inject(TYPES.NoteRepository) repository: AssociatedRepository<Note>) {
    super(Note, repository);
  }
}
