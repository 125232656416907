import { inject, injectable } from "inversify";

import Note from "@model/Note";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class NoteShowVM extends AssociatedShowViewModel<Note, AssociatedRepository<Note>> {
  constructor(@inject(TYPES.NoteRepository) repository: AssociatedRepository<Note>) {
    super(Note, repository);
  }
}
