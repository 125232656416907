import Breadcrumbs from "@component/Breadcrumbs";
import EnumerationContainerList from "@view/Enumeration/List";
import EnumerationContainerShow from "@view/Enumeration/Show";
import EnumCreate from "@view/Enumeration/Show/Create";
import EnumEdit from "@view/Enumeration/Show/Edit";

import { uriHelper } from "../config";

import BaseRouter from "./Base";

export const enumerationRoutes: IAppRoute[] = [
  {
    path: uriHelper.enumeration_uri(),
    exact: true,
    component: EnumerationContainerList,
  },
  {
    path: uriHelper.show_enumeration_uri(),
    component: EnumerationContainerShow,
    title: "enumeration.show",
  },
  {
    path: uriHelper.edit_enumeration_enum_uri(),
    exact: true,
    modalComponent: EnumEdit,
  },
  {
    path: uriHelper.new_enumeration_enum_uri(),
    exact: true,
    modalComponent: EnumCreate,
  },
];

export default class EnumerationDetail extends BaseRouter {
  render() {
    return (
      <>
        <div className="emankit__layout__content">
          <Breadcrumbs />
          {this.pageRouteRender(enumerationRoutes)}
        </div>
      </>
    );
  }
}
