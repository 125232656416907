import { injectable } from "inversify";

import Contract from "@model/Contract";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class ContractRepository extends BaseAssociatedRepository<Contract> {
  constructor() {
    super(Contract, "users/$ID/contracts", "contract");
  }
}
