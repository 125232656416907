import BaseModel from "@model/BaseModel";
import ShowViewModel from "@vm/Show/ShowViewModel";
import { observable } from "mobx";

export default class AssociatedShowViewModel<TModel extends BaseModel, TRepository extends AssociatedRepository<TModel>>
  extends ShowViewModel<TModel, TRepository>
  implements ViewModel.Associated {
  @observable parentId: number;

  init(id: number, parentId?: number) {
    if (this.parentId === undefined && !parentId) {
      throw new Error("You have to set parentID first to use this associated VM");
    }

    if (parentId) {
      this.setParentId(parentId);
    }

    super.init(id);
  }

  setParentId(parentId: number): void {
    this.parentId = parentId;
    this.repository.setId(parentId);
  }
}
