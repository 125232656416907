import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import PreviousEmployment from "@model/PreviousEmployment";
import { DatePickerBox, TextAreaBox, TextBox } from "@util/Form";
import PreviousEmploymentFormVM from "@vm/Form/PreviousEmployment";

export interface PreviousEmploymentFormProps {
  vm: PreviousEmploymentFormVM;
}

@observer
export default class PreviousEmploymentForm extends BaseForm<PreviousEmployment, PreviousEmploymentFormProps> {
  modelName = "previous_employment";
  prefix = "employee.personal_information";

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-6">{this.generateFormField("company_name", <TextBox />, true)}</div>
        <div className="col-md-6">{this.generateFormField("job_position_name", <TextBox />, true)}</div>
        <div className="col-md-6">
          {this.generateFormField("valid_from", <DatePickerBox fromMonth={this.props.vm.fromDate} />, true)}
        </div>
        <div className="col-md-6">{this.generateFormField("valid_to", <DatePickerBox fromMonth={this.props.vm.fromDate} />)}</div>
        <div className={"col"}>{this.generateFormField("note", <TextAreaBox />)}</div>
      </div>
    );
  }
}
