import React from "react";
import { Switch } from "react-router";
import { NavLink, Redirect, Route } from "react-router-dom";

// General base components
import BaseComponent from "@component/BaseComponent";
import PageRoute from "@component/PageRoute";
import Icon, { IconType } from "@eman/emankit/Icon";
import VerticalMenu from "@eman/emankit/VerticalMenu";

export interface SidebarLink {
  path: string;
  title: string;
  isChild?: boolean;
}

export default abstract class RouterBase<TProps = {}, TState = {}> extends BaseComponent<TProps, TState> {
  /**
   * Generate redirect component to given path
   *
   * @param path string
   */
  redirect(pathFunction: (match: any) => string) {
    return (match: any) => <Redirect to={pathFunction(match.match.params)} />;
  }

  /**
   * Generate breadcrumb title.
   * @param code
   */
  breadcrumbTitle(code: string) {
    return this.locs.tg(`breadcrumb.${code}`);
  }

  /**
   * Generate sidebar links.
   *
   * @param links
   * @param backLink
   */
  sidebarLinks(links: SidebarLink[], backLink?: string) {
    return (
      <>
        <div>
          {backLink && (
            <div className="mb-20 mt-20">
              <Icon icon={IconType.ArrowBack} onClick={() => window.history.back()} style={{ cursor: "pointer" }} />
            </div>
          )}
        </div>
        <VerticalMenu
          links={links.map(link => {
            return (
              <NavLink key={link.path} to={link.path} activeClassName={"active"}>
                <span className={link.isChild ? "indented" : ""}>
                  {link.title ? this.breadcrumbTitle(link.title) : undefined}
                </span>
              </NavLink>
            );
          })}
        />
      </>
    );
  }

  /**
   * Renders recursively AppRoutes
   *
   * In one switch `component` or `render` function
   * In separately switch renders modalComponent
   *
   */
  pageRouteRender = (pageRoutes: IAppRoute[]) => {
    return (
      <>
        <Switch>
          {pageRoutes.map(route => {
            let renderFunction;
            const { redirect, component, render } = route;

            if (render !== undefined) {
              renderFunction = render;
            } else if (redirect !== undefined) {
              renderFunction = this.redirect(redirect);
            }

            if (renderFunction === undefined && component === undefined) {
              return null;
            }

            return (
              <PageRoute
                key={route.path.toString()}
                path={route.path}
                title={route.title ? this.breadcrumbTitle(route.title) : undefined}
                exact={route.exact}
                render={renderFunction}
                component={component}
              />
            );
          })}
        </Switch>
        <Switch>
          {pageRoutes.map(route => {
            const modalComponent = route.modalComponent;

            if (!modalComponent) {
              return null;
            }

            return <Route key={`${route.path}-modal`} path={route.path} exact={route.exact} component={modalComponent} />;
          })}
        </Switch>
      </>
    );
  };
}
