import PerformanceManagement from "@model/PerformanceManagement";

import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";
import { inject, injectable } from "inversify";

import TYPES from "../../inversify.types";
import { action } from "mobx";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PerformanceManagementEditVM extends AssociatedEditViewModel<
  PerformanceManagement,
  AssociatedRepository<PerformanceManagement>
> {
  constructor(@inject(TYPES.PerformanceManagementRepository) repository: AssociatedRepository<PerformanceManagement>) {
    super(PerformanceManagement, repository);
  }

  @action
  async fetchItem(scrollTop = true): Promise<number> {
    const returnValue = await super.fetchItem(scrollTop);

    this.entity.data_values.forEach((answer: string, index: number) => {
      this.entity[`answer${index}`] = answer;
    });

    return returnValue;
  }

  async update(): Promise<boolean> {
    const length = this.enums.values("performance_management_questions").length;
    const errors = {};

    this.entity.data_values = [];

    for (let i = 0; i < length; i++) {
      const answer_identifier = `answer${i}`;
      const answer = this.entity[answer_identifier];

      if (answer === null || answer === undefined) {
        errors[answer_identifier] = ["Odpověd je povinná"];
      }

      this.entity.data_values.push(answer);
    }

    if (Object.keys(errors).length > 0) {
      this.entity.setErrors(errors);
      return false;
    } else {
      this.entity.clearErrors();
    }

    return super.update();
  }
}
