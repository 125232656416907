import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import HealthInsurance from "@model/HealthInsurance";
import Form from "@view/Employee/HealthInsurance/Form";
import HealthInsuranceShowVM from "@vm/Show/HealthInsurance";
import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class HealthInsuranceShowModal extends ShowModal<HealthInsurance> {
  modelName = "health_insurance";
  prefix = "employee";

  @lazyInject(TYPES.HealthInsuranceShow)
  vm: HealthInsuranceShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_health_insurances(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} readonly={true} />;
  }
}
