import JobTitleFormVM from "@vm/Form/JobTitle";
import { observer } from "mobx-react";
import React from "react";

import BaseForm from "@component/BaseForm";
import JobTitle from "@model/JobTitle";
import { CheckBox, DatePickerBox, SelectWithSearchBox, TextAreaBox } from "@util/Form";

export interface JobTitleFormProps {
  vm: JobTitleFormVM;
  canCreateBeforeToday?: boolean;
  isEdit?: boolean;
}

@observer
export default class JobTitleForm extends BaseForm<JobTitle, JobTitleFormProps> {
  modelName = "job_title";

  componentDidMount() {
    this.props.vm.fetchWorkingTimeRatios();
  }

  componentWillUnmount(): void {
    this.props.vm.turnOffReactions();
  }

  leftColumnFields = (): React.ReactNode => {
    return [
      this.generateFormField(
        "valid_from",
        <DatePickerBox
          dateFrom={
            !this.props.canCreateBeforeToday && !this.props.isEdit
              ? this.props.vm.minimalValidFrom
              : this.props.vm.contractsVM.currentContract?.valid_from
          }
          disabledDays={{
            before:
              !this.props.canCreateBeforeToday && !this.props.isEdit
                ? this.props.vm.minimalValidFrom
                : this.props.vm.contractsVM.currentContract?.valid_from,
          }}
        />,
        true
      ),
      this.generateFormField(
        "organization_structure_id",
        <SelectWithSearchBox options={this.props.vm.availableOrganizations} emptyRow={this.emptyRow} parseValue={parseInt} />,
        true
      ),
      this.generateFormField("primary", <CheckBox />, false),
    ];
  };

  rightColumnFields = (): React.ReactNode => {
    return [
      this.generateFormField(
        "valid_to",
        <DatePickerBox
          disabledDays={{
            before: !this.props.canCreateBeforeToday ? this.props.vm.minimalValidTo : undefined,
            after: this.props.vm.maximalValidTo,
          }}
          dateFrom={!this.props.canCreateBeforeToday ? this.props.vm.minimalValidTo : undefined}
          dateTo={this.props.vm.maximalValidTo}
        />
      ),
      this.generateFormField(
        "job_position_id",
        <SelectWithSearchBox options={this.props.vm.availablePositions} emptyRow={this.emptyRow} />,
        true
      ),
    ];
  };

  renderFormBody() {
    return (
      <>
        <div className="row">
          <div className="col-md-6 col-sm-12">{this.leftColumnFields()}</div>
          <div className="col-md-6 col-sm-12">{this.rightColumnFields()}</div>
          <div className="col-md-12">{this.generateFormField("reason", <TextAreaBox />)}</div>
        </div>
      </>
    );
  }
}
