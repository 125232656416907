import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";
import Panel from "@eman/emankit/Panel";

import Create from "@component/Create";
import Contract from "@model/Contract";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import ContractCreateVM from "@vm/Create/Contract";
import ContractsVM from "@vm/Other/Contracts";

import { lazyInject, TYPES } from "../../../inversify.config";

import Form from "./Form";
import ContractHeader from "./Header";

@observer
export default class ContractCreate extends Create<Contract, ContractCreateVM, PropsFromRouter> {
  modelName = "contract";
  prefix = "employee";

  @lazyInject(TYPES.ContractCreate)
  vm: ContractCreateVM;

  @lazyInject(TYPES.Contracts)
  contractsVM: ContractsVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
  }

  componentWillUnmount(): void {
    this.vm.turnOffReactions();
  }

  onCreateClick = async () => {
    const id = await this.vm.create();

    if (id) {
      EventBus.trigger(SHOW_TOAST, this.locs.tg("form.created"));
      this.contractsVM.setContractId(id);
    }
  };

  render() {
    return (
      <>
        <ContractHeader match={this.props.match} />
        <Panel>
          {this.user.allowToObject(UserRightsObjects.CONTRACT, UserRightsOperations.CREATE) ? (
            <>
              <HeaderRow title={this.tg("title")} level={2}>
                <Button onClick={this.onCreateClick} variant={ButtonVariant.Active}>
                  {this.locs.tg("form.create")}
                </Button>
              </HeaderRow>
              <Form entity={this.vm.entity} vm={this.vm.contractFormVM} />
            </>
          ) : (
            <HeaderRow title={this.locs.tg("employee.contract.no_contract")} level={2} />
          )}
        </Panel>
      </>
    );
  }
}
