import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import JobPosition from "@model/JobPosition";
import { CheckBox, MultiSelectBox, TextAreaBox, TextBox } from "@util/Form";
import { OptionType } from "@eman/emankit";

export interface JobPositionFormProps {
  organizationStructureOptions: OptionType<number>[];
}

@observer
export default class JobPositionForm extends BaseForm<JobPosition, JobPositionFormProps> {
  modelName = "job_position";

  render() {
    return (
      <div className="row">
        <div className="col-md-6">{this.generateFormField("name", <TextBox />, true)}</div>
        <div className="col-md-6">
          {this.generateFormField(
            "organization_structure_ids",
            <MultiSelectBox options={this.props.organizationStructureOptions} />,
            true,
            { label: this.ta("organization_structure_id"), errorProperty: "organization_structures" }
          )}
        </div>
        <div className="col-md-6">{this.generateFormField("manager", <CheckBox />)}</div>
        <div className="col-md-6">{this.generateFormField("specialist", <CheckBox />)}</div>
        <div className="col-md-12">{this.generateFormField("description", <TextAreaBox />)}</div>
      </div>
    );
  }
}
