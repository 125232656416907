import { inject, injectable } from "inversify";

import ListViewModel from "@vm/List/ListViewModel";
import Balance from "@model/Balance";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class BalanceListVM extends ListViewModel<Balance, Repository<Balance>> {
  constructor(@inject(TYPES.BalanceRepository) repository: Repository<Balance>) {
    super(repository);
  }
}
