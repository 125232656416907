import BaseModel from "@model/BaseModel";
import BaseRepository from "@repository/BaseRepository";
import ApiClient, { AxiosConfig } from "@util/ApiClient";

export default abstract class BaseRepositoryWithDownload<T extends BaseModel>
  extends BaseRepository<T>
  implements RepositoryWithDownload<T> {
  async fileDownload(id: number | string) {
    const config: AxiosConfig = {
      url: `${this.uri}/${id}/download`,
      loading: true,
      id: `DOWNLOAD_${this.modelName}`,
      responseType: "blob",
    };

    const { data, headers } = await ApiClient.fetchData(config, true);
    let fileName;
    if (headers.hasOwnProperty("content-disposition")) {
      fileName = headers["content-disposition"].split('filename="')[1].slice(0, -1);
    }

    return {
      data,
      fileName,
    };
  }
}
