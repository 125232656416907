import BaseModel from "@model/BaseModel";
import { observable } from "mobx";
import User from "@model/User";
import JobTitle from "@model/JobTitle";
import { Type } from "@eman/class-transformer";

export default class AllocationUser extends BaseModel {
  @Type(() => User)
  @observable
  user: User;

  @Type(() => JobTitle)
  @observable
  job_title: JobTitle[] = [];
}
