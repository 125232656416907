import { injectable } from "inversify";

import Export from "@model/Export";
import BaseRepositoryWithDownload from "@repository/BaseRepositoryWithDownload";

@injectable()
export default class ExportRepository extends BaseRepositoryWithDownload<Export> {
  constructor() {
    super(Export, "exports", "export");
  }
}
