import { computed, observable } from "mobx";

import BaseModel from "./BaseModel";

export enum NotificationStatus {
  IN_QUEUE = "in_queue",
  DONE = "done",
  FAILED = "failed",
}

export type NotificationType = "ExportJob" | "ExportReportJob";

export default class Notification extends BaseModel {
  // Don't override this is generated from source code
  @observable message: string;
  @observable status: NotificationStatus;
  @observable human_name: string;
  @observable type: NotificationType;
  @observable format_type?: string;

  // This is entities variables
  @observable in_progress: boolean;
  @observable errored: boolean;
  @observable closable: boolean;

  @computed get succeed(): boolean {
    return !this.in_progress && !this.errored;
  }
}
