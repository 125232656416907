import { observer } from "mobx-react";
import React from "react";

import Columns from "@eman/emankit/Columns";

import BaseForm from "@component/BaseForm";
import Dependent from "@model/Dependent";
import { CheckBox, DatePickerBox, SelectBox, TextBox } from "@util/Form";

export interface DependentFormProps {
  hasCurrentHPPContract?: boolean;
}
@observer
export default class DependentForm extends BaseForm<Dependent, DependentFormProps> {
  modelName = "dependent";

  renderFormBody() {
    return (
      <Columns>
        {this.generateFormField("name", <TextBox />, true)}
        {this.generateFormField(
          "enumeration_relationship_id",
          <SelectBox options={this.enums.valuesForSelect("relationships")} emptyRow={this.locs.tg("form.select_value")} />,
          true
        )}
        {this.generateFormField("identification", <TextBox />, this.props.hasCurrentHPPContract)}
        {this.generateFormField("birth_date", <DatePickerBox />)}
        {this.generateFormField("household", <CheckBox />)}
        {this.generateFormField("valid_from", <DatePickerBox />)}
        {this.generateFormField("valid_to", <DatePickerBox />)}
      </Columns>
    );
  }
}
