import { injectable } from "inversify";

import Vacation from "@model/Vacation";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class VacationRepository extends BaseAssociatedRepository<Vacation> {
  constructor() {
    super(Vacation, "users/$ID/vacations", "vacation");
  }
}
