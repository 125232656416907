import { injectable } from "inversify";

import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";
import Training from "@model/Training";
import ApiClient, { AxiosConfig } from "@util/ApiClient";
import EventBus, { ENTITY_UPDATE } from "@util/EventBus";

@injectable()
export default class TrainingRepository extends BaseAssociatedRepository<Training> {
  constructor() {
    super(Training, "users/$ID/trainings", "training");
  }

  confirmTraining(trainingId: number, userId: number): Promise<ApiResponse<Training>> {
    const config: AxiosConfig = {
      url: `${this.uri}/${trainingId}/confirm`,
      method: "GET",
      id: "CONFIRM_TRAINING",
    };

    return ApiClient.fetchResponse(config).then(response => {
      EventBus.trigger(ENTITY_UPDATE, {
        identificator: this.classModelName,
        id: trainingId,
      });

      return response;
    });
  }
}
