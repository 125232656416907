import BaseComponent from "@component/BaseComponent";

export default abstract class ResourceComponent<TProps = {}, TState = {}> extends BaseComponent<TProps, TState> {
  abstract get modelName(): string;

  prefix = "";

  suffix = "";

  get nameSpace(): string {
    let nameSpace = this.modelName;

    if (this.prefix) {
      nameSpace = `${this.prefix}.${this.modelName}`;
    }

    if (this.suffix) {
      nameSpace += `.${this.suffix}`;
    }

    return nameSpace;
  }

  /**
   * Translate attribute according to current model.
   *
   * @param attribute
   */
  ta = (attribute: string): string => {
    return this.locs.ta(this.modelName, attribute);
  };

  /**
   * Translate global code according to current nameSpace
   *
   * @param code
   */
  tg = (code: string, replacements?: { [key: string]: string | number }): string => {
    let text = this.locs.tg(`${this.nameSpace}.${code}`);

    if (replacements) {
      for (const key in replacements) {
        text = text.replace(`%{${key}}`, `${replacements[key]}`);
      }
    }

    return text;
  };
}
