import { observer } from "mobx-react";
import React from "react";
import Columns from "@eman/emankit/Columns";
import BaseForm from "@component/BaseForm";
import EvidenceState, { EvidenceStateStatus } from "@model/EvidenceState";
import { DatePickerBox, SelectBox, TextAreaBox } from "@util/Form";
import EnumType from "@model/EnumType";
import EvidenceStateCreateVM from "@vm/Create/EvidenceState";
import { OptionType } from "@eman/emankit";

interface EvidenceStateFormProps {
  currentStatus?: EvidenceStateStatus;
  vm: EvidenceStateCreateVM;
}

@observer
export default class EvidenceStateForm extends BaseForm<EvidenceState, EvidenceStateFormProps> {
  modelName = "evidence_state";
  prefix = "employee.contract";

  get evidenceStateOptions(): OptionType<number>[] {
    return (
      this.enums
        .values("evidence_state_reasons")
        //@ts-ignore
        .filter((reason: EnumType) => !reason.deleted && reason.before_status === this.props.currentStatus)
        .map(item => {
          const status = this.locs.tg(`${this.prefix}.${this.modelName}.status.${(item as EnumType).after_status!}`);
          const label = `${item.name} (${status})`;
          return { label, value: item.id };
        })
    );
  }

  renderFormBody() {
    return (
      <Columns>
        {this.generateFormField(
          "enumeration_evidence_state_reason_id",
          <SelectBox options={this.evidenceStateOptions} emptyRow={this.locs.tg("form.select_value")} />,
          true
        )}
        {this.generateFormField(
          "valid_from",
          <DatePickerBox dateFrom={this.props.vm.availableFrom} disabledDays={{ before: this.props.vm.availableFrom }} />,
          true
        )}

        {this.generateFormField("description", <TextAreaBox />)}

        {this.props.vm.selectedReason?.after_status === "removed" ? (
          this.generateFormField(
            "expected_to",
            <DatePickerBox
              dateFrom={this.props.vm.availableExpectedTo}
              disabledDays={{ before: this.props.vm.availableExpectedTo }}
              disabledInput={!this.props.vm.entity.valid_from}
            />
          )
        ) : (
          <div />
        )}
      </Columns>
    );
  }
}
