import BaseModel from "@model/BaseModel";
import { observable } from "mobx";

export default class WeekAmount extends BaseModel {
  @observable monday = 0;
  @observable tuesday = 0;
  @observable wednesday = 0;
  @observable thursday = 0;
  @observable friday = 0;
  @observable saturday = 0;
  @observable sunday = 0;
}
