import { Exclude, Transform, Type } from "@eman/class-transformer";
import { observable } from "mobx";

import Attachment from "@model/Attachment";
import BaseModel from "@model/BaseModel";
import Document from "@model/Document";
import User from "@model/User";
import { enumValue } from "@util/Enumerable";

export enum AnnouncementStatus {
  PROCCESSED = "processed",
  WAITING = "waiting",
  REJECTED = "rejected",
  CANCELED = "canceled",
}

export enum AnnouncementEvent {
  PROCESS = "processed",
  REJECT = "rejected",
  CANCEL = "cancel",
}

export default class Announcement extends BaseModel implements models.IWithAttachments {
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;
  @observable description: string;

  @observable user_id: number;
  @Exclude({ toPlainOnly: true })
  @Type(() => User)
  @observable
  user: User;

  @observable enumeration_announcement_type_id: number;
  @observable enumeration_announcement_status: AnnouncementStatus;

  @Exclude({ toPlainOnly: true })
  @observable
  available_events: AnnouncementEvent[] = [];

  @observable refusal_reason?: string;

  @Exclude()
  @enumValue("announcement_types", "enumeration_announcement_type_id")
  @observable
  announcement_type: models.IEnumType;

  @Exclude()
  @enumValue("announcement_statuses", "enumeration_announcement_status_id")
  @observable
  announcement_status: models.IEnumType;

  @observable documents_attributes: Attachment[] = [];

  @Exclude({ toPlainOnly: true })
  @observable
  documents: Document[] = [];
}
