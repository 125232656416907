import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import Badge from "@eman/emankit/Badge";
import Button from "@eman/emankit/Button";
import { BaseColor } from "@eman/emankit/Colors";
import { confirmYesNo } from "@eman/emankit/ConfirmAlert";
import FilterBar from "@eman/emankit/FilterBar";
import NotificationCard from "@eman/emankit/NotificationCard";
import Pagination from "@eman/emankit/Pagination";

import ResourceComponent from "@component/ResourceComponent";
import Allocation, { AllocationEvent, AllocationStatus } from "@model/Allocation";
import { StateRightsOperations, UserRightsOperations } from "@model/Rights";
import { JobTitleState } from "@model/JobTitle";
import AllocationListVM from "@vm/List/Allocation";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import ViewHelpers from "@util/ViewHelpers";

import { lazyInject } from "../../../../inversify.config";
import TYPES from "../../../../inversify.types";

@observer
export default class ManageAllocations extends ResourceComponent {
  modelName = "allocation";
  suffix = "manage";

  @lazyInject(TYPES.AllocationList)
  vm: AllocationListVM;

  componentDidMount(): void {
    const settings: ListSettings = this.user.savedSettings(this.modelName);

    const updatedOrder = { ...settings, order: { field: "created_at", direction: "desc" as const } };

    if (Object.keys(updatedOrder).length > 0) {
      this.vm.setSettings(updatedOrder);
    }
    this.vm.init();
  }

  performEvent = async (itemId: number, event: AllocationEvent) => {
    const response = await this.vm.performEvent(itemId, event);

    if (response.status) {
      EventBus.trigger(SHOW_TOAST, this.locs.tg(`${this.modelName}.status_changed`));
    }
  };

  onEventClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const itemId = parseInt(e.currentTarget.getAttribute("data-id")!, 10);
    const event = e.currentTarget.getAttribute("data-event")! as AllocationEvent;

    const confirmMessage: React.ReactNode = this.locs.tg(`${this.modelName}.event_confirm`);

    confirmYesNo(confirmMessage, () => {
      this.performEvent(itemId, event);
    });
  };

  onFilterChange = (values: FilterValues, visible: string[], reset: boolean) => {
    if (reset) {
      this.vm.setSearchValue(undefined);
    }

    this.vm.setFilters(values, visible, undefined, true, reset);
  };

  onPageChange = (page: number, pageSize: number) => {
    this.vm.setPageAndPageSize(page, pageSize);
  };

  onDetailClick = (item: Allocation) => {
    if (this.user.allowToAllocation(item, UserRightsOperations.EDIT)) {
      this.router.pageLink(this.uriHelper.edit_allocations(item.id));
    } else if (this.user.allowToAllocation(item, UserRightsOperations.SHOW)) {
      this.router.pageLink(this.uriHelper.show_allocations(item.id));
    }
  };

  actionsButtons = (item: Allocation): React.ReactNode[] => {
    const buttons = [];

    if (this.user.allowToAllocation(item, StateRightsOperations.STATUS_CHANGE)) {
      item.available_events
        .filter(event => this.user.allowToAllocation(item, StateRightsOperations.STATUS_CHANGE, event))
        // tslint:disable-next-line:no-misleading-array-reverse
        .sort((a, b) => {
          if (a === AllocationEvent.APPROVE) {
            return -1;
          } else if (b === AllocationEvent.APPROVE) {
            return 1;
          } else {
            return 0;
          }
        })
        .forEach(event => {
          buttons.push(
            <Button
              data-id={item.id}
              data-event={event}
              key={`action-${event}`}
              onClick={this.onEventClick}
              small={true}
              variant={ViewHelpers.allocationEventToButtonType(event)}
              icon={ViewHelpers.allocationEventToIcon(event)}>
              {this.locs.tg(`${this.modelName}.event.${event}`)}
            </Button>
          );
        });
    }

    if (this.user.allowToAllocation(item, UserRightsOperations.SHOW)) {
      const onClick = () => this.onDetailClick(item);

      buttons.push(
        <Button onClick={onClick} small={true}>
          {this.tg("show_more")}
        </Button>
      );
    }

    return buttons;
  };

  filters = (): FilterData[] => {
    return [
      {
        id: "author_id",
        label: this.ta("author_id"),
        values: this.vm.userOptions,
      },
      {
        id: "user_id",
        label: this.ta("user_id"),
        values: this.vm.userOptions,
      },
      {
        id: "project_id",
        label: this.ta("project_id"),
        values: this.vm.projectOptions,
      },
      {
        id: "valid_from",
        label: this.ta("valid_from"),
        type: "date_range",
      },
      {
        id: "valid_to",
        label: this.ta("valid_to"),
        type: "date_range",
      },
      {
        id: "organization_unit_id",
        label: this.locs.tm("organization_unit"),
        values: this.vm.organizationUnitOptions,
        hasChildren: true,
      },
      {
        id: "enumeration_allocation_status",
        label: this.ta("enumeration_allocation_status"),
        values: Object.values(AllocationStatus).map(status => ({
          label: this.locs.tg(`${this.modelName}.state.${status}`),
          value: status,
        })),
      },
      {
        id: "enumeration_allocation_type_id",
        label: this.ta("enumeration_allocation_type_id"),
        values: this.enums.valuesForSelect("allocation_types"),
      },
      {
        id: "job_title_state",
        label: this.locs.ta("employee", "job_title_state"),
        values: Object.values(JobTitleState).map(state => ({
          label: this.locs.tg(`employee.contract.job_title.state.${state}`),
          value: state,
        })),
      },
      {
        id: "service",
        label: this.locs.ta("allocation", "service"),
        values: ViewHelpers.booleanOptions(this.locs),
        single: true,
      },
    ];
  };

  title = (item: Allocation): React.ReactNode => {
    let badge;
    if (!item.allocation_type.is_work) {
      badge = <Badge color={BaseColor.Turquoise}>{this.tg("vacation")}</Badge>;
    }

    return (
      <>
        <strong className="mr-12">{ViewHelpers.userName(item.user)}</strong>
        {item.project && (
          <>
            <span className="mr-12" style={{ fontWeight: "normal" }}>
              {this.tg("on_project")}
            </span>
            <strong className="mr-12">{item.project.name}</strong>
          </>
        )}
        {badge}
      </>
    );
  };

  allocationValue = (item: Allocation) => {
    return (
      <>
        {ViewHelpers.allocationValue(item.value, this.locs)}{" "}
        <span>({`${this.locs.formatDate(item.valid_from)} - ${this.locs.formatDate(item.valid_to)}`})</span>
      </>
    );
  };

  renderItem = (item: Allocation) => {
    const onTitleClick = () => this.onDetailClick(item);

    const onAuthorClick = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (this.user.isAdmin || this.user.managedUserIDs.indexOf(item.author_id) !== -1) {
        this.router.pageLink(this.uriHelper.show_employees(item.author_id));
      }
    };

    return (
      <NotificationCard
        date={this.locs.formatDateTime(item.created_at)}
        author={ViewHelpers.userName(item.author)}
        title={this.title(item)}
        badge={
          <Badge color={ViewHelpers.allocationStateToColor(item.enumeration_allocation_status)}>
            {this.locs.tg(`allocation.state.${item.enumeration_allocation_status}`)}
          </Badge>
        }
        actions={this.actionsButtons(item)}
        onTitleClick={onTitleClick}
        onAuthorClick={onAuthorClick}
        authorLink={this.uriHelper.show_employees(item.author_id)}
        className={"mb-20"}
        key={`alocation-${item.id}`}>
        {this.allocationValue(item)}
      </NotificationCard>
    );
  };

  render() {
    return (
      <>
        <FilterBar
          localization={this.locs.filters}
          onFilterChange={this.onFilterChange}
          filters={this.filters()}
          selected={toJS(this.vm.selectedItems)}
          visible={this.vm.visibleFilters}
        />
        {this.vm.list.map(item => this.renderItem(item))}
        <Pagination
          totalRecords={this.vm.total}
          pageSize={this.vm.pagination.pageSize}
          page={this.vm.pagination.page}
          onPageChange={this.onPageChange}
        />
      </>
    );
  }
}
