import { inject, injectable } from "inversify";

import Certification from "@model/Certification";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class CertificationShowVM extends AssociatedShowViewModel<Certification, AssociatedRepository<Certification>> {
  constructor(@inject(TYPES.CertificationRepository) repository: AssociatedRepository<Certification>) {
    super(Certification, repository);
  }
}
