import BaseModel from "@model/BaseModel";
import BaseRepository from "@repository/BaseRepository";
import { unmanaged } from "inversify";

export default abstract class BaseAssociatedRepository<T extends BaseModel>
  extends BaseRepository<T>
  implements AssociatedRepository<T> {
  id: string | number;

  uriBackup: string;

  constructor(@unmanaged() model: new () => T, @unmanaged() protected uri: string, @unmanaged() protected modelName: string) {
    super(model, uri, modelName);
    this.uriBackup = uri;
  }

  setId(id: string | number, ...rest: any) {
    this.id = id;
    this.uri = this.uriBackup.replace("$ID", id.toString());
  }
}
