import { observer } from "mobx-react";
import React from "react";

import Badge from "@eman/emankit/Badge";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import { BaseColor } from "@eman/emankit/Colors/";

import BaseList from "@component/BaseList";
import Contact, { CONTACT_TYPES } from "@model/Contact";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import ViewHelpers from "@util/ViewHelpers";
import ContactListVM from "@vm/List/Contact";
import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class ContactList extends BaseList<Contact, ContactListVM, PropsFromRouter> {
  modelName = "contact";
  prefix = "employee.contact_information";
  searchable = false;
  persistQueryString = false;

  @lazyInject(TYPES.ContactList)
  vm: ContactListVM;

  initVM() {
    this.vm.init(this.props.match.params.user_id);
  }

  renderBadge = (value: boolean) => {
    return (
      <Badge color={value ? BaseColor.LightBlue : BaseColor.Turquoise}>
        {this.tg(`contact_type.${value ? "work" : "personal"}`)}
      </Badge>
    );
  };

  renderRow = (item: Contact, column: string): React.ReactNode => {
    switch (column) {
      case "contact_type":
        return item.contact_type ? CONTACT_TYPES[item.contact_type] : "";
      case "work":
        return this.renderBadge(item.work);
      case "public":
        return ViewHelpers.booleanStringify(this.locs, item.public);
      case "valid_from":
        return this.locs.formatDate(item.valid_from);
      case "valid_to":
        return this.locs.formatDate(item.valid_to);
      default:
        return item[column];
    }
  };

  handleOpenCreate = () => {
    this.router.pageLink(this.uriHelper.new_employees_contact_informations_contacts(this.props.match.params.user_id));
  };

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "contact_type",
        label: this.ta("contact_type"),
        values: Object.keys(CONTACT_TYPES).map(item => ({ value: item, label: CONTACT_TYPES[item] })),
      },
      {
        id: "value",
        label: this.ta("value"),
        type: "search",
      },
      {
        id: "work",
        label: this.ta("work"),
        values: [
          {
            value: "true",
            label: this.tg("contact_type.work"),
          },
          {
            value: "false",
            label: this.tg("contact_type.personal"),
          },
        ],
      },
    ];

    return {
      filters,
    };
  }

  headerProps() {
    return {
      text: this.tg("title"),
      level: 2,
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.CONTACT, UserRightsOperations.CREATE)) {
          return (
            <Button variant={ButtonVariant.Primary} key="new_position" onClick={this.handleOpenCreate}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  dataProps() {
    const columns = [
      this.createField("contact_type", { width: 150, nosort: true }),
      this.createField("value", { width: 250 }),
      this.createField("work", { width: 100, nosort: true }),
      this.createField("public", { width: 100 }),
      this.createField("valid_from", { width: 150 }),
      this.createField("valid_to", { width: 150 }),
      this.createField("description", {}),
    ];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: Contact) => {
        this.objectEditOrShowLink("employees_contact_informations_contacts", [this.props.match.params.user_id, item.id]);
      },
    };
  }
}
