import { injectable, unmanaged } from "inversify";
import { action, computed } from "mobx";

import BaseModel from "@model/BaseModel";
import ListViewModel from "@vm/List/ListViewModel";

/**
 * Abstract parent of all edit list view models.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
@injectable()
export default abstract class EditListViewModel<TModel extends BaseModel, TRepository extends Repository<TModel>>
  extends ListViewModel<TModel, TRepository>
  implements ViewModel.EditList<TModel> {
  model: new () => TModel;

  constructor(@unmanaged() model: new () => TModel, @unmanaged() repository: TRepository) {
    super(repository);
    this.model = model;
  }

  /**
   * In EditListVM we dont want to do anything on change callback
   */
  entityCallback = (params: any) => {
    // do nothing
  };

  @action
  add(entity?: TModel) {
    if (!entity) {
      this.list.push(new this.model());
    } else {
      this.list.push(entity);
    }
    this.total++;
  }

  @action
  remove(model: TModel): void {
    model.markedForDestroy = true;
  }

  @action.bound
  async saveOrCreateItems(): Promise<Array<ApiResponse<TModel>>> {
    this.currentlyFetching = true;
    const promises: any = this.list.map(item => {
      if (item.saved && item.id && item.markedForDestroy) {
        return this.repository.destroy(item.id);
      } else if (!item.saved && !item.markedForDestroy) {
        return this.repository.create(item);
      } else if (item.saved && !item.markedForDestroy && item.changes) {
        return this.repository.update(item.id!, item);
      } else {
        return Promise.resolve({
          status: true,
          original: {},
        });
      }
    });

    const response: Array<ApiResponse<TModel>> = await Promise.all(promises);
    const deletedItems: any[] = [];

    response.forEach((result, index) => {
      const status: boolean = result.status;

      if (status) {
        // Item was created
        if (!this.list[index].saved) {
          this.list[index].saved = status;

          // Item was deleted
        } else if (this.list[index].markedForDestroy) {
          deletedItems.push(this.list[index]);

          // Item was updated
        } else {
          this.list[index].clearErrors();
        }
      } else {
        const errors = result.errors || { base: ["Unknown error"] };
        this.list[index].setErrors(errors);
      }
    });

    deletedItems.forEach(item => {
      // @ts-ignore
      this.list.remove(item);
    });

    this.currentlyFetching = false;

    return response;
  }

  @computed
  get changes(): number {
    let itemsChanged = 0;
    this.list.forEach((item: TModel) => {
      if (item.changes) {
        itemsChanged++;
      }
    });

    return itemsChanged;
  }

  @computed
  get errorCount(): number {
    let errorsCount = 0;

    this.list.forEach((item: TModel) => {
      if (item.errorCount) {
        errorsCount++;
      }
    });

    return errorsCount;
  }

  @action
  setListAndTotal(list: TModel[], total: number) {
    super.setListAndTotal(
      list.map((item: TModel) => {
        item.saved = true;
        return item;
      }),
      total
    );
  }
}
