import React from "react";

import ResourceComponent from "@component/ResourceComponent";

// Just pass props to component
// Implement basic list methods
export default abstract class Show<
  TModel extends models.IBase,
  TViewModel extends ViewModel.Show<TModel>,
  OtherProps = {},
  OtherState = {}
> extends ResourceComponent<PropsFromRouter & OtherProps, OtherState> {
  abstract vm: TViewModel;

  constructor(props: PropsFromRouter & OtherProps) {
    super(props);
    this.setIdFromParams();
  }

  componentDidMount() {
    this.setIdFromParams();
  }

  /**
   * Method get ID from params and update view model context, this is because you can "click" in notifications area
   * to link to edit on page where you are... If you want to load additional data use ViewModel or override this
   * in subclasses.
   */
  setIdFromParams() {
    const params = this.props.match.params;
    // tslint:disable-next-line: no-string-literal
    const key = `${this.vm.repository["modelName"]}_id`; // TODO: This hack I personaly dont like it... but it will works for sub-show / list vm either..
    this.vm.init(params[key]);
  }

  componentWillUnmount() {
    this.vm.cleanUp();
  }

  // Specify in view models which details to show using label and key
  details(): any {
    return [];
  }

  render(): React.ReactNode {
    const { entity } = this.vm;
    return (
      <React.Fragment>
        <h1>{`Detail for ${this.modelName} ${entity.id}`}</h1>
        {this.details().map((detail: any) => (
          <div key={detail.key}>
            <strong>{detail.label}: </strong>
            {entity[detail.key]}
          </div>
        ))}
      </React.Fragment>
    );
  }
}
