import { inject, injectable } from "inversify";

import Role from "@model/Role";
import CreateViewModel from "@vm/Create/CreateViewModel";
import RoleFormVM from "@vm/Form/Role";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class RoleCreateVM extends CreateViewModel<Role, Repository<Role>> {
  constructor(@inject(TYPES.RoleRepository) repository: Repository<Role>, @inject(TYPES.RoleForm) public roleFormVM: RoleFormVM) {
    super(Role, repository);
  }

  setEntity(entity: Role) {
    super.setEntity(entity);
    this.roleFormVM.setEntity(entity);
  }

  resetEntityAndErrors() {
    super.resetEntityAndErrors();
    this.roleFormVM.setEntity(this.entity);
  }
}

// This is dependent on name field in DB
export enum UserRoles {
  ADMIN = "Administrátor",
  EMPLOYEE = "Zaměstnanec",
  PERSONAL_SPECIALIST = "Personální specialista",
  ALLOCATION_MANAGER = "Manažer alokací",
  TEAM_LEAD = "Team Leader",
  PROJECT_MANAGER = "Projektový manažer",
}
