import subYears from "date-fns/subYears";
import { computed } from "mobx";
import { inject, injectable } from "inversify";
import PreviousEmployment from "@model/PreviousEmployment";
import UserShowVM from "@vm/Show/User";
import FormViewModel from "@vm/Form/FormViewModel";

import TYPES from "../../inversify.types";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PreviousEmploymentFormVM extends FormViewModel<PreviousEmployment> {
  constructor(@inject(TYPES.UserShow) private userShowVM: UserShowVM) {
    super();
  }

  @computed
  get fromDate() {
    return this.userShowVM.entity?.personal_detail?.birth_date || subYears(new Date(), 100);
  }
}
