import { observable } from "mobx";
import BaseModel from "@model/BaseModel";

export default class PerformanceManagement extends BaseModel {
  @observable user_id: string;
  @observable evaluation_date: Date;
  @observable data_values: string[];
  // placeholders
  // FYI, I'm fully aware that this is completely insane
  @observable answer0: string;
  @observable answer1: string;
  @observable answer2: string;
  @observable answer3: string;
  @observable answer4: string;
  @observable answer5: string;
  @observable answer6: string;
  @observable answer7: string;
  @observable answer8: string;
  @observable answer9: string;
  @observable answer10: string;
  @observable answer11: string;
  @observable answer12: string;
  @observable answer13: string;
  @observable answer14: string;
  @observable answer15: string;
  @observable answer16: string;
  @observable answer17: string;
  @observable answer18: string;
  @observable answer19: string;
  @observable answer20: string;
  @observable answer21: string;
  @observable answer22: string;
  @observable answer23: string;
  @observable answer24: string;
  @observable answer25: string;
  @observable answer26: string;
  @observable answer27: string;
  @observable answer28: string;
  @observable answer29: string;
  @observable answer30: string;
  @observable answer31: string;
  @observable answer32: string;
  @observable answer33: string;
  @observable answer34: string;
  @observable answer35: string;
  @observable answer36: string;
  @observable answer37: string;
  @observable answer38: string;
  @observable answer39: string;
  @observable answer40: string;
  @observable answer41: string;
  @observable answer42: string;
  @observable answer43: string;
  @observable answer44: string;
  @observable answer45: string;
  @observable answer46: string;
  @observable answer47: string;
  @observable answer48: string;
  @observable answer49: string;
}
