import { BalanceGroupWithDuration } from "@util/Calendar";
import AllocationTableItem from "../Item";
import { YearBalance } from "./year";

interface Props {
  balance: BalanceGroupWithDuration | YearBalance;
}

const BalanceRowItem = ({ balance }: Props) => {
  let className = "";
  let text = "";

  if (balance.is_work) {
    if (balance.value === 0) {
      className = "full";
      text = "Full";
    }

    if (balance.zero_sum_value_ok === false) {
      className = "wrong-full";
    }
    if (balance.value > 0) {
      className = "free";
      text = `${-1 * balance.value}`;
    }

    if (balance.value < 0) {
      className = "over";
      text = `+${-1 * balance.value}`;
    }
  } else if (balance.is_work === false) {
    className = "off";
    text = "Off";
  } else if (balance.is_work === null && balance.value > 0) {
    className = "free";
    text = `${-1 * balance.value}`;
  } else {
    className = "disabled";
    text = "";
  }

  // Edge case overalocated
  if (balance.is_work === false && balance.value < 0) {
    className = "over";
    text = `+${-1 * balance.value}`;
  }

  return (
    <AllocationTableItem className={`balance-item ${className}`}>
      <span className="text">{text}</span>
    </AllocationTableItem>
  );
};

export default BalanceRowItem;
