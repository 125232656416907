import OrganizationUnit from "@model/OrganizationUnit";
import CreateViewModel from "@vm/Create/CreateViewModel";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class OrganizationUnitCreateVM extends CreateViewModel<OrganizationUnit, Repository<OrganizationUnit>> {
  constructor(@inject(TYPES.OrganizationUnitRepository) repository: Repository<OrganizationUnit>) {
    super(OrganizationUnit, repository);
  }
}
