import EventBus, { SCROLL_TO_FIRST_ERROR } from "@util/EventBus";
import { action } from "mobx";
import ShowViewModel from "../Show/ShowViewModel";

/**
 * Edit view model.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
export default abstract class EditViewModel<TModel extends models.IBase, TRepository extends Repository<TModel>>
  extends ShowViewModel<TModel, TRepository>
  implements ViewModel.Edit<TModel> {
  /**
   * Update entity and send it to repository.
   *
   * @returns {Promise<boolean>}
   * @memberof EditViewModel
   */
  async update(): Promise<boolean> {
    this.currentlyFetching = true;
    const response = await this.repository.update(this.entity.id!, this.entity);

    // On 422 unprocessable entity server returns an object of errors, otherwise (different kind of errors)
    // response.error is string
    if (!response.status && response.errors && response.errors.constructor === Object) {
      this.entity.setErrors(response.errors);
      EventBus.trigger(SCROLL_TO_FIRST_ERROR);
    }

    if (response.status) {
      this.fetchItem(false);
    }

    this.currentlyFetching = false;

    return response.status;
  }

  async create(): Promise<boolean> {
    this.currentlyFetching = true;
    const response = await this.repository.create(this.entity);

    // On 422 unprocessable entity server returns an object of errors, otherwise (different kind of errors)
    // response.error is string
    if (!response.status && response.errors && response.errors.constructor === Object) {
      this.entity.setErrors(response.errors);
      EventBus.trigger(SCROLL_TO_FIRST_ERROR);
    }

    if (response.status) {
      this.fetchItem(false);
    }

    this.currentlyFetching = false;

    return response.status;
  }

  @action
  resetEntityAndErrors() {
    // TODO why not here setEntity?
    this.entity = new this.model();
  }
}
