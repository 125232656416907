import { observer } from "mobx-react";
import React from "react";

import BaseList from "@component/BaseList";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import PerformanceManagement from "@model/PerformanceManagement";
import PerformanceManagementListVM from "@vm/List/PerformanceManagement";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class PerformanceManagementList extends BaseList<
  PerformanceManagement,
  PerformanceManagementListVM,
  PropsFromRouter
> {
  modelName = "performance_management";
  prefix = "employee";
  searchable = false;
  savedSettingsName = "user_performance_managements";

  @lazyInject(TYPES.PerformanceManagementList)
  vm: PerformanceManagementListVM;

  initVM() {
    this.vm.init(this.props.match.params.user_id);
  }

  renderRow = (item: PerformanceManagement, column: string): React.ReactNode => {
    if (column === "evaluation_date") {
      return this.locs.formatDate(item.evaluation_date);
    }

    if (column.includes("answer")) {
      const index = column.replace(/^\D+/g, "");
      return item.data_values[index];
    }

    return item[column];
  };

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_employees_performance_managements(this.props.match.params.user_id));
  };

  headerProps() {
    return {
      text: this.tg("title"),
      level: 2,
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.PERFORMANCE_MANAGEMENT, UserRightsOperations.CREATE)) {
          return (
            <Button variant={ButtonVariant.Primary} key="new_performance_management" onClick={this.onAddClick}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "evaluation_date",
        label: this.ta("evaluation_date"),
        type: "date_range",
      },
    ];

    return {
      filters,
      hideReset: false,
      hideFilterSettings: false,
      hideColumnSettings: false,
    };
  }

  dataProps() {
    const columns = [this.createField("id", {}), this.createField("evaluation_date", { width: 100 })];

    this.enums.values("performance_management_questions").forEach((question, index) => {
      columns.push(this.createField(`answer${index}`, { label: question.name }));
    });

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: PerformanceManagement) => {
        this.objectEditOrShowLink("employees_performance_managements", [this.props.match.params.user_id, item.id]);
      },
    };
  }
}
