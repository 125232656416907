import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import Capacity from "@model/Capacity";
import CapacityShowVM from "@vm/Show/Capacity";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class CapacityShowModal extends ShowModal<Capacity> {
  modelName = "capacity";
  prefix = "employee";

  @lazyInject(TYPES.CapacityShow)
  vm: CapacityShowVM;

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.show_employees_contracts(params.user_id, params.contract_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} vm={this.vm.capacityFormVM} readonly={true} />;
  }
}
