import { observer } from "mobx-react";

import AutoCreateInput, { AutoCreateOption } from "@eman/emankit/AutoCreateInput";
import { InputVariant } from "@eman/emankit/Input";
import BindingElement from "./index";
import { checkGroup, SelectBoxProps } from "./SelectBox";
import { OptionType, GroupedOptionsType } from "@eman/emankit";

@observer
export default class AutoCreateBox<
  K extends number | string = number,
  TOptionType extends OptionType<K> = OptionType<K>
> extends BindingElement<SelectBoxProps<K, TOptionType>, any> {
  protected handleValueChanged = (option: AutoCreateOption) => {
    this.setValue(option.value);
  };

  protected handleCreateOption = async (newOption: AutoCreateOption): Promise<void> => {
    if (this.props.onCreateOption) {
      return this.props.onCreateOption(newOption.label);
    }
    return Promise.reject();
  };

  protected get preparedOptions() {
    const items: AutoCreateOption[] = [];
    const { options } = this.props;

    if (options) {
      options.forEach(option => {
        if (checkGroup(option)) {
          const opt = option as GroupedOptionsType<K, TOptionType>;

          // @TODO Add real support for groups at SelectWithSearch component
          opt.options.forEach(item => {
            items.push({
              value: item.value,
              label: String(item.label),
            });
          });
        } else {
          const opt = (option as unknown) as TOptionType;

          items.push({
            value: opt.value,
            label: String(opt.label),
          });
        }
      });
    }

    return items;
  }

  protected get selectedValue() {
    return this.preparedOptions.find(item => item.value === this.value);
  }

  // tslint:disable-next-line:member-ordering
  render() {
    const { variant, createCardConfig } = this.props;

    return (
      <AutoCreateInput
        {...this.props}
        variant={variant || InputVariant.Grey}
        options={this.preparedOptions}
        onChange={this.handleValueChanged}
        onCreate={this.handleCreateOption}
        createCardConfig={createCardConfig || {}}
        value={this.selectedValue}
      />
    );
  }
}
