import { inject, injectable } from "inversify";

import PerformanceManagement from "@model/PerformanceManagement";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PerformanceManagementListVM extends AssociatedListViewModel<
  PerformanceManagement,
  AssociatedRepository<PerformanceManagement>
> {
  static defaults = {
    order: {
      field: "created_at",
      direction: "desc",
    },
    columns: [
      "id",
      "evaluation_date",
      "answer0",
      "answer1",
      "answer2",
      "answer3",
      "answer4",
      "answer5",
      "answer6",
      "answer7",
      "answer8",
      "answer9",
      "answer10",
      "answer11",
      "answer12",
      "answer13",
      "answer14",
      "answer15",
      "answer16",
      "answer17",
      "answer18",
      "answer19",
      "answer20",
      "answer21",
      "answer22",
      "answer23",
      "answer24",
      "answer25",
      "answer26",
      "answer27",
      "answer28",
      "answer29",
      "answer30",
      "answer31",
      "answer32",
      "answer33",
      "answer34",
      "answer35",
      "answer36",
      "answer37",
      "answer38",
      "answer39",
      "answer40",
      "answer41",
      "answer42",
      "answer43",
      "answer44",
      "answer45",
      "answer46",
      "answer47",
      "answer48",
      "answer49",
    ],
    visibleFilters: ["evaluation_date"],
  };

  constructor(@inject(TYPES.PerformanceManagementRepository) repository: AssociatedRepository<PerformanceManagement>) {
    super(repository);
  }
}
