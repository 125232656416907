import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";

import BaseComponent from "@component/BaseComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import UserEditVM from "@vm/Edit/User";
import PersonalDocumentsList from "./List";

export interface PersonalDocumentsProps extends PropsFromRouter {
  vm: UserEditVM;
}

@observer
export default class PersonalDocuments extends BaseComponent<PersonalDocumentsProps> {
  tg = (code: string) => {
    return this.locs.tg(`employee.personal_information.personal_document.${code}`);
  };

  onAddClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.router.pageLink(this.uriHelper.new_employees_personal_data_personal_documents(this.props.vm.id));
  };

  render() {
    let button;
    if (this.user.allowToObject(UserRightsObjects.PERSONAL_DOCUMENT, UserRightsOperations.CREATE)) {
      button = (
        <Button variant={ButtonVariant.Active} onClick={this.onAddClick}>
          {this.tg("add")}
        </Button>
      );
    }

    return (
      <>
        <HeaderRow title={this.tg("title")} level={2}>
          {button}
        </HeaderRow>
        <PersonalDocumentsList userId={this.props.match.params.user_id} />
      </>
    );
  }
}
