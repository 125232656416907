import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import PreviousEmployment from "@model/PreviousEmployment";
import PreviousEmploymentEditVM from "@vm/Edit/PreviousEmployment";

import { lazyInject, TYPES } from "../../../../inversify.config";

import Form from "./Form";

@observer
export default class PreviousEmploymentEditModal extends EditModal<PreviousEmployment> {
  modelName = "previous_employment";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.PreviousEmploymentEdit)
  vm: PreviousEmploymentEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <div style={{ minHeight: 370 }}>
        <Form entity={this.vm.entity} vm={this.vm.previousEmploymentFormVM} />
      </div>
    );
  }
}
