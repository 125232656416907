import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
import { action, computed, observable } from "mobx";
import AllocationUserItemsVM from "@vm/Items/AllocationUser";
import Localization from "@service/Localization";
import Project from "@model/Project";
import startOfMonth from "date-fns/startOfMonth";
import {
  addInterval,
  AllocationInterval,
  createSubGroupData,
  FirstDayOfWeek,
  JobData,
  normalizeBalanceGroups,
  prepareMainGroupData,
  SubGroupData,
} from "@util/Calendar";
import CurrentUser from "@service/CurrentUser";
import BalanceGroupRepository from "@repository/BalanceGroup";
import BalanceGroup from "@model/BalanceGroup";
import without from "lodash/without";
import AllocationItemRepository from "@repository/AllocationItem";
import AllocationJobTitleRepository from "@repository/AllocationJobTitle";
import flatten from "lodash/flatten";
import ProjectRepository from "@repository/Project";
import { EmployeeAllocationsOrder } from "@view/Allocation/Tabs/EmployeeCalendar/employeeSort";
import { splitFilters, SplitFilters } from "@util/Calendar";
import BaseModel from "@model/BaseModel";
import AllocationItem from "@model/AllocationItem";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import { UserRightsOperations } from "@model/Rights";
import Router from "@service/Router";
import EnumVM from "@service/Enum";
import Websocket from "@service/Websocket";
import { Subscription } from "@rails/actioncable";
import { AllocationRecalculate, AllocationStatus } from "@model/Allocation";
import startOfDay from "date-fns/startOfDay";
import { endOfDay } from "date-fns";

export interface UpdatingData extends ReceivedData {
  from: Date;
  to: Date;
}

export interface ReceivedData {
  allocation_ids: number[];
  allocation_item_ids: number[];
  allocation_item_group_ids: number[];
  removed_allocation_item_ids: number[];
  removed_allocation_item_group_ids: number[];
  recalculate: AllocationRecalculate;
  user_ids: number[];
  valid_from: string;
  valid_to: string;
}

@injectable()
export default abstract class AllocationsVM {
  @observable interval: AllocationInterval = AllocationInterval.MONTH;

  protected settingsNamespace = "allocation_user";

  @observable startDate: Date;
  @observable endDate: Date;

  protected subscription?: Subscription;

  @observable settings: ItemsSettings;
  @observable protected filters: SplitFilters;
  @observable mainBalanceGroups: BalanceGroup[] = [];
  @observable openGroups: number[] = [];
  @observable openSubGroups: number[] = [];
  @observable groupCache: Record<number, SubGroupData> = {};
  @observable projects: Project[] = [];

  @observable selectedOrderSetting: EmployeeAllocationsOrder = EmployeeAllocationsOrder.USER_LAST_NAME_ASC;

  @inject(TYPES.Localization)
  protected locs: Localization;

  @inject(TYPES.Router)
  protected router: Router;

  @inject(TYPES.UriHelper)
  protected uriHelper: any;

  @inject(TYPES.Enum)
  protected enums: EnumVM;

  @inject(TYPES.Websocket)
  protected webSocket: Websocket;

  constructor(
    @inject(TYPES.User) protected user: CurrentUser,
    @inject(TYPES.AllocationItemRepository) private allocationItemRepository: AllocationItemRepository,
    @inject(TYPES.AllocationJobTitleRepository) private allocationJobTitleRepository: AllocationJobTitleRepository,
    @inject(TYPES.BalanceGroupRepository) private balanceGroupRepository: BalanceGroupRepository,
    @inject(TYPES.ProjectRepository) protected projectRepository: ProjectRepository,
    @inject(TYPES.AllocationUserItems) protected allocationUserItemsVM: AllocationUserItemsVM
  ) {
    this.startDate = this.defaultStartDate;
    this.endDate = addInterval(this.startDate, this.interval);

    this.settings = user.savedSettings(this.settingsNamespace);

    this.filters = splitFilters(this.settings.filters);

    this.allocationUserItemsVM.loading = false;
  }

  @computed
  get firstDayOfWeek(): FirstDayOfWeek {
    return (parseInt(this.locs.tg("uikit.date_picker.strings.firstDayOfWeek"), 10) || 0) as FirstDayOfWeek;
  }

  @computed
  get defaultStartDate(): Date {
    return startOfMonth(new Date());
  }

  async init(): Promise<void> {
    //override in child classes
  }

  setFilters = (filters: FilterValues, visibleFilters: string[]) => {
    this.settings = { ...this.settings, filters, visibleFilters };
    this.filters = splitFilters(filters);
  };

  toggleGroup = async ({ id, open }: { id: number; open: boolean }) => {
    if (!open) {
      await this.getGroupData(id);
      this.openGroups = [...this.openGroups, id];
    } else {
      this.openGroups = without(this.openGroups, id);
    }
  };

  toggleSubGroup = async ({ id }: JobData) => {
    if (!this.openSubGroups.includes(id)) {
      this.openSubGroups = [...this.openSubGroups, id];
    } else {
      this.openSubGroups = without(this.openSubGroups, id);
    }
  };

  editAllocation = (item: AllocationItem) => {
    const allocation = item.allocation;

    if (allocation.recalculate === "waiting" || allocation.recalculate === "in_progress") {
      EventBus.trigger(SHOW_TOAST, this.locs.tg("allocation.calendar.allocation_is_recalculating"));
    } else if (this.user.allowToAllocation(allocation, UserRightsOperations.EDIT)) {
      this.router.pageLink(this.uriHelper.edit_allocations(allocation.id));
    } else if (this.user.allowToAllocation(allocation, UserRightsOperations.SHOW)) {
      this.router.pageLink(this.uriHelper.show_allocations(allocation.id));
    }
  };

  subscribe() {
    if (!this.subscription) {
      this.subscription = this.webSocket.subscribe("AllocationChannel", this.onDataReceived);
    }
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  @action.bound
  onDataReceived(data: ReceivedData): void {
    const dataFrom = startOfDay(BaseModel.parseDate(data.valid_from)!);
    const dataTo = endOfDay(BaseModel.parseDate(data.valid_to)!);

    // Recognize if changed data is actually displayed
    if (!(this.startDate > dataTo || this.endDate < dataFrom)) {
      this.onDataUpdation({
        ...data,
        from: dataFrom,
        to: dataTo,
      });
    }
  }

  abstract onDataUpdation(data: UpdatingData): void;

  @computed
  protected get userIds(): number[] {
    return this.allocationUserItemsVM.list.map(({ user }) => user.id!);
  }

  private fetchProjects = async () => {
    if (this.projects.length === 0) {
      this.projects = (await this.projectRepository.fetchItems({})).items;
    }
  };

  @action
  clearGroups = () => {
    this.openGroups = [];
    this.openSubGroups = [];
  };

  fetchData = async () => {
    await this.fetchProjects();

    this.mainBalanceGroups = await this.fetchMainBalanceGroups().then(items =>
      normalizeBalanceGroups(this.startDate, this.endDate, items.items)
    );

    const groupCache = {};
    if (this.openGroups.length > 0) {
      for (const id of Object.keys(this.groupCache)) {
        const userId = parseInt(id);
        if (this.openGroups.includes(userId)) {
          const data = await this.fetchGroupData(userId);
          groupCache[id] = data;
        }
      }
    }
    this.groupCache = groupCache;
  };

  public getDateFilters = (fieldName = "valid_interval"): FilterValues => {
    return {
      [fieldName]: {
        operator: "between",
        values: [BaseModel.formatDate(this.startDate), BaseModel.formatDate(this.endDate)],
      },
    };
  };

  private getUserIdsFilters = (userIds: number[] = this.userIds, fieldName = "user_id"): FilterValues => {
    return {
      [fieldName]: {
        values: userIds,
        operator: "in",
      },
    };
  };

  private getCapacityIdsFilters = (capacityIds: number[]): FilterValues => {
    return {
      capacity_id: {
        values: capacityIds,
        operator: "in",
      },
    };
  };

  public getAllocationStatus = (AllocationStatuses: AllocationStatus[]): FilterValues => {
    return {
      allocation_enumeration_allocation_status: {
        values: AllocationStatuses,
        operator: "in",
      },
    };
  };

  protected fetchMainBalanceGroups = async () => {
    const userIds = this.userIds;

    let filters: FilterValues = {
      main: {
        operator: "in",
        values: [true],
      },
      ...this.getDateFilters("valid_interval"),
    };

    if (this.settings.filters?.user_last_name || this.settings.filters?.user_first_name) {
      filters = { ...filters, ...this.getUserIdsFilters(userIds, "user_id") };
    }

    return this.balanceGroupRepository.fetchItems({
      filters,
    });
  };

  private getGroupData = async (id: number): Promise<SubGroupData> => {
    if (!this.groupCache[id]) {
      const data = await this.fetchGroupData(id);
      this.groupCache[id] = data;
    }

    return this.groupCache[id];
  };

  private fetchGroupData = async (id: number): Promise<SubGroupData> => {
    const jobTitles = await this.allocationJobTitleRepository.fetchItems({
      filters: {
        ...this.getUserIdsFilters([id]),
        ...this.getDateFilters("valid_interval"),
      },
    });

    const capacityIds = flatten(jobTitles.items.map(jobTitle => jobTitle.capacities.map(capacity => capacity.id!)));
    const balanceGroups = await this.balanceGroupRepository
      .fetchItems({
        filters: {
          ...this.getDateFilters(),
          ...this.getCapacityIdsFilters(capacityIds),
        },
      })
      .then(items => normalizeBalanceGroups(this.startDate, this.endDate, items.items));

    const alocationItemsFilters = {
      ...this.filters.allocation,
      ...this.getDateFilters("date"),
      ...this.getUserIdsFilters([id], "allocation_user_id"),
      ...this.getDateFilters("allocation_valid_interval"),
    };

    if (this.settings.filters) {
      // Delete project filters only allocation item when its project filtering on
      if (this.settings.filters.allocation_project_id?.values) {
        delete alocationItemsFilters.allocation_project_id;
      }
    }

    const allocationItems = await this.allocationItemRepository.fetchItems({ filters: alocationItemsFilters });

    return createSubGroupData(
      id,
      jobTitles.items,
      this.projects,
      balanceGroups,
      allocationItems.items,
      this.enums.values("allocation_types")
    );
  };

  //calendar values
  @computed
  get groups() {
    return prepareMainGroupData(this.allocationUserItemsVM.list, this.mainBalanceGroups, this.openGroups);
  }

  getGroups = () => {
    return this.groups;
  };

  @computed
  get subgroupsData() {
    return this.groupCache;
  }
}
