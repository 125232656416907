import { inject, injectable } from "inversify";
import { action, computed, observable, when } from "mobx";

import Contract from "@model/Contract";
import Enum from "@service/Enum";
import Router from "@service/Router";
import ContractListVM from "@vm/List/Contract";
import UserShowVM from "@vm/Show/User";
import TYPES from "../../inversify.types";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

/**
 * This VM handle contract selection
 */
@injectable()
export default class ContractsVM {
  @observable contractListVM: ContractListVM;
  @observable contractId?: number;
  @observable userId?: number;

  constructor(
    @inject(TYPES.ContractList) contractListVM: ContractListVM,
    @inject(TYPES.UserShow) private userShowVM: UserShowVM,
    @inject(TYPES.Router) private router: Router,
    @inject(TYPES.Enum) private enums: Enum,
    @inject(TYPES.UriHelper) private uriHelper: any
  ) {
    this.contractListVM = contractListVM;
    this.contractListVM.setSettings({
      order: { direction: "desc", field: "valid_from" },
      pagination: { pageSize: 1000, page: 0 },
      save: false,
    });
  }

  @action
  init(userId: number) {
    if (userId !== this.userId) {
      this.userId = userId;
      this.contractListVM.init(userId);
      this.userShowVM.init(userId);
    }
  }

  clean() {
    this.contractId = undefined;
    this.userId = undefined;
  }

  /**
   * Select main contract or redirect to new
   */
  @action
  async contractAutoSelect() {
    if (!this.userId) {
      throw new Error("You have to set user ID first");
    }

    await when(() => !this.userShowVM.currentlyFetching);
    const mainContract = this.userShowVM.entity.main_contract;
    this.contractId = mainContract ? mainContract.id : undefined;

    if (this.contractId) {
      this.router.pageLink(this.uriHelper.show_employees_contracts(this.userId, this.contractId), undefined, true);
    } else {
      this.router.pageLink(this.uriHelper.new_employees_contracts(this.userId), undefined, true);
    }
  }

  @action
  setContractId(id?: number, withRedirect = true) {
    if (id !== this.contractId) {
      this.contractId = id ? +id : undefined;

      if (withRedirect) {
        if (this.contractId) {
          this.router.pageLink(this.uriHelper.show_employees_contracts(this.userId, this.contractId));
        } else {
          this.router.pageLink(this.uriHelper.new_employees_contracts(this.userId));
        }
      }
    }
  }

  @computed
  get contracts(): Contract[] {
    return this.contractListVM.list;
  }

  @computed
  get hasAnyContract(): boolean {
    return this.contractListVM.list.length !== 0;
  }

  @computed
  get currentContract(): Contract | undefined {
    const contract =
      this.contractListVM.list.find((item: Contract) => item.id === this.contractId) || this.userShowVM.entity.main_contract;

    if (contract) {
      this.enums.assignObjectEnum(contract);
    }

    return contract;
  }
}
