import { observer } from "mobx-react";
import React from "react";

import Badge from "@eman/emankit/Badge";

import Avatar from "@component/Avatar";
import ResourceComponent from "@component/ResourceComponent";
import AllocationHistory, { AllocationHistoryDetail } from "@model/AllocationHistory";
import ViewHelpers from "@util/ViewHelpers";
import AllocationFormVM from "@vm/Form/Allocation";

export interface LogRowProps {
  entity: AllocationHistory;
  vm: AllocationFormVM;
}

export interface AllocationChange {
  action: React.ReactNode;
  from: React.ReactNode;
  to: React.ReactNode;
}

@observer
export default class LogRow extends ResourceComponent<LogRowProps> {
  prefix = "allocation";
  modelName = "allocation_history";

  changes(detail: AllocationHistoryDetail): AllocationChange[] {
    const changes: AllocationChange[] = [];
    const vm = this.props.vm;

    if (detail.enumeration_allocation_status) {
      changes.push({
        action: this.tg("status_change"),
        from: (
          <Badge color={ViewHelpers.allocationStateToColor(detail.enumeration_allocation_status[0])}>
            {this.locs.tg(`allocation.state.${detail.enumeration_allocation_status[0]}`)}
          </Badge>
        ),
        to: (
          <Badge color={ViewHelpers.allocationStateToColor(detail.enumeration_allocation_status[1])}>
            {this.locs.tg(`allocation.state.${detail.enumeration_allocation_status[1]}`)}
          </Badge>
        ),
      });
    }

    if (detail.enumeration_allocation_type_id) {
      changes.push({
        action: this.tg("type_change"),
        from: this.enums.value("allocation_types", detail.enumeration_allocation_type_id[0])?.name,
        to: this.enums.value("allocation_types", detail.enumeration_allocation_type_id[1])?.name,
      });
    }

    if (detail.value) {
      changes.push({
        action: this.tg("value_change"),
        from: ViewHelpers.allocationValue(detail.value[0], this.locs),
        to: ViewHelpers.allocationValue(detail.value[1], this.locs),
      });
    }

    if (detail.valid_from) {
      changes.push({
        action: this.tg("valid_from_change"),
        from: this.locs.formatDate(detail.valid_from[0]),
        to: this.locs.formatDate(detail.valid_from[1]),
      });
    }

    if (detail.valid_to) {
      changes.push({
        action: this.tg("valid_to_change"),
        from: this.locs.formatDate(detail.valid_to[0]),
        to: this.locs.formatDate(detail.valid_to[1]),
      });
    }

    if (detail.note) {
      changes.push({
        action: this.tg("note_change"),
        from: detail.note[0],
        to: detail.note[1],
      });
    }

    if (detail.user_id) {
      const userFrom = vm.idToUser[detail.user_id[0]];
      const userTo = vm.idToUser[detail.user_id[1]];

      changes.push({
        action: this.tg("user_change"),
        from: userFrom?.user ? ViewHelpers.userName(userFrom.user) : detail.user_id[0],
        to: userTo?.user ? ViewHelpers.userName(userTo.user) : detail.user_id[1],
      });
    }

    if (detail.project_id) {
      const projectFrom = vm.idToProject[detail.project_id[0]];
      const projectTo = vm.idToProject[detail.project_id[1]];

      const parentFromName = projectFrom?.meta?.group?.name;
      const parentToName = projectTo?.meta?.group?.name;

      if (parentFromName !== parentToName) {
        changes.push({
          action: this.tg("project_parent_change"),
          from: parentFromName,
          to: parentToName,
        });
      }

      changes.push({
        action: this.tg("project_change"),
        from: projectFrom?.name || detail.project_id[0],
        to: projectTo?.name || detail.project_id[1],
      });
    }

    return changes;
  }

  render() {
    const { entity } = this.props;
    const changes = this.changes(entity.detail);

    return (
      <>
        {changes.map((change, index) => {
          return (
            <tr key={index}>
              {index === 0 && (
                <>
                  <td rowSpan={changes.length} style={{ width: 130, verticalAlign: "top", lineHeight: "20px" }}>
                    {this.locs.formatDateTime(entity.created_at)}
                  </td>
                  <td rowSpan={changes.length} style={{ width: 25, verticalAlign: "top" }}>
                    <Avatar user={entity.author} size={20} />
                  </td>
                  <td rowSpan={changes.length} style={{ width: 130, verticalAlign: "top", lineHeight: "20px" }}>
                    {ViewHelpers.userName(entity.author)}
                  </td>
                </>
              )}
              <td>
                <span className="mr-12">{change.action}</span>
                <span>{change.from}</span>
                <span className="ml-12 mr-12">→</span>
                <span>{change.to}</span>
              </td>
            </tr>
          );
        })}
      </>
    );
  }
}
