import { inject, injectable, postConstruct } from "inversify";
import TYPES from "../../inversify.types";
import { action, computed, IReactionDisposer, observable, reaction } from "mobx";
import AllocationUserItemsVM from "@vm/Items/AllocationUser";
import OptionsVM from "@vm/Other/Options";
import JobPosition from "@model/JobPosition";
import OrganizationUnit from "@model/OrganizationUnit";
import AllocationUser from "@model/AllocationUser";
import Project from "@model/Project";
import CurrentUser from "@service/CurrentUser";
import BalanceGroupRepository from "@repository/BalanceGroup";
import AllocationItemRepository from "@repository/AllocationItem";
import AllocationJobTitleRepository from "@repository/AllocationJobTitle";
import ProjectRepository from "@repository/Project";
import { EmployeeAllocationsOrder } from "@view/Allocation/Tabs/EmployeeCalendar/employeeSort";
import AllocationsVM, { UpdatingData } from "./Allocations";
import { OptionType } from "@eman/emankit";
import { JobTitleState } from "@model/JobTitle";
import EventBus, { DATE_CHANGED, ENTITY_UPDATE, HIDE_LOADING, IDisposable, SHOW_LOADING } from "@util/EventBus";
import { EvidenceStateStatus } from "@model/EvidenceState";
import { AllocationStatus } from "@model/Allocation";
import OrganizationStructure from "@model/OrganizationStructure";
import { splitFilters } from "@util/Calendar";
import ViewHelpers from "@util/ViewHelpers";
import { UserStateByContract } from "@model/User";

export enum EmployeeFilterItem {
  FIRST_NAME = "user_first_name",
  LAST_NAME = "user_last_name",
  USER_STATE = "user_state",
  JOB_POSITION = "job_title_job_position_id",
  ORGANIZATION_UNIT = "organization_unit_id",
  PROJECT = "allocation_project_id",
  USER = "user_id",
  OFFICE_BRANCH = "branch_office_name",
  CONTRACTOR = "contract_contractor",
  JOB_TITLE_STATE = "job_title_state",
  CONTRACT_STATE = "contract_state",
}

@injectable()
export default class EmployeeAllocationsVM extends AllocationsVM {
  private projectOptionsVM: OptionsVM<Project>;
  private organizationUnitOptionsVM: OptionsVM<OrganizationUnit>;
  private jobPositionOptionsVM: OptionsVM<JobPosition>;
  private userOptionsVM: OptionsVM<AllocationUser>;
  private jobPositionsOptions: OptionsVM<JobPosition>;
  private organizationsOptions: OptionsVM<OrganizationStructure>;

  @observable selectedOrderSetting: EmployeeAllocationsOrder = EmployeeAllocationsOrder.USER_LAST_NAME_ASC;

  @observable order: OrderOptions;

  @observable list: any[] = [];

  @observable pagination: Pagination = {
    page: 0,
    pageSize: 20,
  };

  @observable isLoading: boolean;

  private reactionDisposers: IReactionDisposer[] = [];

  private readonly eventHandlers: IDisposable[] = [];

  static defaults = {
    visibleFilters: [
      "user_first_name",
      "user_last_name",
      "job_title_job_position_id",
      "organization_unit_id",
      "user_state",
      "job_title_state",
      "evidence_state_evidence_status",
      "contract_state",
    ],
    filters: {
      job_title_state: {
        operator: "in",
        values: [JobTitleState.ACTIVE],
      },
      evidence_state_evidence_status: {
        operator: "in",
        values: [EvidenceStateStatus.ACTIVED],
      },
      contract_state: {
        operator: "in",
        values: [
          UserStateByContract.ACTIVE,
          UserStateByContract.CREATED,
          UserStateByContract.DISMISSAL,
          UserStateByContract.PROBATION,
        ],
      },
    },
  };

  constructor(
    @inject(TYPES.User) user: CurrentUser,
    @inject(TYPES.AllocationItemRepository) allocationItemRepository: AllocationItemRepository,
    @inject(TYPES.AllocationJobTitleRepository) allocationJobTitleRepository: AllocationJobTitleRepository,
    @inject(TYPES.BalanceGroupRepository) balanceGroupRepository: BalanceGroupRepository,
    @inject(TYPES.ProjectRepository) projectRepository: ProjectRepository,
    @inject(TYPES.AllocationUserItems) allocationUserItemsVM: AllocationUserItemsVM,
    @inject(TYPES.AllocationUserRepository) private allocationUserRepository: Repository<AllocationUser>,
    @inject(TYPES.OrganizationUnitRepository) private organizationUnitRepository: Repository<OrganizationUnit>,
    @inject(TYPES.JobPositionRepository) private jobPositionRepository: Repository<JobPosition>,
    @inject(TYPES.OrganizationStructureRepository) organizationStructureRepository: Repository<OrganizationStructure>
  ) {
    super(
      user,
      allocationItemRepository,
      allocationJobTitleRepository,
      balanceGroupRepository,
      projectRepository,
      allocationUserItemsVM
    );
    this.jobPositionsOptions = new OptionsVM(jobPositionRepository, "name", {
      order: { direction: "asc", field: "id" },
      filters: {
        primary: {
          operator: "in",
          values: true,
        },
      },
    });
    this.organizationsOptions = new OptionsVM(organizationStructureRepository, "name", {
      order: { direction: "asc", field: "id" },
      filters: {
        primary: {
          operator: "in",
          values: true,
        },
      },
    });
  }

  @postConstruct()
  listenEventBus() {
    this.eventHandlers.push(EventBus.on(DATE_CHANGED, () => this.fetchUsers()));
    this.eventHandlers.push(EventBus.on(ENTITY_UPDATE, () => this.fetchUsers()));
    this.eventHandlers.push(
      EventBus.on(SHOW_LOADING, () => {
        this.isLoading = true;
      })
    );
    this.eventHandlers.push(
      EventBus.on(HIDE_LOADING, () => {
        this.isLoading = false;
      })
    );
  }

  addBalanceSumFilter() {
    this.settings.filters = {
      ...this.settings.filters,
      ...this.getDateFilters("balance_sum"),
    };
    if (this.settings.filters) {
      this.allocationUserItemsVM.setFilters(this.settings.filters, this.settings.visibleFilters, true);
    }
  }

  cleanBalanceSumFilter() {
    if (this.settings?.filters) {
      delete this.settings.filters.balance_sum;
    }
    if (this.settings.filters) {
      this.allocationUserItemsVM.setFilters(this.settings.filters, this.settings.visibleFilters, true);
    }
  }

  async init(): Promise<void> {
    await this.fetchUsers();
    this.subscribe();
    this.turnOnReactions();
  }

  dispose() {
    this.eventHandlers.forEach(x => x.dispose());
    this.turnOffReactions();
    this.unsubscribe();
  }

  @action.bound
  onDataUpdation(data: UpdatingData): void {
    this.getGroups();
    this.fetchData();
  }

  //reactions
  private turnOnReactions(): void {
    this.reactionDisposers.push(
      reaction(() => [this.settings, this.order], this.fetchUsers),
      reaction(() => [this.userIds, this.startDate], this.fetchData)
    );
  }

  /**
   * Trigger reseting view to default
   */
  onResetView = () => {
    this.settings = { ...this.settings, ...this.constructor["defaults"] };
    this.allocationUserItemsVM.setSettings(this.constructor["defaults"]);
    this.allocationUserItemsVM.setFilters(
      this.constructor["defaults"].filters,
      this.constructor["defaults"].visibleFilters,
      true
    );
  };

  turnOffReactions(): void {
    this.reactionDisposers.forEach(disposer => disposer());
    this.reactionDisposers = [];
  }

  @computed
  get getJobPositionsOptions() {
    return this.jobPositionsOptions.items;
  }

  @computed
  get getOrganizationsOptions() {
    return this.organizationsOptions.items;
  }

  setFilters = (filters: FilterValues, visibleFilters: string[]) => {
    // Checking for all options checked
    [
      { id: "job_title_job_position_id", options: this.jobPositionsOptions.items },
      { id: "organization_unit_id", options: this.organizationUnitOptions },
      {
        id: "job_title_working_time_ratio_primary_job_title_organization_structure_id",
        options: this.organizationsOptions.items,
      },
      { id: "job_title_working_time_ratio_primary_job_title_job_position_id", options: this.jobPositionsOptions.items },
      { id: "allocation_project_id", options: this.projectOptions },
      { id: "user_id", options: this.userOptions },
      { id: "contract_state", options: this.contractStateOptions },
    ].forEach(item => ViewHelpers.cleanFilters(item.options, filters, item.id));

    // If filter not visible delete it
    [
      "user_id",
      "user_first_name",
      "user_last_name",
      "evidence_state_evidence_status",
      "job_title_job_position_id",
      "organization_unit_id",
      "allocation_project_id",
      "allocation_service",
      "branch_office_name",
      "contract_enumeration_user_type_id",
      "job_title_state",
      "job_title_working_time_ratio_primary_job_title_job_position_id",
      "job_title_working_time_ratio_primary_job_title_job_position_id",
    ].forEach(item => !visibleFilters.includes(item) && delete filters[item]);

    this.pagination.page = 0;
    this.settings = { ...this.settings, filters, visibleFilters };
    this.filters = splitFilters(filters);
  };

  //data fetching
  protected fetchUsers = async () => {
    const settings = { ...this.settings, filters: this.filters.user, order: this.order };
    this.allocationUserItemsVM.setSettings(settings);

    if (this.settings.filters) {
      // Added additional filters only when project filters are on
      if (this.settings.filters.allocation_project_id?.values) {
        this.settings.filters = {
          ...this.settings.filters,
          ...this.getDateFilters("allocation_valid_interval"),
        };
      }

      // Added additional filters only when project, emergency, organization, externist, jobtitle filters are on
      if (this.settings.filters.job_title_job_position_id?.values) {
        this.settings.filters = {
          ...this.settings.filters,
          ...this.getDateFilters("job_title_valid_interval"),
        };
      }
      if (this.settings.filters.allocation_service?.values) {
        this.settings.filters = {
          ...this.settings.filters,
          ...this.getDateFilters("allocation_valid_interval"),
          ...this.getAllocationStatus([
            AllocationStatus.APPROVED,
            AllocationStatus.REQUEST,
            AllocationStatus.WARNING,
            AllocationStatus.PROBLEM,
          ]),
        };
      }
      if (this.settings.filters.allocation_project_id?.values) {
        this.settings.filters = {
          ...this.settings.filters,
          ...this.getDateFilters("allocation_valid_interval"),
          ...this.getAllocationStatus([
            AllocationStatus.APPROVED,
            AllocationStatus.REQUEST,
            AllocationStatus.WARNING,
            AllocationStatus.PROBLEM,
          ]),
        };
      }
      if (this.settings.filters.organization_unit_id?.values) {
        this.settings.filters = {
          ...this.settings.filters,
          ...this.getDateFilters("job_title_valid_interval"),
        };
      }

      if (
        this.settings.filters.job_title_working_time_ratio_primary_job_title_job_position_id?.values ||
        this.settings.filters.job_title_working_time_ratio_primary_job_title_organization_structure_id?.values
      ) {
        this.settings.filters = {
          ...this.settings.filters,
          ...this.getDateFilters("allocation_valid_interval"),
        };
      }

      if (this.settings.filters.balance_sum) {
        this.addBalanceSumFilter();
      }

      if (
        !this.settings.filters.allocation_project_id?.values &&
        !this.settings.filters.allocation_service?.values &&
        !this.settings.filters.allocation_project_id?.values &&
        !this.settings.filters.job_title_working_time_ratio_primary_job_title_job_position_id?.values &&
        !this.settings.filters.job_title_working_time_ratio_primary_job_title_organization_structure_id?.values
      ) {
        if (this.settings?.filters) {
          delete this.settings.filters.allocation_valid_interval;
        }
      }

      if (!this.settings.filters.job_title_job_position_id?.values) {
        if (this.settings?.filters) {
          delete this.settings.filters.job_title_valid_interval;
        }
      }

      if (!this.settings.filters.organization_unit_id?.values) {
        if (this.settings?.filters) {
          delete this.settings.filters.job_title_valid_interval;
        }
      }
    }

    if (this.settings.filters) {
      this.allocationUserItemsVM.setFilters(this.settings.filters, this.settings.visibleFilters, true);
    }
    this.allocationUserItemsVM.fetchList(false, true, this.pagination);
  };

  //filter select options
  @computed
  get projectOptions() {
    if (!this.projectOptionsVM) {
      this.projectOptionsVM = new OptionsVM<Project>(this.projectRepository, "name");
    }

    return this.projectOptionsVM.selectOptions;
  }

  @computed
  get organizationUnitOptions() {
    if (!this.organizationUnitOptionsVM) {
      this.organizationUnitOptionsVM = new OptionsVM(this.organizationUnitRepository, "name");
    }

    return ViewHelpers.createSubOrganizationOptions(this.organizationUnitOptionsVM.items);
  }

  @computed
  get jobPositionOptions() {
    if (!this.jobPositionOptionsVM) {
      this.jobPositionOptionsVM = new OptionsVM(this.jobPositionRepository, "name");
    }

    return this.jobPositionOptionsVM.selectOptions;
  }

  @computed
  get total() {
    return this.allocationUserItemsVM.total;
  }

  @computed
  get contractStateOptions() {
    return Object.values(UserStateByContract).map(state => ({ label: this.locs.tg(`employee.state.${state}`), value: state }));
  }

  @computed
  get userOptions(): OptionType<string>[] {
    if (!this.userOptionsVM) {
      this.userOptionsVM = new OptionsVM<AllocationUser>(this.allocationUserRepository, "name" as any, {
        order: {
          field: "user_last_name",
          direction: "asc",
        },
      });
    }

    return this.userOptionsVM.items.map(({ user }) => ({
      value: user.id!.toString(),
      label: user.fullName,
    }));
  }

  @action
  setPageAndPageSize = (page: number, pageSize: number) => {
    this.pagination = { page, pageSize };
    this.fetchUsers();
  };
}
