import { observer } from "mobx-react";

import Show from "@component/Show";
import EnumContainer from "@model/EnumContainer";
import EnumContainerShowVM from "@vm/Show/EnumContainer";

import { lazyInject, TYPES } from "../../../inversify.config";

import EnumList from "./List";

interface Props {
  match: {
    params: {
      enumeration_id: string;
    };
  };
}

@observer
export default class EnumerationContainerShow extends Show<EnumContainer, EnumContainerShowVM, Props> {
  modelName = "enumeration_container";

  @lazyInject(TYPES.EnumContainerShow)
  vm: EnumContainerShowVM;

  setIdFromParams() {
    this.vm.init(this.props.match.params.enumeration_id);
  }

  render() {
    return <EnumList vm={this.vm.enumListVM} />;
  }
}
