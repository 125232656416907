import { observer } from "mobx-react";

import BaseComponent from "@component/BaseComponent";
import VacationList from "./List";

@observer
export default class Vacations extends BaseComponent<PropsFromRouter> {
  render() {
    return (
      <>
        <VacationList match={this.props.match} />
      </>
    );
  }
}
