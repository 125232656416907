import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import JobTitle from "@model/JobTitle";
import JobTitleShowVM from "@vm/Show/JobTitle";

import { lazyInject, TYPES } from "../../../../inversify.config";
import Form from "./Form";

@observer
export default class JobTitleShowModal extends ShowModal<JobTitle> {
  modelName = "job_title";
  prefix = "employee.contract";

  @lazyInject(TYPES.JobTitleShow)
  vm: JobTitleShowVM;

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.show_employees_contracts(params.user_id, params.contract_id));
  };

  renderModalBody() {
    return <Form entity={this.vm.entity} vm={this.vm.jobTitleFormVM} readonly={true} />;
  }
}
