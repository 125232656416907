import { inject, injectable } from "inversify";
import { computed } from "mobx";

import Role from "@model/Role";
import User from "@model/User";
import ListViewModel from "@vm/List/ListViewModel";
import OptionsVM from "@vm/Other/Options";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class UserListVM extends ListViewModel<User, Repository<User>> {
  static defaults = {
    order: {
      field: "last_name",
      direction: "asc",
    },
    columns: ["avatar", "first_name", "last_name", "email", "middle_name", "employee_number", "role_id"],
    visibleFilters: ["first_name", "last_name", "state"],
  };

  private roleOptions: OptionsVM<Role>;

  constructor(
    @inject(TYPES.UserRepository) repository: Repository<User>,
    @inject(TYPES.RoleRepository) roleRepository: Repository<Role>
  ) {
    super(repository);
    this.roleOptions = new OptionsVM(roleRepository, "name");
  }

  @computed
  get roles() {
    return this.roleOptions.selectOptions;
  }

  roleName(roleId: number): string | undefined {
    return this.roleOptions.items?.find(item => item.id === roleId)?.name;
  }
}
