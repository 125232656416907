import { injectable } from "inversify";

import BranchOffice from "@model/BranchOffice";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";
import ApiClient from "@util/ApiClient";
import { plainToClass } from "@eman/class-transformer";

@injectable()
export default class BranchOfficeRepository extends BaseAssociatedRepository<BranchOffice> {
  constructor() {
    super(BranchOffice, "/companies/$ID/branch_offices", "branch_office");
  }

  fullList() {
    const params = {
      limit: 10000,
      offset: 0,
      "order[field]": "name",
      "order[direction]": "asc",
      save: false,
    };

    const config = {
      url: this.uri.replace("/companies/$ID", ""),
      params,
      loading: false,
      id: `FETCH_LIST_${this.modelName}`,
      ignoreErrors: false,
    };

    return ApiClient.fetchData(config).then(response => {
      const data = {
        total: response.count,
        list: [],
        others: response.others || {},
        original: response,
      };

      data.list = plainToClass(this.model, response.items) as any;

      return data;
    });
  }
}
