import { CreateCardConfig } from "@eman/emankit/AutoCreateInput";
import { InputVariant } from "@eman/emankit/Input";
import { action, get, set } from "mobx";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";

export interface BindingProps<T> {
  target?: T;
  property?: string;
  errorProperty?: string;
  placeholder?: string;
  id?: any;
  type?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  iconStart?: any;
  iconStop?: any;
  textEnd?: string;
  format?: "row" | "column";
  style?: CSSProperties;
  tabIndex?: number;
  onValueChanged?: (value: any) => void;
  labelCol?: number;
  skipDirty?: boolean;
  fromMonth?: Date;
  toMonth?: Date;
  formatValue?: (value: any) => any;
  parseValue?: (value: any) => any;
  valueKey?: string;
  labelKey?: string;
  values?: any;
  variant?: InputVariant;

  // WorkDurationBox
  max?: number;
  step?: number;

  // Autocomplete
  searchSuggestions?: (value: string) => any;
  getInitialSuggestions?: (value?: string) => T[] | Promise<T[]>;
  submitAnyUserInput?: boolean;
  renderSuggestionsOnFocus?: boolean;

  //AutoCreate
  onCreateOption?: (value: string) => Promise<void>;
  createCardConfig?: CreateCardConfig;

  // Checkbox
  ownLabel?: string | React.ReactFragment | React.Component;

  // CKEditor config
  readOnly?: boolean;

  // Time picker
  inMinutes?: boolean;
}

// tslint:disable-next-line: bool-param-default
export const setValue = (target: any, property: any, value: any, skipDirty?: boolean, errorProperty?: string) => {
  // Check is dirty property
  if (target.oldValues && !target.oldValues.hasOwnProperty(property)) {
    const currentValue = get(target, property);
    set(target.oldValues, property, currentValue !== undefined ? currentValue : ""); // Only undefined convert to an empty strings
  }

  // Set value
  set(target, property, value);

  if (target.errors) {
    if (errorProperty && target.errors.has(errorProperty)) {
      target.errors.delete(errorProperty);
    }

    if (property && target.errors.has(property)) {
      target.errors.delete(property);
    }

    if (property && target.errors.has(property.replace("_id", ""))) {
      target.errors.delete(property.replace("_id", ""));
    }
  }

  if (skipDirty !== true && target.isDirty && target.oldValues) {
    // tslint:disable-next-line:triple-equals
    let dirtyCheck = target.oldValues[property] != value;

    // There is problem with comparsion '' and 0 because according to JS this is EQUAL!
    if ((target.oldValues[property] === "" && value === 0) || (target.oldValues[property] === 0 && value === "")) {
      dirtyCheck = true;
    }

    target.isDirty.set(property, dirtyCheck);
  }
};

@observer
export default class BindingElement<TProps extends BindingProps<TModel>, TModel> extends React.Component<TProps> {
  @action.bound
  protected setValue(value: any) {
    const { errorProperty, target, property, onValueChanged, skipDirty, parseValue, type } = this.props;

    if (type === "number") {
      value = parseFloat(value);
    }

    if (parseValue) {
      value = parseValue(value);
    }

    // This automatically set property as changable
    if (target && property) {
      setValue(target, property, value, skipDirty, errorProperty);
    }

    if (onValueChanged) {
      onValueChanged(value);
    }
  }

  protected get value() {
    const { target, property, formatValue } = this.props as TProps;

    if (!target) {
      throw new Error(`'target' of property '${property}' prop has not been set`);
    }

    if (!property) {
      throw new Error("'property' prop has not been set");
    }

    let value = get(target, property);

    if (formatValue) {
      value = formatValue(value);
    }

    return value;
  }
}
