import { observable, computed } from "mobx";

import BaseModel from "@model/BaseModel";
import User from "./User";

export enum NOTE_RIGHT_TYPES {
  ADMIN = "admin",
  COMMON = "common",
  PERSONAL = "personal",
  SUPERVISOR = "supervisor",
}

export default class Note extends BaseModel {
  @observable user_id: string;
  @observable description: string;
  @observable right_type = NOTE_RIGHT_TYPES.PERSONAL;
  @observable archived: boolean;
  @observable author: User;

  @computed
  get authorFullName() {
    if (this.author && this.author.first_name && this.author.last_name) {
      return this.author.first_name + " " + this.author.last_name;
    }
    return null;
  }
}
