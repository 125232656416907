import { inject, injectable } from "inversify";

import Skill from "@model/Skill";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class SkillListVM extends AssociatedListViewModel<Skill, AssociatedRepository<Skill>> {
  static defaults = {
    order: {
      field: "created_at",
      direction: "desc",
    },
    columns: ["enumeration_skill_type_id", "enumeration_skill_level_id", "created_at"],
  };

  constructor(@inject(TYPES.SkillRepository) repository: AssociatedRepository<Skill>) {
    super(repository);
  }
}
