import BaseComponent from "@component/BaseComponent";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import InputContainer from "@eman/emankit/InputContainer";
import Modal from "@eman/emankit/Modal";
import TextArea from "@eman/emankit/TextArea";
import { observer } from "mobx-react";
import React from "react";

export interface ConfirmModalProps {
  onSuccess: (reason?: string) => Promise<void>;
  onClose: () => void;
  open: boolean;
}

export interface ConfirmModalState {
  reason?: string;
}

@observer
export default class RejectConfirmModal extends BaseComponent<ConfirmModalProps, ConfirmModalState> {
  state = {
    reason: undefined,
  };

  clearReason = () => {
    this.setState({ reason: "" });
  };

  onCloseClick = () => {
    this.props.onClose();
    this.clearReason();
  };

  onConfirmClick = async () => {
    await this.props.onSuccess(this.state.reason);
    this.clearReason();
  };

  onInputChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState({ reason: e.currentTarget.value });
  };

  buttons = () => {
    const buttons = [
      <Button className="mr-12" key="cancel" onClick={this.onCloseClick}>
        {this.locs.tg("form.cancel")}
      </Button>,
    ];

    if (this.state.reason) {
      buttons.push(
        <Button key="confirm" variant={ButtonVariant.Active} onClick={this.onConfirmClick}>
          {this.locs.tg("form.confirm")}
        </Button>
      );
    }

    return <div style={{ textAlign: "right" }}>{buttons}</div>;
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        buttons={this.buttons}
        header={this.locs.tg("announcement.reject_confirm.title")}
        onClose={this.onCloseClick}
        width={600}>
        <InputContainer label={this.locs.tg("announcement.reject_confirm.label")} format="column">
          <TextArea onChange={this.onInputChange} value={this.state.reason} />
        </InputContainer>
      </Modal>
    );
  }
}
