import { observer } from "mobx-react";
import React from "react";

import WorkDurationPicker from "@eman/emankit/WorkDurationPicker";

import BindingElement, { BindingProps } from "./index";

@observer
export default class WorkDurationBox extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = (value: number) => {
    this.setValue(value);
  };

  render() {
    return (
      <WorkDurationPicker
        {...this.props}
        onValueChange={this.handleValueChanged}
        value={isNaN(this.value) || this.value === Infinity ? 0 : this.value}
      />
    );
  }
}
