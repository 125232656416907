import { classToPlain, plainToClass } from "@eman/class-transformer";
import EventBus, { ENTITY_CREATED, ENTITY_DELETED, ENTITY_UPDATE } from "@util/EventBus";
import { injectable, unmanaged } from "inversify";
import { toJS } from "mobx";

import ApiClient from "../Utils/ApiClient";

@injectable()
export default abstract class BaseRepository<T extends models.IBase> implements Repository<T> {
  protected model: new () => T;

  constructor(
    @unmanaged() model: new () => T,
    @unmanaged() protected uri: string,
    @unmanaged() protected modelName: string // in snake case
  ) {
    this.model = model;
  }

  get classModelName(): string {
    return this.modelName;
  }

  // tslint:disable-next-line: cognitive-complexity
  filtersToWhereParams(filters?: FilterValues): any {
    const where = {};
    if (filters) {
      for (const compositeKey in filters) {
        if (filters.hasOwnProperty(compositeKey)) {
          const keys = compositeKey.split(",");
          let values: any = toJS(filters[compositeKey].values);
          let operator: FilterOperator = filters[compositeKey].operator;

          // Correct requests to lte or gte if only one value is set
          if (["between", "lte", "gte", "lt", "gt"].indexOf(operator) !== -1 && Array.isArray(values) && values.length === 2) {
            if (values[0] === null || values[0] === undefined) {
              operator = "lte";
              values = values[1];
            } else if (values[1] === null || values[1] === undefined) {
              operator = "gte";
              values = values[0];
            }
          }

          keys.forEach(key => {
            where[`where[${key}][type]`] = operator;
            where[`where[${key}][values]`] = values;
          });

          if (keys.length > 1) {
            where[`or[]`] = compositeKey;
          }
        }
      }
    }
    return where;
  }

  /**
   * Fetch items
   * @param args
   */
  fetchItems(args: FetchItemsArguments): Promise<{ items: T[] }> {
    const { loading, filters, ignoreErrors, save, order, id, preferencePrefix, ...rest } = args;

    const params = {
      save,
      key_prefix: preferencePrefix,
      "order[field]": order ? order.field : undefined,
      "order[direction]": order ? order.direction : undefined,
      ...this.filtersToWhereParams(filters),
      ...rest,
    };

    const config = {
      url: this.uri,
      params,
      id: id || `FETCH_DATA_${this.modelName}`,
      loading,
      ignoreErrors,
    };

    return ApiClient.fetchData(config).then(response => {
      const data = {
        items: [],
        count: response.count,
      };

      data.items = (plainToClass(this.model, response.items) as any) || [];
      return data;
    });
  }

  /**
   * Fetch data list.
   *
   * @param limit
   * @param offset
   */
  fetchList(args: FetchListArguments): Promise<{ list: T[]; total: number; others: any }> {
    const { loading, pagination, save, order, filters, ignoreErrors, preferencePrefix, id, ...rest } = args;

    // TODO and WARNING: This is a workaround for a difficult problem that needed to be fixed urgently.
    // Please, identify and FIX the real problem ASAP and DELETE those lines.
    // The problem is with duplicate "enum_code" in visibleFilters and "action" in columns
    // Those duplicate values are added on every fetchList call, making the app crash if the request
    // grows too large.
    // Cool set trick. Too bad spread is not supported in TS for now
    // refer to https://stackoverflow.com/questions/33464504/using-spread-syntax-and-new-set-with-typescript
    if (rest.visibleFilters) {
      rest.visibleFilters = Array.from(new Set(rest.visibleFilters));
    }
    if (rest.columns) {
      rest.columns = Array.from(new Set(rest.columns));
    }

    const params = {
      limit: pagination.pageSize,
      offset: pagination.page * pagination.pageSize,
      "order[field]": order ? order.field : undefined,
      "order[direction]": order ? order.direction : undefined,
      ...this.filtersToWhereParams(filters),
      save,
      key_prefix: preferencePrefix,
      ...rest, // Columns, visible columns etc...
    };

    const config = {
      url: this.uri,
      params,
      loading,
      id: id || `FETCH_LIST_${this.modelName}`,
      ignoreErrors,
    };

    return ApiClient.fetchData(config).then(response => {
      const data = {
        total: response.count,
        list: [],
        others: response.others || {},
        original: response,
      };

      data.list = plainToClass(this.model, response.items) as any;
      return data;
    });
  }

  /**
   * Autocomplete for table helpers.
   * @param value Key to fetch.
   */
  autocomplete(value: string): Promise<string[]> {
    const config = {
      url: `${this.uri}/autocomplete`,
      id: `AUTOCOMPLETE_${this.modelName}`,
      params: {
        q: value,
      },
    };

    return ApiClient.fetchData(config).then(data => data.data);
  }

  /**
   * Enqueue to export.
   *
   * @param type Format type (xlsx/csv)
   */
  startExport(type?: string): Promise<boolean> {
    const config = {
      url: "exports",
      method: "POST" as "POST" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      data: {
        export: {
          format_type: type || "csv",
          type: this.uri,
        },
      },
      id: `CREATE_EXPORT_${this.modelName}`,
    };

    return ApiClient.fetchResponse(config).then(response => {
      return response.status;
    });
  }

  /**
   * Enqueue to export report.
   *
   * @param type Format type (xlsx/csv)
   * @param offset How many months into the past should be queried
   * @param name Optional filename prefix
   */
  startExportReport(type: string, offset: number, name?: string): Promise<ApiResponse<T>> {
    const config = {
      url: "exports/create_report",
      method: "POST" as "POST" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      data: {
        export: {
          format_type: "xlsx",
          name: name,
        },
        export_type: type,
        offset: offset,
      },
      id: `CREATE_EXPORT_REPORT_${this.modelName}`,
    };

    return ApiClient.fetchResponse(config).then(response => {
      return response;
    });
  }

  /**
   * Create.
   *
   * @param object Object
   */
  create(object: T): Promise<ApiResponse<T>> {
    const config = {
      url: this.uri,
      method: "POST" as "POST" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      id: `CREATING_${this.modelName}`,
      data: {
        [this.modelName]: classToPlain(object, { groups: ["create"] }),
      },
    };

    return ApiClient.fetchResponse(config).then(response => {
      if (response.original && response.original.entity) {
        EventBus.trigger(ENTITY_CREATED, {
          identificator: this.modelName,
          id: response.original.entity.id,
        });
      }

      return response;
    });
  }

  /**
   * Get data.
   *
   * @param id
   */
  show(id: number, loading = true): Promise<ApiResponse<T>> {
    const config = {
      url: `${this.uri}/${id}`,
      method: "GET" as "GET" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      id: `GET_${this.modelName}`,
      loading,
    };

    return ApiClient.fetchResponse(config).then(response => {
      if (response.original && response.original.entity) {
        response.entity = plainToClass(this.model, response.original.entity) as any;
      }

      return response;
    });
  }

  /**
   * Destroy
   *
   * @param object Object
   */
  destroy(id: number): Promise<ApiResponse<T>> {
    const config = {
      url: `${this.uri}/${id}`,
      method: "DELETE" as "DELETE" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      id: `DELETE_${this.modelName}`,
    };

    return ApiClient.fetchResponse(config).then(response => {
      if (response.status) {
        EventBus.trigger(ENTITY_DELETED, {
          identificator: this.modelName,
          id,
        });
      }

      return response;
    });
  }

  /**
   * Update.
   *
   * @param id object id
   * @param object Object data
   */
  update(id: number, object: any, params: any = {}) {
    const config = {
      url: `${this.uri}${id ? `/${id}` : ""}`,
      method: "PUT" as "PUT" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      id: `UPDATING_${this.modelName}`,
      params,
      data: {
        [this.modelName]: classToPlain(object, { groups: ["update"] }),
      },
    };

    return ApiClient.fetchResponse(config).then(response => {
      EventBus.trigger(ENTITY_UPDATE, {
        identificator: this.modelName,
        id,
      });

      return response;
    });
  }
}
