import { inject, injectable } from "inversify";

import PersonalDocument from "@model/PersonalDocument";
import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";
import TYPES from "../../inversify.types";
import PersonalDocumentFormVM from "@vm/Form/PersonalDocument";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PersonalDocumentEditVM extends AssociatedEditViewModel<
  PersonalDocument,
  AssociatedRepository<PersonalDocument>
> {
  constructor(
    @inject(TYPES.PersonalDocumentRepository) repository: AssociatedRepository<PersonalDocument>,
    @inject(TYPES.PersonalDocumentForm) public personalDocumentFormVM: PersonalDocumentFormVM
  ) {
    super(PersonalDocument, repository);
  }

  setEntity(entity: PersonalDocument) {
    super.setEntity(entity);
  }
}
