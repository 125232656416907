import { inject, injectable } from "inversify";

import EnumType from "@model/EnumType";
import Enum from "@service/Enum";
import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";
import TYPES from "../../inversify.types";
import OptionsVM from "@vm/Other/Options";
import AllocationUser from "@model/AllocationUser";
import JobPosition from "@model/JobPosition";
import { computed, observable } from "mobx";
import { OptionType } from "@eman/emankit";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class EnumEditVM extends AssociatedEditViewModel<EnumType, IEnumRepository<EnumType>> {
  enums: Enum;
  @observable
  private userOptionsVM: OptionsVM<AllocationUser>;
  @observable
  private jobPositionsOptionsVM: OptionsVM<JobPosition>;

  constructor(
    @inject(TYPES.EnumRepository) repository: IEnumRepository<EnumType>,
    @inject(TYPES.AllocationUserRepository) private allocationUserRepository: Repository<AllocationUser>,
    @inject(TYPES.JobPositionRepository) private jobPositionRepository: Repository<JobPosition>,
    @inject(TYPES.Enum) enums: Enum
  ) {
    super(EnumType, repository);
    this.enums = enums;

    // this is a hack but it prevents loading unnecessary data
    if (window.location.pathname.includes("training_types")) {
      this.userOptionsVM = new OptionsVM(this.allocationUserRepository, "user_last_name" as any);
      this.jobPositionsOptionsVM = new OptionsVM(this.jobPositionRepository, "name");
    }
  }

  @computed
  get possibleUsers(): OptionType<number>[] {
    return this.userOptionsVM?.items.map((item: AllocationUser) => ({
      label: `${item.user.first_name} ${item.user.last_name}`,
      value: item.user.id!,
    }));
  }

  @computed
  get possibleJobPositions(): OptionType<string>[] {
    return this.jobPositionsOptionsVM?.items.map((item: JobPosition) => ({
      label: `${item.name}`,
      value: `${item.id!}`,
    }));
  }

  async update(): Promise<boolean> {
    const status = await super.update();

    if (status) {
      this.enums.resetEnums();
    }

    return status;
  }

  async destroy(): Promise<boolean> {
    const response = await this.repository.destroy(this.entity.id);

    if (response.status) {
      this.enums.resetEnums();
    }

    return response.status;
  }
}
