import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import PerformanceManagement from "@model/PerformanceManagement";
import { DatePickerBox, GenericFormField, TextBox } from "@util/Form";

@observer
export default class PerformanceManagementForm extends BaseForm<PerformanceManagement> {
  modelName = "performance_management";
  prefix = "employee";

  questions = (): React.ReactNode[] => {
    return this.enums
      .values("performance_management_questions")
      .sort((a, b) => (a.order || 9999) - (b.order || 9999))
      .map((question: models.IEnumType, index: number) => {
        return (
          <div className="col-md-12" key={`ts_answer_${index}`}>
            <GenericFormField
              key={`answer${index}`}
              target={this.props.entity}
              property={`answer${index}`}
              errorProperty={`answer${index}`}
              label={question.name}
              required={true}
              disabled={false}>
              {<TextBox />}
            </GenericFormField>
          </div>
        );
      });
  };

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-6">{this.generateFormField("evaluation_date", <DatePickerBox />, true)}</div>
        {this.questions()}
      </div>
    );
  }
}
