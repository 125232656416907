import { confirmYesNo } from "@eman/emankit/ConfirmAlert";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";

import BaseModal from "@component/BaseModal";
import EventBus, { JOB_TITLES_UPDATED, SHOW_TOAST } from "@util/EventBus";

export default abstract class EditModal<TModel extends models.IBase> extends BaseModal<TModel> {
  suffix = "edit";

  abstract vm: ViewModel.Edit<TModel>;

  get hasDelete() {
    return true;
  }

  componentDidMount(): void {
    this.vm.scroll = false;
    this.setIdFromParams();
  }

  componentDidUpdate(): void {
    this.setIdFromParams();
  }

  componentWillUnmount(): void {
    this.vm.cleanUp();
  }

  setIdFromParams() {
    this.vm.init(this.getId());
  }

  buttons() {
    const editButton = (
      <Button variant={ButtonVariant.Primary} onClick={this.onSave}>
        {this.locs.tg("form.edit")}
      </Button>
    );

    if (this.hasDelete) {
      return (
        <div className="row">
          <div className="col-6" style={{ textAlign: "left" }}>
            <Button variant={ButtonVariant.Dangerous} onClick={this.onDelete}>
              {this.locs.tg("form.delete")}
            </Button>
          </div>
          <div className="col-6">{editButton}</div>
        </div>
      );
    } else {
      return editButton;
    }
  }

  successMessage(): string {
    return this.locs.tg("form.updated");
  }

  onSave = async () => {
    const status = await this.vm.update();
    if (status) {
      this.vm.entity.clearErrors();
      EventBus.trigger(JOB_TITLES_UPDATED);
      EventBus.trigger(SHOW_TOAST, this.successMessage());
      this.onClose();
    }

    return status;
  };

  onDelete = async () => {
    confirmYesNo(
      <>
        {this.locs.tg("form.delete_confirm.begin")}
        <br />
        {this.locs.tg("form.delete_confirm.end")}
      </>,
      async () => {
        const status = await this.vm.destroy();

        if (status) {
          EventBus.trigger(SHOW_TOAST, this.locs.tg("form.deleted"));
          this.onClose();
        }

        return status;
      },
      { confirmButtonType: ButtonVariant.SecondaryBlue, cancelButtonType: ButtonVariant.Primary }
    );
  };
}
