import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import PerformanceManagement from "@model/PerformanceManagement";
import PerformanceManagementShowVM from "@vm/Show/PerformanceManagement";
import { lazyInject, TYPES } from "../../../inversify.config";

import PerformanceManagementForm from "./Form";

@observer
export default class PerformanceManagementShowModal extends ShowModal<PerformanceManagement> {
  modelName = "performance_management";
  prefix = "employee";

  @lazyInject(TYPES.PerformanceManagementShow)
  vm: PerformanceManagementShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_performance_managements(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <>
        <PerformanceManagementForm entity={this.vm.entity} readonly={true} />
      </>
    );
  }
}
