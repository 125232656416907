import { observer } from "mobx-react";
import React from "react";

import Button from "@eman/emankit/Button";
import { confirmYesNo } from "@eman/emankit/ConfirmAlert";
import InputContainer from "@eman/emankit/InputContainer";

import ShowModal from "@component/ShowModal";
import Announcement, { AnnouncementEvent } from "@model/Announcement";
import { StateRightsOperations, UserRightsObjects } from "@model/Rights";
import { GenericFormField, TextAreaBox } from "@util/Form";
import ViewHelpers from "@util/ViewHelpers";
import AnnouncementForm from "@view/Announcement/Form";
import RejectConfirmModal from "@view/Announcement/RejectConfirmModal";
import Documents from "@view/Common/Documents";
import AnnouncementShowVM from "@vm/Show/Announcement";

import { lazyInject } from "../../inversify.config";
import TYPES from "../../inversify.types";

@observer
export default class AnnouncementShowModal extends ShowModal<Announcement> {
  modelName = "announcement";

  state = {
    openRejectModal: false,
  };

  @lazyInject(TYPES.AnnouncementShow)
  vm: AnnouncementShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.announcements());
  };

  onEmployeeClick = () => {
    this.router.pageLink(this.uriHelper.show_employees(this.vm.entity.user_id));
  };

  buttons = () => {
    const { entity } = this.vm;

    return (
      <div className="row">
        <div className="col-md-6" style={{ textAlign: "left" }}>
          <Button onClick={this.onEmployeeClick}>{this.tg("employee_folder")}</Button>
        </div>
        <div className="col-md-6">
          {this.user.allowToObject(UserRightsObjects.ANNOUNCEMENT, StateRightsOperations.STATUS_CHANGE) &&
            entity.available_events
              .sort((a, b) => {
                if (a === AnnouncementEvent.CANCEL) {
                  return -1;
                } else if (b === AnnouncementEvent.CANCEL) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map(event => (
                <Button
                  data-id={entity.id}
                  data-event={event}
                  key={`action-${event}`}
                  className="ml-12"
                  onClick={this.onEventClick}
                  variant={ViewHelpers.announcementEventToButtonType(event)}
                  icon={ViewHelpers.announcementEventToIcon(event)}>
                  {this.locs.tg(`${this.modelName}.event.${event}`)}
                </Button>
              ))}
        </div>
      </div>
    );
  };

  onEventClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const itemId = parseInt(e.currentTarget.getAttribute("data-id")!, 10);
    const event = e.currentTarget.getAttribute("data-event")! as AnnouncementEvent;

    if (event === AnnouncementEvent.REJECT) {
      this.setState({ openRejectModal: true });
    } else {
      let confirmMessage: React.ReactNode = this.locs.tg(`${this.modelName}.event_confirm`);
      if (event === AnnouncementEvent.CANCEL) {
        confirmMessage = (
          <>
            {this.locs.tg(`${this.modelName}.cancel_event_confirm.part1`)}
            <br />
            {this.locs.tg(`${this.modelName}.cancel_event_confirm.part2`)}
          </>
        );
      }

      confirmYesNo(confirmMessage, () => {
        this.performEvent(itemId, event);
      });
    }
  };

  performEvent = async (itemId: number, event: AnnouncementEvent, reason?: string) => {
    const response = await this.vm.performEvent(itemId, event, reason);

    if (response.status) {
      this.setState({ openRejectModal: false });
    }
  };

  onRejectConfirm = (reason?: string) => {
    return this.performEvent(this.vm.entity.id!, AnnouncementEvent.REJECT, reason || "");
  };

  onRejectClose = () => {
    this.setState({ openRejectModal: false });
  };

  renderModalBody() {
    const { entity } = this.vm;

    return (
      <>
        <AnnouncementForm entity={entity} disabled={true} />
        {entity.refusal_reason && (
          <GenericFormField target={entity} property={"refusal_reason"} label={this.ta("refusal_reason")} disabled={true}>
            <TextAreaBox />
          </GenericFormField>
        )}
        {entity.documents.length > 0 && (
          <InputContainer label={this.ta("documents")} format="column">
            <Documents vm={this.vm} />
          </InputContainer>
        )}

        <RejectConfirmModal onSuccess={this.onRejectConfirm} open={this.state.openRejectModal} onClose={this.onRejectClose} />
      </>
    );
  }
}
