import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import Capacity from "@model/Capacity";
import { CheckBox, GenericFormField, WorkDurationBox, SelectWithSearchBox } from "@util/Form";
import { DatePickerBox } from "@util/Form";

import CapacityFormVM from "@vm/Form/Capacity";
import { DAY, MAXDAYHOURS } from "src/config";
import WorkingTimeRatiosListVM from "@vm/List/WorkingTimeRatios";
import Badge from "@eman/emankit/Badge";
import { BaseColor } from "@eman/emankit/Colors";
import { components, OptionProps, SingleValueProps } from "react-select";
import { JobTitleState } from "@model/JobTitle";
import { JobOptionType } from "@util/ViewHelpers";
import { formatDate } from "@util/DateFormats";
import { CUSTOM_DATE_FORMAT } from "@model/BaseModel";
import add from "date-fns/add";
import min from "date-fns/min";

interface CapacityFormProps {
  vm: CapacityFormVM;
  workingTimeRatiosListVM?: WorkingTimeRatiosListVM;
  allowedToSave?: boolean;
  allowedRatiosTitles?: JobOptionType[];
  disabledOnEdit?: boolean;
  canCreateBeforeToday?: boolean;
}

@observer
export default class CapacityForm extends BaseForm<Capacity, CapacityFormProps> {
  modelName = "capacity";
  prefix = "employee";

  componentWillUnmount(): void {
    this.props.vm.turnOffReactions();
    this.props.vm.clearSelectedRate();
  }

  handleDurationChange(day: DAY) {
    if (!this.props.vm.selectedRatio) {
      return;
    }
  }

  createFieldsForDay(day: DAY, withCheckBox = true) {
    return (
      <>
        <div className="col-md-1 d-md-flex align-items-md-center">
          {withCheckBox ? (
            <div>
              <GenericFormField target={this.props.vm.days} property={day} disabled={!this.props.entity.rate_id}>
                <CheckBox ownLabel={this.ta(day)} />
              </GenericFormField>
              {this.props.vm.lessThan7Days && (
                <div className="date_row">
                  {this.props.vm.getDayOfInterval(day) && formatDate(this.props.vm.getDayOfInterval(day)!, CUSTOM_DATE_FORMAT)}
                </div>
              )}
            </div>
          ) : (
            <label>{this.ta(day)}</label>
          )}
        </div>
        <div className="col-md-11">
          <WorkDurationBox
            step={1}
            max={MAXDAYHOURS}
            target={this.props.entity}
            property={day}
            disabled={withCheckBox && !this.props.vm.days[day]}
            onValueChanged={this.handleDurationChange.bind(this, day)}
          />
        </div>
      </>
    );
  }

  renderFormBody() {
    const { vm, allowedRatiosTitles, canCreateBeforeToday } = this.props;

    const isNotEqual = this.props.entity.totalCapacity === this.props.vm.selectedRate?.free_rate_hours;

    const componentsValue = (value: JobOptionType) => {
      return (
        <div className="d-flex align-items-center">
          {value.state === JobTitleState.IN_PREPARATION && (
            <div key="in_preparation" className="badge-row">
              <Badge color={BaseColor.LightBlue}>{this.ta("in_preparation")}</Badge>
            </div>
          )}
          {value.state === JobTitleState.ACTIVE && (
            <div key="active" className="badge-row">
              <Badge color={BaseColor.Green}>{this.ta("active")}</Badge>
            </div>
          )}
          {value.label}
        </div>
      );
    };

    const singleValueRender = (props: SingleValueProps<JobOptionType>) => (
      <components.SingleValue {...props}>{componentsValue(props.data)}</components.SingleValue>
    );

    const optionsRender = (props: OptionProps<JobOptionType, false>) => (
      <components.Option {...props}>{componentsValue(props.data)}</components.Option>
    );

    const sum = (
      <>
        <div className="col-md-12">
          <div className="emankit__work_duration_picker">
            <span className={`selected_hours ${isNotEqual ? "valid" : "invalid"}`} style={{ marginLeft: "auto" }}>
              {vm.isDatesSelectable
                ? `${this.props.entity.totalCapacity}/${this.props.vm?.getFreeHours?.free_rate_hours}h`
                : "0/0h"}
            </span>
          </div>
        </div>
      </>
    );

    const days = this.props.vm.days;

    const visibleDays = this.props.vm.visibleDays;

    const dayRows: any[] = [];

    {
      ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].forEach((day, index) => {
        if (visibleDays[day]) {
          dayRows.push(this.createFieldsForDay(day as DAY));
        }
        // Slice method is used for detect only days which are after current day
        // Sum is shown only when every day after is false
        if (
          this.props.vm.selectedRate?.free_rate_hours &&
          days[day] &&
          this.props.vm.daysKeys.slice(index + 1).every(day => days[day] === false)
        ) {
          dayRows.push(sum);
        }
      });
    }

    const validToLimitationPast =
      this.props.entity?.valid_from && vm.selectedRatio?.valid_from < this.props.entity?.valid_from
        ? this.props.entity?.valid_from
        : vm.selectedRatio?.valid_from;

    const minimumYesterday = this.props.entity.valid_from && min([this.props.entity.valid_from, new Date()]);

    return (
      <>
        <div className="row">
          <div className="col-md-12">
            {this.generateFormField(
              "job_title_working_time_ratio_id",
              <SelectWithSearchBox<number, JobOptionType>
                options={allowedRatiosTitles as JobOptionType[]}
                singleValueRender={singleValueRender}
                optionsRender={optionsRender}
              />,
              true,
              {
                label: this.ta("job_title_id"),
                disabled: this.props.disabledOnEdit,
              }
            )}
          </div>
          <div className="col-md-12">
            {this.generateFormField("rate_id", <SelectWithSearchBox options={vm.allowedRates} />, true, {
              label: this.ta("rate_id"),
              disabled: !vm.isRatioSelectable || this.props.disabledOnEdit,
            })}
          </div>
          <div className="col-md-6">
            {this.generateFormField(
              "valid_from",
              <DatePickerBox
                dateFrom={
                  !canCreateBeforeToday && vm.selectedRatio?.isActive
                    ? this.props.entity?.valid_from && minimumYesterday
                    : this.props.vm.contractsVM.currentContract?.valid_from
                }
                dateTo={vm.selectedRatio?.valid_to}
                disabledDays={{
                  before:
                    !canCreateBeforeToday && vm.selectedRatio?.isActive
                      ? this.props.entity?.valid_from && minimumYesterday
                      : this.props.vm.contractsVM.currentContract?.valid_from,
                  after: vm.selectedRatio?.valid_to,
                }}
              />,
              true,
              { disabled: !vm.isDatesSelectable || this.props.disabledOnEdit }
            )}
          </div>
          <div className="col-md-6">
            {this.generateFormField(
              "valid_to",
              <DatePickerBox
                dateFrom={!canCreateBeforeToday && validToLimitationPast ? add(validToLimitationPast, { days: 1 }) : undefined}
                dateTo={this.props.vm?.originalEntityValidTo ? this.props.vm?.originalEntityValidTo : undefined}
                disabledDays={{
                  before: !canCreateBeforeToday && validToLimitationPast ? add(validToLimitationPast, { days: 1 }) : undefined,
                  after: this.props.vm?.originalEntityValidTo ? this.props.vm?.originalEntityValidTo : undefined,
                }}
                disabled={this.props.vm.isCreate}
              />,
              false,
              { disabled: !vm.isDatesSelectable || !this.props.vm?.originalEntityValidTo || this.props.disabledOnEdit }
            )}
          </div>
          {dayRows}
        </div>
      </>
    );
  }
}
