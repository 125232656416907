import { observer } from "mobx-react";
import React from "react";
import Icon, { IconType } from "@eman/emankit/Icon";
import Tooltip from "@eman/emankit/Tooltip";
import SmallList from "@component/SmallList";

import PersonalDocument from "@model/PersonalDocument";
import PersonalDocumentListVM from "@vm/List/PersonalDocument";
import ViewHelpers from "@util/ViewHelpers";

import { lazyInject, TYPES } from "../../../../inversify.config";

export interface PersonalDocumentsListProps {
  userId: number;
}

@observer
export default class PersonalDocumentsList extends SmallList<
  PersonalDocument,
  PersonalDocumentListVM,
  PersonalDocumentsListProps
> {
  @lazyInject(TYPES.PersonalDocumentList)
  vm: PersonalDocumentListVM;

  modelName = "personal_document";
  prefix = "employee.personal_information";

  componentDidMount(): void {
    this.vm.setParentId(this.props.userId);
    super.componentDidMount();
  }

  renderNoteIcon(note: string) {
    return note ? (
      <Tooltip title={this.tg("note_tooltip")} trigger="mouseenter">
        <Icon icon={IconType.Document} />
      </Tooltip>
    ) : null;
  }

  renderRow = (item: PersonalDocument, column: string) => {
    switch (column) {
      case "valid_from":
      case "valid_to":
        return this.locs.formatDate(item[column]);

      case "enumeration_personal_document_type_id":
      case "issuer_state_id":
        return ViewHelpers.enumName(item, column);

      case "note":
        return this.renderNoteIcon(item.note);

      default:
        return item[column];
    }
  };

  onClick = (item: PersonalDocument): void => {
    this.objectEditOrShowLink("employees_personal_data_personal_documents", [this.props.userId, item.id!]);
  };

  rows() {
    return [
      this.createField("enumeration_personal_document_type_id", {}),
      this.createField("number", {}),
      this.createField("issuer", {}),
      this.createField("issuer_state_id", {}),
      this.createField("valid_from", {}),
      this.createField("valid_to", {}),
      this.createField("note", { hideLabel: true, nosort: true, width: 30 }),
    ];
  }
}
