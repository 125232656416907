import { observer } from "mobx-react";
import React from "react";

import SmallList from "@component/SmallList";
import Dependent from "@model/Dependent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import ViewHelpers from "@util/ViewHelpers";
import DependentListVM from "@vm/List/Dependent";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class DependentList extends SmallList<Dependent, DependentListVM, PropsFromRouter> {
  @lazyInject(TYPES.DependentList)
  vm: DependentListVM;

  modelName = "dependent";
  prefix = "employee";

  initVM() {
    this.vm.init(this.props.match.params.user_id);
  }

  renderRow = (item: Dependent, column: string): React.ReactNode => {
    switch (column) {
      case "enumeration_relationship_id":
        return item.relationship ? item.relationship.name : "";
      case "valid_from":
      case "valid_to":
      case "birth_date":
        return this.locs.formatDate(item[column]);
      case "household":
        return ViewHelpers.booleanToBadgeSmooth(this.locs, item[column]);
      default:
        return item[column];
    }
  };

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_employees_dependents(this.props.match.params.user_id));
  };

  otherRender(): React.ReactNode {
    let button;

    if (this.user.allowToObject(UserRightsObjects.DEPENDENT, UserRightsOperations.CREATE)) {
      button = (
        <Button variant={ButtonVariant.Active} onClick={this.onAddClick}>
          {this.tg("add")}
        </Button>
      );
    }

    return (
      <HeaderRow title={this.tg("title")} level={2}>
        {button}
      </HeaderRow>
    );
  }

  filterProps = (): FilterData[] => {
    return [
      {
        id: "name",
        label: this.ta("name"),
        type: "search",
      },
      {
        id: "birth_date",
        label: this.ta("birth_date"),
        type: "date_range",
      },
      {
        id: "valid_from",
        label: this.ta("valid_from"),
        type: "date_range",
      },
      {
        id: "valid_to",
        label: this.ta("valid_to"),
        type: "date_range",
      },
      {
        id: "identification",
        label: this.ta("identification"),
        type: "search",
      },
      {
        id: "household",
        label: this.ta("household"),
        single: true,
        values: ViewHelpers.booleanOptions(this.locs),
      },
      {
        id: "enumeration_relationship_id",
        label: this.ta("enumeration_relationship_id"),
        values: this.enums.valuesForSelect("relationships"),
      },
    ];
  };

  onClick = (item: Dependent) => {
    this.objectEditOrShowLink("employees_dependents", [this.props.match.params.user_id, item.id]);
  };

  rows() {
    return [
      this.createField("enumeration_relationship_id", {}),
      this.createField("name", {}),
      this.createField("identification", {}),
      this.createField("birth_date", {}),
      this.createField("household", {}),
      this.createField("valid_from", {}),
      this.createField("valid_to", {}),
    ];
  }
}
