import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";
import Panel from "@eman/emankit/Panel";

import ResourceComponent from "@component/ResourceComponent";
import JobTitleList from "./List";
import JobTitlesCheckVM from "@vm/Other/JobTitlesCheck";
import TYPES from "src/inversify.types";
import { lazyInject } from "src/inversify.config";
import EventBus, { IDisposable, JOB_TITLES_CHECK } from "@util/EventBus";

@observer
export default class JobTitle extends ResourceComponent<PropsFromRouter> {
  modelName = "job_title";
  prefix = "employee.contract";

  private readonly eventHandlers: IDisposable[] = [];

  @lazyInject(TYPES.JobTitlesCheck)
  vm: JobTitlesCheckVM;

  componentDidMount() {
    const params = this.props.match.params;
    this.vm.init(params.user_id, params.contract_id);
    this.eventHandlers.push(EventBus.on(JOB_TITLES_CHECK, this.checkShowButton));
  }

  componentWillUnmount() {
    this.eventHandlers.forEach(x => x.dispose());
  }

  onAddClick = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.new_employees_contracts_job_titles(params.user_id, params.contract_id));
  };

  checkShowButton = () => {
    const params = this.props.match.params;
    this.vm.init(params.user_id, params.contract_id);
  };

  renderButton() {
    if (!this.vm.canChangeJobTitle) {
      return null;
    }

    return (
      <Button variant={ButtonVariant.Active} onClick={this.onAddClick}>
        {this.tg("add")}
      </Button>
    );
  }

  render() {
    return (
      <Panel>
        <HeaderRow title={this.tg("title")} level={2}>
          {this.renderButton()}
        </HeaderRow>
        <JobTitleList match={this.props.match} />
      </Panel>
    );
  }
}
