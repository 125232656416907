import WorkingTimeRatio from "@model/WorkingTimeRatio";
import { injectable } from "inversify";
import BaseAssociatedRepository from "./BaseAssociatedRepository";

@injectable()
export default class WorkingTimeRatioRepository extends BaseAssociatedRepository<WorkingTimeRatio> {
  constructor() {
    super(WorkingTimeRatio, `users/$ID/contracts/$CONTRACT_ID/working_time_ratios`, "working_time_ratio");
  }

  setId(id: number, contractId: number) {
    this.id = id;
    this.uri = this.uriBackup.replace("$ID", id.toString()).replace("$CONTRACT_ID", contractId.toString());
  }
}
