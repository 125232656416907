import { observer } from "mobx-react";

import Columns from "@eman/emankit/Columns";

import BaseForm from "@component/BaseForm";
import PaymentInfo from "@model/PaymentInfo";
import { SelectWithSearchBox, TextBox } from "@util/Form";

@observer
export default class PaymentInfoForm extends BaseForm<PaymentInfo> {
  modelName = "payment_info";
  prefix = "employee.personal_information";

  renderFormBody() {
    return (
      <Columns>
        {this.generateFormField("account_number", <TextBox />, true)}
        {this.generateFormField(
          "enumeration_bank_code_id",
          <SelectWithSearchBox<number>
            options={this.enums.valuesForSelect("bank_codes", true)}
            emptyRow={this.locs.tg("form.select_value")}
          />,
          true
        )}
        {this.generateFormField("account_number_prefix", <TextBox />)}
        {this.generateFormField("iban", <TextBox />)}
      </Columns>
    );
  }
}
