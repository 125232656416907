import Rate from "@model/Rate";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import RateRepository from "@repository/Rate";
import User from "@service/CurrentUser";
import EnumVM from "@service/Enum";
import { inject, injectable } from "inversify";
import { computed, observable } from "mobx";
import TYPES from "src/inversify.types";
import maxBy from "lodash/maxBy";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

/**
 * Checks if employee can have new job title created
 */
@injectable()
export default class JobTitlesCheckVM {
  employeeId: number;
  contractId: number;

  @observable rate?: Rate;
  @observable rateLatestFrom?: Date;

  constructor(
    @inject(TYPES.RateRepository) public repository: RateRepository,
    @inject(TYPES.User) public user: User,
    @inject(TYPES.Enum) public enumService: EnumVM
  ) {}

  @computed
  get canChangeJobTitle(): boolean {
    if (!this.user.allowToObject(UserRightsObjects.JOB_TITLE, UserRightsOperations.CREATE)) {
      return false;
    }

    return this.rate !== undefined;
  }

  async init(employeeId: number, contractId: number) {
    if (this.employeeId == employeeId && this.contractId == contractId) {
      return;
    }

    this.repository.setId(employeeId);

    let fetchActiveAndInPreparation: Rate[] = [];

    if (this.user.allowToObject(UserRightsObjects.RATE, UserRightsOperations.LIST)) {
      fetchActiveAndInPreparation = (await this.repository.fetchActiveAndInPreparation(contractId)).list;
    } else {
      fetchActiveAndInPreparation = [];
    }

    this.rate = fetchActiveAndInPreparation[0];

    this.rateLatestFrom = fetchActiveAndInPreparation.some(value => value.valid_to === null)
      ? undefined
      : maxBy(fetchActiveAndInPreparation || [], "valid_to")?.valid_to;

    if (this.rate) {
      this.enumService.assignObjectEnum(this.rate);
    }
  }
}
