import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import Skill from "@model/Skill";
import SkillShowVM from "@vm/Show/Skill";
import { lazyInject, TYPES } from "../../../inversify.config";

import SkillForm from "./Form";

@observer
export default class SkillShowModal extends ShowModal<Skill> {
  modelName = "skill";
  prefix = "employee";

  @lazyInject(TYPES.SkillShow)
  vm: SkillShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_skills(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <>
        <SkillForm entity={this.vm.entity} readonly={true} />
      </>
    );
  }
}
