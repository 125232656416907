import { inject, injectable } from "inversify";
import { computed } from "mobx";
import keyBy from "lodash/keyBy";

import Company from "@model/Company";
import Employee from "@model/Employee";
import JobPosition from "@model/JobPosition";
import BranchOffice from "@model/BranchOffice";
import OrganizationUnit from "@model/OrganizationUnit";
import Role from "@model/Role";
import ListViewModel from "@vm/List/ListViewModel";
import OptionsVM from "@vm/Other/Options";
import CurrentUser from "@service/CurrentUser";

import TYPES from "../../inversify.types";
import { EvidenceStateStatus } from "@model/EvidenceState";
import BranchOfficeOptionsVM from "@vm/Other/BranchOfficeOptions";
import OrganizationStructure from "@model/OrganizationStructure";
import ViewHelpers from "@util/ViewHelpers";
import { UserStateByContract } from "@model/User";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class EmployeeListVM extends ListViewModel<Employee, Repository<Employee>> {
  static defaults = {
    order: {
      field: "last_name",
      direction: "asc",
    },
    columns: [
      "action",
      "avatar",
      "first_name",
      "last_name",
      "job_title_job_position_name",
      "job_title_organization_unit_name",
      "contract_enumeration_contract_type_id",
    ],
    visibleFilters: [
      "first_name",
      "last_name",
      "active_job_titles_job_position_id",
      "active_job_titles_organization_unit_id",
      "contracts_contract_type_id",
      "evidence_state_evidence_status",
      "contract_state",
    ],
    filters: {
      evidence_state_evidence_status: {
        operator: "in",
        values: [EvidenceStateStatus.ACTIVED],
      },
      contract_state: {
        operator: "in",
        values: [
          UserStateByContract.ACTIVE,
          UserStateByContract.CREATED,
          UserStateByContract.DISMISSAL,
          UserStateByContract.PROBATION,
        ],
      },
    },
  };

  private roleOptions: OptionsVM<Role>;
  private jobPositionNameOptions: OptionsVM<JobPosition>;
  private jobPositionsOptions: OptionsVM<JobPosition>;
  private organizationsOptions: OptionsVM<OrganizationStructure>;
  private organizationUnitNameOptions: OptionsVM<OrganizationUnit>;
  private companyNameOptions: OptionsVM<Company>;
  private branchOfficeOptions: OptionsVM<BranchOffice>;

  constructor(
    @inject(TYPES.EmployeeRepository) repository: Repository<Employee>,
    @inject(TYPES.RoleRepository) roleRepository: Repository<Role>,
    @inject(TYPES.JobPositionRepository) jobRepository: Repository<JobPosition>,
    @inject(TYPES.OrganizationUnitRepository) organizationUnitRepository: Repository<OrganizationUnit>,
    @inject(TYPES.OrganizationStructureRepository) organizationStructureRepository: Repository<OrganizationStructure>,

    @inject(TYPES.CompanyRepository) companyRepository: Repository<Company>,
    @inject(TYPES.BranchOfficeRepository) branchOfficeRepository: Repository<BranchOffice>,
    @inject(TYPES.User) private currentUser: CurrentUser
  ) {
    super(repository);
    this.roleOptions = new OptionsVM(roleRepository, "name");
    this.jobPositionNameOptions = new OptionsVM(jobRepository, "name");
    this.organizationUnitNameOptions = new OptionsVM(organizationUnitRepository, "name");
    this.companyNameOptions = new OptionsVM(companyRepository, "name");
    this.branchOfficeOptions = new BranchOfficeOptionsVM(branchOfficeRepository, "name");
    this.jobPositionsOptions = new OptionsVM(jobRepository, "name", {
      order: { direction: "asc", field: "id" },
      filters: {
        primary: {
          operator: "in",
          values: true,
        },
      },
    });
    this.organizationsOptions = new OptionsVM(organizationStructureRepository, "name", {
      order: { direction: "asc", field: "id" },
      filters: {
        primary: {
          operator: "in",
          values: true,
        },
      },
    });
  }

  @computed
  get roles() {
    return this.roleOptions.selectOptions;
  }

  @computed
  get jobPositionNames() {
    return this.jobPositionNameOptions.selectOptions;
  }

  @computed
  get organizationUnitNames() {
    return ViewHelpers.createSubOrganizationOptions(this.organizationUnitNameOptions.items);
  }

  @computed
  get getJobPositionsOptions() {
    return this.jobPositionsOptions.items;
  }

  @computed
  get getOrganizationsOptions() {
    return this.organizationsOptions.items;
  }

  @computed
  get companyNames() {
    return this.companyNameOptions.selectOptions;
  }

  @computed
  get branchOfficeNames() {
    return this.branchOfficeOptions.selectOptions;
  }

  roleName(roleId: number): string | undefined {
    return this.roleOptions.items?.find(item => item.id === roleId)?.name;
  }

  jobPositionName(id: number): string | undefined {
    return this.jobPositionNameOptions.items?.find(x => x.id === id)?.name;
  }

  companyName(id: number): string | undefined {
    return this.companyNameOptions.items?.find(x => x.id === id)?.name;
  }

  branchOfficeName(id: number): string | undefined {
    return this.branchOfficeOptions.items?.find(x => x.id === id)?.name;
  }

  jobPositionById(id: number) {
    return this.jobPositionNameOptions.items?.find(x => x.id === id);
  }

  organizationUnitName(id: number): string | undefined {
    return this.organizationUnitNameOptions.items?.find(x => x.id === id)?.name;
  }

  @computed get jobPositions(): { [key: number]: JobPosition | undefined } {
    return keyBy(this.jobPositionNameOptions.items || [], "id");
  }

  defaultFiltersValues(): FilterValues | undefined {
    const filters: any = super.defaultFiltersValues() || {};

    if (this.currentUser.entity.parent_company) {
      filters.company_id = {
        operator: "in",
        values: `${this.currentUser.entity.parent_company.id}`, // @TODO fix this - it should working with numbers
      };
    }

    return filters;
  }

  // tslint:disable-next-line: bool-param-default
  async setFilters(filters: FilterValues, visible?: string[], skipFetching?: boolean, save?: boolean, reset?: boolean) {
    this.pagination.page = 0; // Reset pagination when filter changes

    // Checking for all options checked
    [
      { id: "active_job_titles_job_position_id", options: this.jobPositionNameOptions.items },
      { id: "active_job_titles_organization_unit_id", options: this.organizationUnitNameOptions.items },
      { id: "active_primary_job_title_organization_unit_id", options: this.organizationsOptions.items },
      { id: "active_primary_job_title_job_position_id", options: this.jobPositionsOptions.items },
    ].forEach(item => ViewHelpers.cleanFilters(item.options, filters, item.id));

    // If filter not visible delete it
    if (visible) {
      [
        "active_primary_job_title_organization_unit_id",
        "active_primary_job_title_job_position_id",
        "evidence_state_evidence_status",
        "contracts_enumeration_user_type_id",
        "contracts_contract_type_id",
        "contracts_branch_office_id",
        "contracts_company_id",
        "contract_state",
        "active_job_titles_organization_unit_id",
        "active_job_titles_job_position_id",
        "active_job_titles_job_position_specialist",
        "role_id",
        "degree_after",
        "degree_before2",
        "degree_before1",
        "employee_number",
        "redmine_uuid",
        "enumeration_citizenship_id",
        "last_name_at_birth",
        "email",
        "middle_name",
        "last_name",
        "first_name",
      ].forEach(item => !visible.includes(item) && delete filters[item]);
    }

    return super.setFilters(filters, visible, skipFetching, save, reset);
  }
}
