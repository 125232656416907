import { injectable } from "inversify";

import Company from "@model/Company";
import BaseRepository from "@repository/BaseRepository";

@injectable()
export default class CompanyRepository extends BaseRepository<Company> {
  constructor() {
    super(Company, "companies", "company");
  }
}
