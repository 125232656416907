import { inject, injectable } from "inversify";

import PersonalDocument from "@model/PersonalDocument";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import TYPES from "../../inversify.types";
import PersonalDocumentFormVM from "@vm/Form/PersonalDocument";
import { action } from "mobx";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PersonalDocumentCreateVM extends AssociatedCreateViewModel<
  PersonalDocument,
  AssociatedRepository<PersonalDocument>
> {
  constructor(
    @inject(TYPES.PersonalDocumentRepository) repository: AssociatedRepository<PersonalDocument>,
    @inject(TYPES.PersonalDocumentForm) public personalDocumentFormVM: PersonalDocumentFormVM
  ) {
    super(PersonalDocument, repository);
  }

  @action
  resetEntityAndErrors() {
    super.resetEntityAndErrors();
    this.personalDocumentFormVM.setEntity(this.entity);
  }
}
