import AllocationItem from "@model/AllocationItem";
import AllocationTableItem from "../Item";
import cn from "classnames";
import { AllocationStatus } from "@model/Allocation";

interface Props extends React.HTMLProps<HTMLDivElement> {
  allocation: AllocationItem;
}

const AllocationRowItem = ({ allocation, className, ...rest }: Props) => {
  let rowClassName = "";

  const allocationItem = allocation.allocation;

  // Keep this comparation isnstead of computed values,
  if (
    [AllocationStatus.PROBLEM, AllocationStatus.WARNING, AllocationStatus.REQUEST].indexOf(
      allocationItem.enumeration_allocation_status
    ) !== -1
  ) {
    if (allocation.allocation.service) {
      rowClassName = "not-approved-service";
    } else if (!allocation.allocation.is_work) {
      rowClassName = "not-work-not-approved";
    } else {
      rowClassName = "not-approved";
    }
  } else if (allocationItem.enumeration_allocation_status === AllocationStatus.APPROVED) {
    if (allocation.allocation.service) {
      rowClassName = "approved-service";
    } else if (!allocation.allocation.is_work) {
      rowClassName = "not-work-approved";
    } else {
      rowClassName = "approved";
    }
  }

  const customClassName = cn("allocation-item", className, rowClassName, {
    problem: allocationItem.enumeration_allocation_status === AllocationStatus.PROBLEM,
    warning: allocationItem.enumeration_allocation_status === AllocationStatus.WARNING,
  });

  return (
    <AllocationTableItem className={customClassName} {...rest}>
      {allocation.value}h
    </AllocationTableItem>
  );
};

export default AllocationRowItem;
