import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";

import Breadcrumb from "@component/Breadcrumb";

export type PageBackgroundColorType = "white" | "grey";

export interface PageRouteProps extends RouteProps {
  title?: string;
  backgroundColor?: PageBackgroundColorType;
  noOverflow?: boolean;
}

// Create and export the component
export default class PageRoute extends React.Component<PageRouteProps> {
  componentDidMount(): void {
    if (this.props.backgroundColor && this.props.backgroundColor === "grey") {
      document.body.classList.add("grey-background");
    }

    if (this.props.noOverflow) {
      document.body.classList.add("no-overflow");
    }
  }

  componentWillUnmount(): void {
    if (this.props.backgroundColor && this.props.backgroundColor === "grey") {
      document.body.classList.remove("grey-background");
    }

    if (this.props.noOverflow) {
      document.body.classList.remove("no-overflow");
    }
  }

  render() {
    const { title, component, render, ...otherProps } = this.props;

    if (title) {
      // tslint:disable:jsx-no-lambda
      return (
        <Route
          {...otherProps}
          render={(routeComponentProps: RouteComponentProps) => (
            <Breadcrumb
              crumb={{
                title,
                pathname: routeComponentProps.match.url,
              }}>
              {component ? React.createElement(component as any, { ...routeComponentProps }) : null}
              {render ? render(routeComponentProps) : null}
            </Breadcrumb>
          )}
        />
      );
    } else {
      return <Route {...otherProps} component={component} render={render} />;
    }
  }
}
