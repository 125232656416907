import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import Certification from "@model/Certification";
import CertificationEditVM from "@vm/Edit/Certification";

import CertificationForm from "./Form";
import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class CertificationEdit extends EditModal<Certification> {
  modelName = "certification";
  prefix = "employee";

  @lazyInject(TYPES.CertificationEdit)
  vm: CertificationEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_certifications(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <>
        <CertificationForm entity={this.vm.entity} />
      </>
    );
  }
}
