import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";

import BaseComponent from "@component/BaseComponent";
import RateItem from "@model/RateItem";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import Item from "./Item";

export interface RateItemsProps {
  onAddItem?: () => void;
  onRemoveItem?: (item: RateItem) => void;
  items: RateItem[];
  withState?: boolean;
  readonly?: boolean;
}

@observer
export default class RateItems extends BaseComponent<RateItemsProps> {
  render() {
    const { items, onRemoveItem, onAddItem, readonly, withState } = this.props;

    return (
      <>
        {items.map((item, index) => {
          let onRemoveClick;
          if (onRemoveItem) {
            onRemoveClick = () => {
              onRemoveItem(item);
            };
          }

          return (
            <Item
              key={`item-${index}`}
              entity={item}
              onRemoveClick={onRemoveClick}
              index={index}
              withState={withState}
              readonly={readonly}
            />
          );
        })}
        <div className="mt-20">
          {this.user.allowToObject(UserRightsObjects.RATE_ITEM, UserRightsOperations.CREATE) && !readonly && onAddItem && (
            <Button type="button" onClick={onAddItem} variant={ButtonVariant.Active}>
              {this.locs.tg("employee.contract.rate.form.add_item")}
            </Button>
          )}
        </div>
      </>
    );
  }
}
