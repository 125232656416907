import { Exclude, Transform, Type } from "@eman/class-transformer";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";
import { enumValue } from "@util/Enumerable";

export enum PersonalDocumentType {
  IDENTITY_CARD = "1",
  PASSPORT = "2",
  DRIVING_LICENSE = "3",
  OTHER = "4",
  WORK_PERMIT = "5",
  IČO = "6",
}
export default class PersonalDocument extends BaseModel {
  @observable number: number;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;
  @observable issuer: string;

  @observable enumeration_personal_document_type_id: number;
  @observable issuer_state_id: number;

  @observable note: string;

  @Exclude()
  @enumValue("states", "issuer_state_id")
  @observable
  issuer_state: models.IEnumType;

  @Exclude()
  @enumValue("personal_document_types", "enumeration_personal_document_type_id")
  @observable
  personal_document_type: models.IEnumType;
}
