import Dependent from "@model/Dependent";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class DependentListVM extends AssociatedListViewModel<Dependent, AssociatedRepository<Dependent>> {
  static defaults = {
    order: {
      field: "valid_from",
      direction: "desc",
    },
    columns: ["enumeration_relationship_id", "name", "identification", "birth_date", "household"],
    visibleFilters: ["name"],
  };

  constructor(@inject(TYPES.DependentRepository) repository: AssociatedRepository<Dependent>) {
    super(repository);
  }
}
