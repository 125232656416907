import { observer } from "mobx-react";

import FileBar from "@eman/emankit/FileBar";

import BaseComponent from "@component/BaseComponent";

export interface AttachmentsProps {
  vm: ViewModel.WithAttachments;
}

@observer
export default class Attachments extends BaseComponent<AttachmentsProps> {
  render() {
    const attachments = this.props.vm.attachments;

    if (!attachments.length) {
      return null;
    }

    return (
      <div className="row">
        {attachments.map((item, index: number) => {
          const onDeleteClick = () => this.props.vm.removeFile(item.id!);

          return (
            <div className="col-md-4 col-sm-6 mb-20" key={`file-${index}`}>
              <FileBar title={item.name} total={item.total} onDelete={onDeleteClick} />
            </div>
          );
        })}
      </div>
    );
  }
}
