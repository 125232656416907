import BaseComponent from "@component/BaseComponent";

import Menu from "@eman/emankit/Menu";
import { MenuItemProps } from "@eman/emankit/Menu/MenuItem";
import { UserRightsObjects, UserRightsOperations, UserRightsPages } from "@model/Rights";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { SynchronizedHistory } from "mobx-react-router";

export interface MenuComponentState {
  active?: string;
}

@observer
export default class MenuComponent extends BaseComponent<{}, MenuComponentState> {
  unregisterSubscriber: UnregisterCallback;

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const history = this.router.history as SynchronizedHistory;
    this.unregisterSubscriber = history.subscribe((location, action) => {
      const paths = location.pathname.split("/");
      if (paths.length) {
        this.setState({ active: paths[1] });
      } else {
        this.setState({ active: undefined });
      }
    });
  }

  componentWillUnmount() {
    const history = this.router.history as SynchronizedHistory;
    history.unsubscribe = this.unregisterSubscriber;
  }

  changeMenu = (key: string) => {
    this.router.pageLink(key);
  };

  render() {
    const { active } = this.state;
    const menuItems: MenuItemProps[] = [];

    if (this.user.allowToPage(UserRightsPages.EMPLOYEES)) {
      menuItems.push({
        id: this.uriHelper.employees(),
        label: `${this.locs.tm("employee", 5)}`,
      });
    } else {
      menuItems.push({
        id: this.uriHelper.show_employees(this.user.entity.id),
        label: `${this.locs.tm("employee", 1)}`,
      });
    }

    if (this.user.allowToPage(UserRightsPages.EMPLOYER)) {
      menuItems.push({
        id: this.uriHelper.employer(),
        label: `${this.locs.tm("employer", 1)}`,
      });
    }

    if (this.user.allowToPage(UserRightsPages.ALLOCATION)) {
      menuItems.push({
        id: this.uriHelper.allocations(),
        label: `${this.locs.tm("allocation", 5)}`,
      });
    }

    if (this.user.allowToPage(UserRightsPages.ANNOUNCEMENT)) {
      menuItems.push({
        id: this.uriHelper.announcements_uri(),
        label: `${this.locs.tm("announcement")}`,
        count: this.user.entity.announcement_count,
      });
    }

    if (this.user.allowToObject(UserRightsObjects.EXPORT, UserRightsOperations.LIST)) {
      menuItems.push({
        id: this.uriHelper.exports_uri(),
        label: `${this.locs.tm("export", 5)}`,
      });
    }

    return <Menu items={menuItems} onChange={this.changeMenu} active={active} />;
  }
}
