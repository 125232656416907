import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";

import BaseComponent from "@component/BaseComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import UserEditVM from "@vm/Edit/User";
import PaymentInfosList from "./List";

export interface PaymentInfosProps extends PropsFromRouter {
  vm: UserEditVM;
}

@observer
export default class PaymentInfos extends BaseComponent<PaymentInfosProps> {
  tg = (code: string) => {
    return this.locs.tg(`employee.personal_information.payment_info.${code}`);
  };

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_employees_personal_data_payment_infos(this.props.vm.id));
  };

  render() {
    let button;
    if (this.user.allowToObject(UserRightsObjects.PAYMENT_INFO, UserRightsOperations.CREATE)) {
      button = (
        <Button variant={ButtonVariant.Active} onClick={this.onAddClick}>
          {this.tg("add")}
        </Button>
      );
    }

    return (
      <>
        <HeaderRow title={this.tg("title")} level={2}>
          {button}
        </HeaderRow>
        <PaymentInfosList userId={this.props.match.params.user_id} />
      </>
    );
  }
}
