import { Transform, Type } from "@eman/class-transformer";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";

export enum CONTACT_TYPES {
  PHONE = "Telefon",
  SKYPE = "Skype",
  EMAIL = "Email",
  WEB = "Web",
  FACEBOOK = "Facebook",
  LINKEDIN = "LinkedIn",
}

export default class Contact extends BaseModel {
  @observable contact_type: string;
  @observable contactable_id: number;
  @observable contactable_type: string;
  @observable description: string;
  @observable public: boolean;
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;
  @observable value: string;
  @observable work: boolean;
}
