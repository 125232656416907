import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import JobPosition from "@model/JobPosition";
import JobPositionShowVM from "@vm/Show/JobPosition";
import JobPositionForm from "./Form";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class JobPositionShowModal extends ShowModal<JobPosition> {
  modelName = "job_position";
  prefix = "employer";

  @lazyInject(TYPES.JobPositionShow)
  vm: JobPositionShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employer_job_positions());
  };

  renderModalBody() {
    return (
      <JobPositionForm
        entity={this.vm.entity}
        readonly={true}
        organizationStructureOptions={this.vm.availableOrganizationStructures}
      />
    );
  }
}
