import { injectable } from "inversify";

import JobPosition from "@model/JobPosition";
import BaseRepository from "@repository/BaseRepository";

@injectable()
export default class PositionRepository extends BaseRepository<JobPosition> {
  constructor() {
    super(JobPosition, "job_positions", "job_position");
  }
}
