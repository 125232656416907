import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";
import { injectable } from "inversify";

import EnumType from "@model/EnumType";
import ApiClient from "@util/ApiClient";

@injectable()
export default class EnumRepository extends BaseAssociatedRepository<EnumType> {
  constructor() {
    super(EnumType, "enumerations/$ID", "enum");
  }

  fetchCurrent(loading: false): Promise<EnumContainer> {
    const url = this.uriBackup.replace("/$ID", "");

    const config = {
      url,
      id: "FETCH_ENUMS",
      loading,
    };
    return ApiClient.fetchData(config).then(response => {
      const data: EnumContainer = {};
      const items = Object.entries(response);

      items.forEach(enm => {
        // name: enm[0], values: enm[1]
        data[enm[0]] = enm[1] as EnumType[];
      });

      return data;
    });
  }
}
