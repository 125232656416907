import { inject, postConstruct, unmanaged } from "inversify";
import { action, observable } from "mobx";

import EventBus, {
  ENTITY_CREATED,
  ENTITY_DELETED,
  ENTITY_UPDATE,
  LOCALE_CHANGE,
  REFRESH_USER_FILTER_SETTINGS,
  SCROLL_TOP,
} from "@util/EventBus";

import BaseModel from "@model/BaseModel";
import ViewModel from "@vm/ViewModel";
import Enum from "@service/Enum";

import TYPES from "../../inversify.types";
import { defaultFiltersValues } from "../../config";

export default abstract class ItemsViewModel<TModel extends BaseModel, TRepository extends Repository<TModel>>
  extends ViewModel<TModel, TRepository>
  implements ViewModel.Items<TModel> {
  /**
   * Items collection.
   */
  @observable list: TModel[] = [];

  /**
   * Filter settings.
   */
  @observable selectedItems: FilterValues;

  /**
   * Visible filter items.
   */
  @observable visibleFilters: string[];

  /**
   * Sortable settings.
   */
  @observable order: OrderOptions;

  /**
   * Save ListQuery (send save == false to API).
   */
  save = true;

  @observable total = 20;

  /**
   * Preference prefix - recognize Public vs Admin section
   */
  preferencePrefix: string;

  @inject(TYPES.Enum)
  protected enums: Enum;

  constructor(@unmanaged() repository: TRepository) {
    super(repository);

    // tslint:disable:no-string-literal
    this.setSettings(this.constructor["defaults"] || {});
    EventBus.on(LOCALE_CHANGE, this.resetEnums);
  }

  @postConstruct()
  runAfterConstruct() {
    const defaultFilterValues = this.defaultFiltersValues();
    if (defaultFilterValues) {
      this.setSettings({ filters: defaultFilterValues });
    }

    EventBus.on(ENTITY_UPDATE, this.entityCallback);
    EventBus.on(ENTITY_CREATED, this.entityCallback);
    EventBus.on(ENTITY_DELETED, this.entityCallback);
  }

  /**
   * Overload at children if you need specify dynamic default filter values
   */
  defaultFiltersValues(): FilterValues | undefined {
    const filtersFromConfig = defaultFiltersValues.get(this.repository.classModelName);
    const filtersFromVM = this.constructor["defaults"] ? this.constructor["defaults"].filters : undefined;

    if (!filtersFromConfig && !filtersFromVM) {
      return undefined;
    } else {
      // Merge default filters from config file and default filters from VMs - default filters from config are prioritized
      return {
        ...(filtersFromVM || {}),
        ...(filtersFromConfig || {}),
      };
    }
  }

  entityCallback = (params: any) => {
    if (params?.identificator === this.repository.classModelName) {
      this.fetchList(false, false);
    }
  };

  /**
   * Set settings data.
   *
   * @param data Settings values
   */
  @action
  setSettings({ order, filters, visibleFilters, save }: ItemsSettings) {
    if (order) {
      this.order = order;
    }

    if (visibleFilters) {
      this.visibleFilters = visibleFilters;
    }

    this.selectedItems = filters || {};

    this.save = save !== undefined ? save : true;
  }

  /**
   * Fetch items from server via repository.
   */
  async fetchList(scrollTop = true, save = true, pagination?: any) {
    this.currentlyFetching = true;

    const items = await this.repository.fetchItems({
      ...(pagination && { limit: pagination.pageSize, offset: pagination.page * pagination.pageSize }),
      filters: this.selectedItems,
      loading: this.loading,
      order: this.order,
      save: this.save && save,
      visibleFilters: this.visibleFilters,
      preferencePrefix: this.preferencePrefix,
    });

    this.total = (items as any).count;

    if (this.save && save) {
      EventBus.trigger(REFRESH_USER_FILTER_SETTINGS);
    }

    this.setList(items.items);

    if (scrollTop && this.scroll) {
      EventBus.trigger(SCROLL_TOP);
    }

    this.currentlyFetching = false;
  }

  /**
   * Change filtering.
   *
   * @param {FilterValues} filters
   * @param {string[]} visible Visible filter list
   * @param {boolean} skipFetch Don't call fetchList(), view take care about it itself
   * @param {boolean} save Save the filters to user preference
   * @memberof ListViewModelBase
   */
  @action
  // tslint:disable-next-line: bool-param-default
  async setFilters(selectedItems: FilterValues, visible?: string[], skipFetching?: boolean, save?: boolean, reset?: boolean) {
    // filter reset
    if (reset) {
      this.selectedItems = this.defaultFiltersValues() || {};
    } else {
      this.selectedItems = selectedItems;
    }

    if (visible !== undefined) {
      this.visibleFilters = visible;
    }

    if (save !== undefined) {
      this.save = save;
    }

    if (skipFetching !== true) {
      await this.fetchList();
    }
  }

  /**
   * Test if filter is set to entity value, this is really for sub view models in entities.
   *
   * @param {string} key
   * @param {string[]} value
   * @memberof ListViewModel
   */
  hasFilter(key: string, value: models.IBase) {
    if (value.id) {
      return this.selectedItems && this.selectedItems[key].values === [value.stringId!];
    } else {
      return false;
    }
  }

  /**
   * Call parent and add save == false.
   */
  setAsChildVM() {
    super.setAsChildVM();
    this.save = false;
  }

  /**
   * Initialize (method to override in children, for loading more required items)
   */
  init(...rest: any) {
    return this.fetchList();
  }

  /**
   * Set items from api
   *
   * @param {TModel[]} list
   */
  @action
  setList(list: TModel[]) {
    this.list = list;
    this.list.forEach(item => this.enums.assignObjectEnum(item));
  }

  /**
   * Change sorting.
   *
   * @param {OrderOptions} sort
   * @memberof ListViewModelBase
   */
  @action
  setOrder = (order: OrderOptions) => {
    this.order = order;
    return this.fetchList();
  };

  @action.bound
  resetEnums() {
    this.list.forEach(item => this.enums.resetObjectEnums(item));
  }
}
