import { observer } from "mobx-react";
import React from "react";

import Columns from "@eman/emankit/Columns";

import BaseForm from "@component/BaseForm";
import HealthInsurance from "@model/HealthInsurance";
import { DatePickerBox, SelectBox, TextBox } from "@util/Form";

@observer
export default class HealthInsuranceForm extends BaseForm<HealthInsurance> {
  modelName = "health_insurance";
  prefix = "employee";

  renderFormBody() {
    return (
      <Columns>
        {this.generateFormField(
          "enumeration_health_insurance_office_id",
          <SelectBox
            options={this.enums.valuesForSelect("health_insurance_offices")}
            emptyRow={this.locs.tg("form.select_value")}
          />,
          true
        )}
        {this.generateFormField("valid_from", <DatePickerBox />, true)}
        {this.generateFormField("insurance_number", <TextBox />)}
      </Columns>
    );
  }
}
