import { observer } from "mobx-react";
import { Tooltip } from "react-tippy";

import BaseForm from "@component/BaseForm";
import Allocation from "@model/Allocation";
import { DatePickerBox, GenericFormField, TextAreaBox, TextBox, TimeBox } from "@util/Form";
import SelectWithSearchBox from "@util/Form/BindingElement/SelectWithSearchBox";

import AllocationFormVM, { PossibleDay } from "@vm/Form/Allocation";
import CheckBox from "@util/Form/BindingElement/CheckBox";
import { JobOptionType, UserAllocationStateOptionType } from "@util/ViewHelpers";
import { JobTitleState } from "@model/JobTitle";
import { BaseColor } from "@eman/emankit/Colors";
import Badge from "@eman/emankit/Badge";
import { components, OptionProps, SingleValueProps } from "react-select";
import { UserAllocationState } from "@model/User";
import ProgressIndicator from "@eman/emankit/ProgressIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface AllocationFormProps {
  vm: AllocationFormVM;
  canCreateEditBeforeToday?: boolean;
}

@observer
export default class AllocationForm extends BaseForm<Allocation, AllocationFormProps> {
  modelName = "allocation";

  componentWillUnmount(): void {
    this.props.vm.turnOffReactions();
  }

  createDayCheckBoxFor(vm: AllocationFormVM, day: PossibleDay) {
    const excessiveDay = vm.isExcessiveDay(day.name);

    return (
      <div key={day.name}>
        <div className="col-12">
          <div style={{ maxWidth: 100 }}>
            <GenericFormField
              label={
                <>
                  {this.locs.ta("capacity", day.name)}
                  <Tooltip title={this.dayTooltipTitle(day)} arrow={true} animation="shift" className="emankit__label_tooltip">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                  {excessiveDay && (
                    <Tooltip
                      title={this.tg("overload_allocation")}
                      arrow={true}
                      animation="shift"
                      style={{ display: "inline-block" }}
                      className="emankit__label_tooltip">
                      ⚠
                    </Tooltip>
                  )}
                </>
              }
              target={this.props.entity.value.week_amount}
              property={day.name}
              format="column"
              className={excessiveDay ? "warning" : undefined}
              disabled={this.props.readonly}>
              <TextBox
                type="number"
                disabledDecimal
                onValueChanged={(v: number) => {
                  this.props.vm.onDayValueChanged(v, day.name);
                  this.props.vm.minMaxInDayValidation(v, day.name);
                }}
              />
            </GenericFormField>
          </div>
        </div>
      </div>
    );
  }

  private dayTooltipTitle(propertyName: PossibleDay): string | undefined {
    const difference =
      propertyName.capacity - (this.props.entity.value.week_amount ? this.props.entity.value.week_amount[propertyName.name] : 0);
    const date = propertyName.date?.toLocaleString("cs-CS", { year: "numeric", month: "numeric", day: "numeric" });

    return `${date ? date + "<br>" : ""} ${this.tg(difference < 0 ? "over_hours" : "free_hours")}: ${Math.abs(difference)}`;
  }

  createTimeFromPickerFor(propertyName: string) {
    return (
      <div key={propertyName}>
        <div className="col-12">
          <div style={{ maxWidth: 100 }}>
            <GenericFormField
              label={this.ta("valid_from")}
              target={this.props.entity.valid_from_time}
              property={propertyName}
              format="column"
              disabled={this.props.readonly}>
              <TimeBox inMinutes={true} />
            </GenericFormField>
          </div>
        </div>
      </div>
    );
  }

  parseNumber = (value: string) => {
    return parseInt(value, 10);
  };

  // tslint:disable-next-line: cognitive-complexity
  renderFormBody() {
    const { entity, vm, canCreateEditBeforeToday } = this.props;

    if (!entity) {
      return null;
    }

    let parentSelectBox = null;
    let childSelectBox = null;

    if (!entity.is_work) {
      parentSelectBox = this.generateFormField(
        "enumeration_allocation_type_id",
        <SelectWithSearchBox options={vm.possibleAllocationTypes} parseValue={this.parseNumber} emptyRow={this.emptyRow} />,
        true
      );
    } else if (vm.possibleGroups?.length > 0) {
      parentSelectBox = this.generateFormField(
        "project_parent_id",
        <SelectWithSearchBox options={vm.possibleGroups} allowEmpty={true} />,
        true,
        { errorProperty: entity.project_parent_id ? undefined : "project_id" }
      );

      if (entity.project_parent_id) {
        childSelectBox = this.generateFormField("project_id", <SelectWithSearchBox options={vm.possibleChilds} />, true, {
          label: this.locs.ta("allocation", "deal_id"),
        });
      }
    } else {
      parentSelectBox = this.generateFormField(
        "project_id",
        <SelectWithSearchBox options={vm.possibleProjects} allowEmpty={true} />
      );
    }

    const userLabel = (
      <>
        {this.ta("user_id")}
        {vm.userHasNoCapacities && (
          <Tooltip title={this.tg("user_has_no_capacities")} arrow={true} animation="shift" className="emankit__label_tooltip">
            ⚠
          </Tooltip>
        )}
      </>
    );

    const validFromLabel = (
      <>
        {this.ta("valid_from")}
        {vm.noCapacitiesValidFrom && (
          <Tooltip title={this.tg("no_capacities_from")} arrow={true} animation="shift" className="emankit__label_tooltip">
            ⚠
          </Tooltip>
        )}
      </>
    );

    const componentsValue = (value: JobOptionType) => {
      return (
        <div className="d-flex align-items-center">
          {value.state === JobTitleState.IN_PREPARATION && (
            <div key="in_preparation" className="badge-row">
              <Badge color={BaseColor.LightBlue}>{this.locs.tg("employee.contract.job_title.state.in_preparation")}</Badge>
            </div>
          )}
          {value.state === JobTitleState.ACTIVE && (
            <div key="active" className="badge-row">
              <Badge color={BaseColor.Green}>{this.locs.tg("employee.contract.job_title.state.active")}</Badge>
            </div>
          )}
          {value.label}
        </div>
      );
    };

    const UserAllocationComponentsValue = (value: UserAllocationStateOptionType) => {
      return (
        <div className="d-flex align-items-center user-allocation-state-option">
          {value.label}
          {value.allocation_state === UserAllocationState.MISSING_CAPACITY && (
            <div key="missing_capacity" className="badge-row">
              <Badge color={BaseColor.Orange}>⚠ {this.locs.tg("employee.capacity.missing")}</Badge>
            </div>
          )}
          {value.allocation_state === UserAllocationState.MISSING_JOB_TITLE && (
            <div key="missing_job_title" className="badge-row">
              <Badge color={BaseColor.Red}>⚠ {this.locs.tg("employee.contract.job_title.missing")}</Badge>
            </div>
          )}
        </div>
      );
    };

    const singleValueRender = (props: SingleValueProps<JobOptionType>) => (
      <components.SingleValue {...props}>{componentsValue(props.data)}</components.SingleValue>
    );

    const singleValueRenderPossibleUsers = (props: SingleValueProps<UserAllocationStateOptionType>) => (
      <components.SingleValue {...props}>{UserAllocationComponentsValue(props.data)}</components.SingleValue>
    );

    const optionsRender = (props: OptionProps<JobOptionType, false>) => (
      <components.Option {...props}>{componentsValue(props.data)}</components.Option>
    );

    const optionsRenderPossibleUsers = (props: OptionProps<UserAllocationStateOptionType, false>) => (
      <components.Option {...props}>{UserAllocationComponentsValue(props.data)}</components.Option>
    );

    const toDay = new Date();

    const validFromFromLimitation =
      vm.currentCapacity?.valid_from && vm.currentCapacity?.valid_from <= toDay ? toDay : vm.currentCapacity?.valid_from;

    return (
      <>
        {vm.allocationFormLoading && <ProgressIndicator />}
        <div className="row">
          {!entity.is_work && !!this.props.vm.remainingVacation && (
            <div className="col-md-12">
              <GenericFormField
                key={"remainingVacation"}
                target={this.props.vm}
                property={"remainingVacation"}
                label={this.ta("remaining_vacation")}
                required={false}
                disabled={true}>
                {<TextBox />}
              </GenericFormField>
            </div>
          )}
          <div className="col-md-6">
            {this.generateFormField(
              "user_id",
              <SelectWithSearchBox<number, UserAllocationStateOptionType>
                options={vm.possibleUsers}
                singleValueRender={singleValueRenderPossibleUsers}
                optionsRender={optionsRenderPossibleUsers}
                emptyRow={this.emptyRow}
              />,
              true,
              {
                label: userLabel,
                disabled: !vm.allowUserSelection,
              }
            )}
          </div>
          {entity.is_work && (
            <>
              <div className="col-md-6">
                {this.generateFormField(
                  "job_title_working_time_ratio_id",
                  <SelectWithSearchBox<number, JobOptionType>
                    options={vm.allowedWorkingTimeRatios}
                    singleValueRender={singleValueRender}
                    optionsRender={optionsRender}
                    emptyRow={this.emptyRow}
                  />,
                  true,
                  {
                    label: this.ta("job_title_id"),
                    disabled: !vm.allowWorkingTimeRationSelection,
                  }
                )}
              </div>
              <div className="col-md-6">
                {this.generateFormField(
                  "capacity_id",
                  <SelectWithSearchBox options={vm.capacities} emptyRow={this.emptyRow} />,
                  true,
                  {
                    disabled: !vm.allowCapacitySelection,
                  }
                )}
              </div>
            </>
          )}
          <div className="col-md-6">{parentSelectBox}</div>
          {childSelectBox && (
            <>
              <div className="col-md-6">&nbsp;</div>
              <div className="col-md-6">{childSelectBox}</div>
            </>
          )}
          <div className="col-md-6">
            {this.generateFormField(
              "valid_from",
              <DatePickerBox
                disabledInput={!vm.allowDateSelection}
                dateFrom={!canCreateEditBeforeToday ? validFromFromLimitation : this.props.vm.currentCapacity?.valid_from}
                dateTo={!canCreateEditBeforeToday ? entity.valid_to : vm.currentCapacity?.valid_to}
                disabledDays={{
                  before: !canCreateEditBeforeToday ? validFromFromLimitation : this.props.vm.currentCapacity?.valid_from,
                  after: !canCreateEditBeforeToday ? entity.valid_to : vm.currentCapacity?.valid_to,
                }}
              />,
              true,
              {
                label: validFromLabel,
              }
            )}
          </div>
          <div className="col-md-6">
            {this.generateFormField(
              "valid_to",
              <DatePickerBox
                disabledInput={!vm.allowDateSelection}
                dateFrom={entity.valid_from || this.props.vm.currentCapacity?.valid_from}
                dateTo={vm.currentCapacity?.valid_to}
                disabledDays={{
                  before: entity.valid_from,
                }}
              />,
              true
            )}
            {vm.due_date_advance === true && (
              <Tooltip title={this.tg("bc_due_date_eis_shift")} arrow={true} animation="shift" className="emankit__label_tooltip">
                ⚠ {this.tg("bc_due_date_eis_shift")}
              </Tooltip>
            )}
          </div>
          {entity.is_work && (
            <div className="col-md-6">
              {this.generateFormField(
                "enumeration_allocation_type_id",
                <SelectWithSearchBox
                  options={vm.possibleAllocationTypes}
                  parseValue={this.parseNumber}
                  emptyRow={this.emptyRow}
                />,
                true
              )}
            </div>
          )}
          {vm.daysSectionInvisible === true && (
            <div className="col-md-6">
              <GenericFormField
                target={entity.value}
                property={"total_amount"}
                label={this.ta("total_amount")}
                required={true}
                disabled={this.props.readonly}>
                <TextBox type="number" disabledDecimal min={0} />
              </GenericFormField>
            </div>
          )}
          {vm.daysSectionInvisible === false && (
            <>
              <div className="col-md-6">
                <GenericFormField target={vm} property={"hide_empty_capacities"} label={this.tg("hide_empty_capacities")}>
                  <CheckBox onValueChanged={vm.onEmptyDaysChange} />
                </GenericFormField>
              </div>
              <div className="col-md-12">
                <div className="row emankit__input_container">
                  <label className="col-form-label col-sm-12">{this.tg("repeat_days")}</label>
                  {vm.possibleDays.map(day => this.createDayCheckBoxFor(vm, day))}
                  {vm.possibleDays.length === 0 && <div className="col-12">{this.tg("empty_interval")}</div>}
                  {entity.value.hasError("week_amount") && (
                    <div className="col-12">
                      <div className="error_message">{entity.value.errors.get("week_amount")}</div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {vm.daysSectionInvisible === false && vm.allowValidFromDays && (
            <div className="col-md-12">
              <div className="row emankit__input_container">
                {vm.possibleDays.map(day => this.createTimeFromPickerFor(day.name))}
              </div>
            </div>
          )}
          {entity.enumeration_allocation_type_id !== 3 && entity.is_work && (
            <div className="col-md-12">{this.generateFormField("service", <CheckBox />, false)}</div>
          )}
          <div className="col-md-12">{this.generateFormField("note", <TextAreaBox />)}</div>
        </div>
      </>
    );
  }
}
