import { observable } from "mobx";

import BaseModel from "@model/BaseModel";

export default class Attachment extends BaseModel implements models.IAttachment {
  @observable identificator: number; // Identificator is local ID before entity is uploaded
  @observable total: number; // Total is size to transfer over network may be differ from file.size property
  @observable uploaded: number;
  @observable name: string;

  @observable attach: File;
}
