import { inject, injectable } from "inversify";
import { computed, IReactionDisposer, observable, reaction } from "mobx";

import JobPosition from "@model/JobPosition";
import JobTitle from "@model/JobTitle";
import OrganizationStructure from "@model/OrganizationStructure";
import FormViewModel from "@vm/Form/FormViewModel";
import OptionsVM from "@vm/Other/Options";
import TYPES from "../../inversify.types";
import JobTitlesCheckVM from "@vm/Other/JobTitlesCheck";
import WorkingTimeRatioRepository from "@repository/WorkingTimeRatio";
import WorkingTimeRatio from "@model/WorkingTimeRatio";
import JobTitleWorkingTimeRatio from "@model/JobTitleWorkingTimeRatio";
import { HoursInWorkWeek } from "@model/Rate";
import { OptionType } from "@eman/emankit";
import ContractsVM from "@vm/Other/Contracts";
import Company from "@model/Company";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import User from "@service/CurrentUser";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class JobTitleFormVM extends FormViewModel<JobTitle> implements ViewModel.WithReactions {
  private organizationStructureChangeReaction: IReactionDisposer;
  private companyReaction: IReactionDisposer;

  private jobPositionOptionsOptionsVM: OptionsVM<JobPosition>;
  private organizationStructureOptionsVM: OptionsVM<OrganizationStructure>;
  @observable private jobTitlesCheckVM: JobTitlesCheckVM;
  private companyOptionsVM: OptionsVM<Company>;

  private workingTimeRatioRepository: WorkingTimeRatioRepository;

  @observable private workingTimeRatio?: WorkingTimeRatio;

  @observable isCreate = false;

  constructor(
    @inject(TYPES.JobPositionRepository) jobPositionRepository: Repository<JobPosition>,
    @inject(TYPES.OrganizationStructureRepository) organizationStructureRepository: Repository<OrganizationStructure>,
    @inject(TYPES.JobTitlesCheck) jobTitlesCheckVM: JobTitlesCheckVM,
    @inject(TYPES.WorkingTimeRatioRepository) workingTimeRatioRepository: WorkingTimeRatioRepository,
    @inject(TYPES.Contracts) public contractsVM: ContractsVM,
    @inject(TYPES.CompanyRepository) companyRepository: Repository<Company>,
    @inject(TYPES.User) private user: User
  ) {
    super();
    this.jobPositionOptionsOptionsVM = new OptionsVM(jobPositionRepository, "name");
    this.organizationStructureOptionsVM = new OptionsVM(organizationStructureRepository, "name");

    this.companyOptionsVM = new OptionsVM(companyRepository, "name");

    this.jobTitlesCheckVM = jobTitlesCheckVM;
    this.workingTimeRatioRepository = workingTimeRatioRepository;
  }

  setEntity(entity: JobTitle) {
    super.setEntity(entity);
    this.turnOnReactions();
  }

  init(employeeId: number, contractId: number) {
    this.jobTitlesCheckVM.init(employeeId, contractId);
    this.user.allowToObject(UserRightsObjects.WORKING_TIME_RATIO, UserRightsOperations.LIST) &&
      this.workingTimeRatioRepository.setId(employeeId, contractId);
    this.fetchOrganizationItems();
  }

  async fetchWorkingTimeRatios() {
    if (this.user.allowToObject(UserRightsObjects.WORKING_TIME_RATIO, UserRightsOperations.LIST)) {
      this.workingTimeRatio = (
        await this.workingTimeRatioRepository.fetchList({
          order: {
            field: "valid_from",
            direction: "desc",
          },
          pagination: {
            page: 0,
            pageSize: 1,
          },
        })
      ).list[0];
    }
  }

  /**
   * React on Organization structure selection and reset selected position
   */
  turnOnReactions(): void {
    this.organizationStructureChangeReaction = reaction(
      () => this.entity.organization_structure_id,
      () => this.resetPosition()
    );
    this.companyReaction = reaction(
      () => this.getCurrentCompany,
      () => {
        this.fetchOrganizationItems();
      }
    );
  }

  turnOffReactions(): void {
    this.organizationStructureChangeReaction();
    this.companyReaction();
  }

  resetPosition = () => {
    if (this.isCreate) {
      this.entity.job_position_id = undefined;
    }
  };

  fetchOrganizationItems = () => {
    this.organizationStructureOptionsVM.fetchItems({
      lft: {
        operator: "gte",
        values: this.getCurrentCompany?.lft,
      },
      rgt: {
        operator: "lte",
        values: this.getCurrentCompany?.rgt,
      },
    });
  };

  @computed
  get availablePositions(): OptionType<number>[] {
    return this.jobPositionOptionsOptionsVM.items
      .filter(item => {
        if (this.entity && this.entity.organization_structure_id && item.organization_structures) {
          return item.organization_structure_ids.includes(this.entity.organization_structure_id);
        } else {
          return false;
        }
      })
      .map(item => ({ label: item.name, value: item.id! }));
  }

  @computed
  get availableOrganizations() {
    return this.organizationStructureOptionsVM.selectOptions;
  }

  @computed
  get getCurrentCompany() {
    return this.companyOptionsVM?.items.find(company => company.id === this.contractsVM.currentContract?.company_id);
  }

  @computed
  get minimalValidFrom(): Date {
    const today = new Date();
    const rateValidFrom = this.jobTitlesCheckVM.rate?.valid_from || new Date();
    return today > rateValidFrom ? today : rateValidFrom;
  }

  @computed
  get minimalValidTo(): Date {
    return this.entity?.valid_from || this.minimalValidFrom;
  }

  @computed
  get maximalValidTo(): Date | undefined {
    return this.jobTitlesCheckVM.rateLatestFrom;
  }

  @computed
  get workingTimeRatios(): JobTitleWorkingTimeRatio[] {
    let ratios = this.workingTimeRatio?.job_title_working_time_ratios || [];

    if (!this.entity.id) {
      ratios = [new JobTitleWorkingTimeRatio(), ...ratios];
    }
    return ratios;
  }

  @computed
  get availableWorkingHours(): number {
    if (!this.jobTitlesCheckVM.rate?.working_time) {
      return 0;
    }

    return this.jobTitlesCheckVM.rate.working_time.coeficient! * HoursInWorkWeek;
  }
}
