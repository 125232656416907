import { observer } from "mobx-react";
import React from "react";
import Panel from "@eman/emankit/Panel";

import BaseComponent from "@component/BaseComponent";
import List from "./List";

@observer
export default class Capacties extends BaseComponent<PropsFromRouter> {
  render() {
    return (
      <Panel>
        <List match={this.props.match} />
      </Panel>
    );
  }
}
