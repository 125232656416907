import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import FixedFooter from "@eman/emankit/FixedFooter";
import { IconType } from "@eman/emankit/Icon";

import BaseComponent from "@component/BaseComponent";

export interface ChangeFooterProps {
  changes: number | boolean;
  errorCount: number;
  onResetClick: () => void;
  onSaveClick: () => void;
  disableSaveButton?: boolean;
}

export default class ChangeFooter extends BaseComponent<ChangeFooterProps> {
  render() {
    const { changes, errorCount, onResetClick, onSaveClick } = this.props;

    return (
      <FixedFooter hide={(changes === 0 || changes === false) && errorCount === 0}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2 className={errorCount > 0 ? "error" : ""}>
                {this.locs
                  .tg(`changed.${errorCount > 0 ? "cannot_be_saved" : "unsaved_changes"}`)
                  .replace("%COUNT%", `${errorCount}`)}
              </h2>
            </div>
            {changes !== 0 && (
              <div className="col-md-4 align_right">
                <Button
                  variant={ButtonVariant.Active}
                  icon={IconType.Save}
                  onClick={onSaveClick}
                  disabled={this.props.disableSaveButton}>
                  {this.locs.tg("changed.save_all")}
                </Button>
                &nbsp; &nbsp;
                <Button icon={IconType.Cross} onClick={onResetClick}>
                  {this.locs.tg("changed.reset_all")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </FixedFooter>
    );
  }
}
