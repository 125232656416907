import BaseModel from "@model/BaseModel";
import { observable } from "mobx";
import { AllocationEvent } from "@model/Allocation";

// RightsObjects represents Entities

export enum UserRightsObjects {
  ADDRESS = "address",
  ALLOCATION_WORKING = "allocation_working",
  ALLOCATION_NONWORKING = "allocation_nonworking",
  ALLOCATION_NONWORKING_SELF = "allocation_nonworking_self",
  APPLICATION = "application",
  APPLICATION_GRANT = "application_grant",
  ANNOUNCEMENT = "announcement",
  CAPACITY = "capacity",
  CERTIFICATION = "certification",
  CONTACT = "contact",
  CONTRACT = "contract",
  DEPENDENT = "dependent",
  DOCUMENT = "document",
  ENUMERATION = "enumeration",
  EVIDENCE_STATE = "evidence_state",
  EXPORT = "export",
  HEALTH_INSURANCE = "health_insurance",
  JOB_POSITION = "job_position",
  MEDICAL_EXAMINATION = "medical_examination",
  MEDIAL_INSITUTION = "medical_institution",
  NOTIFICATION = "notification",
  ORGANIZATION_STRUCTURE = "organization_structure",
  PROJECT = "project",
  ROLE = "role",
  RATE = "rate",
  RATE_ITEM = "rate_item",
  USER = "user",
  FOREIGN_PERSONAL_DETAIL = "foreign_personal_detail",
  JOB_TITLE = "job_title",
  PAYMENT_INFO = "payment_info",
  PERSONAL_DETAIL = "personal_detail",
  PERSONAL_DOCUMENT = "personal_document",
  PREVIOUS_EMPLOYMENT = "previous_employment",
  BRANCH_OFFICE = "branch_office",
  SCHOOL = "school",
  NOTE = "note",
  PERFORMANCE_MANAGEMENT = "performance_management",
  TRAINING = "training",
  SKILL = "skill",
  TIME_LIMITS = "time_limits",
  VACATION = "vacation",
  WORKING_TIME_RATIO = "working_time_ratio",
}

export enum AllocationEventObjects {
  ALLOCATION_WORKING_EVENTS = "allocation_working_events",
  ALLOCATION_NONWORKING_EVENTS = "allocation_nonworking_events",
  ALLOCATION_NONWORKING_SELF_EVENTS = "allocation_nonworking_self_events",
}

export enum AllocationScopeObjects {
  ALLOCATION_SCOPE = "allocation_scope",
}
export enum AllocationScopeRightsOperations {
  IGNORE_ORGANIZATION_STRUCTURE_WORKING = "ignore_organization_structure_working",
  IGNORE_ORGANIZATION_STRUCTURE_NONWORKING = "ignore_organization_structure_nonworking",
}

export enum StateRightsOperations {
  STATUS_CHANGE = "status_change",
}

// CRUD operation + special actions for specific objects
export enum UserRightsOperations {
  LIST = "index",
  CREATE = "create",
  SHOW = "show",
  EDIT = "edit",
  DELETE = "delete",

  // Only for document
  DOWNLOAD = "download",
  UPLOAD = "upload",

  // Only for vacation
  REPORT = "report",

  // Time limits
  IGNORE_TODAYS_DEPENDENT_VALIDATIONS = "ignore_todays_dependent_validations",
}

// Pages object based on view which is consist of two ore more Objects
export enum UserRightsPages {
  ALLOCATION = "allocation",
  ANNOUNCEMENT = "announcement",
  CONTACT_INFORMATION = "contact_information",
  CONTRACT = "contract",
  ORGANIZATION_STRUCTURE = "organization_structure",
  EMPLOYEES = "employees",
  EMPLOYER = "employer",
  ENUMERATION = "enumeration",
  EVIDENCE_STATE = "evidence_state",
  ROLE = "role",
  PERSONAL_DATA = "personal_data",
}

export default class Rights extends BaseModel {
  @observable address: UserRightsOperations[] = [];
  @observable allocation_working: Array<UserRightsOperations | StateRightsOperations> = [];
  @observable allocation_working_events: AllocationEvent[] = [];
  @observable allocation_nonworking: Array<UserRightsOperations | StateRightsOperations> = [];
  @observable allocation_nonworking_events: AllocationEvent[] = [];
  @observable allocation_nonworking_self: Array<UserRightsOperations | StateRightsOperations> = [];
  @observable allocation_nonworking_self_events: AllocationEvent[] = [];
  @observable allocation_scope: AllocationScopeRightsOperations[] = [];
  @observable application: UserRightsOperations[] = [];
  @observable application_grant: Array<UserRightsOperations | StateRightsOperations> = [];
  @observable announcement: UserRightsOperations[] = [];
  @observable capacity: UserRightsOperations[] = [];
  @observable certification: UserRightsOperations[] = [];
  @observable contact: UserRightsOperations[] = [];
  @observable contract: UserRightsOperations[] = [];
  @observable dependent: UserRightsOperations[] = [];
  @observable document: UserRightsOperations[] = [];
  @observable enumeration: UserRightsOperations[] = [];
  @observable evidence_state: UserRightsOperations[] = [];
  @observable export: UserRightsOperations[] = [];
  @observable health_insurance: UserRightsOperations[] = [];
  @observable job_position: UserRightsOperations[] = [];
  @observable medical_examination: UserRightsOperations[] = [];
  @observable medical_institution: UserRightsOperations[] = [];
  @observable notification: UserRightsOperations[] = [];
  @observable organization_structure: UserRightsOperations[] = [];
  @observable project: UserRightsOperations[] = [];
  @observable role: UserRightsOperations[] = [];
  @observable rate: UserRightsOperations[] = [];
  @observable rate_item: UserRightsOperations[] = [];
  @observable user: UserRightsOperations[] = [];
  @observable foreign_personal_detail: UserRightsOperations[] = [];
  @observable job_title: UserRightsOperations[] = [];
  @observable payment_info: UserRightsOperations[] = [];
  @observable personal_detail: UserRightsOperations[] = [];
  @observable personal_document: UserRightsOperations[] = [];
  @observable previous_employment: UserRightsOperations[] = [];
  @observable branch_office: UserRightsOperations[] = [];
  @observable school: UserRightsOperations[] = [];
  @observable note: UserRightsOperations[] = [];
  @observable training: UserRightsOperations[] = [];
  @observable report: UserRightsOperations[] = [];
  @observable performance_management: UserRightsOperations[] = [];
  @observable time_limits: UserRightsOperations[] = [];
  @observable skill: UserRightsOperations[] = [];
  @observable vacation: UserRightsOperations[] = [];
  @observable working_time_ratio: UserRightsOperations[] = [];
}
