import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import Address from "@model/Address";
import AddressCreateVM from "@vm/Create/Address";

import AddressForm from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class AddressCreateModal extends CreateModal<Address> {
  modelName = "address";
  prefix = "employee.contact_information";

  @lazyInject(TYPES.AddressCreate)
  vm: AddressCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_contact_informations(this.props.match.params.user_id));
  };

  renderModalBody() {
    return <AddressForm entity={this.vm.entity} />;
  }
}
