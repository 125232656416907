import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import Badge from "@eman/emankit/Badge";
import Button from "@eman/emankit/Button";
import { confirmYesNo } from "@eman/emankit/ConfirmAlert";
import FilterBar from "@eman/emankit/FilterBar";
import HeaderRow from "@eman/emankit/HeaderRow";
import NotificationCard from "@eman/emankit/NotificationCard";
import Pagination from "@eman/emankit/Pagination";
import Panel from "@eman/emankit/Panel";

import ResourceComponent from "@component/ResourceComponent";
import Announcement, { AnnouncementEvent, AnnouncementStatus } from "@model/Announcement";
import { StateRightsOperations, UserRightsObjects } from "@model/Rights";
import RejectConfirmModal from "@view/Announcement/RejectConfirmModal";
import AnnouncementListVM from "@vm/List/Announcement";
import ViewHelpers from "@util/ViewHelpers";
import { lazyInject, TYPES } from "../../inversify.config";

export interface AnnouncementState {
  itemId?: number;
}

@observer
export default class AnnouncementList extends ResourceComponent<PropsFromRouter, AnnouncementState> {
  modelName = "announcement";

  @lazyInject(TYPES.AnnouncementList)
  vm: AnnouncementListVM;

  state = {
    itemId: undefined,
  };

  componentDidMount() {
    this.vm.init();
  }

  onFilterChange = (values: FilterValues, visible: string[], reset: boolean) => {
    if (reset) {
      this.vm.setSearchValue(undefined);
    }

    this.vm.setFilters(values, visible, undefined, true);
  };

  onPageChange = (page: number, pageSize: number) => {
    this.vm.setPageAndPageSize(page, pageSize);
  };

  onDetailClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const itemId = parseInt(e.currentTarget.getAttribute("data-id")!, 10);

    if (itemId) {
      this.router.pageLink(this.uriHelper.show_announcements(itemId));
    }
  };

  onEventClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const itemId = parseInt(e.currentTarget.getAttribute("data-id")!, 10);
    const event = e.currentTarget.getAttribute("data-event")! as AnnouncementEvent;

    if (event === AnnouncementEvent.REJECT) {
      this.setState({ itemId });
    } else {
      let confirmMessage: React.ReactNode = this.tg("event_confirm");
      if (event === AnnouncementEvent.CANCEL) {
        confirmMessage = (
          <>
            {this.tg("cancel_event_confirm.part1")}
            <br />
            {this.tg("cancel_event_confirm.part2")}
          </>
        );
      }

      confirmYesNo(confirmMessage, () => {
        this.performEvent(itemId, event);
      });
    }
  };

  onRejectConfirm = (reason?: string) => {
    return this.performEvent(this.state.itemId!, AnnouncementEvent.REJECT, reason || "");
  };

  onRejectClose = () => {
    this.setState({ itemId: undefined });
  };

  performEvent = async (itemId: number, event: AnnouncementEvent, reason?: string) => {
    const response = await this.vm.performEvent(itemId, event, reason);

    if (response.status) {
      this.setState({ itemId: undefined });
    }
  };

  actionsButtons = (item: Announcement): React.ReactNode[] => {
    const buttons = [];

    if (this.user.allowToObject(UserRightsObjects.ANNOUNCEMENT, StateRightsOperations.STATUS_CHANGE)) {
      // tslint:disable-next-line: no-misleading-array-reverse
      item.available_events
        .sort((a, b) => {
          if (a === AnnouncementEvent.PROCESS) {
            return -1;
          } else if (b === AnnouncementEvent.PROCESS) {
            return 1;
          } else {
            return 0;
          }
        })
        .forEach(event => {
          buttons.push(
            <Button
              data-id={item.id}
              data-event={event}
              key={`action-${event}`}
              onClick={this.onEventClick}
              small={true}
              variant={ViewHelpers.announcementEventToButtonType(event)}
              icon={ViewHelpers.announcementEventToIcon(event)}>
              {this.tg(`event.${event}`)}
            </Button>
          );
        });
    }

    buttons.push(
      <Button data-id={item.id} onClick={this.onDetailClick} small={true}>
        {this.tg("show_more")}
      </Button>
    );

    return buttons;
  };

  renderItem = (item: Announcement) => {
    const onTitleClick = () => {
      this.router.pageLink(this.uriHelper.show_announcements(item.id));
    };

    const onAuthorClick = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      this.router.pageLink(this.uriHelper.show_employees(item.user_id));
    };

    return (
      <NotificationCard
        date={this.locs.formatDateTime(item.created_at)}
        author={item.user ? ViewHelpers.userName(item.user) : ""}
        title={item.announcement_type ? item.announcement_type.name : ""}
        validity={this.locs.formatDate(item.valid_from)}
        badge={
          <Badge color={ViewHelpers.announcementStateToColor(item.enumeration_announcement_status)}>
            {this.tg(`state.${item.enumeration_announcement_status}`)}
          </Badge>
        }
        actions={this.actionsButtons(item)}
        onTitleClick={onTitleClick}
        onAuthorClick={onAuthorClick}
        authorLink={this.uriHelper.show_employees(item.user_id)}
        className={"mb-20"}
        key={`announcement-${item.id}`}>
        {item.description}
      </NotificationCard>
    );
  };

  render() {
    const filters: FilterData[] = [
      {
        id: "valid_from",
        label: this.ta("valid_from"),
        type: "date_range",
      },
      {
        id: "enumeration_announcement_status",
        label: this.ta("enumeration_announcement_status_id"),
        values: Object.values(AnnouncementStatus).map(status => ({ label: this.tg(`state.${status}`), value: status })),
      },
    ];

    return (
      <>
        <HeaderRow title={this.tg("title")} />
        <FilterBar
          localization={this.locs.filters}
          onFilterChange={this.onFilterChange}
          filters={filters}
          selected={toJS(this.vm.selectedItems)}
          visible={this.vm.visibleFilters}
        />
        {this.vm.list.map(item => this.renderItem(item))}
        {this.vm.total === 0 && !this.vm.currentlyFetching && (
          <Panel>
            <HeaderRow level={3} title={this.tg("empty")} />
          </Panel>
        )}
        <Pagination
          totalRecords={this.vm.total}
          pageSize={this.vm.pagination.pageSize}
          page={this.vm.pagination.page}
          onPageChange={this.onPageChange}
        />
        <RejectConfirmModal
          onSuccess={this.onRejectConfirm}
          open={this.state.itemId !== undefined}
          onClose={this.onRejectClose}
        />
      </>
    );
  }
}
