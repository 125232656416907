import React from "react";
import { Prompt } from "react-router-dom";

import Modal from "@eman/emankit/Modal";

import ResourceComponent from "@component/ResourceComponent";

/**
 * Base parent for Create, Show and Edit modals
 */
export default abstract class BaseModal<TModel extends models.IBase> extends ResourceComponent<PropsFromRouter> {
  abstract vm: ViewModel.Base<TModel, Repository<TModel>>;

  abstract renderModalBody(): React.ReactNode;

  abstract onClose(id?: string | number | undefined): void;

  title(): string {
    return this.tg("title");
  }

  buttons(): React.ReactNode {
    // to be overloaded in children if needed
    return null;
  }

  getId() {
    const params = this.props.match.params;
    // tslint:disable-next-line: no-string-literal
    const key = `${this.vm.repository["modelName"]}_id`; // TODO: This hack I personaly dont like it... but it will works for sub-show / list vm either..
    return params[key];
  }

  get changesPrompt(): boolean {
    return this.vm.entity.changes > 0;
  }

  render() {
    const buttons = () => {
      return <div style={{ textAlign: "right" }}>{this.buttons()}</div>;
    };

    if (!this.vm.entity) {
      return null;
    }

    return (
      <>
        <Modal open={true} onClose={this.onClose} style={{ width: 1000 }} buttons={buttons} header={this.title()}>
          {this.renderModalBody()}
        </Modal>
        <Prompt when={this.changesPrompt} message={this.locs.tg("form.unsaved_prompt")} />
      </>
    );
  }
}
