import { inject, injectable } from "inversify";

import Note from "@model/Note";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class NoteListVM extends AssociatedListViewModel<Note, AssociatedRepository<Note>> {
  static defaults = {
    order: {
      field: "created_at",
      direction: "desc",
    },
    columns: ["description", "right_type", "created_at", "archived"],
    visibleFilters: ["archived"],
    filters: {
      archived: {
        operator: "in",
        values: "false",
      },
    },
  };

  constructor(@inject(TYPES.NoteRepository) repository: AssociatedRepository<Note>) {
    super(repository);
  }
}
