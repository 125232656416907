import WithAttachmentsCreateViewModel from "@vm/Create/WithAttachmentsCreateViewModel";
import { inject, injectable } from "inversify";

import Announcement from "@model/Announcement";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class AnnouncementCreateVM extends WithAttachmentsCreateViewModel<Announcement, Repository<Announcement>> {
  constructor(@inject(TYPES.AnnouncementRepository) repository: Repository<Announcement>) {
    super(Announcement, repository);
  }
}
