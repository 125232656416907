import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import EnumType from "@model/EnumType";
import EnumEditVM from "@vm/Edit/Enum";

import { lazyInject, TYPES } from "../../../inversify.config";
import Form from "./Form";

@observer
export default class EnumEdit extends EditModal<EnumType> {
  modelName = "enumeration";
  prefix = "enumeration_container";

  @lazyInject(TYPES.EnumEdit)
  vm: EnumEditVM;

  get hasDelete() {
    return this.vm.entity.valid;
  }

  get autoReplyOptions() {
    return this.vm.entity.auto_reply;
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.show_enumeration(this.props.match.params.enumeration_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.enumeration_id);
  }

  renderModalBody() {
    return (
      <Form
        entity={this.vm.entity}
        enumerationContainerName={this.vm.parentId as string}
        autoReplyOptions={this.autoReplyOptions}
        possibleJobPositions={this.vm.possibleJobPositions}
        possibleUsers={this.vm.possibleUsers}
        enums={this.vm.enums}
      />
    );
  }
}
