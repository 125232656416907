import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import PerformanceManagement from "@model/PerformanceManagement";
import PerformanceManagementEditVM from "@vm/Edit/PerformanceManagement";

import PerformanceManagementForm from "./Form";
import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class PerformanceManagementEdit extends EditModal<PerformanceManagement> {
  modelName = "performance_management";
  prefix = "employee";

  @lazyInject(TYPES.PerformanceManagementEdit)
  vm: PerformanceManagementEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_performance_managements(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <>
        <PerformanceManagementForm entity={this.vm.entity} />
      </>
    );
  }
}
