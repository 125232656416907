import ListViewModel from "@vm/List/ListViewModel";
import AllocationItemGroup from "@model/AllocationItemGroup";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class AllocationItemGroupListVM extends ListViewModel<AllocationItemGroup, Repository<AllocationItemGroup>> {
  constructor(@inject(TYPES.AllocationItemGroupRepository) repository: Repository<AllocationItemGroup>) {
    super(repository);
  }
}
