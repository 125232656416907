import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import BranchOffice from "@model/BranchOffice";
import BranchOfficeShowVM from "@vm/Edit/BranchOffice";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class BranchOfficeShowModal extends ShowModal<BranchOffice> {
  modelName = "branch_office";
  prefix = "employee.organization_structure";

  @lazyInject(TYPES.BranchOfficeShow)
  vm: BranchOfficeShowVM;

  onClose = () => {
    this.router.pageLink(
      this.uriHelper.employer_organization_structures_branch_offices(this.props.match.params.organization_structure_id)
    );
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.organization_structure_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} readonly={true} />;
  }
}
