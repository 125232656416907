import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import PersonalDocument from "@model/PersonalDocument";
import PersonalDocumentCreateVM from "@vm/Create/PersonalDocument";
import PersonalDocumentForm from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class PersonalDocumentCreate extends CreateModal<PersonalDocument> {
  modelName = "personal_document";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.PersonalDocumentCreate)
  vm: PersonalDocumentCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  renderModalBody() {
    return <PersonalDocumentForm entity={this.vm.entity} vm={this.vm.personalDocumentFormVM} />;
  }
}
