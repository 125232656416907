import axios, { AxiosRequestConfig } from "axios";

import EventBus, { HIDE_LOADING, LOGOUT_USER, SHOW_ERROR, SHOW_LOADING, START_REQUEST, STOP_REQUEST } from "./EventBus";
import localeProvider, { LocaleProvider } from "./LocaleProvider";

// Set defaults
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL || ""}/api/`;

// Version check
if (process.env.APP_VERSION !== "undefined") {
  axios.defaults.headers = { "X-App-Version": `${process.env.APP_VERSION} ${process.env.DEPLOYED_AT}` };
}

export interface AxiosConfig extends AxiosRequestConfig {
  url: string;
  id: string;
  loading?: boolean;
  ignoreErrors?: boolean;
}

class ApiClient {
  // Default locale
  provider: LocaleProvider = localeProvider;

  /**
   * This is usefull for Create / Update statements, it fetch data checks their validity
   * and returns normalized api response.
   */
  fetchResponse = (config: AxiosConfig): Promise<ApiResponse<any>> => {
    return this.fetchData(config)
      .then(response => {
        return {
          status: true,
          entity: response.entity,
          original: response,
        };
      })
      .catch(error => {
        return {
          status: false,
          errors: error.response.data.errors ? error.response.data.errors : error.response.data,
          original: error.response,
        };
      });
  };

  // Default error handler
  errorHandler = (config: AxiosConfig, error: any) => {
    if (process.env.NODE_ENV === "development") {
      // tslint:disable-next-line:no-console
      console.error(error);
    }

    const response = error.response;

    // Network error!
    if (!response) {
      if (config.ignoreErrors !== true) {
        EventBus.trigger(SHOW_ERROR, { code: "errors.unknown_error", single: true });
      }
      return error;
    }

    const status = response.status;

    let errorMessage: string | ErrorMessage = "";

    switch (status) {
      case 400:
        // Bad request
        errorMessage = response.data.error;

        if (response.data.errors && Array.isArray(response.data.errors)) {
          // Test for array
          errorMessage = response.data.errors[0];
        }
        break;

      case 401:
        // Unauthorized - logout user
        EventBus.trigger(LOGOUT_USER);
        break;

      case 403: // Unauthorized access
        if (response.data.data) {
          errorMessage = response.data.data.message;
        } else {
          errorMessage = { code: "errors.unauthorized" };
        }
        break;

      case 404: // Not found
      case 409: // Conflict
        if (response.data.data) {
          errorMessage = response.data.data.message;
        } else {
          errorMessage = response.data.error;
        }

        break;

      case 412:
        // Precondition failed... invalid version of app
        window.location.reload();
        errorMessage = { code: "errors.invalid_app_version" };
        break;

      case 414:
        // URL is too large -> it´s typically caused by to many filters
        errorMessage = { code: "errors.to_many_filters" };
        break;

      case 500:
        // Internal
        if (response.data.data) {
          errorMessage = response.data.data.errors;
        } else {
          // This is for HTML error returned by server
          errorMessage = { code: "errors.unknown_error" };
        }
        break;
    }

    if (String(errorMessage).length > 0 && config.ignoreErrors !== true) {
      EventBus.trigger(SHOW_ERROR, errorMessage);
    }

    return error;
  };

  /**
   * This method calls anyhting, not parse it and raise error for all not OK responses.
   * @param config Axios configuration
   */
  fetchData(config: AxiosConfig, withHeaders = false): Promise<any> {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Api-Locale": this.provider.locale,
      },
      method: "get",
      ...config,
    } as any;

    if (!axiosConfig.params) {
      axiosConfig.params = {};
    }

    if (config.loading === undefined || config.loading) {
      EventBus.trigger(SHOW_LOADING, config.id);
    }
    EventBus.trigger(START_REQUEST, config.id);

    return axios
      .request(axiosConfig)
      .catch(reason => {
        if (config.loading !== false) {
          EventBus.trigger(HIDE_LOADING, config.id);
        }
        EventBus.trigger(STOP_REQUEST, config.id);

        throw this.errorHandler(config, reason);
      })
      .then(response => {
        if (config.loading !== false) {
          EventBus.trigger(HIDE_LOADING, config.id);
        }
        EventBus.trigger(STOP_REQUEST, config.id);

        return withHeaders ? response : response.data;
      });
  }
}

export default new ApiClient();
