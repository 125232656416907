import { observer } from "mobx-react";

import MyAllocationsVM from "@vm/Other/MyAllocations";
import { lazyInject, TYPES } from "../../../../inversify.config";
import { AllocationInterval } from "@util/Calendar";
import AllocationTable from "@component/AllocationTable";
import AllocationCalendarNavigation from "@component/AllocationCalendarNavigation";
import ResourceComponent from "@component/ResourceComponent";
import EventBus, { DATE_CHANGED, IDisposable } from "@util/EventBus";

@observer
export default class MyAllocationsCalendar<TProps = {}, TState = {}> extends ResourceComponent<TProps, TState> {
  private readonly eventHandlers: IDisposable[] = [];

  prefix = "allocation";
  modelName = "calendar";

  @lazyInject(TYPES.MyAllocations)
  vm: MyAllocationsVM;

  componentDidMount(): void {
    this.vm.init();
    this.eventHandlers.push(EventBus.on(DATE_CHANGED, () => this.vm.fetchData()));
  }

  componentWillUnmount(): void {
    this.vm.dispose();
    this.eventHandlers.forEach(x => x.dispose());
  }

  onDatesChange = (startDate: Date, endDate: Date, interval: AllocationInterval) => {
    this.vm.startDate = startDate;
    this.vm.endDate = endDate;
    this.vm.interval = interval;
  };

  onFilterChange = (values: FilterValues, visible: string[], reset: boolean) => {
    this.vm.setFilters(values, visible);
  };

  render() {
    return (
      <div>
        <AllocationTable
          startDate={this.vm.startDate}
          endDate={this.vm.endDate}
          data={this.vm.groups}
          subgroupsData={this.vm.subgroupsData}
          onGroupClick={this.vm.toggleGroup}
          onSubGroupClick={this.vm.toggleSubGroup}
          openSubGroups={this.vm.openSubGroups}
          onAllocationClick={this.vm.editAllocation}
          locs={this.locs}
          allocationInterval={this.vm.interval}
        />
        <AllocationCalendarNavigation
          isMyAllocations
          onChange={this.onDatesChange}
          defaultStartDate={this.vm.startDate}
          defaultInterval={this.vm.interval}
          locs={this.locs}
          clearGroups={this.vm.clearGroups}
          tooltip={this.locs.tg("uikit.date_picker.strings.todayButton")}
        />
      </div>
    );
  }
}
