import AnnouncementList from "@view/Announcement/List";
import AnnouncementShowModal from "@view/Announcement/Show";
import { uriHelper } from "../config";
import BaseRouter from "./Base";

export const announcementsRoutes: IAppRoute[] = [
  {
    path: uriHelper.announcements_uri(),
    exact: false,
    component: AnnouncementList,
  },
  {
    path: uriHelper.show_announcements_uri(),
    exact: true,
    modalComponent: AnnouncementShowModal,
  },
];

export default class Announcements extends BaseRouter {
  render() {
    return (
      <>
        <div className="emankit__layout__content">{this.pageRouteRender(announcementsRoutes)}</div>
      </>
    );
  }
}
