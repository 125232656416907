import { observer } from "mobx-react";
import React from "react";
import { Prompt } from "react-router";

import Columns from "@eman/emankit/Columns";
import HeaderRow from "@eman/emankit/HeaderRow";
import Contract from "@model/Contract";

import BaseForm from "@component/BaseForm";
import { DatePickerBox, SelectBox, TextAreaBox, TextBox } from "@util/Form";
import ContractFormVM from "@vm/Form/Contract";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import { computed } from "mobx";

export interface ContractFormProps {
  vm: ContractFormVM;
}

@observer
export default class ContractFormTermination extends BaseForm<Contract, ContractFormProps> {
  modelName = "contract";
  prefix = "employee";

  @computed
  get canEditInToPast(): boolean {
    return this.user.allowToObject(UserRightsObjects.TIME_LIMITS, UserRightsOperations.IGNORE_TODAYS_DEPENDENT_VALIDATIONS);
  }

  terminationFields = (): React.ReactNode[] => {
    return [
      this.generateFormField(
        "enumeration_termination_method_id",
        <SelectBox options={this.enums.valuesForSelect("termination_methods")} allowEmpty={true} />
      ),
      {
        ...(this.props.vm.allowValidToSelection ? (
          this.generateFormField(
            "terminated_at",
            <DatePickerBox
              disabledDays={{ after: this.props.vm.terminatedAtMaximumDate, before: this.props.entity.valid_from }}
              dateFrom={this.props.entity.valid_from}
              dateTo={this.props.vm.terminatedAtMaximumDate}
            />
          )
        ) : (
          <div />
        )),
      },
      this.generateFormField(
        "valid_to",
        <DatePickerBox
          disabledDays={{
            after: this.props.vm.validToTerminationMaximumDate,
            before: this.canEditInToPast
              ? this.props.entity.valid_from
              : this.props.vm.validToTerminationMinimumDate || new Date(),
          }}
          dateFrom={
            this.canEditInToPast ? this.props.entity.valid_from : this.props.vm.validToTerminationMinimumDate || new Date()
          }
          dateTo={this.props.vm.validToTerminationMaximumDate}
        />
      ),
      this.generateFormField("termination_severance", <TextBox />),
      this.generateFormField("termination_description", <TextAreaBox />),
    ];
  };

  renderFormBody() {
    const { entity } = this.props;

    return (
      <>
        {entity.id && (
          <>
            <HeaderRow title={this.tg("termination_title")} level={2} />
            <Columns byColumns={true}>{this.terminationFields()}</Columns>
          </>
        )}
        <Prompt when={entity.changes > 0} message={this.locs.tg("form.unsaved_prompt")} />
      </>
    );
  }
}
