import { inject, injectable } from "inversify";

import School from "@model/School";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import SchoolForm from "@vm/Form/School";
import TYPES from "../../inversify.types";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class SchoolShowVM extends AssociatedShowViewModel<School, AssociatedRepository<School>> {
  constructor(
    @inject(TYPES.SchoolRepository) repository: AssociatedRepository<School>,
    @inject(TYPES.SchoolForm) public schoolFormVM: SchoolForm
  ) {
    super(School, repository);
  }

  setEntity(entity: School) {
    super.setEntity(entity);
    this.schoolFormVM.setEntity(entity);
  }
}
