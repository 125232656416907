import PreviousEmployment from "@model/PreviousEmployment";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";
import { injectable } from "inversify";

@injectable()
export default class PreviousEmploymentRepository extends BaseAssociatedRepository<PreviousEmployment> {
  constructor() {
    super(PreviousEmployment, "users/$ID/previous_employments", "previous_employment");
  }
}
