import ChangeFooter from "@component/ChangeFooter";
import HeaderRow from "@eman/emankit/HeaderRow";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import { observer } from "mobx-react";

import Edit from "@component/Edit";
import Role from "@model/Role";
import RoleEditVM from "@vm/Edit/Role";

import { lazyInject, TYPES } from "../../inversify.config";
import Form from "./Form";

@observer
export default class RoleEdit extends Edit<Role, RoleEditVM> {
  modelName = "role";

  @lazyInject(TYPES.RoleEdit)
  vm: RoleEditVM;

  updateRole = async () => {
    const response = await this.vm.update();

    if (response) {
      EventBus.trigger(SHOW_TOAST, this.locs.tg("form.updated"));
    }
  };

  resetChanges = () => {
    this.vm.fetchItem(false);
  };

  render() {
    const { entity, roleFormVM, hasRealChanges } = this.vm;

    return (
      <>
        <HeaderRow title={this.tg("title")} />
        <Form entity={entity} vm={roleFormVM} />
        <ChangeFooter
          errorCount={entity.errorCount}
          // TODO do it better with base entity default funcionality
          changes={hasRealChanges ? entity.changes : 0}
          onSaveClick={this.updateRole}
          onResetClick={this.resetChanges}
        />
      </>
    );
  }
}
