import BaseModal from "@component/BaseModal";

export default abstract class ShowModal<TModel extends models.IBase> extends BaseModal<TModel> {
  suffix = "show";

  abstract vm: ViewModel.Show<TModel>;

  constructor(props: PropsFromRouter) {
    super(props);
    this.setIdFromParams();
  }

  componentDidMount() {
    this.setIdFromParams();
    this.vm.scroll = false;
  }

  setIdFromParams() {
    this.vm.init(this.getId());
  }
}
