import { injectable } from "inversify";

import AllocationsVM, { UpdatingData } from "@vm/Other/Allocations";
import { action } from "mobx";

@injectable()
export default class MyAllocationsVM extends AllocationsVM {
  protected settingsNamespace = "my_allocations";

  async init(): Promise<void> {
    await this.fetchUsers();
    await this.fetchData();
    this.subscribe();
    this.toggleGroup({ id: this.user.entity.id!, open: false });
  }

  dispose() {
    this.unsubscribe();
  }

  protected fetchUsers = async () => {
    this.allocationUserItemsVM.setSettings({
      filters: {
        user_id: {
          values: [this.user.entity.id],
          operator: "in",
        },
      },
    });

    this.allocationUserItemsVM.fetchList(false, false);
  };

  @action.bound
  onDataUpdation(data: UpdatingData): void {
    // Recognize if changed data is actually displayed
    if (data.user_ids.includes(this.user.entity.id!)) {
      this.fetchData();
    }
  }
}
