import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import Certification from "@model/Certification";
import CertificationCreateVM from "@vm/Create/Certification";

import CertificationForm from "./Form";
import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class CertificationCreate extends CreateModal<Certification> {
  modelName = "certification";
  prefix = "employee";

  @lazyInject(TYPES.CertificationCreate)
  vm: CertificationCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_certifications(this.props.match.params.user_id));
  };

  renderModalBody() {
    return (
      <>
        <CertificationForm entity={this.vm.entity} />
      </>
    );
  }
}
