import { inject, injectable } from "inversify";

import PerformanceManagement from "@model/PerformanceManagement";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
import { action } from "mobx";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PerformanceManagementShowVM extends AssociatedShowViewModel<
  PerformanceManagement,
  AssociatedRepository<PerformanceManagement>
> {
  constructor(@inject(TYPES.PerformanceManagementRepository) repository: AssociatedRepository<PerformanceManagement>) {
    super(PerformanceManagement, repository);
  }

  @action
  async fetchItem(scrollTop = true): Promise<number> {
    const returnValue = await super.fetchItem(scrollTop);

    this.entity.data_values.forEach((answer: string, index: number) => {
      this.entity[`answer${index}`] = answer;
    });

    return returnValue;
  }
}
