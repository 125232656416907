import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import PreviousEmployment from "@model/PreviousEmployment";
import PreviousEmploymentCreateVM from "@vm/Create/PreviousEmployment";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class PreviousEmploymentCreateModal extends CreateModal<PreviousEmployment> {
  modelName = "previous_employment";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.PreviousEmploymentCreate)
  vm: PreviousEmploymentCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  renderModalBody() {
    return (
      <div style={{ minHeight: 370 }}>
        <Form entity={this.vm.entity} vm={this.vm.previousEmploymentFormVM} />
      </div>
    );
  }
}
