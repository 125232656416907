import { Transform, Type } from "@eman/class-transformer";
import { enumValue } from "@util/Enumerable";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";

export enum EvidenceStateStatus {
  ACTIVED = "actived",
  REMOVED = "removed",
  CLOSED = "closed",
}

export enum EvidenceStateEvent {
  ACTIVE = "active",
  CLOSE = "close",
  REMOVAL = "removal",
}

export default class EvidenceState extends BaseModel {
  @observable enumeration_evidence_state_reason_id: number;
  @observable description: string;
  @observable user_id: number;
  @observable contract_id: number;
  @observable available_events: string[] = [];
  @observable evidence_status: EvidenceStateStatus;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  expected_to: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;

  @observable
  @enumValue("evidence_state_reasons", "enumeration_evidence_state_reason_id")
  evidence_state_reason: models.IEnumType;
}
