import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import PaymentInfo from "@model/PaymentInfo";
import PaymentInfoEditVM from "@vm/Edit/PaymentInfo";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class PaymentInfoEditModal extends EditModal<PaymentInfo> {
  modelName = "payment_info";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.PaymentInfoEdit)
  vm: PaymentInfoEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <div style={{ minHeight: 300 }}>
        <Form entity={this.vm.entity} />
      </div>
    );
  }
}
