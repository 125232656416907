import { observer } from "mobx-react";

import SmallList from "@component/SmallList";
import PaymentInfo from "@model/PaymentInfo";
import PaymentInfoListVM from "@vm/List/PaymentInfo";

import { lazyInject, TYPES } from "../../../../inversify.config";

export interface PaymentInfosListProps {
  userId: number;
}

@observer
export default class PaymentInfosList extends SmallList<PaymentInfo, PaymentInfoListVM, PaymentInfosListProps> {
  @lazyInject(TYPES.PaymentInfoList)
  vm: PaymentInfoListVM;

  modelName = "payment_info";

  componentDidMount(): void {
    this.vm.setParentId(this.props.userId);
    super.componentDidMount();
  }

  renderRow = (item: PaymentInfo, column: string) => {
    if (column === "enumeration_bank_code_id") {
      return item.bank_code ? item.bank_code.code : "";
    } else {
      return item[column];
    }
  };

  onClick = (item: PaymentInfo): void => {
    this.objectEditOrShowLink("employees_personal_data_payment_infos", [this.props.userId, item.id!]);
  };

  rows() {
    return [
      this.createField("account_number_prefix", {}),
      this.createField("account_number", {}),
      this.createField("enumeration_bank_code_id", {}),
      this.createField("iban", {}),
    ];
  }
}
