import BaseModel from "@model/BaseModel";
import { Exclude, Type } from "@eman/class-transformer";
import User from "./User";
import OrganizationStructure from "./OrganizationStructure";

export default class BranchOffice extends BaseModel {
  @Exclude({ toPlainOnly: true })
  @Type(() => User)
  user: User;

  @Exclude({ toPlainOnly: true })
  @Type(() => OrganizationStructure)
  organization_structure: OrganizationStructure;
}
