import { observable } from "mobx";

import { Type } from "@eman/class-transformer";
import BaseModel from "@model/BaseModel";

export default class SupergrossRate extends BaseModel {
  @Type(() => Number)
  @observable
  rate_h_super: number;
  @Type(() => Number)
  @observable
  rate_md_super: number;
  @Type(() => Number)
  @observable
  rate_month_super: number;
}
