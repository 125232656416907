import BaseComponent from "@component/BaseComponent";
import ContractsVM from "@vm/Other/Contracts";

import { lazyInject, TYPES } from "../../../inversify.config";

export default class Contract extends BaseComponent<PropsFromRouter> {
  @lazyInject(TYPES.Contracts)
  vm: ContractsVM;

  async componentDidMount() {
    await this.vm.init(this.props.match.params.user_id); /* eslint-disable-line @typescript-eslint/await-thenable */
    this.vm.contractAutoSelect();
  }

  // This view just invoke VM initialization, which handle redirection logic
  render() {
    return null;
  }
}
