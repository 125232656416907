import BaseForm from "@component/BaseForm";
import { InputVariant } from "@eman/emankit/Input";
import RateItem from "@model/RateItem";
import { SelectBox, TextBox } from "@util/Form";
import { observer } from "mobx-react";

@observer
export default class RateItemForm extends BaseForm<RateItem> {
  modelName = "rate_item";

  render() {
    return (
      <div className="bg-grey container pb-14 pt-16">
        <div className="row">
          <div className="col-md-6">
            {this.generateFormField(
              "enumeration_rate_item_type_id",
              <SelectBox
                options={this.enums.valuesForSelect("rate_item_types")}
                variant={InputVariant.White}
                emptyRow={this.emptyRow}
              />,
              true
            )}
          </div>
          <div className="col-md-6">
            {this.generateFormField("amount", <TextBox type="number" variant={InputVariant.White} />, true)}
          </div>
        </div>
      </div>
    );
  }
}
