import { observable } from "mobx";

import BaseModel from "@model/BaseModel";
import { enumValue } from "@util/Enumerable";

export default class PaymentInfo extends BaseModel {
  @observable account_number: string;
  @observable account_number_prefix?: string;
  @observable enumeration_bank_code_id: string;
  @observable iban: string;
  @observable user_id: string;

  @enumValue("bank_codes", "enumeration_bank_code_id")
  @observable
  bank_code: models.IEnumType;
}
