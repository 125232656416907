import { inject, injectable } from "inversify";
import { IReactionDisposer, observable, reaction, action } from "mobx";

import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import Role from "@model/Role";
import RoleRepository from "@repository/Role";
import FormViewModel from "@vm/Form/FormViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class RoleFormVM extends FormViewModel<Role> implements ViewModel.WithReactions {
  @observable rights: Map<UserRightsObjects, UserRightsOperations[]> = new Map();

  private rightsChangeReactions: IReactionDisposer[] = [];

  private repository: RoleRepository;

  constructor(@inject(TYPES.RoleRepository) repository: RoleRepository) {
    super();
    this.repository = repository;
  }

  setEntity(entity: Role) {
    super.setEntity(entity);
    this.fetchRights();
    this.turnOnReactions();
  }

  /**
   * React on Rights changes
   */
  turnOnReactions(): void {
    Object.values(UserRightsObjects).forEach(right => {
      this.rightsChangeReactions.push(
        reaction(
          () => this.entity.rights[right],
          changedRight => this.onRightsChange(changedRight as UserRightsOperations[])
        )
      );
    });
  }

  turnOffReactions(): void {
    this.rightsChangeReactions.forEach(disposer => disposer());
  }

  /**
   * Auto check Show right with Edit
   *
   * @param rights
   */
  onRightsChange = (rights: UserRightsOperations[]) => {
    if (rights.indexOf(UserRightsOperations.EDIT) !== -1 && rights.indexOf(UserRightsOperations.SHOW) === -1) {
      rights.push(UserRightsOperations.SHOW);
    }
  };

  @action
  async fetchRights() {
    const rights = await this.repository.fetchRights();

    if (rights.items) {
      for (const key in rights.items) {
        if (rights.items.hasOwnProperty(key)) {
          this.rights.set(key as UserRightsObjects, rights.items[key]);
        }
      }
    }
  }
}
