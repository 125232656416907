import { useEffect, useState } from "react";

import Slider from "rc-slider";

import "rc-slider/assets/index.css";
import "./style.scss";
import JobTitleWorkingTimeRatio from "@model/JobTitleWorkingTimeRatio";

interface Props {
  ratios: JobTitleWorkingTimeRatio[];
  availableWorkingHours: number;
  onChange: (state: StatePatch) => void;
}

export interface StatePatch {
  [id: string]: number;
}

export interface RatioState {
  id: string;
  name: string;
  organization: string;
  ratio: number;
}

export interface RatioStateChange {
  id: string;
  value: number;
}

const createRatioStates = (ratios: JobTitleWorkingTimeRatio[]): RatioState[] => {
  return ratios.map(ratio => {
    const name = ratio.job_title.job_position.name;
    const organization = ratio.job_title.organization_structure.name;
    const id = ratio.id?.toString() || "current";
    return {
      id,
      name,
      organization,
      ratio: ratio.ratio || 0,
    };
  });
};

// update value of changed slider in shared state, prevent from exceeding 100%
const updateRatioStates = (state: RatioState[], change: RatioStateChange): RatioState[] => {
  const newValue = change.value / 100;

  const index = state.findIndex(({ id }) => id === change.id);

  const sumOfOthers = state
    .map(({ id, ratio }) => {
      if (id !== change.id) {
        return ratio;
      }

      return 0;
    })
    .reduce((prev, current) => prev + current, 0);

  const maxValue = 1 - sumOfOthers;

  state[index].ratio = Math.min(newValue, maxValue);

  return state;
};

const statePatch = (state: RatioState[]): StatePatch => {
  const patch = {};

  state.forEach(({ id, ratio }) => {
    patch[id] = ratio;
  });

  return patch;
};

const WorkingTimeRatioSlider = ({ ratios, onChange }: Props) => {
  const [state, setState] = useState(createRatioStates(ratios));

  const sumOfAll = state.map(({ ratio }) => ratio).reduce((prev, current) => prev + current, 0);

  useEffect(() => {
    setState(createRatioStates(ratios));
  }, [ratios]);

  const handleChange = (ratioId: string, value: number) => {
    const id = ratioId?.toString() || "current";

    const newState = updateRatioStates(state, { id, value });
    setState([...newState]);
    onChange(statePatch(newState));
  };

  return (
    <div className="working-time-ratio-sliders">
      {state.map(({ id, name, ratio, organization }, index) => {
        return (
          <div key={id} className="slider-row">
            <div className="job-title">
              <strong>{name}</strong>
              <br />
              <p className="ratio-organization">{organization}</p>
            </div>
            <div className="slider">
              <Slider onChange={handleChange.bind(null, id)} value={ratio * 100} step={1} />
            </div>
            <div className="ratio">
              <span className="percentage">{(ratio * 100).toFixed(2)}%</span>
            </div>
          </div>
        );
      })}
      <div className="slider-row">
        <div className="job-title" />
        <div className="slider" />
        <div className="ratio">
          <span className={`percentage ${sumOfAll === 1 ? "valid" : "invalid"}`}>{(sumOfAll * 100).toFixed(2)}%</span>
        </div>
      </div>
    </div>
  );
};

export default WorkingTimeRatioSlider;
