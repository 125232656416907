import { v4 as uuid } from "uuid";

import Toast, { ToastItem } from "@eman/emankit/Toast";

import EventBus, { IDisposable, SHOW_ERROR, SHOW_TOAST } from "@util/EventBus";
import BaseComponent from "@component/BaseComponent";

interface ToastBarState {
  toasts: { [key: string]: ToastItem };
}

export default class ToastBar extends BaseComponent<{}, ToastBarState> {
  DEFAULT_INTERVAL = 5;

  private readonly eventHandlers: IDisposable[] = [];
  private timeouts: number[] = [];

  constructor(props: any) {
    super(props);

    this.state = {
      toasts: {},
    };
  }

  // Hook to event bus directly
  componentDidMount() {
    this.eventHandlers.push(EventBus.on(SHOW_TOAST, this.showToast));
    this.eventHandlers.push(EventBus.on(SHOW_ERROR, this.showError));
  }

  componentWillUnmount() {
    this.eventHandlers.forEach(x => x.dispose());
    this.timeouts.forEach(x => clearTimeout(x));
  }

  showError = (message: string | ErrorMessage) => {
    let errorMessage: string | undefined;

    if (typeof message === "string") {
      errorMessage = message;
    } else {
      errorMessage = this.locs.tg(message.code);
    }

    if (errorMessage) {
      this.addAndPlanHide({ message: errorMessage, error: true }, 3);
    }
  };

  // New request to show message
  showToast = (message: string) => {
    this.addAndPlanHide({ message, error: false });
  };

  addAndPlanHide = (toast: ToastItem, interval: number = this.DEFAULT_INTERVAL) => {
    const { toasts } = this.state;
    const id: string = uuid();

    this.setState({
      toasts: {
        ...toasts,
        [id]: toast,
      },
    });

    this.timeouts.push(
      window.setTimeout(() => {
        const newToasts = {
          ...this.state.toasts,
        };

        delete newToasts[id];

        this.setState({ toasts: newToasts });
      }, interval * 1000)
    );
  };

  render() {
    const toasts = Object.values(this.state.toasts).filter(x => x);
    return <Toast toasts={toasts} />;
  }
}
