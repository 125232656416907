export type HandlerFunction = (...args: any[]) => void;

// UI notifications
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const START_REQUEST = "START_REQUEST";
export const STOP_REQUEST = "STOP_REQUEST";
export const SHOW_ERROR = "SHOW_ERROR";
export const SHOW_TOAST = "SHOW_TOAST";

export const ENTITY_UPDATE = "ENTITY_UPDATED";
export const ENTITY_CREATED = "ENTITY_CREATED";
export const ENTITY_DELETED = "ENTITY_DELETED";

export const AVATAR_UPLOADED = "AVATAR_UPLOADED";

// File upload
export const UPLOAD_FILE_PROGRESS = "UPLOAD_FILE_PROGRESS";

// Scrolling events
export const SCROLL_TOP = "SCROLL_TOP";
export const SCROLL_TO_FIRST_ERROR = "SCROLL_TO_FIRST_ERROR";

// Locale
export const LOCALE_CHANGE = "LOCALE_CHANGE";

// Force load notifications
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const REFRESH_NOTIFICATION = "REFRESH_NOTIFICATION";

// User events
export const LOGIN_USER = "LOGIN_USER";
export const REFRESH_USER_FILTER_SETTINGS = "REFRESH_USER_FILTER_SETTINGS";
export const LOGOUT_USER = "LOGOUT_USER";
export const JOB_TITLES_CHECK = "JOB_TITLES_CHECK";
export const DATE_CHANGED = "DATE_CHANGED";

// Contract events
export const CONTRACT_UPDATED = "CONTRACT_UPDATED";

// Job Title events
export const JOB_TITLES_UPDATED = "JOB_TITLES_UPDATED";

export interface IDisposable {
  dispose(): void;
}

const emptyDispose: IDisposable = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispose: () => {},
};

class EventBus {
  // Turn on-off debug mode
  static DEBUG_MODE = process.env.NODE_ENV === "development" && !process.env.REACT_APP_TURN_OFF_EVENT_BUS;

  // private handlers: { [event: string]: HandlerFunction[] } = {};
  private handlers: Record<string, Set<HandlerFunction>> = {};

  /**
   * Register handler.
   *
   * @param event Event name
   * @param handler Handler
   */
  on(event: string, handler: HandlerFunction): IDisposable {
    if (!this.hasEvent(event)) {
      this.handlers[event] = new Set<HandlerFunction>();
    }

    if (EventBus.DEBUG_MODE) {
      console.log(`-> EventBus - add handler for ${event}`);
    }

    if (!this.handlers[event].has(handler)) {
      this.handlers[event].add(handler);
      return {
        dispose: () => {
          this.handlers[event].delete(handler);
        },
      };
    }

    return emptyDispose;
  }

  /**
   * Trigger event with data object.
   *
   * @param event Event name
   * @param data Any data
   */
  trigger(event: string, data?: any) {
    if (EventBus.DEBUG_MODE) {
      console.log(`-> EventBus - trigger handler for ${event} ${JSON.stringify(data)}`);
    }

    if (this.hasEvent(event)) {
      this.handlers[event].forEach(fct => {
        fct(data);
      });
    }
  }

  /**
   * Is event already defined?
   *
   * @param event Event name
   * @return Boolean
   */
  private hasEvent(event: string): boolean {
    return event in this.handlers;
  }
}

export default new EventBus();
