import { observer } from "mobx-react";

import Columns from "@eman/emankit/Columns";

import BaseForm from "@component/BaseForm";
import Company from "@model/Company";
import { OrganizationStructureType } from "@model/OrganizationStructure";
import OrganizationUnit from "@model/OrganizationUnit";
import { SelectBox, TextAreaBox, TextBox } from "@util/Form";
import SelectWithSearchBox from "@util/Form/BindingElement/SelectWithSearchBox";
import { OptionType } from "@eman/emankit";

export interface OrganizationStructureFormProps {
  type: OrganizationStructureType;
  possibleParents: OptionType<number>[];
}

@observer
export default class OrganizationStructureForm extends BaseForm<OrganizationUnit | Company, OrganizationStructureFormProps> {
  get modelName(): string {
    if (this.props.type === OrganizationStructureType.ORGANIZATION_UNIT) {
      return "organization_unit";
    } else {
      return "company";
    }
  }

  organizationUnitFields = () => {
    return [
      this.generateFormField(
        "parent_id",
        <SelectWithSearchBox options={this.props.possibleParents} emptyRow={this.emptyRow} />,
        true
      ),
      this.generateFormField("unit_type", <TextBox />),
    ];
  };

  companyFields = () => {
    return [
      this.generateFormField(
        "legal_form_id",
        <SelectBox options={this.enums.valuesForSelect("legal_forms")} emptyRow={this.emptyRow} />,
        true
      ),
      this.generateFormField("company_number", <TextBox />, true),
      this.generateFormField("vat_number", <TextBox />),
      this.generateFormField(
        "social_security_office_id",
        <SelectBox options={this.enums.valuesForSelect("social_security_offices")} allowEmpty={true} />
      ),
      this.generateFormField("parent_id", <SelectWithSearchBox options={this.props.possibleParents} allowEmpty={true} />),
      this.generateFormField("working_time", <TextBox />),
    ];
  };

  renderFormBody() {
    const { type } = this.props;

    if (!type) {
      return null;
    }

    return (
      <Columns>
        {this.generateFormField("name", <TextBox />, true)}
        {type === OrganizationStructureType.COMPANY ? this.companyFields() : this.organizationUnitFields()}
        {this.generateFormField("description", <TextAreaBox />)}
      </Columns>
    );
  }
}
