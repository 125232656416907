import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import Certification from "@model/Certification";
import { DatePickerBox, SelectBox, TextBox } from "@util/Form";

@observer
export default class CertificationForm extends BaseForm<Certification> {
  modelName = "certification";
  prefix = "employee";

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-6">{this.generateFormField("name", <TextBox />, true)}</div>
        <div className="col-md-6">
          {this.generateFormField(
            "enumeration_certificate_issuer_id",
            <SelectBox options={this.enums.valuesForSelect("certificate_issuers")} emptyRow={this.emptyRow} />,
            true
          )}
        </div>
        <div className="col-md-6">{this.generateFormField("credential_id", <TextBox />, true)}</div>
        <div className="col-md-6">{this.generateFormField("credential_url", <TextBox />, true)}</div>
        <div className="col-md-6">{this.generateFormField("date_from", <DatePickerBox />, true)}</div>
        <div className="col-md-6">
          {this.generateFormField("date_to", <DatePickerBox fromMonth={this.props.entity.date_from} />, true)}
        </div>
      </div>
    );
  }
}
