import BaseModel from "@model/BaseModel";
import { Type } from "@eman/class-transformer";
import { observable } from "mobx";

import WeekAmount from "@model/WeekAmount";

export default class AllocationValue extends BaseModel {
  @observable total_amount?: number;

  @Type(() => WeekAmount)
  @observable
  week_amount?: WeekAmount = new WeekAmount();

  isDirtyRelations(): string[] {
    return ["week_amount"];
  }
}
