import { observer } from "mobx-react";
import React from "react";

import Columns from "@eman/emankit/Columns";
import HeaderRow from "@eman/emankit/HeaderRow";

import BaseForm from "@component/BaseForm";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import User from "@model/User";
import Form, { DatePickerBox, GenericFormField, SelectBox, TextBox } from "@util/Form";
import { OptionType } from "@eman/emankit";

export interface UserFormProps {
  roleOptions: OptionType<number>[];
}

@observer
export default class UserForm extends BaseForm<User, UserFormProps> {
  modelName = "user";

  get nameSpace(): string {
    return "employee.personal_information.form";
  }

  showForeignInformations = (): boolean => {
    const entity = this.props.entity;
    let show = false;

    if (
      entity.enumeration_citizenship_id &&
      (this.user.allowToObject(UserRightsObjects.FOREIGN_PERSONAL_DETAIL, UserRightsOperations.SHOW) ||
        this.user.allowToObject(UserRightsObjects.FOREIGN_PERSONAL_DETAIL, UserRightsOperations.EDIT))
    ) {
      const citizenship = this.enums.value("citizenships", entity.enumeration_citizenship_id);
      if (citizenship && !citizenship.eurozone) {
        show = true;
      }
    }

    return show;
  };

  generatePersonalDetailFormFiled = (
    property: string,
    formField: React.ReactNode,
    // tslint:disable-next-line: bool-param-default
    required?: boolean,
    label?: string,
    // tslint:disable-next-line: bool-param-default
    disabled?: boolean
  ) => {
    return (
      <GenericFormField
        key={property}
        target={this.props.entity.personal_detail}
        property={property}
        label={label || this.locs.ta("personal_detail", property)}
        required={required}
        disabled={disabled || this.props.readonly}>
        {formField}
      </GenericFormField>
    );
  };

  generateForeignPersonalDetailFormFiled = (
    property: string,
    formField: React.ReactNode,
    // tslint:disable-next-line: bool-param-default
    required?: boolean,
    label?: string,
    // tslint:disable-next-line: bool-param-default
    disabled?: boolean
  ) => {
    return (
      <GenericFormField
        key={property}
        target={this.props.entity.foreign_personal_detail}
        property={property}
        label={label || this.locs.ta("foreign_personal_detail", property)}
        required={required}
        disabled={disabled || this.props.readonly}>
        {formField}
      </GenericFormField>
    );
  };

  basicInformations = (): React.ReactNode => {
    return [
      this.generateFormField("first_name", <TextBox />, true),
      this.generateFormField("last_name", <TextBox />, true),
      this.generateFormField("role_id", <SelectBox options={this.props.roleOptions} emptyRow={this.emptyRow} />, true),
      this.generateFormField(
        "enumeration_citizenship_id",
        <SelectBox options={this.enums.valuesForSelect("citizenships")} emptyRow={this.emptyRow} />,
        true
      ),
      this.generateFormField("middle_name", <TextBox />),
      this.generateFormField("last_name_at_birth", <TextBox />),
      this.generateFormField("degree_before1", <TextBox />),
      this.generateFormField("degree_before2", <TextBox />),
      this.generateFormField("degree_after", <TextBox />),
      this.generateFormField("email", <TextBox />, true),
      this.generateFormField("employee_number", <TextBox />),
      this.generateFormField("redmine_uuid", <TextBox />),
    ];
  };

  personalInformations = (): React.ReactNode => {
    return [
      this.generatePersonalDetailFormFiled("birth_place", <TextBox />),
      this.generatePersonalDetailFormFiled("birth_date", <DatePickerBox fromMonth={new Date("1924-01-01")} />),
      this.generatePersonalDetailFormFiled("birth_number", <TextBox />),
      this.generatePersonalDetailFormFiled(
        "enumeration_marital_status_id",
        <SelectBox options={this.enums.valuesForSelect("marital_statuses")} allowEmpty={true} />
      ),
      this.generatePersonalDetailFormFiled(
        "enumeration_gender_id",
        <SelectBox options={this.enums.valuesForSelect("genders")} allowEmpty={true} />
      ),
      this.generatePersonalDetailFormFiled(
        "enumeration_education_level_id",
        <SelectBox options={this.enums.valuesForSelect("education_levels")} allowEmpty={true} />
      ),
      this.generatePersonalDetailFormFiled("dependents_count", <TextBox type="number" />),
      this.generatePersonalDetailFormFiled("first_contract_from", <DatePickerBox />),
    ];
  };

  foreignInformations = (): React.ReactNode => {
    return [
      this.generateForeignPersonalDetailFormFiled("residence_permit_from", <DatePickerBox />),
      this.generateForeignPersonalDetailFormFiled("residence_permit_to", <DatePickerBox />),
      this.generateForeignPersonalDetailFormFiled("work_permit_valid_from", <DatePickerBox />),
      this.generateForeignPersonalDetailFormFiled("work_permit_valid_to", <DatePickerBox />),
      this.generateForeignPersonalDetailFormFiled("vat_number", <TextBox />),
    ];
  };

  render() {
    return (
      <Form>
        <HeaderRow title={this.tg("title")} level={2} />
        <Columns className="mb-20">{this.basicInformations()}</Columns>

        {(this.user.allowToObject(UserRightsObjects.PERSONAL_DETAIL, UserRightsOperations.SHOW) ||
          this.user.allowToObject(UserRightsObjects.PERSONAL_DETAIL, UserRightsOperations.EDIT)) && (
          <Columns className="mb-20">{this.personalInformations()}</Columns>
        )}

        {this.showForeignInformations() && (
          <>
            <HeaderRow title={this.tg("foreign_title")} level={2} />
            <Columns>{this.foreignInformations()}</Columns>
          </>
        )}
      </Form>
    );
  }
}
