import { injectable } from "inversify";

import Note from "@model/Note";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class NoteRepository extends BaseAssociatedRepository<Note> {
  constructor() {
    super(Note, "users/$ID/notes", "note");
  }
}
