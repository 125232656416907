import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import { IconType } from "@eman/emankit/Icon";

import BaseList from "@component/BaseList";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import Role from "@model/Role";
import RoleListVM from "@vm/List/Role";

import { lazyInject, TYPES } from "../../inversify.config";

@observer
export default class RoleList extends BaseList<Role, RoleListVM> {
  modelName = "role";
  searchable = false;

  @lazyInject(TYPES.RoleList)
  vm: RoleListVM;

  onAddRoleClick = () => {
    this.router.pageLink(this.uriHelper.new_roles());
  };

  headerProps() {
    return {
      text: this.tg("title"),
      buttons: () => {
        const items: React.ReactNode[] = [];

        if (this.user.allowToObject(UserRightsObjects.ROLE, UserRightsOperations.CREATE)) {
          items.push(
            <Button icon={IconType.Plus} variant={ButtonVariant.Active} onClick={this.onAddRoleClick} key="add_role">
              {this.tg("add")}
            </Button>
          );
        }

        return items;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "name",
        label: this.ta("name"),
        type: "search",
      },
    ];

    return {
      filters,
    };
  }

  dataProps() {
    const columns = [this.createField("name", {}), this.createField("order", {})];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: Role) => {
        if (this.user.allowToObject(UserRightsObjects.ROLE, UserRightsOperations.EDIT)) {
          this.router.pageLink(this.uriHelper.edit_roles(item.id));
        }
      },
    };
  }
}
