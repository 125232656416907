import { injectable } from "inversify";

import PerformanceManagement from "@model/PerformanceManagement";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class PerformanceManagementRepository extends BaseAssociatedRepository<PerformanceManagement> {
  constructor() {
    super(PerformanceManagement, "users/$ID/performance_managements", "performance_management");
  }
}
