import { observer } from "mobx-react";

import DatePicker, { DisabledDays } from "@eman/emankit/DatePicker";

import BindingElement, { BindingProps } from "./index";

interface DatePickerBoxProps<T> extends BindingProps<T> {
  dateFrom?: Date;
  dateTo?: Date;
  disabledInput?: boolean;
  disabledDays?: DisabledDays;
}

@observer
export default class DatePickerBox extends BindingElement<DatePickerBoxProps<any>, any> {
  protected handleValueChanged = (formattedDate: string, date: Date | null) => {
    // "Today" button is actually a "Now" button - so, clicking it will return current date + (undesired) current time
    // So, this will "cut" a time information to just a date information
    if (Object.prototype.toString.call(date) === "[object Date]") {
      date?.setHours(0);
      date?.setMinutes(0);
      date?.setSeconds(0);
      date?.setMilliseconds(0);
    }

    //check if selected date is in range of given date constraints if they exist
    if (this.props.dateFrom && date && date < this.props.dateFrom) {
      this.setValue(this.props.dateFrom);
      return;
    }

    if (this.props.dateTo && date && date > this.props.dateTo) {
      this.setValue(this.props.dateTo);
      return;
    }

    this.setValue(date);
  };

  render() {
    const { dateFrom, dateTo, fromMonth, toMonth, disabled, disabledInput, disabledDays, readOnly } = this.props;

    return (
      <DatePicker
        {...this.props}
        fromMonth={dateFrom || fromMonth}
        toMonth={dateTo || toMonth}
        onChange={this.handleValueChanged}
        value={this.value || ""}
        hideOnDayClick={true}
        disabledDays={disabledDays}
        disabled={disabled || readOnly || disabledInput}
      />
    );
  }
}
