import { Transform, Type } from "@eman/class-transformer";
import BaseModel from "@model/BaseModel";
import { enumValue } from "@util/Enumerable";
import { observable } from "mobx";

export default class Address extends BaseModel {
  @observable name: string;
  @observable enumeration_address_type_id: number;
  @observable street: string;
  @observable house_number: string;
  @observable street_number: string;
  @observable evidence_number: string;
  @observable city_part: string;
  @observable city: string;
  @observable zip: string;
  @observable ruian_code: string;
  @observable description: string;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;

  // Related entity
  @observable addresable_type: string;
  @observable addresable_id: string;

  @enumValue("address_types", "enumeration_address_type_id")
  @observable
  address_type: models.IEnumType;
}
