import { injectable } from "inversify";

import Capacity from "@model/Capacity";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class CapacityRepository extends BaseAssociatedRepository<Capacity> {
  constructor() {
    super(Capacity, "/users/$ID/capacities", "capacity");
  }
}
