import { injectable } from "inversify";

import Certification from "@model/Certification";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class CertificationRepository extends BaseAssociatedRepository<Certification> {
  constructor() {
    super(Certification, "users/$ID/certifications", "certification");
  }
}
