import AddressCreateModal from "@view/Employee/ContactInformation/Address/Create";
import AddressEditModal from "@view/Employee/ContactInformation/Address/Edit";
import AddressShowModal from "@view/Employee/ContactInformation/Address/Show";
import ContactCreateModal from "@view/Employee/ContactInformation/Contact/Create";
import ContactEditModal from "@view/Employee/ContactInformation/Contact/Edit";
import ContactShowModal from "@view/Employee/ContactInformation/Contact/Show";
import ContactShow from "@view/Employee/ContactInformation/Show";

import { uriHelper } from "../../../config";
import BaseRouter from "../../Base";

export const contactsRoutes: IAppRoute[] = [
  {
    path: uriHelper.employees_contact_informations_uri(),
    exact: false,
    component: ContactShow,
  },
  {
    path: uriHelper.new_employees_contact_informations_contacts_uri(),
    exact: true,
    modalComponent: ContactCreateModal,
  },
  {
    path: uriHelper.edit_employees_contact_informations_contacts_uri(),
    exact: true,
    modalComponent: ContactEditModal,
  },
  {
    path: uriHelper.show_employees_contact_informations_contacts_uri(),
    exact: true,
    modalComponent: ContactShowModal,
  },
  {
    path: uriHelper.new_employees_contact_informations_addresses_uri(),
    exact: true,
    modalComponent: AddressCreateModal,
  },
  {
    path: uriHelper.edit_employees_contact_informations_addresses_uri(),
    exact: true,
    modalComponent: AddressEditModal,
  },
  {
    path: uriHelper.show_employees_contact_informations_addresses_uri(),
    exact: true,
    modalComponent: AddressShowModal,
  },
];

export default class Contacts extends BaseRouter {
  render() {
    return this.pageRouteRender(contactsRoutes);
  }
}
