import { injectable } from "inversify";

import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";
import JobTitle from "@model/JobTitle";

@injectable()
export default class JobTitlesRepository extends BaseAssociatedRepository<JobTitle> {
  constructor() {
    super(JobTitle, "/job_titles", "job_title");
  }
}
