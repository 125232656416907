import { inject, injectable } from "inversify";

import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
import Vacation from "@model/Vacation";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class VacationListVM extends AssociatedListViewModel<Vacation, AssociatedRepositoryWithConfirm<Vacation>> {
  static defaults = {
    order: {
      field: "year",
      direction: "desc",
    },
    columns: ["year", "number_of_hours", "previous_remaining_hours", "free_approved", "exhausted"],
  };

  constructor(@inject(TYPES.VacationRepository) repository: AssociatedRepositoryWithConfirm<Vacation>) {
    super(repository);
  }
}
