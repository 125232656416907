import UriHelper, { Container } from "@util/UriHelper";

export const EXPORT_MAX_RECORDS = 2000;

export const MAXDAYHOURS = 16;

export const REDMINE_URL = "https://redmine.eman.cz";
export const PEOPLEMAN_WIKI_URL = "https://eman.pages.eman.cz/peopleman-docs";
export const REDMINE_HELPDESK_URL = `${REDMINE_URL}/projects/helpdesk/issues/new`;

export const routes: Container = {
  announcements: {
    key: "announcement_id",
  },
  employees: {
    key: "user_id",
    children: {
      personal_data: {
        type: "single",
        children: {
          schools: {
            key: "school_id",
          },
          payment_infos: {
            key: "payment_info_id",
          },
          personal_documents: {
            key: "personal_document_id",
          },
          previous_employments: {
            key: "previous_employment_id",
          },
        },
      },
      certifications: {
        key: "certification_id",
      },
      contracts: {
        key: "contract_id",
        children: {
          capacities: {
            key: "capacity_id",
          },
          job_titles: {
            key: "job_title_id",
          },
          working_time_ratios: {
            key: "working_time_ratio_id",
          },
          rates: {
            key: "rate_id",
          },
        },
      },
      evidence_states: {
        key: "evidence_state_id",
      },
      health_insurances: {
        key: "health_insurance_id",
      },
      contact_informations: {
        type: "single",
        children: {
          contacts: {
            key: "contact_id",
          },
          addresses: {
            key: "address_id",
          },
        },
      },
      dependents: {
        key: "dependent_id",
      },
      notes: {
        key: "note_id",
      },
      performance_managements: {
        key: "performance_management_id",
      },
      skills: {
        key: "skill_id",
      },
      trainings: {
        key: "training_id",
      },
      vacations: {
        key: "vacation_id",
      },
    },
  },
  employer: {
    type: "single",
    children: {
      organization_structures: {
        key: "organization_structure_id",
        collection: ["visualize", "org_chart"],
        children: {
          branch_offices: {
            key: "branch_office_id",
          },
        },
      },
      job_positions: {
        key: "job_position_id",
      },
    },
  },
  enumeration: {
    key: "enumeration_id",
    children: {
      enum: {
        key: "enum_id",
      },
    },
  },
  exports: {
    key: "export_id",
  },
  roles: {
    key: "role_id",
  },
  allocations: {
    key: "allocation_id",
  },
};

export const uriHelper = new UriHelper(routes) as any;

export const defaultFiltersValues: Map<string, FilterValues> = new Map();

// TODO: This is candidate to remove, because it is used only for translations...
export enum PAGES {
  EMPLOYEE = "employee",
  EMPLOYER = "employer",
}

export enum EMPLOYEE_SUBPAGES {
  PERSONAL_DATA = "personal_data",
  JOB_TITLE = "job_title",
  CONTACT_INFORMATION = "contact_information",
  CONTRACT = "contract",
  EVIDENCE_STATE = "evidence_state",
  HEALTH_INSURANCE = "health_insurance",
  VACATION = "vacation",
  SKILL = "skill",
  CERTIFICATION = "certification",
  PERFORMANCE_MANAGEMENT = "performance_management",
}

export enum EMPLOYER_SUBPAGES {
  JOB_POSITION = "job_position",
  ORGANIZATION_STRUCTURE = "organization_structure",
}

export const ORGANIZATION_STRUCTURE_TYPE_PARAM = "structure_type";
export const ALLOCATION_WORK_TYPE_PARAM = "is_work";
export const RATIO_SPLIT = "split";

export type DAY = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday" | "workdays";
