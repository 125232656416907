import { OptionType } from "@eman/emankit";
import OrganizationStructure, { OrganizationStructureType } from "@model/OrganizationStructure";
import CompanyCreateVM from "@vm/Create/Company";
import CreateViewModel from "@vm/Create/CreateViewModel";
import OrganizationUnitCreateVM from "@vm/Create/OrganizationUnit";
import OptionsVM from "@vm/Other/Options";

import { inject, injectable } from "inversify";
import { action, computed, observable } from "mobx";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class OrganizationStructureCreateVM extends CreateViewModel<
  OrganizationStructure,
  Repository<OrganizationStructure>
> {
  @observable type: OrganizationStructureType;

  private organizationUnitCreateVM: OrganizationUnitCreateVM;

  private companyCreateVM: CompanyCreateVM;

  private parentOptionsVM: OptionsVM<OrganizationStructure>;

  constructor(
    @inject(TYPES.OrganizationStructureRepository) repository: Repository<OrganizationStructure>,
    @inject(TYPES.OrganizationUnitCreate) organizationUnitCreateVM: OrganizationUnitCreateVM,
    @inject(TYPES.CompanyCreate) companyCreateVM: CompanyCreateVM
  ) {
    super(OrganizationStructure, repository);
    this.organizationUnitCreateVM = organizationUnitCreateVM;
    this.companyCreateVM = companyCreateVM;
    this.parentOptionsVM = new OptionsVM<OrganizationStructure>(repository, "name");
  }

  @computed
  get possibleParents(): OptionType<number>[] {
    switch (this.type) {
      case OrganizationStructureType.ORGANIZATION_UNIT:
        return this.parentOptionsVM.selectOptions;

      case OrganizationStructureType.COMPANY:
        return this.parentOptionsVM.items
          .filter(item => item.type === OrganizationStructureType.COMPANY)
          .map(item => ({ value: item.id!, label: item.name }));
    }
  }

  @action.bound
  resetEntityAndErrors() {
    switch (this.type) {
      case OrganizationStructureType.ORGANIZATION_UNIT:
        this.organizationUnitCreateVM.resetEntityAndErrors();
        this.entity = this.organizationUnitCreateVM.entity;
        break;

      case OrganizationStructureType.COMPANY:
        this.companyCreateVM.resetEntityAndErrors();
        this.entity = this.companyCreateVM.entity;
        break;

      default:
        this.entity = new this.model();
    }
  }

  async create(): Promise<number | undefined> {
    this.currentlyFetching = true;

    let response;
    switch (this.type) {
      case OrganizationStructureType.ORGANIZATION_UNIT:
        response = await this.organizationUnitCreateVM.create();
        this.entity = this.organizationUnitCreateVM.entity;
        break;

      case OrganizationStructureType.COMPANY:
        response = await this.companyCreateVM.create();
        this.entity = this.companyCreateVM.entity;
        break;
    }

    this.currentlyFetching = false;

    return response;
  }
}
