import { inject, injectable } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import debounce from "lodash/debounce";

import CurrentUser from "@model/CurrentUser";
import {
  AllocationEventObjects,
  StateRightsOperations,
  UserRightsObjects,
  UserRightsOperations,
  UserRightsPages,
  AllocationScopeObjects,
  AllocationScopeRightsOperations,
} from "@model/Rights";
import AvatarService from "@service/Avatar";
import EnumVM from "@service/Enum";
import EventBus, { AVATAR_UPLOADED, ENTITY_UPDATE, LOGIN_USER, LOGOUT_USER, REFRESH_USER_FILTER_SETTINGS } from "@util/EventBus";

import TYPES from "../inversify.types";
import Allocation, { AllocationEvent } from "@model/Allocation";
import { isEnum } from "@util/TypeGuards";

/**
 * Current user service
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
@injectable()
export default class User implements Services.CurrentUser {
  // tslint:disable-next-line:member-ordering
  @observable entity: CurrentUser;

  @inject(TYPES.CurrentUserRepository)
  private repository: ICurrentUserRepository<CurrentUser>;

  @inject(TYPES.Enum)
  private enums: EnumVM;

  @inject(TYPES.Avatar)
  private avatarService: AvatarService;

  constructor() {
    // Reset user entity (this behaves similar like CreateViewModel)
    this.entity = new CurrentUser();

    // Don't use logoutUser action here, because it triggers LOGOUT_USER event again!
    EventBus.on(LOGOUT_USER, this.clearUser);
    EventBus.on(REFRESH_USER_FILTER_SETTINGS, debounce(this.refreshUserFilterSettings, 300));
    EventBus.on(AVATAR_UPLOADED, this.reloadUsersAvatar);
    EventBus.on(ENTITY_UPDATE, this.fetchUser);
  }

  @computed
  get isLoggedIn(): boolean {
    return !this.entity.newRecord && this.entity.authenticated;
  }

  @action
  setFilterSettings(filterSettings: ListSavedSettings) {
    this.entity.filter_settings = filterSettings;
  }

  fetchUser = async () => {
    const response = await this.repository.fetchCurrent(true);
    this.setEntityFromResponse(response);
  };

  /**
   * This callback is used when avatar is changed, it forces to reload
   * current user data and set new avatar URI.
   * @param data
   */
  reloadUsersAvatar = async (data: { id: number }) => {
    if (this.entity.id === data.id) {
      const response = await this.repository.fetchCurrent(false);

      runInAction(() => {
        this.entity.last_avatar_updated_at = response.entity!.last_avatar_updated_at;
      });
    }
  };

  refreshUserFilterSettings = async () => {
    const response = await this.repository.fetchCurrent(false);
    if (response.status && response.entity) {
      this.setFilterSettings(response.entity.filter_settings);
    }
  };

  async loginUser(): Promise<models.IErrors | undefined> {
    window.location.href = "/api/users/auth/google_oauth2";
    return Promise.resolve(undefined);
  }

  logoutUser = async () => {
    await this.repository.logout();
    EventBus.trigger(LOGOUT_USER);
  };

  @action
  clearUser = () => {
    // Reset only authenticated  users with ID (LOGOUT SIGNAL)
    if (this.entity.authenticated) {
      this.entity = new CurrentUser();
    }
  };

  @computed
  get avatar() {
    return this.avatarService.getUrl(this.entity.id!, this.entity.last_avatar_updated_at);
  }

  /**
   * Override parent to send global event about login user.
   * @memberof GeneralUser
   */
  @action
  setEntityFromResponse(response: ApiResponse<CurrentUser>) {
    if (response.errors) {
      this.entity.setErrors(response.errors);
    }

    if (response.status && response.entity) {
      this.entity = response.entity;
      if (this.entity.authenticated) {
        this.enums.assignObjectEnum(this.entity);
      }

      // If you uncomment this, function with save language in cookie stop working
      // LocaleProvider.changeLocaleByUserLang(this.entity.lang);
      EventBus.trigger(LOGIN_USER);
    }
  }

  /**
   * Return saved settings for specific model
   */
  savedSettings(model: string): ListSettings {
    let settings: ListSettings = {};

    if (this.entity.filter_settings && this.entity.filter_settings[model]) {
      const { filters, ...savedSettings } = this.entity.filter_settings[model];

      // Filter values has to be transformed to proper format
      const transformedFilters = {};
      for (const key in filters.where) {
        if (filters.where.hasOwnProperty(key)) {
          transformedFilters[key] = {
            values: filters.where[key].values,
            operator: filters.where[key].type,
          };
        }
      }

      settings = {
        ...savedSettings,
        filters: transformedFilters,
      };
    }

    return settings;
  }

  /**
   * Check if user can do specified operation with given object
   *
   * @param object  Entity
   * @param operation  basically CRUD operations or other actions for some entities
   */
  allowToObject(object: UserRightsObjects, operation?: UserRightsOperations): boolean;
  allowToObject(object: UserRightsObjects.ANNOUNCEMENT, operation?: UserRightsOperations | StateRightsOperations): boolean;
  allowToObject(object: UserRightsObjects.ALLOCATION_WORKING, operation?: UserRightsOperations | StateRightsOperations): boolean;
  allowToObject(object: AllocationScopeObjects.ALLOCATION_SCOPE, operation?: AllocationScopeRightsOperations): boolean;
  allowToObject(
    object: UserRightsObjects.ALLOCATION_NONWORKING,
    operation?: UserRightsOperations | StateRightsOperations
  ): boolean;
  allowToObject(
    object: UserRightsObjects.ALLOCATION_NONWORKING_SELF,
    operation?: UserRightsOperations | StateRightsOperations
  ): boolean;
  allowToObject(object: UserRightsObjects.APPLICATION_GRANT, operation?: UserRightsOperations | StateRightsOperations): boolean;
  allowToObject(object: AllocationEventObjects, operation: AllocationEvent): boolean;
  allowToObject(
    object: AllocationEventObjects | UserRightsObjects | AllocationScopeObjects,
    // tslint:disable:max-union-size
    operation:
      | AllocationEvent
      | StateRightsOperations
      | AllocationScopeRightsOperations
      | UserRightsOperations = UserRightsOperations.LIST
    // tslint:enable:max-union-size
  ): boolean {
    const isAllocationEventObject = isEnum(AllocationEventObjects);
    const isAllocationEvent = isEnum(AllocationEvent);
    const isAllocationScopeObject = isEnum(AllocationScopeObjects);
    const isAllocationScopeRightsOperations = isEnum(AllocationScopeRightsOperations);
    const isUserRightsObjects = isEnum(UserRightsObjects);
    const isUserRightsOperations = isEnum(UserRightsOperations);
    const isStateRightsOperations = isEnum(StateRightsOperations);

    // @TODO findout how to do it simplier without as any
    if (isAllocationScopeObject(object) && isAllocationScopeRightsOperations(operation)) {
      return this.entity.role && this.entity.role.rights[object] && this.entity.role.rights[object].indexOf(operation) !== -1;
    } else if (isAllocationEventObject(object) && isAllocationEvent(operation)) {
      return this.entity.role && this.entity.role.rights[object] && this.entity.role.rights[object].indexOf(operation) !== -1;
    } else if (isUserRightsObjects(object) && (isUserRightsOperations(operation) || isStateRightsOperations(operation))) {
      // This rights dependents on User->Show right
      if (
        operation === UserRightsOperations.LIST &&
        [UserRightsObjects.BRANCH_OFFICE, UserRightsObjects.JOB_POSITION, UserRightsObjects.ROLE].indexOf(object) !== -1
      ) {
        return this.allowToObject(UserRightsObjects.USER, UserRightsOperations.SHOW);
      }

      return (
        this.entity.role &&
        this.entity.role.rights[object] &&
        this.entity.role.rights[object].indexOf(operation as UserRightsOperations) !== -1
      );
    }

    return false;
  }

  /**
   * Authorize if current user can perform operation with given Allocation entity
   *
   */
  allowToAllocation(allocation: Allocation, operation: StateRightsOperations.STATUS_CHANGE, event?: AllocationEvent): boolean;
  allowToAllocation(allocation: Allocation, operation: UserRightsOperations.EDIT | UserRightsOperations.SHOW): boolean;
  allowToAllocation(
    allocation: Allocation,
    operation: UserRightsOperations.EDIT | UserRightsOperations.SHOW | StateRightsOperations.STATUS_CHANGE,
    event?: AllocationEvent
  ): boolean {
    if (allocation.is_work) {
      return (
        this.allowToObject(UserRightsObjects.ALLOCATION_WORKING, operation) &&
        (!event ||
          (event &&
            operation === StateRightsOperations.STATUS_CHANGE &&
            this.allowToObject(AllocationEventObjects.ALLOCATION_WORKING_EVENTS, event)))
      );
    } else {
      const selfCondition: boolean =
        this.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING_SELF, operation) &&
        (!event ||
          (event &&
            operation === StateRightsOperations.STATUS_CHANGE &&
            this.allowToObject(AllocationEventObjects.ALLOCATION_NONWORKING_SELF_EVENTS, event)));

      const baseCondition: boolean =
        this.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING, operation) &&
        (!event ||
          (event &&
            operation === StateRightsOperations.STATUS_CHANGE &&
            this.allowToObject(AllocationEventObjects.ALLOCATION_NONWORKING_EVENTS, event)));

      if (allocation.user_id === this.entity.id) {
        return selfCondition || baseCondition;
      } else {
        return baseCondition;
      }
    }
  }

  /**
   * Check if user can create, read or delete specified object
   *
   * @param object Entity
   */
  allowToManageObject(object: UserRightsObjects): boolean {
    return (
      this.allowToObject(object, UserRightsOperations.CREATE) ||
      this.allowToObject(object, UserRightsOperations.EDIT) ||
      this.allowToObject(object, UserRightsOperations.DELETE)
    );
  }

  /**
   * Computed rights for Pages which is consist of more entities or need other additional logic
   */
  allowToPage(page: UserRightsPages): boolean {
    switch (page) {
      case UserRightsPages.ANNOUNCEMENT:
        return this.isAdmin || this.allowToObject(UserRightsObjects.ANNOUNCEMENT);

      case UserRightsPages.PERSONAL_DATA:
        return this.allowToObject(UserRightsObjects.USER, UserRightsOperations.SHOW);

      case UserRightsPages.CONTACT_INFORMATION:
        return this.allowToObject(UserRightsObjects.CONTACT) || this.allowToObject(UserRightsObjects.ADDRESS);

      case UserRightsPages.CONTRACT:
        return (
          this.allowToObject(UserRightsObjects.CONTRACT) &&
          this.allowToObject(UserRightsObjects.CONTRACT, UserRightsOperations.SHOW)
        );

      case UserRightsPages.EMPLOYEES:
        return this.allowToObject(UserRightsObjects.USER) && (this.hasSubordinates || this.isAdmin);

      case UserRightsPages.ENUMERATION:
        return this.isAdmin && this.allowToManageObject(UserRightsObjects.ENUMERATION);

      case UserRightsPages.ROLE:
        return this.isAdmin && this.allowToManageObject(UserRightsObjects.ROLE);

      case UserRightsPages.EMPLOYER:
      case UserRightsPages.ORGANIZATION_STRUCTURE:
        return this.allowToObject(UserRightsObjects.USER, UserRightsOperations.SHOW);

      case UserRightsPages.ALLOCATION:
        return (
          this.allowToObject(UserRightsObjects.ALLOCATION_WORKING) ||
          this.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING) ||
          this.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING_SELF)
        );

      default:
        return true;
    }
  }

  /**
   * Admins
   * - can see all employee hierarchy not depending on its real placement in it
   * - see all Announcements not just self
   */
  get isAdmin(): boolean {
    return this.entity.role.admin;
  }

  /**
   * HasSubordinates
   * - if user has any subordinate in employee hiearchy, its computed from its job title
   */
  get hasSubordinates(): boolean {
    return this.entity.has_subordinates;
  }

  /**
   * IDs of subordinates which current user can view
   */
  get managedUserIDs(): number[] {
    return this.entity.managed_user_ids;
  }
}
