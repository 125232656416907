import { observer } from "mobx-react";
import React from "react";

import Alert from "@eman/emankit/Alert";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import { BaseColor } from "@eman/emankit/Colors";
import HeaderRow from "@eman/emankit/HeaderRow";
import { IconType } from "@eman/emankit/Icon";

import ResourceComponent from "@component/ResourceComponent";
import RateItem, { RateItemState } from "@model/RateItem";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import RateItemForm from "./ItemForm";

export interface RateItemProps {
  entity: RateItem;
  index: number;
  onRemoveClick?: () => void;
  withState?: boolean;
  readonly?: boolean;
}

@observer
export default class Item extends ResourceComponent<RateItemProps> {
  modelName = "rate_item";
  prefix = "employee.contract.rate.form";

  stateToColor = (): BaseColor | undefined => {
    if (!this.props.withState) {
      return undefined;
    }

    return {
      [`${RateItemState.HAS_ERRORS}`]: BaseColor.Red,
      [`${RateItemState.CHANGED}`]: BaseColor.LightBlue,
      [`${RateItemState.SAVED}`]: BaseColor.Green,
      [`${RateItemState.NOT_CHANGED}`]: BaseColor.Gray,
    }[this.props.entity.state];
  };

  badgeText = (): string | undefined => {
    if (!this.props.withState) {
      return undefined;
    }

    const state = this.props.entity.state;
    let text: string;

    if (state === RateItemState.NOT_CHANGED) {
      text = "";
    } else {
      text = this.tg(`state.${state}`);
    }

    return text;
  };

  render() {
    const { entity, index, onRemoveClick, readonly } = this.props;

    return (
      <div className="rateItem">
        <HeaderRow
          title={this.tg("title").replace("%ID", (index + 1).toString())}
          badge={this.badgeText()}
          badgeColor={this.stateToColor()}
          level={3}>
          {this.user.allowToObject(UserRightsObjects.RATE_ITEM, UserRightsOperations.DELETE) && !readonly && onRemoveClick && (
            <Button type="button" onClick={onRemoveClick} icon={IconType.Trash} variant={ButtonVariant.Dangerous} />
          )}
        </HeaderRow>
        {entity.hasError("base") && (
          <Alert className="mb-20" color={BaseColor.Red}>
            {entity.errors.get("base")}
          </Alert>
        )}
        <RateItemForm entity={entity} readonly={readonly} />
      </div>
    );
  }
}
