import { inject, injectable } from "inversify";
import { computed } from "mobx";

import Company from "@model/Company";
import OrganizationStructure, { OrganizationStructureType } from "@model/OrganizationStructure";
import OrganizationUnit from "@model/OrganizationUnit";
import OptionsVM from "@vm/Other/Options";
import ShowViewModel from "@vm/Show/ShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class OrganizationStructureShowVM extends ShowViewModel<OrganizationStructure, Repository<OrganizationStructure>> {
  private type: OrganizationStructureType;
  private parentOptionsVM: OptionsVM<OrganizationStructure>;

  constructor(
    @inject(TYPES.OrganizationStructureRepository) repository: Repository<OrganizationStructure>,
    @inject(TYPES.CompanyRepository) private companyRepository: Repository<Company>,
    @inject(TYPES.OrganizationUnitRepository) private organizationUnitRepository: Repository<OrganizationUnit>
  ) {
    super(OrganizationStructure, repository);
    this.parentOptionsVM = new OptionsVM(repository, "name");
  }

  @computed
  get possibleParents() {
    return this.parentOptionsVM.selectOptions;
  }

  init(id: number, type?: OrganizationStructureType) {
    this.type = type!;

    switch (this.type) {
      case OrganizationStructureType.COMPANY:
        this.repository = this.companyRepository;
        this.model = Company;
        break;

      case OrganizationStructureType.ORGANIZATION_UNIT:
        this.repository = this.organizationUnitRepository;
        this.model = OrganizationUnit;
        break;
    }

    super.init(id);
  }
}
