import WorkingTimeRatio from "@model/WorkingTimeRatio";
import WorkingTimeRatioRepository from "@repository/WorkingTimeRatio";
import User from "@service/CurrentUser";
import EnumVM from "@service/Enum";
import { inject, injectable } from "inversify";
import { computed } from "mobx";
import TYPES from "src/inversify.types";
import AssociatedEditViewModel from "./AssociatedEditViewModel";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class WorkingTimeRatiosEditVM extends AssociatedEditViewModel<
  WorkingTimeRatio,
  AssociatedRepository<WorkingTimeRatio>
> {
  repository: WorkingTimeRatioRepository;
  user: User;
  enumService: EnumVM;

  employeeId: number;
  contractId: number;

  constructor(
    @inject(TYPES.WorkingTimeRatioRepository) repository: WorkingTimeRatioRepository,
    @inject(TYPES.User) user: User,
    @inject(TYPES.Enum) enumService: EnumVM
  ) {
    super(WorkingTimeRatio, repository);
    this.repository = repository;
    this.user = user;
    this.enumService = enumService;
  }

  async initVM(userId: number, contractId: number, workTimeRatioId: number) {
    if (workTimeRatioId && this.id === workTimeRatioId) {
      return;
    }

    this.repository.setId(contractId, userId);
    this.setId(workTimeRatioId);
  }

  @computed
  get allowedToSave() {
    return this.entity.job_title_working_time_ratios.map(({ ratio }) => ratio).reduce((prev, current) => prev + current, 0) !== 1;
  }
}
