import AllocationMain from "@view/Allocation";
import AllocationCreate from "@view/Allocation/Create";
import AllocationEdit from "@view/Allocation/Edit";

import BaseRouter from "./Base";

import { uriHelper } from "../config";

export const allocationsRouter: IAppRoute[] = [
  {
    path: uriHelper.allocations_uri(),
    exact: false,
    component: AllocationMain,
  },
  {
    path: uriHelper.new_allocations_uri(),
    exact: true,
    modalComponent: AllocationCreate,
  },
  {
    path: uriHelper.edit_allocations_uri(),
    exact: true,
    modalComponent: AllocationEdit,
  },
  {
    path: uriHelper.show_allocations_uri(),
    exact: true,
    modalComponent: AllocationEdit,
  },
];

export default class Allocations extends BaseRouter {
  render() {
    return (
      <>
        <div className="emankit__layout__content">{this.pageRouteRender(allocationsRouter)}</div>
      </>
    );
  }
}
