import { observer } from "mobx-react";

import ResourceComponent from "@component/ResourceComponent";
import EvidenceStateList from "@view/Employee/EvidenceState/List";

@observer
export default class EvidenceState extends ResourceComponent<PropsFromRouter> {
  modelName = "evidence_state";
  prefix = "employee.contract";

  render() {
    return <EvidenceStateList match={this.props.match} />;
  }
}
