import { observer } from "mobx-react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";
import { IconType } from "@eman/emankit/Icon";

import ResourceComponent from "@component/ResourceComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import AllocationTabs from "@view/Allocation/Tabs";

import { ALLOCATION_WORK_TYPE_PARAM } from "../../config";

import "./styles.scss";
import React from "react";

const scrollDetectionBorder = 105;

const scrollTopBorder = 0;

@observer
export default class Allocation extends ResourceComponent {
  modelName = "allocation";

  private buttonsUpdate: any;
  private buttonsRef = React.createRef<any>();

  displayNewWork = () => {
    this.router.pageLink(this.uriHelper.new_allocations_uri(), { [ALLOCATION_WORK_TYPE_PARAM]: true });
  };

  displayNewAbsence = () => {
    this.router.pageLink(this.uriHelper.new_allocations_uri(), { [ALLOCATION_WORK_TYPE_PARAM]: false });
  };

  componentDidMount(): void {
    this.buttonsUpdate = () => this.handleScroll();
    window.addEventListener("scroll", this.buttonsUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.buttonsUpdate);
  }

  handleScroll = () => {
    const height = document?.getElementById("floating")?.getBoundingClientRect()?.height;

    if (scrollDetectionBorder < window.pageYOffset) {
      this.buttonsRef.current?.classList.add("floating-buttons");
      if (height) {
        this.buttonsRef.current.style.top = `${height + 4}px`;
      }
    } else if (scrollTopBorder === window.pageYOffset) {
      this.buttonsRef.current?.classList.remove("floating-buttons");
      this.buttonsRef.current.style.top = `0px`;
    }
  };

  render() {
    return (
      <>
        <HeaderRow title={this.tg("title")}>
          <div ref={this.buttonsRef}>
            {this.user.allowToObject(UserRightsObjects.ALLOCATION_WORKING, UserRightsOperations.CREATE) && (
              <Button variant={ButtonVariant.Active} icon={IconType.Plus} onClick={this.displayNewWork}>
                {this.tg("new_work")}
              </Button>
            )}
            {(this.user.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING, UserRightsOperations.CREATE) ||
              this.user.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING_SELF, UserRightsOperations.CREATE)) && (
              <Button variant={ButtonVariant.Active} icon={IconType.Plus} onClick={this.displayNewAbsence}>
                {this.tg("new_absence")}
              </Button>
            )}
          </div>
        </HeaderRow>
        <AllocationTabs />
      </>
    );
  }
}
