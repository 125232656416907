import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import Rate from "@model/Rate";
import RateCreateVM from "@vm/Create/Rate";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";
import EventBus, { SHOW_TOAST, JOB_TITLES_CHECK } from "@util/EventBus";
import { confirmYesNo } from "@eman/emankit/ConfirmAlert";
import { ButtonVariant } from "@eman/emankit/Button";

@observer
export default class RateCreateModal extends CreateModal<Rate> {
  modelName = "rate";
  prefix = "employee.contract";

  @lazyInject(TYPES.RateCreate)
  vm: RateCreateVM;

  componentDidMount() {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.show_employees_contracts(params.user_id, params.contract_id));
  };

  onSave = async () => {
    confirmYesNo(
      <>
        <p className="warning-message">{this.locs.tg("form.create_confirm_rate.create_rate")}</p>
        <br />
        {this.locs.tg("form.create_confirm_rate.end")}
      </>,
      async () => {
        const id = await this.vm.create();

        if (id) {
          EventBus.trigger(SHOW_TOAST, this.successMessage());
          EventBus.trigger(JOB_TITLES_CHECK);
          this.onClose();
        }
      },
      { confirmButtonType: ButtonVariant.SecondaryBlue, cancelButtonType: ButtonVariant.Primary }
    );
    return "";
  };

  renderModalBody() {
    return (
      <Form
        entity={this.vm.entity}
        onAddItem={this.vm.addItem}
        onRemoveItem={this.vm.removeItem}
        items={this.vm.items}
        currentContract={this.vm.contractsVM.currentContract}
      />
    );
  }
}
