import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import Vacation from "@model/Vacation";
import { lazyInject, TYPES } from "../../../inversify.config";
import VacationForm from "./Form";
import VacationEditVM from "@vm/Edit/Vacation";

@observer
export default class VacationEdit extends EditModal<Vacation> {
  modelName = "vacation";
  prefix = "employee";

  @lazyInject(TYPES.VacationEdit)
  vm: VacationEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_vacations(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <>
        <VacationForm entity={this.vm.entity} />
      </>
    );
  }
}
