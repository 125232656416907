import { injectable, unmanaged } from "inversify";
import { action, observable, set } from "mobx";

import Attachment from "@model/Attachment";
import EventBus, { IDisposable, SHOW_ERROR, UPLOAD_FILE_PROGRESS } from "@util/EventBus";

@injectable()
export default class AttachmentsVM<TModel> {
  repository: WithAttachmentsRepository<TModel>;

  @observable files: Attachment[] = [];

  @observable currentlyUploading: boolean;

  // This is needed for recognize unique files because filename is not unique
  private idCounter = 0;

  private readonly eventHandlers: IDisposable[] = [];

  constructor(@unmanaged() repository: WithAttachmentsRepository<TModel>) {
    this.repository = repository;
  }

  @action.bound
  uploadFiles(attachableId: number, files: File[], data?: FormDataArguments) {
    this.checkRepository();

    this.eventHandlers.push(EventBus.on(UPLOAD_FILE_PROGRESS, this.uploadProgress));
    this.currentlyUploading = true;

    const promises = Promise.all(
      files.map(async (file: File) => {
        this.idCounter++;

        // plainToClass is not possible here because of Blob data !!!
        const uploaderFile: Attachment = Object.assign(new Attachment(), {
          file,
          total: file.size,
          uploaded: 0,
          identificator: this.idCounter,
        });

        this.files.push(uploaderFile);

        const formData: FormDataArguments = {
          ...data,
        };

        const response = await this.repository.uploadAttachment(attachableId, uploaderFile, formData);

        if (response.status && response.entity && response.entity.id) {
          set(uploaderFile, "id", response.entity.id);
        } else if (!response.status && response.errors) {
          uploaderFile.setErrors(response.errors);
        } else {
          EventBus.trigger(SHOW_ERROR, "errors.unknown_error");
        }

        return uploaderFile;
      })
    );

    promises.finally(() => {
      this.currentlyUploading = false;
      this.eventHandlers.forEach(x => x.dispose());
    });

    return promises;
  }

  @action
  uploadProgress = (data: any) => {
    const index: number = this.files.findIndex((item: models.IAttachment) => item.identificator === data.identificator);

    if (index !== -1) {
      const attachment: models.IAttachment = this.files[index];
      set(attachment, "total", data.progressEvent.total);
      set(attachment, "uploaded", data.progressEvent.loaded);
    }
  };

  /**
   * Remove uploaded file
   */
  @action.bound
  remove(id: number): Promise<any> {
    this.checkRepository();

    this.files = this.files.filter((item: models.IAttachment) => item.id !== id);
    return this.repository.destroy(id);
  }

  checkRepository = () => {
    if (!this.repository) {
      throw new Error("Set repository first");
    }
  };
}
