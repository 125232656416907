import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import Rate from "@model/Rate";
import RateEditVM from "@vm/Edit/Rate";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";
import { ButtonVariant } from "@eman/emankit/Button";
import { confirmYesNo } from "@eman/emankit/ConfirmAlert";
import EventBus, { SHOW_TOAST, JOB_TITLES_CHECK, JOB_TITLES_UPDATED } from "@util/EventBus";

@observer
export default class RateEditModal extends EditModal<Rate> {
  modelName = "rate";
  prefix = "employee.contract";

  @lazyInject(TYPES.RateEdit)
  vm: RateEditVM;

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.show_employees_contracts(params.user_id, params.contract_id));
  };

  get changesPrompt(): boolean {
    return this.vm.entity.changes > 0 || this.vm.rateItemEditListVM.changes > 0;
  }

  onDelete = async () => {
    confirmYesNo(
      <>
        <p className="warning-message">{this.locs.tg("form.delete_confirm_rate.delete_rate")}</p>
        {this.locs.tg("form.delete_confirm_rate.begin")}
        <br />
        {this.locs.tg("form.delete_confirm_rate.end")}
      </>,
      async () => {
        const status = await this.vm.destroy();

        if (status) {
          EventBus.trigger(SHOW_TOAST, this.locs.tg("form.deleted"));

          EventBus.trigger(JOB_TITLES_CHECK);

          this.onClose();
        }

        return status;
      },
      { confirmButtonType: ButtonVariant.SecondaryBlue, cancelButtonType: ButtonVariant.Primary }
    );
  };

  onSave = async () => {
    let status;
    confirmYesNo(
      <>
        <p className="warning-message">
          {this.locs.tg("form.edit_confirm_rate.edit_rate")}
          <br />
          {`${this.locs.tg("form.edit_confirm_rate.from")} ${this.locs.formatDate(this.vm.entity.valid_from)} ${
            this.vm.entity.valid_to ? `${this.locs.tg("form.edit_confirm_rate.to")}` : ""
          } ${this.locs.formatDate(this.vm.entity.valid_to)}`}
        </p>
        {this.locs.tg("form.edit_confirm_rate.end")}
      </>,
      async () => {
        const response = await this.vm.update();
        if (response) {
          this.vm.entity.clearErrors();
          EventBus.trigger(JOB_TITLES_UPDATED);
          EventBus.trigger(SHOW_TOAST, this.successMessage());
          this.onClose();
        }

        status = response;
      },
      { confirmButtonType: ButtonVariant.SecondaryBlue, cancelButtonType: ButtonVariant.Primary }
    );
    return status || false;
  };

  renderModalBody() {
    return <Form entity={this.vm.entity} onAddItem={this.vm.addItem} onRemoveItem={this.vm.removeItem} items={this.vm.items} />;
  }
}
