import { observable } from "mobx";

import BaseModel from "@model/BaseModel";
import { enumValue } from "@util/Enumerable";

export default class Skill extends BaseModel {
  @observable user_id: string;
  @observable enumeration_skill_level_id: number;
  @observable enumeration_skill_type_id: number;

  @enumValue("skill_types", "enumeration_skill_type_id")
  @observable
  type: models.IEnumType;

  @enumValue("skill_levels", "enumeration_skill_level_id")
  @observable
  level: models.IEnumType;
}
