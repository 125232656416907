import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import PreviousEmployment from "@model/PreviousEmployment";
import PreviousEmploymentShowVM from "@vm/Show/PreviousEmployment";

import { lazyInject, TYPES } from "../../../../inversify.config";

import Form from "./Form";

@observer
export default class PreviousEmploymentShowModal extends ShowModal<PreviousEmployment> {
  modelName = "previous_employment";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.PreviousEmploymentShow)
  vm: PreviousEmploymentShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} vm={this.vm.previousEmploymentFormVM} readonly={true} />;
  }
}
