import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import Dependent from "@model/Dependent";
import DependentShowVM from "@vm/Show/Dependent";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class DependentShowModal extends ShowModal<Dependent> {
  modelName = "dependent";
  prefix = "employee";

  @lazyInject(TYPES.DependentShow)
  vm: DependentShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_dependents(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} readonly={true} />;
  }
}
