import { inject, injectable } from "inversify";

import PersonalDocument from "@model/PersonalDocument";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
import PersonalDocumentFormVM from "@vm/Form/PersonalDocument";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PersonalDocumentShowVM extends AssociatedShowViewModel<
  PersonalDocument,
  AssociatedRepository<PersonalDocument>
> {
  constructor(
    @inject(TYPES.PersonalDocumentRepository) repository: AssociatedRepository<PersonalDocument>,
    @inject(TYPES.PreviousEmploymentForm) public personalDocumentFormVM: PersonalDocumentFormVM
  ) {
    super(PersonalDocument, repository);
  }

  setEntity(entity: PersonalDocument) {
    super.setEntity(entity);
    this.personalDocumentFormVM.setEntity(entity);
  }
}
