import { observer } from "mobx-react";
import React from "react";

import HeaderRow from "@eman/emankit/HeaderRow";
import Panel from "@eman/emankit/Panel";

import ChangeFooter from "@component/ChangeFooter";
import Edit from "@component/Edit";
import Contract from "@model/Contract";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import EventBus, { CONTRACT_UPDATED, SHOW_TOAST } from "@util/EventBus";
import ViewHelpers from "@util/ViewHelpers";
import Capacity from "@view/Employee/Contract/Capacity";
import JobTitle from "@view/Employee/Contract/JobTitle";
import Rate from "@view/Employee/Contract/Rate";
import ContractEditVM from "@vm/Edit/Contract";

import { lazyInject, TYPES } from "../../../inversify.config";

import ContractForm from "./Form";
import ContractHeader from "./Header";
import WorkingTimeRatios from "./WorkingTimeRatios";
import ContractFormTermination from "./FormTermination";

@observer
export default class ContractEdit extends Edit<Contract, ContractEditVM, PropsFromRouter> {
  modelName = "contract";
  prefix = "employee";

  @lazyInject(TYPES.ContractEdit)
  vm: ContractEditVM;

  componentDidMount(): void {
    const params = this.props.match.params;

    this.vm.init(params.contract_id, params.user_id);
  }

  updateContract = async () => {
    const response = await this.vm.update();
    if (response) {
      EventBus.trigger(CONTRACT_UPDATED);
      EventBus.trigger(SHOW_TOAST, this.locs.tg("form.updated"));
    }
  };

  resetChanges = () => {
    this.vm.fetchItem(false);
  };

  render() {
    const { entity } = this.vm;

    return (
      <>
        <ContractHeader match={this.props.match} showAdd={true} />
        <Panel>
          <HeaderRow
            title={this.tg("title")}
            level={2}
            badge={entity.state ? this.tg(`state.${entity.state}`) : undefined}
            badgeColor={entity.state ? ViewHelpers.contractStateToColor(entity.state) : undefined}
          />
          <ContractForm
            entity={entity}
            vm={this.vm.contractFormVM}
            readonly={!this.user.allowToObject(UserRightsObjects.CONTRACT, UserRightsOperations.EDIT)}
            validFromDisabled
          />
          <ChangeFooter
            errorCount={entity.errorCount}
            changes={entity.changes}
            onSaveClick={this.updateContract}
            onResetClick={this.resetChanges}
          />
        </Panel>

        {entity.id && this.user.allowToObject(UserRightsObjects.RATE, UserRightsOperations.LIST) && (
          <Rate match={this.props.match} />
        )}

        {entity.id && this.user.allowToObject(UserRightsObjects.JOB_TITLE, UserRightsOperations.LIST) && (
          <JobTitle match={this.props.match} />
        )}

        {entity.id && this.user.allowToObject(UserRightsObjects.WORKING_TIME_RATIO, UserRightsOperations.LIST) && (
          <WorkingTimeRatios match={this.props.match} />
        )}

        {entity.id && this.user.allowToObject(UserRightsObjects.CAPACITY, UserRightsOperations.LIST) && (
          <Capacity match={this.props.match} />
        )}

        <Panel>
          <ContractFormTermination
            entity={entity}
            vm={this.vm.contractFormVM}
            readonly={!this.user.allowToObject(UserRightsObjects.CONTRACT, UserRightsOperations.EDIT)}
          />
          <ChangeFooter
            errorCount={entity.errorCount}
            changes={entity.changes}
            onSaveClick={this.updateContract}
            onResetClick={this.resetChanges}
          />
        </Panel>
      </>
    );
  }
}
