import { inject, injectable } from "inversify";
import { action } from "mobx";

import Rate from "@model/Rate";
import RateItem from "@model/RateItem";
import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";
import RateItemsEditListVM from "@vm/EditList/RateItems";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class RateEditVM extends AssociatedEditViewModel<Rate, AssociatedRepository<Rate>> {
  rateItemEditListVM: RateItemsEditListVM;

  constructor(
    @inject(TYPES.RateRepository) repository: AssociatedRepository<Rate>,
    @inject(TYPES.RateItemEditList) rateItemEditListVM: RateItemsEditListVM
  ) {
    super(Rate, repository);
    this.rateItemEditListVM = rateItemEditListVM;
  }

  init(id: number, parentId?: number) {
    if (id && id !== this.id) {
      this.rateItemEditListVM.setParentId(parentId!, id);
    }
    super.init(id, parentId);
  }

  async update(): Promise<boolean> {
    const responses = await this.rateItemEditListVM.saveOrCreateItems();
    let allStatuses = true;
    responses.forEach(response => {
      const status: boolean = response.status !== undefined ? response.status : (response as any);
      if (!status) {
        allStatuses = false;
      }
    });

    const entityStatus = await super.update();

    return entityStatus && allStatuses;
  }

  @action.bound
  addItem() {
    this.rateItemEditListVM.add();
  }

  @action.bound
  removeItem(item: RateItem) {
    this.rateItemEditListVM.remove(item);
  }

  get items(): RateItem[] {
    return this.rateItemEditListVM.list.filter(item => !item.markedForDestroy || item.errorCount);
  }
}
