import BaseRepository from "@repository/BaseRepository";
import ApiClient, { AxiosConfig } from "@util/ApiClient";

import EventBus, { ENTITY_CREATED, UPLOAD_FILE_PROGRESS } from "@util/EventBus";

import { toJS } from "mobx";

import { classToPlain } from "@eman/class-transformer";

const objectToFormData = (obj: any, rootName = "", ignoreList: string[] = []): FormData => {
  const formData = new FormData();

  function ignore(root: string) {
    return ignoreList.some(x => x === root);
  }

  // tslint:disable-next-line: cognitive-complexity
  function appendFormData(data: any, root: string, lastKey: string) {
    if (!ignore(root) && !ignore(lastKey)) {
      root = root || "";
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          appendFormData(data[i], `${root}[${i}]`, `${i}`);
        }
      } else if (typeof data === "object" && data && !(data instanceof Date)) {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            if (root === "") {
              appendFormData(data[key], key, key);
            } else {
              appendFormData(data[key], `${root}[${key}]`, key);
            }
          }
        }
      } else {
        if (data !== null && typeof data !== "undefined") {
          formData.append(root, data);
        }
      }
    }
  }

  appendFormData(obj, rootName, "");

  return formData;
};

export default abstract class BaseRepositoryWithAttachments<T extends models.IWithAttachments>
  extends BaseRepository<T>
  implements WithAttachmentsRepository<T> {
  create(object: T): Promise<ApiResponse<T>> {
    // Send as form data...

    const data = classToPlain(object);

    // Copy document_attributes as files...
    // @ts-ignore
    data.documents_attributes = toJS(object.documents_attributes);

    let formData: any = objectToFormData(
      {
        [this.modelName]: data,
      },
      "",
      [
        "constructor",
        "saved",
        "markedForDestroy",
        "isDirtyRelations",
        "resetEnums",
        "hasError",
        "__alreadyAssignedEnums",
        "total",
        "id",
      ]
    ) || { [this.modelName]: {} };

    if (formData.entries().next().done) {
      formData = {
        [this.modelName]: {},
      };
    }

    const config = {
      url: this.uri,
      method: "POST" as "POST" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      id: `CREATING_${this.modelName}`,
      data: formData,
    };

    return ApiClient.fetchResponse(config).then(response => {
      if (response.original && response.original.entity) {
        EventBus.trigger(ENTITY_CREATED, {
          identificator: this.modelName,
          id: response.original.entity.id,
        });
      }

      return response;
    });
  }

  uploadAttachment(attachableId: number, attachment: models.IAttachment, data: FormDataArguments): Promise<any> {
    const formData: FormData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    formData.append("file", attachment.attach);

    const config: AxiosConfig = {
      url: `${this.uri}/${attachableId}/documents`,
      method: "POST",
      id: "UPLOAD_ATTACHMENT",
      loading: false,
      data: formData,
      onUploadProgress: (progressEvent: any) => {
        EventBus.trigger(UPLOAD_FILE_PROGRESS, {
          identificator: attachment.identificator,
          progressEvent,
        });
      },
    };

    return ApiClient.fetchResponse(config);
  }

  deleteAttachment(attachableId: number, attachmentID: number) {
    const config: AxiosConfig = {
      url: `${this.uri}/${attachableId}/documents/${attachmentID}`,
      method: "DELETE",
      id: "DELETE_ATTACHMENT",
      loading: false,
    };

    return ApiClient.fetchResponse(config);
  }

  async downloadAttachment(attachableId: number, attachmentID: number): Promise<Blob> {
    const config: AxiosConfig = {
      url: `${this.uri}/${attachableId}/documents/${attachmentID}`,
      id: "DOWNLOAD_DOCUMENT",
      responseType: "blob",
    };

    return ApiClient.fetchData(config);
  }
}
