import { Exclude, Transform, Type } from "@eman/class-transformer";
import BaseModel from "@model/BaseModel";
import Role from "@model/Role";
import { observable } from "mobx";

export default abstract class BaseUser extends BaseModel {
  @observable email: string;
  @observable first_name: string;
  @observable middle_name: string;
  @observable last_name: string;

  @Exclude({ toPlainOnly: true })
  @observable
  role: Role;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  last_avatar_updated_at: Date;

  // Is avatar deleted or not?
  avatar_deleted: boolean;
}
