import { observer } from "mobx-react";
import React from "react";

import TimeInput from "@eman/emankit/TimeInput";

import BindingElement, { BindingProps } from "./index";

@observer
export default class TimeBox extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { inMinutes } = this.props;
    this.setValue(inMinutes ? this.stringTimeToMinutes(e.target.value) : e.target.value);
  };

  stringTimeToMinutes(value: string) {
    const [hours, minutes] = value.split(":");
    if (Number(hours) >= 0 && Number(minutes) >= 0) {
      return Number(hours) * 60 + Number(minutes);
    }
    return 0;
  }

  minutesToTimeString(value: number) {
    const minutes = value % 60;
    const hours = (value - minutes) / 60;
    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  }

  get preparedValue() {
    const { inMinutes } = this.props;
    return inMinutes ? this.minutesToTimeString(this.value) : this.value;
  }

  render() {
    const { skipDirty, formatValue, parseValue, onValueChanged, ...others } = this.props;
    return (
      <TimeInput
        {...others}
        onChange={this.handleValueChanged}
        value={this.value === undefined || this.value === null ? "" : this.preparedValue}
      />
    );
  }
}
