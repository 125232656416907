import Cookies from "js-cookie";

import EventBus, { LOCALE_CHANGE } from "./EventBus";

export class LocaleProvider {
  static DEFAULT_LOCALE = "cs";
  static COOKIE_NAME = "locale";
  locale: string;

  constructor() {
    this.locale = Cookies.get(LocaleProvider.COOKIE_NAME) || LocaleProvider.DEFAULT_LOCALE;
    Cookies.set(LocaleProvider.COOKIE_NAME, this.locale);
  }

  changeLocale = (code: string): void => {
    if (this.locale !== code) {
      this.locale = code;
      Cookies.set(LocaleProvider.COOKIE_NAME, code);
      EventBus.trigger(LOCALE_CHANGE);
    }
  };

  changeLocaleByUserLang = (language: string) => {
    let locale;

    if (language === "CZ") {
      locale = "cs";
    } else {
      locale = "en";
    }

    this.changeLocale(locale);
  };
}

export default new LocaleProvider();
