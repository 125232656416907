import { ReactNode } from "react";

import Layout from "@eman/emankit/Layout";

import Header from "../Header";
import Notifications from "../Notifications";
import ProgressBar from "../ProgressBar";
import ToastBar from "../ToastBar";

export interface LayoutProps {
  children: ReactNode;
}

const layout = (props: LayoutProps) => {
  return (
    <Layout
      before={
        <>
          <Notifications />
          <Header />
        </>
      }
      after={
        <>
          <ToastBar />
          <ProgressBar />
        </>
      }>
      {props.children}
    </Layout>
  );
};

export default layout;
