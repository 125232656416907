import { OptionType } from "@eman/emankit";
import { computed, observable } from "mobx";

export default class OptionsVM<TModel extends models.IBase> {
  @observable items: TModel[] = [];
  @observable isInitialized = false;

  protected repository: Repository<TModel>;
  protected nameProperties: (keyof TModel)[];
  protected order: { direction: string; field: string };
  protected pagination: { page: number; pageSize: number };

  constructor(
    repository: Repository<TModel>,
    defaultKey: keyof TModel,
    options: {
      nameProperties?: (keyof TModel)[];
      order?: OrderOptions;
      pagination?: { page: number; pageSize: number };
      skipFetching?: boolean;
      filters?: FilterValues;
    } = {}
  ) {
    this.repository = repository;

    this.nameProperties = options.nameProperties ?? [defaultKey];
    this.order = options.order ?? { direction: "asc", field: defaultKey as string };
    this.pagination = options.pagination ?? { page: 0, pageSize: 1000 };

    if (!options.skipFetching) {
      this.fetchItems(options.filters);
    }
  }

  async fetchItems(filters?: FilterValues, others?: any) {
    this.isInitialized = false;
    const data = await this.repository.fetchList({
      order: this.order as OrderOptions,
      pagination: this.pagination,
      filters,
      loading: false,
      ...others,
    });

    this.items = data.list;
    this.isInitialized = true;
  }

  @computed
  get selectOptions(): OptionType<number>[] {
    return this.items.map(item => {
      return {
        value: item.id as number,
        label: this.nameProperties.map(propertyName => item[propertyName]).join(" "),
      };
    });
  }
}
