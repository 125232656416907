import { plainToClass } from "@eman/class-transformer";
import axios from "axios";
import { injectable } from "inversify";

import CurrentUser from "@model/CurrentUser";
import ApiClient, { AxiosConfig } from "../Utils/ApiClient";
import BaseRepository from "./BaseRepository";

@injectable()
export default class CurrentUserRepository extends BaseRepository<CurrentUser> implements ICurrentUserRepository<CurrentUser> {
  constructor() {
    super(CurrentUser, "users", "user");
  }

  fetchCurrent(loading: boolean): Promise<ApiResponse<CurrentUser>> {
    const config = {
      url: "session/current",
      id: "FETCH_USER",
      loading,
    };

    return ApiClient.fetchResponse(config).then(response => {
      if (response.status && response.original) {
        response.entity = plainToClass(CurrentUser, response.original.entity);
      }

      return response;
    });
  }

  login(username: string, password: string): Promise<ApiResponse<CurrentUser>> {
    const config = {
      method: "POST" as "POST" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      url: "session",
      id: "LOGIN_USER",
      data: { username, password },
    };

    return ApiClient.fetchResponse(config).then(response => {
      if (!response.status && response.original.data) {
        // In login case we have different structure of errors, we have to
        // transform it for ViewModel
        if (response.original.data.errors) {
          response.errors = response.original.data.errors;
        } else if (response.original.data.error) {
          response.errors = {
            username: response.original.data.error,
          };
        }
      }

      if (response.status && response.original && response.original.entity) {
        response.entity = plainToClass(CurrentUser, response.original.entity);
      }

      return response;
    });
  }

  logout(): Promise<ApiResponse<CurrentUser>> {
    const config = {
      method: "DELETE" as "DELETE" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      url: "session",
      id: "LOGOUT_USER",
    };

    return ApiClient.fetchResponse(config);
  }

  fetchLocalization(): Promise<LocalizeContainer> {
    const config = {
      url: "localization",
      id: "FETCH_LOCS",
    };

    return ApiClient.fetchData(config);
  }

  getAvatar(userId: number): Promise<string | undefined> {
    const config: AxiosConfig = {
      url: `users/${userId}/avatar`,
      method: "GET",
      id: "GET_AVATAR",
      ignoreErrors: true,
    };

    return ApiClient.fetchData(config)
      .then(response => {
        return `${axios.defaults.baseURL}users/${userId}/avatar`;
      })
      .catch(error => {
        return undefined;
      });
  }
}
