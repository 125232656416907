import PreviousEmployment from "@model/PreviousEmployment";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
import PreviousEmploymentFormVM from "@vm/Form/PreviousEmployment";
import { action } from "mobx";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PreviousEmploymentCreateVM extends AssociatedCreateViewModel<
  PreviousEmployment,
  AssociatedRepository<PreviousEmployment>
> {
  constructor(
    @inject(TYPES.PreviousEmploymentRepository) repository: AssociatedRepository<PreviousEmployment>,
    @inject(TYPES.PreviousEmploymentForm) public previousEmploymentFormVM: PreviousEmploymentFormVM
  ) {
    super(PreviousEmployment, repository);
  }

  @action
  resetEntityAndErrors() {
    super.resetEntityAndErrors();
    this.previousEmploymentFormVM.setEntity(this.entity);
  }
}
