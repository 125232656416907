import React from "react";
import Loadable from "react-loadable";

import ProgressIndicator from "@eman/emankit/ProgressIndicator";

import BranchOfficeCreateModal from "@view/Employer/OrganizationStructure/BranchOffice/Create";
import BranchOfficeEditModal from "@view/Employer/OrganizationStructure/BranchOffice/Edit";
import BranchOfficeList from "@view/Employer/OrganizationStructure/BranchOffice/List";
import BranchOfficeShowModal from "@view/Employer/OrganizationStructure/BranchOffice/Show";
import OrganizationStructureCreateModal from "@view/Employer/OrganizationStructure/Create";
import OrganizationStructureEditModal from "@view/Employer/OrganizationStructure/Edit";
import OrganizationStructureList from "@view/Employer/OrganizationStructure/List";
import OrganizationStructureShowModal from "@view/Employer/OrganizationStructure/Show";

import { EMPLOYER_SUBPAGES, PAGES, uriHelper } from "../../config";
import BaseRouter from "../Base";

// Code split visualization (thanks to dagre, storms-diagram etc..)
const visualize = Loadable({
  loader: () => import("@view/Employer/OrganizationStructure/Visualize"),
  loading: () => <ProgressIndicator />,
});

const orgChart = Loadable({
  loader: () => import("@view/Employer/OrganizationStructure/OrgChart"),
  loading: () => <ProgressIndicator />,
});

const emptyComponent = () => {
  return null;
};

export const organizationStructureRoutes: IAppRoute[] = [
  {
    // This must be before list route!
    path: uriHelper.visualize_employer_organization_structures_uri(),
    title: `${PAGES.EMPLOYER}.${EMPLOYER_SUBPAGES.ORGANIZATION_STRUCTURE}.visualize`,
    exact: true,
    component: visualize,
    modalComponent: emptyComponent,
  },
  {
    // This must be before list route!
    path: uriHelper.org_chart_employer_organization_structures_uri(),
    title: `${PAGES.EMPLOYER}.${EMPLOYER_SUBPAGES.ORGANIZATION_STRUCTURE}.org_chart`,
    exact: true,
    component: orgChart,
    modalComponent: emptyComponent,
  },
  {
    path: uriHelper.employer_organization_structures_branch_offices_uri(),
    title: `${PAGES.EMPLOYER}.${EMPLOYER_SUBPAGES.ORGANIZATION_STRUCTURE}.branch_office`,
    component: BranchOfficeList,
  },
  {
    path: uriHelper.new_employer_organization_structures_branch_offices_uri(),
    modalComponent: BranchOfficeCreateModal,
  },
  {
    path: uriHelper.edit_employer_organization_structures_branch_offices_uri(),
    modalComponent: BranchOfficeEditModal,
  },
  {
    path: uriHelper.show_employer_organization_structures_branch_offices_uri(),
    modalComponent: BranchOfficeShowModal,
  },
  {
    path: uriHelper.employer_organization_structures_uri(),
    component: OrganizationStructureList,
  },
  {
    path: uriHelper.new_employer_organization_structures_uri(),
    exact: true,
    modalComponent: OrganizationStructureCreateModal,
  },
  {
    path: uriHelper.edit_employer_organization_structures_uri(),
    exact: true,
    modalComponent: OrganizationStructureEditModal,
  },
  {
    path: uriHelper.show_employer_organization_structures_uri(),
    exact: true,
    modalComponent: OrganizationStructureShowModal,
  },
];

export default class OrganizationStructure extends BaseRouter {
  render() {
    return this.pageRouteRender(organizationStructureRoutes);
  }
}
