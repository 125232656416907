import { useContext, useEffect } from "react";

import { EmployeeContext } from "../../../../Router/Employees/Employee";
import ContractHeader, { ContractHeaderProps } from "./ContractHeader";

/**
 * (Un)Register ContractHeader component inside employee context
 */
const header = (props: ContractHeaderProps) => {
  const contextValue = useContext(EmployeeContext);

  // didMount
  useEffect(() => {
    contextValue.changeHeaderComponent(<ContractHeader {...props} />);
  }, []);

  // willUnmount
  useEffect(() => {
    return () => {
      contextValue.changeHeaderComponent(null);
    };
  }, []);

  return null;
};

export default header;
