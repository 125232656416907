import { observer } from "mobx-react";
import React from "react";

import { lazyInject, TYPES } from "../../inversify.config";

import User from "@service/CurrentUser";
import Enum from "@service/Enum";
import Localization from "@service/Localization";
import Router from "@service/Router";

/**
 * WARNING - this is THE only content for this class! Who try to add something will be punished, not kidding!
 */
@observer
export default class BaseComponent<TProps = {}, TState = {}> extends React.Component<TProps, TState> {
  @lazyInject(TYPES.Localization)
  locs: Localization;

  @lazyInject(TYPES.User)
  user: User;

  @lazyInject(TYPES.Enum)
  enums: Enum;

  @lazyInject(TYPES.Router)
  router: Router;

  @lazyInject(TYPES.UriHelper)
  uriHelper: any;
}
