import { injectable, inject } from "inversify";
import FormViewModel from "./FormViewModel";
import Capacity from "@model/Capacity";
import { observable, IReactionDisposer, reaction, computed, action } from "mobx";
import JobTitle, { JobTitleState } from "@model/JobTitle";
import OptionsVM from "@vm/Other/Options";
import TYPES from "../../inversify.types";
import Rate, { RateState } from "@model/Rate";
import Enum from "@service/Enum";
import BaseModel, { CUSTOM_DATE_FORMAT } from "@model/BaseModel";
import ContractsVM from "@vm/Other/Contracts";
import { OptionType } from "@eman/emankit";
import WorkingTimeRatio from "@model/WorkingTimeRatio";
import Localization from "@service/Localization";
import { add, differenceInCalendarDays, eachDayOfInterval, getDay, isSameDay } from "date-fns";
import { DAY, MAXDAYHOURS } from "../../config";
import startOfDay from "date-fns/startOfDay";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import CurrentUser from "@service/CurrentUser";

interface Days {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

@injectable()
export default class CapacityFormVM extends FormViewModel<Capacity> implements ViewModel.WithReactions {
  @observable days: Days = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false,
  };

  @observable visibleDays: Days = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };

  @observable selectedRate: Rate;
  @observable selectedRatio: WorkingTimeRatio;
  @observable originalEntityValidTo: Date | undefined;
  @observable jobTitleId?: number;

  @observable isCreate = false;

  dummyHours?: number;

  private hoursChangeReactionDisposer: IReactionDisposer;
  private dayChangeReactionDisposer: IReactionDisposer;
  private jobTitleChangeDisposer: IReactionDisposer;
  private userRatesChangeDisposer: IReactionDisposer;
  private userRatesJobTitleChangeDisposer: IReactionDisposer;
  private userDateChangeDisposer: IReactionDisposer;
  private userValidToChangeDisposer: IReactionDisposer;

  private jobTitlesOptionsVM: OptionsVM<JobTitle>;
  private userRatesOptionsVM: OptionsVM<Rate>;
  private userRatiosOptionsVM: OptionsVM<WorkingTimeRatio>;

  private jobTitleRepository: AssociatedRepository<JobTitle>;
  private userRatesRepository: AssociatedRepository<Rate>;
  private workingTimeRatioRepository: AssociatedRepository<WorkingTimeRatio>;

  constructor(
    @inject(TYPES.Contracts) public contractsVM: ContractsVM,
    @inject(TYPES.Enum) private enums: Enum,
    @inject(TYPES.JobTitleRepository) jobTitleRepository: AssociatedRepository<JobTitle>,
    @inject(TYPES.RateRepository) userRatesRepository: AssociatedRepository<Rate>,
    @inject(TYPES.WorkingTimeRatioRepository) workingTimeRatioRepository: AssociatedRepository<WorkingTimeRatio>,
    @inject(TYPES.Localization) private locs: Localization,
    @inject(TYPES.User) public user: CurrentUser
  ) {
    super();

    this.jobTitleRepository = jobTitleRepository;
    this.userRatesRepository = userRatesRepository;
    this.workingTimeRatioRepository = workingTimeRatioRepository;

    this.jobTitlesOptionsVM = new OptionsVM(jobTitleRepository, "id", { skipFetching: true });
    this.userRatesOptionsVM = new OptionsVM(userRatesRepository, "valid_from", { skipFetching: true });
    this.userRatiosOptionsVM = new OptionsVM(workingTimeRatioRepository, "valid_from", { skipFetching: true });
  }

  get daysKeys() {
    return Object.keys(this.days);
  }

  turnOnReactions(): void {
    this.dayChangeReactionDisposer = reaction(
      () => [
        this.days.monday,
        this.days.tuesday,
        this.days.wednesday,
        this.days.thursday,
        this.days.friday,
        this.days.saturday,
        this.days.sunday,
      ],
      () => {
        this.daysKeys.forEach(day => {
          if (!this.days[day]) {
            this.entity[day] = 0;
          }
        });
      }
    );

    this.jobTitleChangeDisposer = reaction(
      () => this.entity.job_title_working_time_ratio_id,
      () => this.fetchItems()
    );

    this.userRatesJobTitleChangeDisposer = reaction(
      () => this.entity.job_title_working_time_ratio_id,
      () => {
        this.userRatioChanged(), this.selectedRate.free_rate_hours && this.changeWorkdaysValues();
        this.selectedRate && this.showWorkdaysPickers();
      }
    );

    this.jobTitleChangeDisposer = reaction(
      () => this.entity.job_title_working_time_ratio_id,
      () => this.resetForm()
    );

    this.userRatesChangeDisposer = reaction(
      () => this.entity.rate_id,
      () => {
        this.userRatioChanged(), this.selectedRate.free_rate_hours && this.changeWorkdaysValues();
        this.showWorkdaysPickers();
      }
    );

    this.userDateChangeDisposer = reaction(
      () => this.entity.valid_from,
      () => {
        this.isCreate && this.selectedRate.free_rate_hours && this.changeWorkdaysValues();
        this.showWorkdaysPickers();
      }
    );

    this.userValidToChangeDisposer = reaction(
      () => this.entity.valid_to,
      () => {
        this.isCreate && this.selectedRate.free_rate_hours && this.changeWorkdaysValues();
        this.showWorkdaysPickers();
      }
    );
  }

  turnOffReactions(): void {
    if (this.hoursChangeReactionDisposer) {
      this.hoursChangeReactionDisposer();
    }

    if (this.dayChangeReactionDisposer) {
      this.dayChangeReactionDisposer();
    }

    if (this.jobTitleChangeDisposer) {
      this.jobTitleChangeDisposer();
    }

    if (this.userRatesChangeDisposer) {
      this.userRatesChangeDisposer();
    }

    if (this.userDateChangeDisposer) {
      this.userDateChangeDisposer();
    }
    if (this.userValidToChangeDisposer) {
      this.userValidToChangeDisposer();
    }
    if (this.userRatesJobTitleChangeDisposer) {
      this.userRatesJobTitleChangeDisposer();
    }
  }

  setEntity(entity: Capacity) {
    this.turnOffReactions();
    super.setEntity(entity);
    this.daysKeys.forEach(day => {
      this.days[day] = entity[day] > 0;
    });
    this.turnOnReactions();

    if (entity.rate) {
      this.enums.assignObjectEnum(entity.rate);
    }
  }

  setParentId(parentId: number) {
    this.jobTitleRepository.setId(parentId);
    this.jobTitlesOptionsVM.fetchItems({
      state: {
        operator: "in",
        values: [JobTitleState.ACTIVE, JobTitleState.IN_PREPARATION],
      },
      contract_id: {
        operator: "in",
        values: this.contractsVM.contractId,
      },
    });

    this.userRatesRepository.setId(parentId);
    this.workingTimeRatioRepository.setId(parentId, this.contractsVM.contractId!);
    this.userRatiosOptionsVM
      .fetchItems({
        state: {
          operator: "in",
          values: [JobTitleState.ACTIVE, JobTitleState.IN_PREPARATION, this.canEditInClosedState && JobTitleState.CLOSED],
        },
        contract_id: {
          operator: "in",
          values: this.contractsVM.contractId,
        },
      })
      .then(() => {
        this.fetchItems();
        this.userRatioChanged();
        this.showWorkdaysPickers();
      });
  }

  @action.bound
  private changeWorkdaysValues() {
    this.daysKeys.forEach(day => {
      if (day !== "saturday" && day !== "sunday") {
        this.days[day] = !this.updateDayHours(day as DAY);
      }
    });
  }

  @action.bound
  private showWorkdaysPickers() {
    this.daysKeys.forEach(day => {
      this.visibleDays[day] = !this.checkDays(day as DAY);
    });
  }

  @action
  updateDayHours(day: DAY) {
    if (this.checkDays(day)) {
      this.entity[day] = 0;
      return true;
    } else {
      const divisionDays = this.getDivisionDays.length > 5 ? 5 : this.getDivisionDays.length;

      // Check for division of hours without decimals
      const checkModulo = this.selectedRate.free_rate_hours % divisionDays == 0;

      if (checkModulo) {
        this.entity[day] = this.selectedRate.free_rate_hours / divisionDays;
      } else {
        // Distribution of hours when is not posibble to divide without equal hours in every day
        this.entity[day] = Math.floor(this.selectedRate.free_rate_hours / divisionDays);
        if (day === this.getRangeDates[0].day) {
          // Rest of hours is added on first day of range
          this.entity[day] =
            this.entity[day] +
            (this.selectedRate.free_rate_hours - divisionDays * Math.floor(this.selectedRate.free_rate_hours / divisionDays));
        }
      }

      return false;
    }
  }

  @computed
  get canEditInClosedState(): boolean {
    return this.user.allowToObject(UserRightsObjects.TIME_LIMITS, UserRightsOperations.IGNORE_TODAYS_DEPENDENT_VALIDATIONS);
  }

  @action
  userRatioChanged() {
    const selectedRatio = this.userRatiosOptionsVM.items.find(item =>
      item.job_title_working_time_ratios.find(option => option.id === this.entity?.job_title_working_time_ratio_id)
    );

    const selectedRate = this.userRatesOptionsVM.items.find(item => item.id === this.entity.rate_id);

    // Keep this dummy value, its fixing bug with undefined hours
    this.dummyHours = selectedRate?.free_rate_hours || undefined;

    if (selectedRate) {
      this.enums.assignObjectEnum(selectedRate);
      this.selectedRate = selectedRate;
    }

    if (selectedRatio && selectedRate) {
      // Reset valid to on selection
      this.entity.valid_to = undefined;
      this.originalEntityValidTo = undefined;

      this.enums.assignObjectEnum(selectedRatio);
      this.selectedRatio = selectedRatio;

      if (selectedRatio.isActive || (this.canEditInClosedState && selectedRatio.isClosed)) {
        if (selectedRate.valid_from && this.isCreate) {
          const now = new Date();
          this.entity.valid_from = selectedRatio.valid_from >= now ? selectedRatio.valid_from : now;
        }
      } else {
        if (selectedRate.valid_from && this.isCreate) {
          this.entity.valid_from = selectedRatio.valid_from;
        }
      }

      if (selectedRatio.isActive || selectedRatio.inPreparation || (this.canEditInClosedState && selectedRatio.isClosed)) {
        if (
          this.selectedRatio?.valid_to <= this.selectedRate?.valid_to ||
          (this.selectedRatio?.valid_to && !this.selectedRate?.valid_to)
        ) {
          this.entity.valid_to = selectedRate.valid_to;
          this.originalEntityValidTo = selectedRate.valid_to;
        } else if (this.selectedRatio?.valid_to > this.selectedRate?.valid_to) {
          this.entity.valid_to = this.selectedRate?.valid_to;
          this.originalEntityValidTo = this.selectedRate?.valid_to;
        }

        if (!this.selectedRatio?.valid_to && !this.selectedRate?.valid_to) {
          this.entity.valid_to = undefined;
          this.originalEntityValidTo = undefined;
        }
      }
    }
  }

  @action
  checkDays(day: DAY) {
    return this.lessThan7Days && this.getRangeDates.filter(specificDay => specificDay.day === day).length === 0 ? true : false;
  }

  @action
  clearSelectedRate() {
    this.selectedRate = new Rate();
  }

  @action
  resetForm() {
    if (this.entity.job_title_working_time_ratio_id && this.entity.rate_id) {
      this.entity.rate_id = undefined;
      this.entity.valid_from = undefined;
      this.entity.valid_to = undefined;
      this.originalEntityValidTo = undefined;

      // KEEP THESE FOR RESETING VALUES AFTER ALL CALCULATIONS IF CONDITION IS TRUE
      setTimeout(() => {
        this.daysKeys.forEach(day => {
          this.days[day] = false;
        });
      }, 1);
    }
  }

  getDateFilters = (startDate: Date, endDate: Date, fieldName = "valid_interval"): FilterValues => {
    return {
      [fieldName]: {
        operator: "between",
        values: [BaseModel.formatDate(startDate), BaseModel.formatDate(endDate)],
      },
    };
  };

  fetchItems() {
    const baseRateStateValues = [RateState.ACTIVE, RateState.IN_PREPARATION];

    if (!this.isCreate) {
      baseRateStateValues.push(RateState.ARCHIVED);
    }

    let filters: FilterValues = {
      contract_id: {
        operator: "in",
        values: this.contractsVM.contractId,
      },
      state: {
        operator: "in",
        values: baseRateStateValues,
      },
    };

    if (this.selectedRatio) {
      filters = {
        ...filters,
        ...this.getDateFilters(this.selectedRatio.valid_from, this.selectedRatio.valid_to),
      };
    }

    if (this.isCreate) {
      filters = {
        ...filters,
        hide_full_for_job_title: {
          operator: "in",
          values: this.selectedJobTitleId,
        },
      };
    }

    this.entity?.job_title_working_time_ratio_id &&
      this.userRatesOptionsVM.fetchItems(filters, {
        job_title_working_time_ratio_id: this.entity?.job_title_working_time_ratio_id,
      });
  }

  @computed
  get getRangeDates() {
    return this.getEachDayInterval.map(day => {
      switch (getDay(day)) {
        case 0:
          return { day: "sunday" as DAY, date: day };
        case 1:
          return { day: "monday" as DAY, date: day };
        case 2:
          return { day: "tuesday" as DAY, date: day };
        case 3:
          return { day: "wednesday" as DAY, date: day };
        case 4:
          return { day: "thursday" as DAY, date: day };
        case 5:
          return { day: "friday" as DAY, date: day };
        case 6:
          return { day: "saturday" as DAY, date: day };
      }
    });
  }

  // User can save form when capacity and free hours are equal and also when, capacity of sliders together is not enougth for free hours
  @computed
  get allowedToSave(): boolean {
    return (
      this.entity?.totalCapacity === this.selectedRate?.free_rate_hours ||
      (this.isDatesSelectable && this.selectedRate?.free_rate_hours > this.getDivisionDays?.length * MAXDAYHOURS)
    );
  }

  @computed
  get lessThan7Days(): boolean {
    return this.entity?.valid_to && this.entity.valid_from
      ? Math.abs(differenceInCalendarDays(this.entity.valid_from, this.entity.valid_to)) < 7
      : false;
  }

  @computed
  get getFreeHours() {
    return this.userRatesOptionsVM.items.find(item => item.id === this.entity.rate_id);
  }

  @computed
  get getDivisionDays() {
    return this.getRangeDates.filter(day => day.day !== "saturday" && day.day !== "sunday");
  }

  @computed
  get getEachDayInterval(): Date[] {
    if (this.entity.valid_to && this.entity.valid_from && isSameDay(this.entity.valid_from, this.entity.valid_to)) {
      return [this.entity.valid_from];
    } else if (!this.entity.valid_from) {
      return [];
    } else if (this.entity.valid_to && this.entity.valid_from.getTime() > this.entity.valid_to.getTime()) {
      return [];
    } else {
      return this.entity.valid_to
        ? eachDayOfInterval({
            start: startOfDay(this.entity.valid_from),
            end: startOfDay(this.entity.valid_to),
          })
        : eachDayOfInterval({
            start: startOfDay(this.entity.valid_from),
            end: add(startOfDay(this.entity.valid_from), { days: 7 }),
          });
    }
  }

  getDayOfInterval(day: DAY): Date | undefined {
    return this.getRangeDates.find(specificDay => specificDay.day === day)?.date;
  }

  @computed
  get allowedJobTitles(): OptionType<number>[] {
    this.selectedJobTitleId;
    return this.jobTitlesOptionsVM.items
      .filter(option => option.state !== JobTitleState.CLOSED)
      .map(option => ({ label: `${option.job_position.name}`, value: option.id! }));
  }

  @computed
  get allowedRates(): OptionType<number>[] {
    return this.userRatesOptionsVM.items.map(option => {
      let dates = "";

      /* eslint-disable no-extra-boolean-cast */
      if (!!option.valid_from && !!option.valid_to) {
        dates = `(${BaseModel.formatDate(option.valid_from, CUSTOM_DATE_FORMAT)} - ${BaseModel.formatDate(
          option.valid_to,
          CUSTOM_DATE_FORMAT
        )})`;
      } else if (!!option.valid_from) {
        dates = `(${BaseModel.formatDate(option.valid_from, CUSTOM_DATE_FORMAT)} - ${this.locs.ta("capacity", "indefinitely")})`;
      } else if (!!option.valid_to) {
        dates = `(${BaseModel.formatDate(option.valid_to, CUSTOM_DATE_FORMAT)})`;
      }
      /* eslint-enable no-extra-boolean-cast */

      return { label: `(${option.free_rate_hours}hod) ${dates}`, value: option.id! };
    });
  }

  @computed
  get isRateSelectable() {
    return !!this.entity && !!this.entity.job_title_working_time_ratio_id;
  }

  @computed
  get isRatioSelectable() {
    return !!this.entity && !!this.entity.job_title_working_time_ratio_id;
  }

  @computed
  get isDatesSelectable() {
    return !!this.entity && !!this.entity.rate_id;
  }

  @computed
  get selectedJobTitleId() {
    this.userRatiosOptionsVM.items.find(
      item =>
        (this.jobTitleId = item.job_title_working_time_ratios.find(
          detail => detail.id === this.entity?.job_title_working_time_ratio_id
        )?.job_title_id)
    );

    return this.jobTitleId;
  }
}
