import ExportCreateModal from "@view/Export/Create";
import ExportList from "@view/Export/List";

import { uriHelper } from "../config";

import BaseRouter from "./Base";

export const exportsRouter: IAppRoute[] = [
  {
    path: uriHelper.exports_uri(),
    exact: true,
    component: ExportList,
  },
  {
    path: uriHelper.new_exports_uri(),
    exact: true,
    modalComponent: ExportCreateModal,
  },
];

export default class Exports extends BaseRouter {
  render() {
    return (
      <>
        <div className="emankit__layout__content">{this.pageRouteRender(exportsRouter)}</div>
      </>
    );
  }
}
