import { inject, injectable } from "inversify";

import JobPosition from "@model/JobPosition";
import ListViewModel from "@vm/List/ListViewModel";

import TYPES from "../../inversify.types";
import OrganizationUnit from "@model/OrganizationUnit";
import ViewHelpers from "@util/ViewHelpers";
import OptionsVM from "@vm/Other/Options";
import { computed } from "mobx";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class JobPositionListVM extends ListViewModel<JobPosition, Repository<JobPosition>> {
  private organizationUnitOptionsVM: OptionsVM<OrganizationUnit>;

  static defaults = {
    order: {
      field: "name",
      direction: "asc",
    },
    columns: ["name", "organization_structure", "manager", "specialist", "description"],
    visibleFilters: ["name"],
  };

  constructor(
    @inject(TYPES.JobPositionRepository) repository: Repository<JobPosition>,
    @inject(TYPES.OrganizationUnitRepository) private organizationUnitRepository: Repository<OrganizationUnit>
  ) {
    super(repository);
  }

  @computed
  get organizationUnitOptions() {
    if (!this.organizationUnitOptionsVM) {
      this.organizationUnitOptionsVM = new OptionsVM(this.organizationUnitRepository, "name");
    }

    return ViewHelpers.createSubOrganizationOptions(this.organizationUnitOptionsVM.items);
  }
}
