import { inject, injectable } from "inversify";

import PersonalDocument from "@model/PersonalDocument";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PersonalDocumentListVM extends AssociatedListViewModel<
  PersonalDocument,
  AssociatedRepository<PersonalDocument>
> {
  constructor(@inject(TYPES.PersonalDocumentRepository) repository: AssociatedRepository<PersonalDocument>) {
    super(repository);
    this.scroll = false;
    this.save = false;
  }
}
