import { observer } from "mobx-react";

import Badge from "@eman/emankit/Badge";

import SmallList from "@component/SmallList";
import JobTitle, { JobTitleState } from "@model/JobTitle";
import ViewHelpers from "@util/ViewHelpers";
import JobTitleListVM from "@vm/List/JobTitle";
import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class JobTitleList extends SmallList<JobTitle, JobTitleListVM, PropsFromRouter> {
  modelName = "job_title";
  prefix = "employee.contract";

  @lazyInject(TYPES.JobTitleList)
  vm: JobTitleListVM;

  initVM() {
    this.vm.init();

    // React to URL change (if changed setId will ensure that this is called only once)
    this.setIdFromParams();
  }

  componentDidUpdate() {
    // React to URL change (if changed setId will ensure that this is called only once)
    this.setIdFromParams();
  }

  setIdFromParams = () => {
    const params = this.props.match.params;
    const employeeId = parseInt(params.user_id, 10);
    const contractId = parseInt(params.contract_id, 10);
    this.vm.setId(employeeId, contractId);
  };

  componentWillUnmount() {
    this.vm.dispose();
  }

  renderRow = (item: JobTitle, column: string) => {
    switch (column) {
      case "job_position":
      case "organization_structure":
      case "unit":
        return item[column] ? item[column].name : null;

      case "supervisor":
        return item.supervisor ? `${item.supervisor.first_name} ${item.supervisor.last_name}` : null;

      case "state":
        return <Badge color={ViewHelpers.jobTitleStateToColor(item.state)}>{this.tg(`state.${item.state}`)}</Badge>;

      case "valid_from":
      case "valid_to":
        return this.locs.formatDate(item[column]);

      case "manager":
        return ViewHelpers.booleanToBadgeSmooth(this.locs, item.job_position[column]);

      case "primary":
        return ViewHelpers.booleanToBadgeSmooth(this.locs, item.primary);

      default:
        return item[column];
    }
  };

  onClick = (entity: JobTitle) => {
    const params = this.props.match.params;

    this.objectEditOrShowLink("employees_contracts_job_titles", [params.user_id, params.contract_id, entity.id]);
  };

  filterProps = (): FilterData[] => {
    return [
      {
        id: "state",
        label: this.ta("state"),
        values: Object.values(JobTitleState).map(state => ({ value: state, label: this.tg(`state.${state}`) })),
      },
      {
        id: "valid_from",
        label: this.ta("valid_from"),
        type: "date_range",
      },
      {
        id: "valid_to",
        label: this.ta("valid_to"),
        type: "date_range",
      },
    ];
  };

  rows() {
    return [
      this.createField("job_position", { nosort: true }),
      this.createField("organization_structure", { nosort: true }),
      this.createField("supervisor", { nosort: true }),
      this.createField("manager", { nosort: true }),
      this.createField("primary", { nosort: true }),
      this.createField("reason", { nosort: true }),
      this.createField("valid_from", {}),
      this.createField("valid_to", {}),
      this.createField("state", { nosort: true }),
    ];
  }
}
