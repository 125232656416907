import { injectable } from "inversify";

import EvidenceState from "@model/EvidenceState";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class EvidenceStateRepository extends BaseAssociatedRepository<EvidenceState> {
  constructor() {
    super(EvidenceState, "users/$ID/evidence_states", "evidence_state");
  }
}
