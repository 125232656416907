import { action } from "mobx";
import { inject, injectable } from "inversify";

import School from "@model/School";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import SchoolForm from "@vm/Form/School";
import TYPES from "../../inversify.types";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class SchoolCreateVM extends AssociatedCreateViewModel<School, AssociatedRepository<School>> {
  constructor(
    @inject(TYPES.SchoolRepository) repository: AssociatedRepository<School>,
    @inject(TYPES.SchoolForm) public schoolFormVM: SchoolForm
  ) {
    super(School, repository);
  }

  @action
  resetEntityAndErrors() {
    super.resetEntityAndErrors();
    this.schoolFormVM.setEntity(this.entity);
  }
}
