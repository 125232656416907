import { injectable } from "inversify";

import Address from "@model/Address";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class AddressRepository extends BaseAssociatedRepository<Address> {
  constructor() {
    super(Address, "/users/$ID/addresses", "address");
  }
}
