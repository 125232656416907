import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import School from "@model/School";
import SchoolEditVM from "@vm/Edit/School";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class SchoolEditModal extends EditModal<School> {
  modelName = "school";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.SchoolEdit)
  vm: SchoolEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} vm={this.vm.schoolFormVM} />;
  }
}
