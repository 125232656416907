import { observer } from "mobx-react";

import BaseComponent from "@component/BaseComponent";
import PerformanceManagementList from "./List";

@observer
export default class PerformanceManagements extends BaseComponent<PropsFromRouter> {
  render() {
    return (
      <>
        <PerformanceManagementList match={this.props.match} />
      </>
    );
  }
}
