import { observer } from "mobx-react";
import uniq from "lodash/uniq";

import Badge from "@eman/emankit/Badge";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import Icon, { IconType } from "@eman/emankit/Icon";
import Tooltip from "@eman/emankit/Tooltip";

import Avatar from "@component/Avatar";
import BaseList from "@component/BaseList";
import Contract, { ContractState } from "@model/Contract";
import Employee from "@model/Employee";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import { JobTitleState } from "@model/JobTitle";
import ViewHelpers from "@util/ViewHelpers";
import EmployeeListVM from "@vm/List/Employee";

import { lazyInject, TYPES } from "../../inversify.config";
import { EvidenceStateStatus } from "@model/EvidenceState";
import { UserStateByContract } from "@model/User";

@observer
export default class EmployeeList extends BaseList<Employee, EmployeeListVM> {
  modelName = "employee";
  searchable = false;

  @lazyInject(TYPES.EmployeeList)
  vm: EmployeeListVM;

  /**
   * @override
   */
  ta = (attribute: string): string => {
    return this.locs.ta("user", attribute);
  };

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_employees());
  };

  renderContractStateBadge = (state: ContractState): React.ReactNode => {
    return <Badge color={ViewHelpers.contractStateToColor(state)}>{this.locs.tg(`employee.contract.edit.state.${state}`)}</Badge>;
  };

  renderJobTitleStateBadge = (state: JobTitleState): React.ReactNode => {
    return (
      <Badge color={ViewHelpers.jobTitleStateToColor(state)}>{this.locs.tg(`employee.contract.job_title.state.${state}`)}</Badge>
    );
  };

  renderNoteIcon(note: boolean) {
    return note ? (
      <Tooltip title={this.tg("note_tooltip")} trigger="mouseenter">
        <Icon icon={IconType.Document} />
      </Tooltip>
    ) : null;
  }

  renderRowEmployee = (item: Employee, column: string) => {
    this.enums.assignObjectEnum(item);

    if (item.rate) {
      this.enums.assignObjectEnum(item.rate);
    }

    if (item.active_health_insurance) {
      this.enums.assignObjectEnum(item.active_health_insurance);
    }

    const primaryJobPositions = item.active_job_titles
      ? item.active_job_titles.map(job_title => this.vm.jobPositions[job_title.job_position_id!])
      : 0;

    switch (column) {
      case "avatar":
        return <Avatar key={item.id} user={item} size={30} />;

      case "role_id":
        return this.vm.roleName(item.role_id);

      case "enumeration_citizenship_id":
        return item.citizenship?.name;

      case "job_title_job_position_name":
        return primaryJobPositions ? primaryJobPositions.map(position => position?.name).join(", ") : undefined;

      case "contract_enumeration_contract_type_id":
        return uniq(
          item.activeContracts
            .map(contract => {
              this.enums.assignObjectEnum(contract);
              return contract.contract_type?.code;
            })
            .filter(x => x)
        ).join(", ");

      case "job_title_job_position_specialist":
        return primaryJobPositions
          ? primaryJobPositions.map(position => {
              if (position?.specialist) ViewHelpers.booleanToBadgeSmooth(this.locs, position.specialist);
            })
          : null;

      case "job_title_supervisor_first_name":
        return item.active_job_titles?.map(job_title => job_title.supervisor?.first_name).join(", ");

      case "job_title_supervisor_last_name":
        return item.active_job_titles?.map(job_title => job_title.supervisor?.last_name).join(", ");

      case "job_title_state":
        return item.active_job_titles
          ? item.active_job_titles.map(job_title => this.renderJobTitleStateBadge(job_title.state))
          : null;

      case "job_title_organization_unit_name":
        return item.active_job_titles
          ? item.active_job_titles.map(job_title => this.vm.organizationUnitName(job_title.organization_structure_id)).join(", ")
          : 0;
      case "note":
        return this.renderNoteIcon(item.note);

      case "job_title_valid_from":
        return super.renderRow((item.active_job_titles || {}) as any, "valid_from");

      case "job_title_valid_to":
        return super.renderRow((item.active_job_titles || {}) as any, "valid_to");

      case "contract_contractor":
        return uniq(
          item.activeContracts
            .map(contract => {
              this.enums.assignObjectEnum(contract);
              return contract.contractor?.name;
            })
            .filter(x => x)
        ).join(", ");

      case "contract_company_name":
        return uniq(item.activeContracts.map(x => x.company_id))
          .map(x => this.vm.companyName(x))
          .join(", ");

      case "contract_branch_office_name":
        return uniq(item.activeContracts.map(x => x.branch_office_id))
          .map(x => this.vm.branchOfficeName(x))
          .join(", ");

      case "personal_detail_birth_date":
        return this.renderRowAny(item.personal_detail, "birth_date");

      case "health_insurance_insurance_number":
        return item.active_health_insurance?.insurance_number;

      case "health_insurance_valid_from":
        return this.renderRowAny(item.active_health_insurance, "valid_from");

      case "health_insurance_enumeration_health_insurance_office_id":
        return item.active_health_insurance?.health_insurance_office?.name;

      default:
        return super.renderRow(item, column);
    }
  };

  renderRowContract = (item: Contract, column: string) => {
    this.enums.assignObjectEnum(item);
    item.rates?.forEach(rate => this.enums.assignObjectEnum(rate));

    switch (column) {
      case "contract_enumeration_contract_type_id":
        return item.contract_type?.code;

      case "contract_company_name":
        return this.vm.companyName(item.company_id);

      case "contract_branch_office_name":
        return this.vm.branchOfficeName(item.branch_office_id);

      case "contract_state":
        return this.renderContractStateBadge(item.state);

      case "contract_contractor":
        return item.contractor?.name;

      case "contract_rates":
        if (item.rates?.length) {
          return (
            <table>
              <thead>
                <tr>
                  <th>{this.locs.ta("rate", "enumeration_working_time_id")}</th>
                  <th>{this.locs.ta("rate", "rate_h")}</th>
                  <th>{this.locs.ta("rate", "rate_md")}</th>
                  <th>{this.locs.ta("rate", "valid_from")}</th>
                  <th>{this.locs.ta("rate", "valid_to")}</th>
                  <th>{this.locs.ta("rate", "enumeration_rate_reason_id")}</th>
                </tr>
              </thead>
              <tbody>
                {item.rates.map(rate => (
                  <tr key={`rate_${item.id}_${rate.id}`}>
                    <td>{rate.working_time?.coeficient}</td>
                    <td>{rate.supergross_rate?.rate_h_super}</td>
                    <td>{rate.supergross_rate?.rate_md_super}</td>
                    <td>{this.renderRowAny(rate, "valid_from")}</td>
                    <td>{this.renderRowAny(rate, "valid_to")}</td>
                    <td>{rate.rate_reason?.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        } else {
          return null;
        }

      default:
        return super.renderRowAny(item, column.replace("contract_", ""));
    }
  };

  renderRow = (item: Employee | Contract, column: string) => {
    if (item instanceof Employee) {
      return this.renderRowEmployee(item, column);
    }

    return this.renderRowContract(item, column);
  };

  headerProps() {
    return {
      text: this.tg("title"),
      buttons: () => {
        const items: React.ReactNode[] = [];

        if (this.user.allowToObject(UserRightsObjects.USER, UserRightsOperations.CREATE)) {
          items.push(
            <Button icon={IconType.Plus} variant={ButtonVariant.Active} onClick={this.onAddClick} key="new_employee">
              {this.tg("add")}
            </Button>
          );
        }

        return items;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "first_name",
        label: this.ta("first_name"),
        type: "search",
      },
      {
        id: "last_name",
        label: this.ta("last_name"),
        type: "search",
      },
      {
        id: "middle_name",
        label: this.ta("middle_name"),
        type: "search",
      },
      {
        id: "email",
        label: this.ta("email"),
        type: "search",
      },
      {
        id: "last_name_at_birth",
        label: this.ta("last_name_at_birth"),
        type: "search",
      },
      {
        id: "enumeration_citizenship_id",
        label: this.ta("enumeration_citizenship_id"),
        values: this.enums.valuesForSelect("citizenships"),
      },
      {
        id: "employee_number",
        label: this.ta("employee_number"),
        type: "search",
      },
      {
        id: "redmine_uuid",
        label: this.ta("redmine_uuid"),
        type: "search",
      },
      {
        id: "degree_before1",
        label: this.ta("degree_before1"),
        type: "search",
      },
      {
        id: "degree_before2",
        label: this.ta("degree_before2"),
        type: "search",
      },
      {
        id: "degree_after",
        label: this.ta("degree_after"),
        type: "search",
      },
      {
        id: "role_id",
        label: this.ta("role_id"),
        values: this.vm.roles,
      },
      {
        id: "active_job_titles_job_position_specialist",
        label: this.locs.ta("job_position", "specialist"),
        values: ViewHelpers.booleanOptions(this.locs),
        single: true,
      },
      {
        id: "active_job_titles_job_position_id",
        columnId: "job_title_job_position_name",
        label: this.locs.tm("job_position"),
        values: this.vm.jobPositionNames,
      },
      {
        id: "active_job_titles_organization_unit_id",
        columnId: "organization_unit_name",
        label: this.locs.tm("organization_unit"),
        values: this.vm.organizationUnitNames,
        hasChildren: true,
      },
      {
        id: "contracts_company_id",
        label: this.locs.tm("company"),
        values: this.vm.companyNames,
      },
      {
        id: "contracts_branch_office_id",
        label: this.locs.tm("branch_office"),
        values: this.vm.branchOfficeNames,
      },
      {
        id: "contracts_contract_type_id",
        label: this.locs.ta("contract", "enumeration_contract_type_id"),
        values: this.enums.valuesForSelect("contract_types"),
      },
      {
        id: "contracts_enumeration_user_type_id",
        label: this.locs.ta("contract", "contractor"),
        values: this.enums.valuesForSelect("user_types"),
      },
      {
        id: "evidence_state_evidence_status",
        label: this.ta("evidence_state"),
        values: Object.values(EvidenceStateStatus).map(status => ({
          value: status,
          label: this.locs.tg(`employee.contract.evidence_state.status.${status}`),
        })),
      },
      {
        id: "contract_state",
        label: this.locs.ta("employee", "user_state_contract"),
        values: Object.values(UserStateByContract).map(status => ({
          value: status,
          label: this.locs.tg(`employee.state.${status}`),
        })),
      },
      {
        id: "active_primary_job_title_job_position_id",
        label: this.ta("primary_job_title"),
        values: this.vm.getJobPositionsOptions.map(jobPosition => ({
          value: jobPosition.id?.toString(),
          label: jobPosition.name.toString(),
        })),
      },
      {
        id: "active_primary_job_title_organization_unit_id",
        label: this.ta("primary_organization_unit"),
        values: this.vm.getOrganizationsOptions.map(organization => ({
          value: organization.id?.toString(),
          label: organization.name.toString(),
        })),
      },
    ];

    return {
      filters,
      onResetView: this.vm.onResetView,
    };
  }

  dataProps() {
    const columns = [
      // Explode contracts field
      this.createField("action", { hideLabel: true, width: 30, nosort: true, childrenOpener: true, alwaysShow: true }),

      // This is directly from user
      this.createField("avatar", { nosort: true }),
      this.createFieldSimple("id"),
      this.createFieldSimple("first_name"),
      this.createFieldSimple("last_name"),
      this.createFieldSimple("middle_name"),
      this.createFieldSimple("last_name_at_birth"),
      this.createFieldSimple("email"),
      this.createFieldSimple("role_id"),
      this.createFieldSimple("degree_before1"),
      this.createFieldSimple("degree_before2"),
      this.createFieldSimple("degree_after"),
      this.createFieldSimple("employee_number"),
      this.createFieldSimple("redmine_uuid"),
      this.createFieldSimple("enumeration_citizenship_id"),

      // PersonalDetail is 1:1 so data are from it
      this.createField("personal_detail_birth_date", { width: 120, label: this.locs.ta("personal_detail", "birth_date") }),

      // This field is on the list from primary
      this.createField("job_title_job_position_name", { label: this.locs.ta("job_position", "name") }),
      this.createField("job_title_job_position_specialist", {
        label: this.locs.ta("job_position", "specialist"),
        nosort: true,
      }),
      this.createField("job_title_state", { label: this.locs.ta("job_title", "state_human"), nosort: true }),
      this.createField("job_title_valid_from", {
        label: this.locs.ta("job_title", "valid_from_human"),
        className: "date",
      }),
      this.createField("job_title_valid_to", { label: this.locs.ta("job_title", "valid_to_human") }),
      this.createField("job_title_supervisor_first_name", {
        nosort: true,
        label: this.locs.ta("job_title", "supervisor_first_name"),
      }),
      this.createField("job_title_supervisor_last_name", {
        nosort: true,
        label: this.locs.ta("job_title", "supervisor_last_name"),
      }),
      this.createField("job_title_organization_unit_name", { label: this.locs.tm("organization_unit") }),

      // User:Contract is in 1:M so this is in sub-rows
      this.createField("contract_contractor", { label: this.locs.ta("contract", "contractor"), nosort: true }),
      this.createField("contract_enumeration_contract_type_id", {
        nosort: true,
        label: this.locs.ta("contract", "enumeration_contract_type_id"),
      }),
      this.createField("contract_working_from", { width: 120, nosort: true, label: this.locs.ta("contract", "working_from") }),
      this.createField("contract_signed_at", { width: 120, nosort: true, label: this.locs.ta("contract", "signed_at") }),
      this.createField("contract_probation_period_to", {
        width: 120,
        nosort: true,
        label: this.locs.ta("contract", "probation_period_to"),
      }),
      this.createField("contract_terminated_at", { width: 120, nosort: true, label: this.locs.ta("contract", "terminated_at") }),
      this.createField("contract_valid_from", { width: 120, nosort: true, label: this.locs.ta("contract", "valid_from") }),
      this.createField("contract_valid_to", { width: 120, nosort: true, label: this.locs.ta("contract", "valid_to") }),
      this.createField("contract_branch_office_name", { label: this.locs.tm("branch_office"), nosort: true }),
      this.createField("contract_company_name", { label: this.locs.tm("company"), nosort: true }),
      this.createField("contract_state", { label: this.locs.ta("employee", "user_state_contract"), nosort: true }),

      // User 1 : M Contract : N rates
      this.createField("contract_rates", { nosort: true, label: this.locs.tm("rate") }),

      // User:HealthInsurance is 1:1 (active)
      this.createField("health_insurance_insurance_number", {
        label: this.locs.ta("health_insurance", "insurance_number"),
        nosort: true,
      }),
      this.createField("health_insurance_enumeration_health_insurance_office_id", {
        nosort: true,
        label: this.locs.tm("health_insurance_office"),
      }),
      this.createField("health_insurance_valid_from", {
        width: 120,
        label: this.locs.ta("health_insurance", "valid_from_human"),
        nosort: true,
      }),

      // This is from user directly
      this.createField("note", { hideLabel: true, width: 30, nosort: true }),
    ];

    return {
      columns,
      childrenProperty: "contracts",
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: Employee | Contract) => {
        if (this.user.allowToObject(UserRightsObjects.USER, UserRightsOperations.SHOW)) {
          if (item instanceof Contract) {
            this.router.pageLink(this.uriHelper.show_employees_contracts(item.user_id, item.id));
          } else if (item.contracts.length > 0) {
            this.router.pageLink(this.uriHelper.employees_contracts(item.id));
          } else {
            this.router.pageLink(this.uriHelper.new_employees_contracts(item.id));
          }
        }
      },
    };
  }
}
