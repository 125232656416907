import Create from "@component/Create";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";
import Role from "@model/Role";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import RoleCreateVM from "@vm/Create/Role";
import { observer } from "mobx-react";
import React from "react";

import { lazyInject, TYPES } from "../../inversify.config";

import Form from "./Form";

@observer
export default class RoleCreate extends Create<Role, RoleCreateVM> {
  modelName = "role";
  suffix = "create";

  @lazyInject(TYPES.RoleCreate)
  vm: RoleCreateVM;

  onSaveClick = async () => {
    const id = await this.vm.create();

    if (id) {
      EventBus.trigger(SHOW_TOAST, this.locs.tg("form.created"));
      this.router.pageLink(this.uriHelper.edit_roles(id));
    }
  };

  render() {
    const { entity, roleFormVM } = this.vm;

    return (
      <>
        <HeaderRow title={this.tg("title")}>
          <Button onClick={this.onSaveClick} variant={ButtonVariant.Active}>
            {this.locs.tg("form.create")}
          </Button>
        </HeaderRow>
        <Form entity={entity} vm={roleFormVM} />
      </>
    );
  }
}
