import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import School from "@model/School";
import SchoolCreateVM from "@vm/Create/School";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class SchoolCreateModal extends CreateModal<School> {
  modelName = "school";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.SchoolCreate)
  vm: SchoolCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  renderModalBody() {
    return <Form entity={this.vm.entity} vm={this.vm.schoolFormVM} />;
  }
}
