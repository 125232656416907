import cn from "classnames";

const AllocationTableItem = ({ children, className, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div className={cn("allocation-table-item", className)} {...rest}>
      {children}
    </div>
  );
};

export default AllocationTableItem;
