import BaseRepository from "@repository/BaseRepository";
import Balance from "@model/Balance";
import { injectable } from "inversify";

@injectable()
export default class BalanceRepository extends BaseRepository<Balance> {
  constructor() {
    super(Balance, "balances", "balance");
  }
}
