import { UserRightsPages } from "@model/Rights";
import EmployeeCreate from "@view/Employee/Create";
import EmployeeList from "@view/Employee/List";
import BaseRouter from "../Base";
import EmployeeDetail from "./Employee";

// Config
import { PAGES, uriHelper } from "../../config";

export const employeesRoutes: IAppRoute[] = [
  {
    path: uriHelper.employees_uri(),
    exact: true,
    component: EmployeeList,
  },
  {
    path: uriHelper.new_employees_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.create`,
    component: EmployeeCreate,
  },
  {
    path: uriHelper.show_employees_uri(),
    exact: false,
    title: `${PAGES.EMPLOYEE}.show`,
    component: EmployeeDetail,
  },
];

export default class Employees extends BaseRouter {
  constructor(props: any) {
    super(props);

    if (!this.user.allowToPage(UserRightsPages.EMPLOYEES)) {
      employeesRoutes.splice(0, 0, {
        path: uriHelper.employees_uri(),
        exact: true,
        redirect: () => uriHelper.show_employees(this.user.entity.id),
      });
    }
  }

  render() {
    return this.pageRouteRender(employeesRoutes);
  }
}
