import { Exclude, Expose, Type } from "@eman/class-transformer";
import { computed, observable } from "mobx";
import BaseModel from "./BaseModel";
import { JobTitleState } from "./JobTitle";
import JobTitleWorkingTimeRatio from "./JobTitleWorkingTimeRatio";

export default class WorkingTimeRatio extends BaseModel {
  @Type(() => Date)
  @observable
  valid_from: Date;

  @Type(() => Date)
  @observable
  valid_to: Date;

  @Exclude({ toPlainOnly: true })
  @Type(() => JobTitleWorkingTimeRatio)
  @observable
  job_title_working_time_ratios: JobTitleWorkingTimeRatio[] = [];

  @Expose({ groups: ["create", "update"] })
  get job_title_working_time_ratios_attributes() {
    return this.job_title_working_time_ratios;
  }

  @observable state: JobTitleState;

  @computed
  get isActive() {
    return this.state === JobTitleState.ACTIVE;
  }

  @computed
  get inPreparation() {
    return this.state === JobTitleState.IN_PREPARATION;
  }

  @computed
  get isClosed() {
    return this.state === JobTitleState.CLOSED;
  }
}
