import { inject, injectable } from "inversify";
import { action } from "mobx";

import Capacity from "@model/Capacity";
import CapacityForm from "@vm/Form/Capacity";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import TYPES from "../../inversify.types";
import WorkingTimeRatiosListVM from "@vm/List/WorkingTimeRatios";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class CapacityCreateVM extends AssociatedCreateViewModel<Capacity, AssociatedRepository<Capacity>> {
  constructor(
    @inject(TYPES.CapacityRepository) repository: AssociatedRepository<Capacity>,
    @inject(TYPES.CapacityForm) public capacityFormVM: CapacityForm,
    @inject(TYPES.WorkingTimeRatiosList) public workingTimeRatiosListVM: WorkingTimeRatiosListVM
  ) {
    super(Capacity, repository);
  }

  @action
  setParentId(parentId: number) {
    super.setParentId(parentId);
    this.capacityFormVM.setParentId(parentId);
    this.capacityFormVM.isCreate = true;
  }

  get allowedToSave(): boolean {
    return this.capacityFormVM.allowedToSave;
  }

  @action
  resetEntityAndErrors() {
    super.resetEntityAndErrors();
    // Keep next line for reseting entity for Capacity,
    this.entity = new Capacity();
    this.capacityFormVM.setEntity(this.entity);
  }
}
