import BaseModel from "@model/BaseModel";
import { observable } from "mobx";
import { Type } from "@eman/class-transformer";
import Allocation from "@model/Allocation";

export default class AllocationItem extends BaseModel {
  @observable value: number;
  @Type(() => Number)
  @observable
  allocation_id: number;
  @Type(() => Date)
  @observable
  date: Date;
  @Type(() => Allocation)
  @observable
  allocation: Allocation;
}
