import { Fragment, ReactNode, useEffect, useRef } from "react";
import cn from "classnames";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import {
  AllocationData,
  AllocationInterval,
  GroupData,
  GroupItem,
  JobData,
  NonAllocationData,
  SubGroupData,
  SubGroupsData,
} from "@util/Calendar";
import AllocationTableHeader from "./Header";
import BalanceRow from "./BalanceRow";
import AllocationRow from "./AllocationRow";
import UserSidebar from "./UserSidebar";
import "./style.scss";
import AllocationItem from "@model/AllocationItem";
import LocalizationService from "@service/Localization";
import getWeek from "date-fns/getWeek";
import BalanceYearRow from "./BalanceRow/year";

interface Props {
  startDate: Date;
  endDate: Date;
  leftHeader?: ReactNode;
  data: GroupData;
  subgroupsData: SubGroupsData;
  onGroupClick: (group: GroupItem) => Promise<void>;
  onSubGroupClick?: (job: JobData) => Promise<void>;
  openSubGroups: number[];
  onAllocationClick: (item: AllocationItem) => void;
  locs?: LocalizationService;
  allocationInterval: AllocationInterval;
}

const createInterval = (start: Date, end: Date, isYear?: boolean): Date[] => {
  return isYear ? eachMonthOfInterval({ start, end }) : eachDayOfInterval({ start, end });
};

const AllocationTable = ({
  startDate,
  endDate,
  leftHeader,
  data,
  subgroupsData,
  locs,
  onGroupClick,
  onAllocationClick,
  allocationInterval,
}: Props) => {
  const isWeek = allocationInterval === AllocationInterval.WEEK;
  const isYear = allocationInterval === AllocationInterval.YEAR;
  const isMonth = allocationInterval === AllocationInterval.MONTH;

  const interval = createInterval(startDate, endDate, isYear);

  const table = useRef<HTMLDivElement>(null);

  const tableHeaderREf = useRef<any>();

  const headerHeight = 60;

  const bodyHeight = 1050;

  const handleScroll = () => {
    if (document.body.scrollHeight < bodyHeight && window.pageYOffset > headerHeight) {
      tableHeaderREf.current?.classList.add("top-margin-220");
    } else if (0 === window.pageYOffset) {
      tableHeaderREf.current?.classList.remove("top-margin-220");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderMainBalance = (group: GroupItem, interval: Date[]) => {
    const { id, user, mainBalances } = group;
    if (isYear) {
      return (
        <BalanceYearRow
          key={id}
          interval={interval}
          balances={mainBalances}
          className="main-balance"
          sidebarRenderer={() => <UserSidebar user={user} onClick={onGroupClick.bind(null, group)} hideOpener={isYear} />}
        />
      );
    }
    return (
      <BalanceRow
        key={id}
        interval={interval}
        balances={mainBalances}
        className="main-balance"
        sidebarRenderer={() => <UserSidebar user={user} onClick={onGroupClick.bind(null, group)} hideOpener={isYear} />}
      />
    );
  };

  const renderOpenGroup = (group: GroupItem, interval: Date[]) => {
    const jobsData = subgroupsData[group.id.toString()] as SubGroupData;
    return (
      <Fragment key={jobsData?.userId}>
        {renderMainBalance(group, interval)}
        {jobsData?.jobPositions?.map(job => {
          return (
            <Fragment key={job.id}>
              {((job.allocations as NonAllocationData[]) || (job.allocations as AllocationData[]))?.map(allocationData => (
                <AllocationRow
                  data={allocationData}
                  interval={interval}
                  key={allocationData.key}
                  onAllocationClick={onAllocationClick}
                  locs={locs}
                />
              ))}
            </Fragment>
          );
        })}
      </Fragment>
    );
  };

  return (
    <>
      <h4 className="d-flex justify-content-center mb-2" ref={tableHeaderREf}>{`${isWeek ? getWeek(startDate) : ""}${
        isWeek ? "." : ""
      } ${isWeek ? locs?.tg("allocation.calendar.week") : ""} ${isWeek ? "-" : ""} ${
        !isYear ? locs?.tg("uikit.date_picker.strings.months")[startDate.getMonth()] : ""
      } ${startDate.getFullYear()}`}</h4>
      <div className="allocation-table" ref={table}>
        <table>
          <AllocationTableHeader
            interval={interval}
            leftHeader={leftHeader}
            locs={locs!}
            isYearInterval={isYear}
            isMonthInterval={isMonth}
          />
          {data.map((group, index) => {
            const open = group.open;
            return (
              <tbody key={`${group.id}"_"${index}`} className={cn("group", { open, closed: !open })}>
                {open ? renderOpenGroup(group, interval) : renderMainBalance(group, interval)}
              </tbody>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default AllocationTable;
