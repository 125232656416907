import { injectable } from "inversify";

import Skill from "@model/Skill";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class SkillRepository extends BaseAssociatedRepository<Skill> {
  constructor() {
    super(Skill, "users/$ID/skills", "skill");
  }
}
