import UserSidebar from "@component/AllocationTable/UserSidebar";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core/lib";
import User from "@model/User";
import LocalizationService from "@service/Localization";
import { useState } from "react";

interface Props {
  projectManager?: User;
  locs?: LocalizationService;
  deal: string;
}

const ProjectManager = ({ projectManager, locs, deal }: Props) => {
  // Popper variables
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const placement: Placement = "top-start";
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="deal" ref={setReferenceElement} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {deal}
      {isOpen && projectManager && (
        <div className="hoverbox projectmanager" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <b>{locs?.tg("allocation.calendar.project_manager")}:</b>
          {projectManager && <UserSidebar user={projectManager} hideOpener={true} eisUrl={true} />}
        </div>
      )}
    </div>
  );
};

export default ProjectManager;
