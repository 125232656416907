import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";
import Panel from "@eman/emankit/Panel";

import Create from "@component/Create";
import User from "@model/User";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import UserCreateVM from "@vm/Create/User";

import { lazyInject, TYPES } from "../../inversify.config";
import UserForm from "./Form";

@observer
export default class EmployeeCreate extends Create<User, UserCreateVM> {
  modelName = "employee";

  @lazyInject(TYPES.UserCreate)
  vm: UserCreateVM;

  onCreateClick = async () => {
    const id = await this.vm.create();

    if (id) {
      EventBus.trigger(SHOW_TOAST, this.locs.tg("form.created"));
      this.router.pageLink(this.uriHelper.show_employees(id));
    }
  };

  onResetClick = async () => {
    this.vm.resetEntityAndErrors();
  };

  render() {
    return (
      <>
        <HeaderRow title={this.tg("title")} fixedHeader={true}>
          <Button onClick={this.onResetClick}>{this.locs.tg("form.reset")}</Button>
          <Button variant={ButtonVariant.Active} onClick={this.onCreateClick}>
            {this.locs.tg("form.add")}
          </Button>
        </HeaderRow>
        <Panel>
          <UserForm entity={this.vm.entity} roleOptions={this.vm.roleOptions} />
        </Panel>
      </>
    );
  }
}
