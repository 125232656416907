import Export from "@model/Export";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import ExportFormVM from "@vm/Form/Export";

import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class ExportCreateVM extends AssociatedCreateViewModel<Export, AssociatedRepository<Export>> {
  constructor(
    @inject(TYPES.ExportRepository) repository: AssociatedRepository<Export>,
    @inject(TYPES.ExportForm) public exportFormVM: ExportFormVM
  ) {
    super(Export, repository);
  }
}
