import { observer } from "mobx-react";
import React from "react";

import SmallList from "@component/SmallList";
import Capacity from "@model/Capacity";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import CapacityListVM from "@vm/List/Capacity";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";

import { lazyInject, TYPES } from "../../../../inversify.config";
import Badge from "@eman/emankit/Badge";
import ViewHelpers from "@util/ViewHelpers";

@observer
export default class CapacityList extends SmallList<Capacity, CapacityListVM, PropsFromRouter> {
  @lazyInject(TYPES.CapacityList)
  vm: CapacityListVM;

  modelName = "capacity";
  prefix = "employee";

  initVM() {
    this.vm.init();

    // React to URL change (if changed setId will ensure that this is called only once)
    this.setIdFromParams();
  }

  componentDidUpdate() {
    // React to URL change (if changed setId will ensure that this is called only once)
    this.setIdFromParams();
  }

  setIdFromParams = () => {
    const params = this.props.match.params;
    const employeeId = parseInt(params.user_id, 10);
    const contractId = parseInt(params.contract_id, 10);
    this.vm.setId(employeeId, contractId);
  };

  componentWillUnmount() {
    this.vm.dispose();
  }

  renderRow = (item: Capacity, column: string): React.ReactNode => {
    if (item.job_title) {
      this.enums.assignObjectEnum(item.job_title);
    }

    if (item.rate) {
      this.enums.assignObjectEnum(item.rate);
    }

    const jobRatio = item?.job_title_working_time_ratio;

    switch (column) {
      case "job_title_id":
        return (
          <>
            <Badge color={ViewHelpers.jobTitleStateToColor(jobRatio?.state)}>
              {this.locs.tg(`employee.contract.job_title.state.${jobRatio?.state}`)}
            </Badge>
            &nbsp;
            <strong>{jobRatio?.job_title.job_position?.name || null}</strong>
            {` - `}
            <span>
              <strong>{jobRatio?.job_title.organization_structure.name}</strong>
            </span>
          </>
        );
      case "rate_id":
        return item.rate && item.free_rate_hours ? `${item.free_rate_hours}h` : null;
      case "valid_from":
      case "valid_to":
        return this.locs.formatDate(item[column]);
      default:
        return ViewHelpers.decimalHoursToTime(item[column]);
    }
  };

  onAddClick = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.new_employees_contracts_capacities(params.user_id, params.contract_id));
  };

  otherRender(): React.ReactNode {
    let button;

    if (this.user.allowToObject(UserRightsObjects.CAPACITY, UserRightsOperations.CREATE)) {
      button = (
        <Button variant={ButtonVariant.Active} onClick={this.onAddClick}>
          {this.tg("add")}
        </Button>
      );
    }

    return (
      <HeaderRow title={this.tg("title")} level={2}>
        {button}
      </HeaderRow>
    );
  }

  filterProps = (): FilterData[] => {
    return [
      {
        id: "valid_from",
        label: this.ta("valid_from"),
        type: "date_range",
      },
      {
        id: "valid_to",
        label: this.ta("valid_to"),
        type: "date_range",
      },
    ];
  };

  onClick = (item: Capacity) => {
    const params = this.props.match.params;
    this.objectEditOrShowLink("employees_contracts_capacities", [params.user_id, params.contract_id, item.id]);
  };

  rows() {
    return [
      this.createField("job_title_id", { nosort: true }),
      this.createField("rate_id", { nosort: true }),
      this.createField("valid_from", {}),
      this.createField("valid_to", {}),
      this.createField("monday", { nosort: true }),
      this.createField("tuesday", { nosort: true }),
      this.createField("wednesday", { nosort: true }),
      this.createField("thursday", { nosort: true }),
      this.createField("friday", { nosort: true }),
      this.createField("saturday", { nosort: true }),
      this.createField("sunday", { nosort: true }),
    ];
  }
}
