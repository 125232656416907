import { observer } from "mobx-react";
import React from "react";

import { lazyInject, TYPES } from "../../inversify.config";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import { AlignType } from "@eman/emankit/DataTable/DataBody";
import { IconType } from "@eman/emankit/Icon";

import BaseList from "@component/BaseList";
import Export from "@model/Export";
import { NotificationStatus } from "@model/Notification";
import ExportListVM from "@vm/List/Export";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";

@observer
export default class ExportList extends BaseList<Export, ExportListVM> {
  modelName = "export";
  searchable = false;

  @lazyInject(TYPES.ExportList)
  vm: ExportListVM;

  componentWillUnmount() {
    this.vm.dispose();
  }

  refreshList = () => {
    this.vm.fetchList(true, false);
  };

  downloadExport = (e: any) => {
    const { id } = e.currentTarget.dataset;

    this.vm.downloadFile(id);
  };

  onDestroy = (e: any) => {
    const { id } = e.currentTarget.dataset;
    this.vm.destroy(id, this.tg("destroyed"));
  };

  renderRow = (item: Export, column: string) => {
    switch (column) {
      case "action":
        const buttons: React.ReactNode[] = [];

        if (item.status === NotificationStatus.DONE) {
          buttons.push(
            <Button
              key={`${item.id}_export_download`}
              icon={IconType.Download}
              tooltip={this.tg("download")}
              data-id={item.id}
              onClick={this.downloadExport}
            />
          );
        }

        if (item.status === NotificationStatus.FAILED || item.status === NotificationStatus.DONE) {
          buttons.push(
            <Button
              key={`${item.id}_export_destroy`}
              icon={IconType.Cross}
              tooltip={this.tg("delete")}
              data-id={item.id}
              variant={ButtonVariant.Dangerous}
              onClick={this.onDestroy}
            />
          );
        }
        return buttons;

      case "status":
        return this.tg(`status.${item.status}`);

      case "lines":
        return this.locs.formatNumber(item.lines);

      case "created_at":
        return this.locs.formatDateTime(item[column]);

      default:
        return item[column];
    }
  };

  filterProps() {
    return {
      hideColumnSettings: true,
      hideFilterSettings: true,
    };
  }

  displayNewExport = () => {
    this.router.pageLink(this.uriHelper.new_exports());
  };

  headerProps() {
    return {
      text: this.tg("title"),
      buttons: () => {
        const items: React.ReactNode[] = [];
        if (this.user.allowToObject(UserRightsObjects.EXPORT, UserRightsOperations.CREATE)) {
          items.push(
            <Button variant={ButtonVariant.Active} icon={IconType.Plus} onClick={this.displayNewExport}>
              {this.tg("add")}
            </Button>
          );
        }

        return items;
      },
    };
  }

  dataProps() {
    return {
      columns: [
        this.createField("action", { className: "noclick", nosort: true, alwaysShow: true, hideLabel: true, width: 100 }),
        this.createField("human_name", { nosort: true, width: 200 }),
        this.createField("status", { nosort: true, width: 150 }),
        this.createField("message", { nosort: true, width: 180 }),
        this.createField("lines", { nosort: true, align: AlignType.Center, width: 120 }),
        this.createField("created_at", { nosort: true, width: 150 }),
      ],
      value: this.renderRow,
    };
  }
}
