import { inject, injectable } from "inversify";
import FormViewModel from "@vm/Form/FormViewModel";

import TYPES from "../../inversify.types";
import Export from "@model/Export";
import { OptionType } from "@eman/emankit";
import { computed } from "mobx";
import ExportRepository from "@repository/Export";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class ExportFormVM extends FormViewModel<Export> {
  constructor(@inject(TYPES.ExportRepository) exportRepository: ExportRepository) {
    super();
  }

  @computed
  get availableTypes(): OptionType<string>[] {
    return [
      { label: "allocation_export_type", value: "allocations" },
      { label: "critical_old_export_type", value: "critical_old" },
      { label: "critical_new_export_type", value: "critical_new" },
    ];
  }
}
