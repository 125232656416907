import { observer } from "mobx-react";
import React from "react";

import InputContainer from "@eman/emankit/InputContainer";

import BindingElement, { BindingProps } from "./BindingElement";
import { fieldError } from "./index";

interface FormFieldProps<T> extends BindingProps<T> {
  label?: React.ReactNode;
  hint?: string;
  note?: string;
  className?: string;
  formGroup?: boolean;
}

const fieldEdited = (target: any, property: string | undefined): boolean => {
  let ret = false;

  if (target && property && target.isDirty) {
    ret = target.isDirty.get(property) || false;
  }

  return ret;
};

@observer
export default class GenericFormField<TProps extends FormFieldProps<any>> extends React.Component<TProps> {
  // This is use-full for code above, we have to iterate over react child and find form items
  static fieldFormInstance = true;

  render() {
    const {
      target,
      property,
      errorProperty,
      disabled,
      skipDirty,
      className,
      id,
      label,
      required,
      format,
      labelCol,
      formGroup,
      hint,
    } = this.props;

    const propsForChildren = { target, property, errorProperty, disabled, skipDirty };

    let forId: string;
    const boundChildren = React.Children.map(this.props.children, (child: any) => {
      if (BindingElement.isPrototypeOf(child.type)) {
        if (child.props.id && !forId) {
          forId = child.props.id;
        }
        return React.cloneElement(child, propsForChildren);
      } else {
        return child;
      }
    });

    const errorMessage = fieldError(target, errorProperty || property);
    const edited = fieldEdited(target, property);

    return (
      <InputContainer
        className={className}
        edited={edited}
        id={id}
        label={label}
        error={errorMessage}
        required={required}
        format={format || "column"}
        labelCol={labelCol}
        formGroup={formGroup}
        hint={hint}>
        {boundChildren}
      </InputContainer>
    );
  }
}
