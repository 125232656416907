import { observable } from "mobx";
import BaseModel from "./BaseModel";

export class VacationSimple {
  @observable free_approved?: number;
  @observable free?: number;
}

export default class Vacation extends BaseModel {
  @observable number_of_hours: number;
  @observable previous_remaining_hours: number;
  @observable year: number;
  @observable free_approved: number;
  @observable free: number;
  @observable exhausted: number;
  @observable scheduled: number;
}
