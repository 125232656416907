import { observer } from "mobx-react";
import React from "react";

import BaseList from "@component/BaseList";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import Skill from "@model/Skill";
import SkillListVM from "@vm/List/Skill";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class SkillList extends BaseList<Skill, SkillListVM, PropsFromRouter> {
  modelName = "skill";
  prefix = "employee";
  searchable = false;
  savedSettingsName = "user_skill";

  @lazyInject(TYPES.SkillList)
  vm: SkillListVM;

  initVM() {
    this.vm.init(this.props.match.params.user_id);
  }

  renderRow = (item: Skill, column: string): React.ReactNode => {
    switch (column) {
      case "enumeration_skill_type_id":
        return item.type ? item.type.name : "";
      case "enumeration_skill_level_id":
        return item.level ? item.level.name : "";
      default:
        return item[column];
    }
  };

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_employees_skills(this.props.match.params.user_id));
  };

  headerProps() {
    return {
      text: this.tg("title"),
      level: 2,
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.SKILL, UserRightsOperations.CREATE)) {
          return (
            <Button variant={ButtonVariant.Primary} key="new_skill" onClick={this.onAddClick}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "created_at",
        label: this.ta("created_at"),
        type: "date_range",
      },
    ];

    return {
      filters,
      hideReset: false,
      hideFilterSettings: false,
      hideColumnSettings: false,
    };
  }

  dataProps() {
    const columns = [
      this.createField("enumeration_skill_level_id", {}),
      this.createField("enumeration_skill_type_id", { nosort: true }),
    ];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: Skill) => {
        this.objectEditOrShowLink("employees_skills", [this.props.match.params.user_id, item.id]);
      },
    };
  }
}
