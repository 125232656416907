import { plainToClass } from "@eman/class-transformer";
import { injectable } from "inversify";

import Company from "@model/Company";
import OrganizationStructure, { OrganizationStructureType } from "@model/OrganizationStructure";
import OrganizationUnit from "@model/OrganizationUnit";
import BaseRepository from "@repository/BaseRepository";

import ApiClient from "../Utils/ApiClient";

@injectable()
export default class OrganizationStructureRepository
  extends BaseRepository<models.OrganizationStructure>
  implements IOrganizationStructureRepository<models.OrganizationStructure> {
  constructor() {
    super(OrganizationStructure, "organization_structures", "organization_structure");
  }

  /**
   * Method overload
   */
  fetchList(args: FetchListArguments): Promise<{ list: models.OrganizationStructure[]; total: number; others: any }> {
    return super.fetchList(args).then((response: any) => {
      response.list = response.original.items.map((item: models.OrganizationStructure) => {
        let entity: models.OrganizationStructure;

        switch (item.type) {
          case OrganizationStructureType.COMPANY:
            entity = plainToClass(Company, item);
            break;
          case OrganizationStructureType.ORGANIZATION_UNIT:
            entity = plainToClass(OrganizationUnit, item);
            break;
          default:
            entity = plainToClass(OrganizationStructure, item);
        }

        return entity;
      });

      return response;
    });
  }

  fetchOrgChart(): Promise<OrgChart> {
    const config = {
      url: `${this.uri}/org_chart`,
      method: "GET" as "GET" /* eslint-disable-line @typescript-eslint/prefer-as-const */,
      loading: true,
      id: "FETCH_ORG_CHART",
    };

    return ApiClient.fetchData(config);
  }
}
