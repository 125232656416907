import { observer } from "mobx-react";
import React from "react";

import NotificationBar, { NotificationItem } from "@eman/emankit/NotificationBar";

import BaseComponent from "@component/BaseComponent";
import Notification from "@service/Notification";

import { lazyInject, TYPES } from "../../inversify.config";

@observer
export default class Notifications extends BaseComponent {
  @lazyInject(TYPES.Notification)
  notification: Notification;

  onItemClick(id: number) {
    return () => {
      this.notification.onItemClick(id);
    };
  }

  onDismiss = (id: number | string) => {
    this.notification.closeNotification(id as any);
  };

  render() {
    const items: NotificationItem[] = this.notification.notifications.map(item => {
      let children: React.ReactNode = item.human_name;

      if (item.succeed) {
        children = <a onClick={this.onItemClick(item.id!)}>{item.human_name}</a>;
      }

      return {
        id: item.id!,
        children,
        inProgress: item.in_progress,
        error: item.errored,
        closable: item.closable,
      };
    });

    return <NotificationBar items={items} onDismiss={this.onDismiss} />;
  }
}
