import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import JobPosition from "@model/JobPosition";
import JobPositionCreateVM from "@vm/Create/JobPosition";
import JobPositionForm from "./Form";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class JobPositionCreateModal extends CreateModal<JobPosition> {
  modelName = "job_position";
  prefix = "employer";

  @lazyInject(TYPES.JobPositionCreate)
  vm: JobPositionCreateVM;

  successMessage(): string {
    return this.tg("created");
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employer_job_positions());
  };

  renderModalBody() {
    return <JobPositionForm entity={this.vm.entity} organizationStructureOptions={this.vm.availableOrganizationStructures} />;
  }
}
