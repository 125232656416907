import { observer } from "mobx-react";

import FileBar from "@eman/emankit/FileBar";

import BaseComponent from "@component/BaseComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";

export interface DocumentsProps {
  vm: ViewModel.WithDocuments;
}

@observer
export default class Documents extends BaseComponent<DocumentsProps> {
  render() {
    const documents = this.props.vm.documents;

    if (!documents.length) {
      return null;
    }

    return (
      <div className="row">
        {documents.map(item => {
          const onClick = () => {
            if (this.user.allowToObject(UserRightsObjects.DOCUMENT, UserRightsOperations.DOWNLOAD)) {
              this.props.vm.download(item.id!);
            }
          };

          return (
            <div className="col-md-4 col-sm-6 mb-20" key={`file-${item.id}`}>
              <FileBar title={item.name} total={item.file_size} onClick={onClick} />
            </div>
          );
        })}
      </div>
    );
  }
}
