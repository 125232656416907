import PreviousEmployment from "@model/PreviousEmployment";
import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
import PreviousEmploymentFormVM from "@vm/Form/PreviousEmployment";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PreviousEmploymentEditVM extends AssociatedEditViewModel<
  PreviousEmployment,
  AssociatedRepository<PreviousEmployment>
> {
  constructor(
    @inject(TYPES.PreviousEmploymentRepository) repository: AssociatedRepository<PreviousEmployment>,
    @inject(TYPES.PreviousEmploymentForm) public previousEmploymentFormVM: PreviousEmploymentFormVM
  ) {
    super(PreviousEmployment, repository);
  }

  setEntity(entity: PreviousEmployment) {
    super.setEntity(entity);
    this.previousEmploymentFormVM.setEntity(entity);
  }
}
