import { injectable } from "inversify";

import OrganizationUnit from "@model/OrganizationUnit";
import BaseRepository from "@repository/BaseRepository";

@injectable()
export default class OrganizationRepository extends BaseRepository<OrganizationUnit> {
  constructor() {
    super(OrganizationUnit, "organization_units", "organization_unit");
  }
}
