import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import JobTitle from "@model/JobTitle";
import JobTitleEditVM from "@vm/Edit/JobTitle";

import { lazyInject, TYPES } from "../../../../inversify.config";
import JobTitleForm from "./Form";

@observer
export default class JobTitleEditModal extends EditModal<JobTitle> {
  modelName = "job_title";
  prefix = "employee.contract";

  @lazyInject(TYPES.JobTitleEdit)
  vm: JobTitleEditVM;

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.show_employees_contracts(params.user_id, params.contract_id));
  };

  renderModalBody() {
    return <JobTitleForm entity={this.vm.entity} vm={this.vm.jobTitleFormVM} isEdit />;
  }
}
