import { inject, injectable } from "inversify";

import PaymentInfo from "@model/PaymentInfo";
import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PaymentInfoEditVM extends AssociatedEditViewModel<PaymentInfo, AssociatedRepository<PaymentInfo>> {
  constructor(@inject(TYPES.PaymentInfoRepository) repository: AssociatedRepository<PaymentInfo>) {
    super(PaymentInfo, repository);
  }
}
