import { computed, observable } from "mobx";

import BaseModel from "@model/BaseModel";
import { enumValue } from "@util/Enumerable";

export enum RateItemState {
  SAVED = "saved",
  CHANGED = "changed",
  NOT_CHANGED = "not_changed",
  HAS_ERRORS = "has_errors",
}

export default class RateItem extends BaseModel {
  @observable enumeration_rate_item_type_id: number;
  @observable amount: number;

  @enumValue("rate_item_types", "enumeration_rate_item_type_id")
  @observable
  rate_item_type: models.IEnumType;

  @computed
  get state(): RateItemState {
    let state: RateItemState;

    if (this.errorCount > 0) {
      state = RateItemState.HAS_ERRORS;
    } else if (this.changes) {
      state = RateItemState.CHANGED;
    } else if (this.saved) {
      state = RateItemState.SAVED;
    } else {
      state = RateItemState.NOT_CHANGED;
    }

    return state;
  }
}
