import { injectable } from "inversify";

import AllocationItemGroup from "@model/AllocationItemGroup";
import BaseRepository from "@repository/BaseRepository";

@injectable()
export default class AllocationItemGroupRepository extends BaseRepository<AllocationItemGroup> {
  constructor() {
    super(AllocationItemGroup, "allocation_item_groups", "allocation_item_group");
  }
}
