import ResourceComponent from "@component/ResourceComponent";
import Form, { GenericFormField } from "@util/Form";

import React from "react";

export interface Property {
  property: string;
  errorProperty: string;
}

export default abstract class BaseForm<TModel extends models.IBase, OtherProps = {}, OtherState = {}> extends ResourceComponent<
  { entity: TModel; onFormSubmit?: () => void; onFormReset?: () => void; readonly?: boolean } & OtherProps,
  OtherState
> {
  suffix = "form";

  renderFormBody(): React.ReactNode {
    return null;
  }

  get emptyRow() {
    return this.locs.tg("form.select_value");
  }

  generateFormField = (
    property: string,
    formField: React.ReactNode,
    required = false,
    options: {
      label?: React.ReactNode;
      disabled?: boolean;
      errorProperty?: string;
    } = {}
  ) => {
    return (
      <GenericFormField
        key={property}
        target={this.props.entity}
        property={property}
        errorProperty={options.errorProperty || property}
        label={options.label || this.ta(property)}
        required={required}
        disabled={options.disabled || this.props.readonly}>
        {formField}
      </GenericFormField>
    );
  };

  render() {
    const { onFormSubmit, onFormReset } = this.props;

    return (
      <Form onFormSubmit={onFormSubmit} onFormReset={onFormReset}>
        {this.renderFormBody()}
      </Form>
    );
  }
}
