import { inject, injectable } from "inversify";

import EvidenceState from "@model/EvidenceState";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class EvidenceStateShowVM extends AssociatedShowViewModel<EvidenceState, AssociatedRepository<EvidenceState>> {
  constructor(@inject(TYPES.EvidenceStateRepository) repository: AssociatedRepository<EvidenceState>) {
    super(EvidenceState, repository);
  }
}
