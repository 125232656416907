import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import WorkingTimeRatio from "@model/WorkingTimeRatio";
import WorkingTimeRatioRepository from "@repository/WorkingTimeRatio";
import User from "@service/CurrentUser";
import EnumVM from "@service/Enum";
import { inject, injectable } from "inversify";
import { computed } from "mobx";
import TYPES from "src/inversify.types";
import AssociatedListViewModel from "./AssociatedListViewModel";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";
import { add } from "date-fns";
import ViewHelpers, { JobOptionType } from "@util/ViewHelpers";
import EventBus, { CONTRACT_UPDATED, ENTITY_DELETED, ENTITY_UPDATE, IDisposable, JOB_TITLES_UPDATED } from "@util/EventBus";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class WorkingTimeRatiosListVM extends AssociatedListViewModel<
  WorkingTimeRatio,
  AssociatedRepository<WorkingTimeRatio>
> {
  repository: WorkingTimeRatioRepository;
  user: User;
  enumService: EnumVM;

  // Current values from params
  private employeeId: number;
  private contractId: number;

  static defaults = {
    order: {
      field: "valid_from",
      direction: "desc",
    },
  };

  private readonly eventHandlers: IDisposable[] = [];

  constructor(
    @inject(TYPES.WorkingTimeRatioRepository) repository: WorkingTimeRatioRepository,
    @inject(TYPES.User) user: User,
    @inject(TYPES.Enum) enumService: EnumVM
  ) {
    super(repository);
    this.repository = repository;
    this.user = user;
    this.enumService = enumService;
    this.scroll = false;
  }

  async init() {
    this.eventHandlers.push(EventBus.on(CONTRACT_UPDATED, this.fetchList));
    this.eventHandlers.push(EventBus.on(JOB_TITLES_UPDATED, this.fetchList));
    this.eventHandlers.push(EventBus.on(ENTITY_DELETED, this.fetchList));
    this.eventHandlers.push(EventBus.on(ENTITY_UPDATE, this.fetchList));
  }

  /**
   * Its called from view on componentWillUmount to prevent memory leak
   */
  dispose() {
    this.eventHandlers.forEach(x => x.dispose());
  }

  @computed
  get canChangeJobTitle(): boolean {
    return this.user.allowToObject(UserRightsObjects.JOB_TITLE, UserRightsOperations.CREATE);
  }

  async setId(employeeId: number, contractId: number) {
    if (this.employeeId == employeeId && this.contractId == contractId) {
      return;
    }

    // Save values
    this.employeeId = employeeId;
    this.contractId = contractId;

    // Set repository and reload later
    this.repository.setId(employeeId, contractId);
    this.fetchList(false);
  }

  @computed
  get oldestCreatedRatioDate(): Date | undefined {
    return minBy(this.list || [], "valid_from")?.valid_from;
  }

  @computed
  get earliestEndedRatioDate(): Date | undefined {
    return maxBy(this.list || [], "valid_to")?.valid_to;
  }

  @computed
  get earliestCreatedRatioDate(): Date | undefined {
    return maxBy(this.list || [], "valid_from")?.valid_from;
  }

  @computed
  get activeRatioDateFrom(): Date {
    if (this.canEditInClosedState) {
      return add(this.earliestCreatedRatioDate || new Date(), { days: 1 });
    }

    return add(new Date(), { days: 1 });
  }

  @computed
  get canEditInClosedState(): boolean {
    return this.user.allowToObject(UserRightsObjects.TIME_LIMITS, UserRightsOperations.IGNORE_TODAYS_DEPENDENT_VALIDATIONS);
  }

  @computed
  get allowedRatiosTitlesCreate(): JobOptionType[] {
    return ViewHelpers.jopOptionFiltering(
      this.list.filter(option => option.isActive || option.inPreparation || (this.canEditInClosedState && option.isClosed))
    );
  }

  @computed
  get allowedRatiosTitlesEdit(): JobOptionType[] {
    return ViewHelpers.jopOptionFiltering(this.list);
  }
}
