import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import { IconType } from "@eman/emankit/Icon";

import BaseList from "@component/BaseList";
import JobPosition from "@model/JobPosition";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import ViewHelpers from "@util/ViewHelpers";
import JobPositionListVM from "@vm/List/JobPosition";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class JobPositionList extends BaseList<JobPosition, JobPositionListVM, PropsFromRouter> {
  modelName = "job_position";
  prefix = "employer";
  searchable = false;

  @lazyInject(TYPES.JobPositionList)
  vm: JobPositionListVM;

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_employer_job_positions());
  };

  renderRow = (item: JobPosition, column: string) => {
    if (column === "manager") {
      return ViewHelpers.booleanToBadgeSmooth(this.locs, item.manager);
    } else if (column === "specialist") {
      return ViewHelpers.booleanToBadgeSmooth(this.locs, item.specialist);
    } else if (column === "organization_structure_name") {
      return item.organization_structures ? item.organization_structures.map(structure => structure.name).join(", ") : null;
    } else {
      return item[column];
    }
  };

  headerProps() {
    return {
      text: this.tg("title"),
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.JOB_POSITION, UserRightsOperations.CREATE)) {
          return (
            <Button icon={IconType.Plus} variant={ButtonVariant.Primary} key="new_position" onClick={this.onAddClick}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "name",
        label: this.ta("name"),
        type: "search",
      },
      {
        id: "description",
        label: this.ta("description"),
        type: "search",
      },
      {
        id: "organization_structure_id",
        label: this.locs.tm("organization_unit"),
        values: this.vm.organizationUnitOptions,
        hasChildren: true,
      },
      {
        id: "manager",
        label: this.ta("manager"),
        values: ViewHelpers.booleanOptions(this.locs),
        single: true,
      },
      {
        id: "specialist",
        label: this.ta("specialist"),
        values: ViewHelpers.booleanOptions(this.locs),
        single: true,
      },
    ];

    return {
      filters,
    };
  }

  dataProps() {
    const columns = [
      this.createField("name", { width: 300 }),
      this.createField("organization_structure_name", { label: this.ta("organization_structure"), nosort: true }),
      this.createField("manager", {}),
      this.createField("specialist", {}),
      this.createField("description", {}),
    ];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: JobPosition) => {
        this.objectEditOrShowLink("employer_job_positions", [this.props.match.params.user_id, item.id]);
      },
    };
  }
}
