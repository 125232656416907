import Skill from "@model/Skill";

import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";

import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class SkillCreateVM extends AssociatedCreateViewModel<Skill, AssociatedRepository<Skill>> {
  constructor(@inject(TYPES.SkillRepository) repository: AssociatedRepository<Skill>) {
    super(Skill, repository);
  }
}
