import { injectable } from "inversify";
import { action, observable } from "mobx";
import isString from "lodash/isString";

import BaseModel from "@model/BaseModel";
import EventBus, { REFRESH_USER_FILTER_SETTINGS, SCROLL_TOP, SHOW_TOAST } from "@util/EventBus";

import ItemsViewModel from "../../ViewModels/Items/ItemsViewModel";

/**
 * Abstract parent of all list view models.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
@injectable()
export default abstract class ListViewModel<TModel extends BaseModel, TRepository extends Repository<TModel>>
  extends ItemsViewModel<TModel, TRepository>
  implements ViewModel.List<TModel> {
  /**
   * Total number of records for pagination.
   */
  @observable total = 0;

  /**
   * Pagination settings.
   */
  @observable pagination: Pagination = {
    page: 0,
    pageSize: 20,
  };

  /**
   * Searched value in header bar.
   */
  @observable searchValue?: string;

  /**
   * Keep track of last search value. Used to determine if Searched value in header bar.
   */
  @observable lastSearchValue?: string;

  /**
   * Selected columns.
   */
  @observable columns: string[];

  /**
   * In lists where we want to select items (via checkboxes).
   *
   * @type {TModel[]}
   */
  @observable selectedRows: TModel[] = [];

  /**
   * Data fetched alongside the list.
   */
  @observable others: any = {};

  // @observable display?: models.DisplaySetting;
  // @observable displaySettings: models.DisplaySetting[] = [];

  /**
   * Set settings data.
   *
   * @param data Settings values
   */
  @action
  setSettings({ order, filters, columns, visibleFilters, pagination, save }: ListSettings) {
    // Override in case it cames from filters
    if (columns) {
      this.columns = columns;
    }

    this.pagination = pagination || { page: 0, pageSize: 20 };

    super.setSettings({ order, filters, visibleFilters, save });
  }

  /**
   * Trigger reseting view to default
   */
  onResetView = () => {
    this.setSettings(this.constructor["defaults"]);
    this.fetchList(false, true);
  };

  /**
   * Initialize (method to override in children, for loading more required data)
   */
  async init(...rest: any) {
    return this.fetchList();
  }

  /**
   * Cleanup before list view, reset "silent" options before unmount.
   */
  cleanUp() {
    this.save = true;
    this.scroll = true;
    this.loading = true;
    this.clearList();
  }

  /**
   * Autocomplete for searching.
   */
  autocomplete = (value: string): Promise<string[]> => {
    return this.repository.autocomplete(value);
  };

  /**
   * Set quick search value.
   */
  @action.bound
  setSearchValue = (value: string | undefined) => {
    this.searchValue = value;
  };

  /**
   * Run export.
   *
   * @param {string} success Success message for toast.
   * @memberof ListViewModelBase
   */
  startExport(format: string): Promise<boolean> {
    return this.repository.startExport(format);
  }

  /**
   * Run export reoirt.
   *
   * @param {string} success Success message for toast.
   * @memberof ListViewModelBase
   */
  startExportReport(type: string, offset: number, name?: string) {
    return this.repository.startExportReport(type, offset, name);
  }

  /**
   * Change pagination settings.
   *
   * @param {number} page
   * @param {number} pageSize
   * @memberof ListViewModelBase
   */
  @action
  setPageAndPageSize = (page: number, pageSize: number) => {
    this.pagination = { page, pageSize };
    this.fetchList();
  };

  /**
   * Set data from api + total number of results.
   *
   * @param {TModel[]} list
   * @param {number} total
   * @memberof ListViewModelBase
   */
  @action
  setListAndTotal(list: TModel[], total: number) {
    this.setList(list);
    this.total = total;
    this.selectedRows = [];
  }

  /**
   * Fetch data from server via repository.
   *
   * @param {boolean} [scrollTop=true]
   * @memberof ListViewModelBase
   */
  public fetchList = async (scrollTop = true, save = true) => {
    // Prepare filters
    const filters = {
      ...this.selectedItems,
    };

    // Trim all string values in filter
    Object.keys(filters).forEach(key => {
      if (isString(filters[key].values)) {
        filters[key].values = (filters[key].values as string).trim();
      } else if (Array.isArray(filters[key].values)) {
        filters[key].values = (filters[key].values as any[]).map(value => (isString(value) ? value.trim() : value));
      }
    });

    // Add "Q" for quick search value
    if (this.searchValue && this.searchValue.length > 0) {
      // If search value changed take user to page 0
      if (this.searchValue !== this.lastSearchValue) {
        this.pagination.page = 0;
      }

      filters["q"].values = this.searchValue;
    }
    // Store last searched value
    this.lastSearchValue = this.searchValue;

    this.currentlyFetching = true;

    const list = await this.repository.fetchList({
      order: this.order,
      filters,
      visibleFilters: this.visibleFilters,
      pagination: this.pagination,
      columns: this.columns,
      save: this.save && save,
      loading: this.loading,
      preferencePrefix: this.preferencePrefix,
    });

    if (this.save && save) {
      EventBus.trigger(REFRESH_USER_FILTER_SETTINGS);
    }

    this.others = list.others;

    this.setListAndTotal(list.list, list.total);

    if (scrollTop && this.scroll) {
      EventBus.trigger(SCROLL_TOP);
    }

    this.currentlyFetching = false;
  };

  /**
   * Clear data list.
   * @memberof ListViewModelBase
   */
  @action
  clearList() {
    this.list = [];
    this.selectedRows = [];
    this.total = 0;
    this.pagination.page = 0;
  }

  /**
   * Change filtering.
   *
   * @param {FilterValues} filters
   * @param {string[]} visible Visible filter list
   * @param {boolean} skipFetch Don't call fetchList(), view take care about it itself
   * @param {boolean} save Save the filters to user preference
   * @memberof ListViewModelBase
   */
  @action
  // tslint:disable-next-line: bool-param-default
  async setFilters(filters: FilterValues, visible?: string[], skipFetching?: boolean, save?: boolean, reset?: boolean) {
    this.pagination.page = 0; // Reset pagination when filter changes
    return super.setFilters(filters, visible, skipFetching, save, reset);
  }

  /**
   * Set filters and order options together.
   *
   * @param filters Filters
   * @param sort Sort options
   */
  @action
  setListAttributes({
    filters,
    order,
    page,
    pageSize,
  }: {
    filters?: FilterValues;
    order?: OrderOptions;
    page?: number;
    pageSize?: number;
  }) {
    if (filters) {
      this.selectedItems = filters;
    }

    if (order) {
      this.order = order;
    }

    if (page !== undefined && pageSize !== undefined) {
      this.pagination.page = page;
      this.pagination.pageSize = pageSize;
    }

    this.fetchList();
  }

  /**
   * Change columns.
   *
   * @param columns Column list
   */
  @action
  setColumns(columns: string[]) {
    this.columns = columns;
    this.fetchList();
  }

  /**
   * Destroy item / record / entity.
   *
   * @param {number} id Entity id
   * @param {string} success Success message toast.
   * @memberof ListViewModelBase
   */
  @action.bound
  async destroy(id: number, success: string) {
    await this.repository.destroy(id);
    EventBus.trigger(SHOW_TOAST, success);
    this.fetchList();
  }

  /**
   * Set selected rows to local variable.
   *
   * @param rows Column list
   */
  @action
  setSelectedRows = (rows: TModel[]) => {
    this.selectedRows = rows;
  };
}
