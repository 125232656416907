import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import BranchOffice from "@model/BranchOffice";
import BranchOfficeCreateVM from "@vm/Create/BranchOffice";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class BranchOfficeCreateModal extends CreateModal<BranchOffice> {
  modelName = "branch_office";
  prefix = "employer.organization_structure";

  @lazyInject(TYPES.BranchOfficeCreate)
  vm: BranchOfficeCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.organization_structure_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(
      this.uriHelper.employer_organization_structures_branch_offices(this.props.match.params.organization_structure_id)
    );
  };

  renderModalBody() {
    return <Form entity={this.vm.entity} />;
  }
}
