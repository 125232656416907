import { OptionType } from "@eman/emankit";
import Company from "@model/Company";
import OrganizationStructure, { OrganizationStructureType } from "@model/OrganizationStructure";
import OrganizationUnit from "@model/OrganizationUnit";
import EditViewModel from "@vm/Edit/EditViewModel";
import { inject, injectable, postConstruct } from "inversify";
import { computed, observable } from "mobx";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class OrganizationStructureEditVM extends EditViewModel<OrganizationStructure, Repository<OrganizationStructure>> {
  type: OrganizationStructureType;

  @observable parentList: OrganizationStructure[] = [];

  constructor(
    @inject(TYPES.OrganizationStructureRepository) repository: Repository<OrganizationStructure>,
    @inject(TYPES.CompanyRepository) private companyRepository: Repository<Company>,
    @inject(TYPES.OrganizationUnitRepository) private organizationUnitRepository: Repository<OrganizationUnit>
  ) {
    super(OrganizationStructure, repository);
  }

  @postConstruct()
  async fetchParents() {
    const response = await this.repository.fetchList({
      pagination: { pageSize: 1000, page: 0 },
      order: { field: "name", direction: "asc" },
    });

    this.parentList = response.list;
  }

  @computed
  get possibleParents(): OptionType<number>[] {
    if (this.type === OrganizationStructureType.ORGANIZATION_UNIT) {
      return this.parentList.filter(item => item.id !== this.entity.id).map(item => ({ value: item.id!, label: item.name }));
    } else {
      return this.parentList
        .filter(item => item.type === OrganizationStructureType.COMPANY && item.id !== this.entity.id)
        .map(item => ({ value: item.id!, label: item.name }));
    }
  }

  init(id: number, type?: OrganizationStructureType) {
    this.type = type!;

    switch (this.type) {
      case OrganizationStructureType.COMPANY:
        this.repository = this.companyRepository;
        this.model = Company;
        break;

      case OrganizationStructureType.ORGANIZATION_UNIT:
        this.repository = this.organizationUnitRepository;
        this.model = OrganizationUnit;
        break;
    }

    super.init(id);
  }
}
