import Notification from "@model/Notification";
import BaseRepository from "./BaseRepository";

import { injectable } from "inversify";

@injectable()
export default class NotificationRepository
  extends BaseRepository<Notification>
  implements INotificationRepository<Notification> {
  constructor() {
    super(Notification, "notifications", "notification");
  }
}
