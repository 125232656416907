/* eslint-disable prefer-spread, sonarjs/no-identical-functions */

interface RouteOption {
  type?: "resources" | "single";
  key?: string;
  collection?: string[];
  member?: string[];
  children?: { [key: string]: RouteOption };
}

export interface Container {
  [key: string]: RouteOption;
}

export default class UriHelper {
  private containerDefinition: Container;

  constructor(container: Container) {
    this.containerDefinition = container;
    this.createHelpers(this.containerDefinition);
  }

  /**
   * Recursively create helpers as functions.
   *
   * @param container  Container / Childrens
   * @param baseUri example '/invoices/:invoice_id' (never ends with /)
   * @param parentName 'invoices_users' etc..
   * @param parentGenerator Function that returns parent route from arguments (...arguments)
   */
  // tslint:disable-next-line: cognitive-complexity
  createHelpers(container: Container, modelName?: string) {
    for (const key in container) {
      if (container.hasOwnProperty(key)) {
        const data = container[key];
        const uriKey = `:${data.key || `${key}_id`}`;
        const nextModelName = modelName ? `${modelName}_${key}` : key;

        // According to type?
        if (!data.type || data.type === "resources") {
          // Generate methods

          // List

          // @ts-ignore
          if (this[nextModelName]) {
            throw new Error(`Conflict route names '${nextModelName}'!`);
          }

          // @ts-ignore
          this[nextModelName] = (...args) => {
            let path = "";

            if (modelName) {
              // @ts-ignore
              path = `${this[`show_${modelName}`].apply(this, args)}`;
            }

            return `${path}/${key}`;
          };

          // @ts-ignore
          this[`${nextModelName}_uri`] = () => {
            let path = "";

            if (modelName) {
              // @ts-ignore
              path = this[`show_${modelName}_uri`].call(this);
            }
            return `${path}/${key}`;
          };

          // Detail

          // @ts-ignore
          this[`show_${nextModelName}`] = (...args) => {
            const id = args.pop();

            let path = "";

            if (modelName) {
              // @ts-ignore
              path = `${this[`show_${modelName}`].apply(this, args)}`;
            }

            return `${path}/${key}/${id}`;
          };

          // @ts-ignore
          this[`show_${nextModelName}_uri`] = () => {
            let path = "";

            if (modelName) {
              // @ts-ignore
              path = this[`show_${modelName}_uri`].call(this);
            }

            return `${path}/${key}/${uriKey}`;
          };

          // EDIT + Member helpers
          const members = data.member || [];
          members.push("edit");
          members.forEach(member => {
            // @ts-ignore
            if (this[`${member}_${nextModelName}`]) {
              throw new Error(`Conflict route names '${member}_${nextModelName}'!`);
            }

            // @ts-ignore
            this[`${member}_${nextModelName}`] = (...args) => {
              const id = args.pop();

              let path = "";

              if (modelName) {
                // @ts-ignore
                path = `${this[`show_${modelName}`].apply(this, args)}`;
              }

              return `${path}/${key}/${id}/${member}`;
            };

            // @ts-ignore
            this[`${member}_${nextModelName}_uri`] = () => {
              let path = "";

              if (modelName) {
                // @ts-ignore
                path = this[`show_${modelName}_uri`].call(this);
              }

              return `${path}/${key}/${uriKey}/${member}`;
            };
          });

          // Collections
          const collections = data.collection || [];
          collections.push("new");
          collections.forEach(collection => {
            // @ts-ignore
            if (this[`${collection}_${nextModelName}`]) {
              throw new Error(`Conflict route names '${collection}_${nextModelName}'!`);
            }

            // @ts-ignore
            this[`${collection}_${nextModelName}`] = (...args) => {
              let path = "";

              if (modelName) {
                // @ts-ignore
                path = `${this[`show_${modelName}`].apply(this, args)}`;
              }

              return `${path}/${key}/${collection}`;
            };

            // @ts-ignore
            this[`${collection}_${nextModelName}_uri`] = () => {
              let path = "";

              if (modelName) {
                // @ts-ignore
                path = this[`show_${modelName}_uri`].call(this);
              }

              return `${path}/${key}/${collection}`;
            };
          });
        } else {
          // Path
          // @ts-ignore
          if (this[nextModelName]) {
            throw new Error(`Conflict route names '${nextModelName}'!`);
          }

          // @ts-ignore
          this[`${nextModelName}`] = (...args) => {
            let path = "";

            if (modelName) {
              // @ts-ignore
              path = `${this[`show_${modelName}`].apply(this, args)}`;
            }

            return `${path}/${key}`;
          };

          // @ts-ignore
          this[`${nextModelName}_uri`] = () => {
            let path = "";

            if (modelName) {
              // @ts-ignore
              path = this[`show_${modelName}_uri`].call(this);
            }

            return `${path}/${key}`;
          };

          // Define show_method for single routes (they are called from resources)
          // @ts-ignore
          this[`show_${nextModelName}`] = (...args) => {
            let path = "";

            if (modelName) {
              // @ts-ignore
              path = `${this[`show_${modelName}`].apply(this, args)}`;
            }

            return `${path}/${key}`;
          };

          // @ts-ignore
          this[`show_${nextModelName}_uri`] = () => {
            let path = "";

            if (modelName) {
              // @ts-ignore
              path = this[`show_${modelName}_uri`].call(this);
            }

            return `${path}/${key}`;
          };
        }

        if (data.children) {
          this.createHelpers(data.children, nextModelName);
        }
      }
    }
  }
}
