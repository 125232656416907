import { observer } from "mobx-react";

import BaseComponent from "@component/BaseComponent";
import SkillList from "./List";

@observer
export default class Skills extends BaseComponent<PropsFromRouter> {
  render() {
    return (
      <>
        <SkillList match={this.props.match} />
      </>
    );
  }
}
