import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { toJS } from "mobx";
import React from "react";

import DataTable, { ExportProps } from "@eman/emankit/DataTable";
import { AlignType, DataBodyProps, SortOrder } from "@eman/emankit/DataTable/DataBody";
import { HeaderProps } from "@eman/emankit/DataTable/HeaderBar";
import { FilterBarProps } from "@eman/emankit/FilterBar";
import FilterItem from "@eman/emankit/FilterBar/FilterItem";

import ResourceComponent from "@component/ResourceComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import ViewHelpers from "@util/ViewHelpers";
import { EXPORT_MAX_RECORDS } from "../../config";

/**
 * Abstract base list for sortable list components.
 */
export default abstract class BaseList<
  TModel extends models.IBase,
  TViewModel extends ViewModel.List<TModel>,
  OtherProps = {},
  OtherState = {}
> extends ResourceComponent<OtherProps, OtherState> {
  // View Model
  abstract vm: TViewModel;

  // Searchable is by default
  searchable = true;

  // Persist query string to URL - if two or more BaseLists are presented on one page it can cause cnoflict problem
  persistQueryString = true;

  // Custom name for saved setting
  savedSettingsName?: string;

  constructor(props: OtherProps) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
  }

  // Array for export formats
  get exportFormats(): string[] {
    return [];
  }

  // Abstract methods to create table
  abstract headerProps(): HeaderProps;
  abstract dataProps(): Omit<Omit<Omit<DataBodyProps, "emptyRow">, "data">, "value">;

  componentDidMount() {
    // Get last saved setting from current user
    const settings: ListSettings = this.user.savedSettings(this.savedSettingsName || this.modelName);

    const queryParams = this.router.getQuery() || {};

    // Override saved filters values with filters from route
    if (queryParams && queryParams.overrideFilters === "false") {
      const filtersValues = omit(queryParams, ["overrideFilters", "pageSize", "page", "save"]);
      settings.filters = {};

      for (const key in filtersValues) {
        if (filtersValues.hasOwnProperty(key)) {
          const filterItem = this.filterProps().filters!.find((item: any) => {
            if (Array.isArray(item.id)) {
              const keys = key.split(",");
              return isEqual(keys, item.id);
            } else {
              return item.id === key;
            }
          });

          // Ignore invalid filter params
          if (filterItem) {
            let operator: FilterOperator = "in";

            if (filterItem.operator !== undefined) {
              operator = filterItem.operator;
            } else if (filterItem.type !== undefined) {
              operator = FilterItem.defaultOperator(filterItem.type);
            }

            settings.filters[key] = {
              values: filtersValues[key] as any,
              operator,
            };
          }
        }
      }
    }

    // Propagate pagination from URL
    if (queryParams.pageSize && queryParams.page) {
      settings.pagination = {
        page: parseInt(queryParams.page as string, 10),
        pageSize: parseInt(queryParams.pageSize as string, 10),
      };
    }

    if (queryParams.q !== undefined) {
      this.vm.setSearchValue(queryParams.q as string);
    }

    if (queryParams.save !== undefined) {
      settings.save = queryParams.save !== "false";
    }

    if (Object.keys(settings).length > 0) {
      this.vm.setSettings(settings);
    }

    this.initVM();
  }

  initVM() {
    this.vm.init();
  }

  /**
   * More items to render above the table?
   */
  otherRender(): React.ReactNode {
    return null;
  }

  /**
   * Helper method for generate router link based on rights
   */
  objectEditOrShowLink = (methodName: string, params: Array<string | number>) => {
    if (this.user.allowToObject(this.modelName as UserRightsObjects, UserRightsOperations.EDIT)) {
      const method = `edit_${methodName}`;
      this.router.pageLink(this.uriHelper[method](...params));
    } else if (this.user.allowToObject(this.modelName as UserRightsObjects, UserRightsOperations.SHOW)) {
      const method = `show_${methodName}`;
      this.router.pageLink(this.uriHelper[method](...params));
    }
  };

  /**
   * Override this to modify.
   */
  filterProps(): Omit<FilterBarProps, "localization"> {
    return {};
  }

  /* displayProps(): Omit<DisplayBarProps, "localization"> | undefined {
    return {
      options: this.vm.displaySettings.map(item => ({name: item.name, id: item.id, default: item.default})),
      onSave: this.onDisplaySave,
      onRemove: this.onDisplayRemove,
      onChange: this.onDisplayChange,
      value: this.vm.display ? this.vm.display.id : ''
    };
  } */

  /**
   * Default rendering.
   */
  renderRow(item: TModel, column: string): React.ReactNode {
    return this.renderRowAny(item, column);
  }

  renderRowAny(item: any, column: string): React.ReactNode {
    if (!item) {
      return undefined;
    }

    if (item[column] instanceof Date) {
      return this.locs.formatDate(item[column]);
    } else if (typeof item[column] === "number") {
      return this.locs.formatNumber(item[column]);
    } else if (typeof item[column] === "boolean") {
      return ViewHelpers.booleanStringify(this.locs, item[column]);
    } else {
      return item[column];
    }
  }

  /**
   * Update query params.
   */
  updateQuery = () => {
    if (!this.persistQueryString) {
      return;
    }

    const filters: any = {
      ...toJS(this.vm.selectedItems),
    };

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        filters[key] = filters[key].values;
      }
    }

    const queryParams: any = {
      ...filters,
      ...this.vm.pagination,
      q: this.vm.searchValue,
    };

    if (Object.keys(this.vm.selectedItems).length > 0) {
      queryParams.overrideFilters = false;
    }

    this.router.updateQuery(queryParams);
  };

  onSortChange = (sort: SortOrder): void => {
    this.vm.setOrder({ field: sort.key, direction: sort.sort });
  };

  onPageChange = (page: number, pageSize: number): void => {
    this.vm.setPageAndPageSize(page, pageSize);
    this.updateQuery();
  };

  onSearchConfirm = () => {
    this.vm.fetchList();
    this.updateQuery();
  };

  onFilterChange = (values: FilterValues, visible: string[], reset: boolean) => {
    if (reset) {
      this.vm.setSearchValue(undefined);
    }

    this.vm.setFilters(values, visible, undefined, true, reset);
    this.updateQuery();
  };

  onColumnsChange = (columns: string[]) => {
    this.vm.setColumns(columns);
  };

  componentWillUnmount() {
    this.vm.cleanUp();
  }

  createField(
    id: string,
    {
      label,
      nosort = false,
      align = AlignType.Left,
      alwaysShow = false,
      hideLabel = false,
      childrenOpener = false,
      width,
      childrenSelector,
      className,
    }: {
      label?: string;
      nosort?: boolean;
      align?: AlignType;
      alwaysShow?: boolean;
      hideLabel?: boolean;
      width?: number;
      childrenOpener?: boolean;
      childrenSelector?: string;
      className?: string;
    }
  ) {
    return {
      id,
      label: label || this.ta(id),
      nosort,
      alwaysShow,
      hideLabel,
      align,
      width,
      childrenOpener,
      childrenSelector,
      className,
    };
  }

  createFieldWithSize(key: string, width: number) {
    return this.createField(key, { width });
  }

  createFieldSimple(key: string) {
    return this.createField(key, {});
  }

  paginationProps() {
    // Make pagination controlled component
    return {
      pageSize: this.vm.pagination.pageSize,
      page: this.vm.pagination.page,
      totalRecords: this.vm.total,
      onPageChange: this.onPageChange,
      perPageClassName: "white",
    };
  }

  render(): React.ReactNode {
    const { vm } = this;

    if (!vm) {
      return null;
    }

    const dataProps = {
      ...this.dataProps(),
      value: this.renderRow,
      data: vm.list,
      sortBy: vm.order
        ? {
            key: vm.order.field,
            sort: vm.order.direction,
          }
        : undefined,
      selectedColumns: vm.columns,
      onSortChange: this.onSortChange,
      onColumnsChange: this.onColumnsChange,
      selectedRows: vm.selectedRows,
      onRowSelection: vm.setSelectedRows,
    };

    const filterProps = this.filterProps();
    if (filterProps) {
      if (filterProps.filters) {
        filterProps.filters.forEach(value => {
          value.values = toJS(value.values);
        });
      }

      filterProps.onFilterChange = this.onFilterChange;
      filterProps.selected = toJS(vm.selectedItems);
      filterProps.visible = vm.visibleFilters;

      if (this.searchable) {
        filterProps.search = {
          placeholder: this.ta("filter_helper"),
          suggestions: [],
          autocomplete: this.vm.autocomplete,
          value: this.vm.searchValue,
          onChangedValue: this.vm.setSearchValue,
          onSearchConfirm: this.onSearchConfirm,
        };
      }
    }

    let exportProps: ExportProps | undefined;
    if (this.exportFormats.length > 0) {
      exportProps = {
        formats: this.exportFormats,
        maxRecords: EXPORT_MAX_RECORDS,
        onExport: async (format: string) => {
          const response = await vm.startExport(format);

          if (response) {
            EventBus.trigger(SHOW_TOAST, this.locs.tg("export.success"));
          }
        },
      };
    }

    return (
      <React.Fragment>
        {this.otherRender()}
        <DataTable
          headerProps={this.headerProps()}
          filterProps={filterProps}
          exportProps={exportProps}
          dataProps={dataProps}
          paginationProps={this.paginationProps()}
          // displayProps={this.displayProps()}
        />
      </React.Fragment>
    );
  }
}
