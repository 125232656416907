import { observer } from "mobx-react";
import React from "react";

import Columns from "@eman/emankit/Columns";

import BaseForm from "@component/BaseForm";
import Rate from "@model/Rate";
import RateItem from "@model/RateItem";
import { DatePickerBox, SelectBox } from "@util/Form";
import RateItems from "./RateItems";
import Contract from "@model/Contract";

export interface RateFormProps {
  onAddItem?: () => void;
  onRemoveItem?: (item: RateItem) => void;
  items: RateItem[];
  currentContract?: Contract;
}

@observer
export default class RateForm extends BaseForm<Rate, RateFormProps> {
  modelName = "rate";
  prefix = "employee.contract";

  renderFormBody() {
    return (
      <>
        <Columns>
          {this.generateFormField(
            "valid_from",
            <DatePickerBox
              disabledDays={{ before: this.props.currentContract?.valid_from }}
              dateFrom={this.props.currentContract?.valid_from}
            />,
            true
          )}
          {this.generateFormField(
            "enumeration_working_time_id",
            <SelectBox options={this.enums.valuesForSelect("working_times")} emptyRow={this.emptyRow} />,
            true
          )}
          {this.generateFormField(
            "enumeration_rate_reason_id",
            <SelectBox options={this.enums.valuesForSelect("rate_reasons")} allowEmpty={true} />
          )}
        </Columns>
        <RateItems
          onAddItem={this.props.onAddItem}
          onRemoveItem={this.props.onRemoveItem}
          items={this.props.items}
          withState={this.props.entity.id !== undefined}
          readonly={this.props.readonly}
        />
      </>
    );
  }
}
