import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import PerformanceManagement from "@model/PerformanceManagement";
import PerformanceManagementCreateVM from "@vm/Create/PerformanceManagement";

import PerformanceManagementForm from "./Form";
import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class PerformanceManagementCreate extends CreateModal<PerformanceManagement> {
  modelName = "performance_management";
  prefix = "employee";

  @lazyInject(TYPES.PerformanceManagementCreate)
  vm: PerformanceManagementCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_performance_managements(this.props.match.params.user_id));
  };

  renderModalBody() {
    return (
      <>
        <PerformanceManagementForm entity={this.vm.entity} />
      </>
    );
  }
}
