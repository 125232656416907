import { ReactNode } from "react";
import { BalanceGroupWithDuration } from "@util/Calendar";
import _, { Dictionary } from "lodash";
import BalanceRowItem from "./item";
import startOfMonth from "date-fns/startOfMonth";
import { endOfMonth } from "date-fns/esm";

interface Props {
  interval: Date[];
  balances: Dictionary<BalanceGroupWithDuration>;
  sidebarRenderer: () => ReactNode;
  className?: string;
  hideSidebar?: boolean;
}

export interface YearBalance {
  is_work: boolean;
  value: number;
  zero_sum_value_ok: boolean;
}

const BalanceYearRow = ({ interval, balances, sidebarRenderer, className, hideSidebar }: Props) => {
  const cells = [];
  for (const month of interval) {
    let value = 0;
    _.forOwn(balances, function (balance, key) {
      const currentDate = new Date(key);
      if (startOfMonth(month) <= currentDate && endOfMonth(month) >= currentDate) {
        value += balance.value;
      }
    });
    const balance: YearBalance = { is_work: true, value: value, zero_sum_value_ok: true };
    const cell = (
      <td className="table-col" data-value={value}>
        <BalanceRowItem balance={balance} />
      </td>
    );
    cells.push(cell);
  }

  return (
    <tr className={`${className} table-row`}>
      <td className="sidebar table-col">{!hideSidebar && sidebarRenderer()}</td>
      {cells}
    </tr>
  );
};

export default BalanceYearRow;
