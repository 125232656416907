import { injectable } from "inversify";

import Allocation, { AllocationEvent } from "@model/Allocation";
import BaseRepository from "./BaseRepository";
import EventBus, { ENTITY_UPDATE } from "@util/EventBus";
import ApiClient, { AxiosConfig } from "@util/ApiClient";

@injectable()
export default class AllocationRepository extends BaseRepository<Allocation> {
  constructor() {
    super(Allocation, "allocations", "allocation");
  }

  performEvent(allocationId: number, event: AllocationEvent): Promise<ApiResponse<Allocation>> {
    const config: AxiosConfig = {
      url: `${this.uri}/${allocationId}/event/${event}`,
      method: "PUT",
      id: "PERFORM_ALLOCATION_EVENT",
    };

    return ApiClient.fetchResponse(config).then(response => {
      EventBus.trigger(ENTITY_UPDATE, {
        identificator: this.classModelName,
        id: allocationId,
      });

      return response;
    });
  }
}
