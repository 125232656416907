import { observer } from "mobx-react";

import Columns from "@eman/emankit/Columns";

import BaseForm from "@component/BaseForm";
import Contact, { CONTACT_TYPES } from "@model/Contact";
import { CheckBox, DatePickerBox, SelectBox, TextAreaBox, TextBox } from "@util/Form";
import { OptionType } from "@eman/emankit";

@observer
export default class ContactForm extends BaseForm<Contact> {
  modelName = "contact";
  prefix = "employee.contact_information";

  contactTypes = (): OptionType<string>[] => {
    return Object.entries(CONTACT_TYPES).map(item => {
      return { value: item[0], label: item[1] };
    });
  };

  renderFormBody() {
    return (
      <Columns byColumns={true}>
        {this.generateFormField(
          "contact_type",
          <SelectBox<string> options={this.contactTypes()} emptyRow={this.locs.tg("form.select_value")} />,
          true
        )}
        {this.generateFormField("value", <TextBox />, true)}
        {this.generateFormField("valid_from", <DatePickerBox />)}
        {this.generateFormField("valid_to", <DatePickerBox />)}
        {this.generateFormField("description", <TextAreaBox />)}
        {this.generateFormField("work", <CheckBox />, false, { label: this.tg("work_contact") })}
        {this.generateFormField("public", <CheckBox />)}
      </Columns>
    );
  }
}
