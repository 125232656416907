import { Consumer, createConsumer, Subscription } from "@rails/actioncable";
import { action } from "mobx";
import { injectable } from "inversify";
import EventBus, { LOGIN_USER, LOGOUT_USER } from "@util/EventBus";

export type WebSocketChannel = "NotificationChannel" | "AllocationChannel";

@injectable()
export default class WebSocket {
  private consumer: Consumer;

  constructor() {
    this.consumer = createConsumer("/api/ws");
    EventBus.on(LOGIN_USER, this.on);
    EventBus.on(LOGOUT_USER, this.off);
  }

  @action.bound
  subscribe(channel: WebSocketChannel, received: (data: any) => void, connected?: () => void): Subscription {
    return this.consumer.subscriptions.create(channel, { received, connected });
  }

  @action.bound
  on() {
    this.consumer.connection.open();
  }

  @action.bound
  off() {
    this.consumer.connection.close();
  }
}
