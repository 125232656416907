import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import { SelectBox, TextBox } from "@util/Form";
import Export from "@model/Export";
import ExportFormVM from "@vm/Form/Export";
import { OptionType } from "@eman/emankit";

interface ExportFormProps {
  vm: ExportFormVM;
}

@observer
export default class ExportForm extends BaseForm<Export, ExportFormProps> {
  modelName = "export";

  exportTypeOptions = (): OptionType<string>[] => {
    return this.props.vm.availableTypes.map(type => ({
      value: type.value,
      label: this.locs.tg(`${this.modelName}.${type.label}`),
    }));
  };

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-6">
          {this.generateFormField(
            "export_type",
            <SelectBox<string> options={this.exportTypeOptions()} allowEmpty={false} emptyRow={this.emptyRow} />,
            true,
            {
              label: this.tg("export_type"),
            }
          )}
        </div>
        <div className="col-md-6">
          {this.generateFormField("offset", <TextBox type="number" disabledDecimal min={0} />, true, {
            label: this.tg("offset"),
          })}
        </div>
        <div className="col-md-6">{this.generateFormField("name", <TextBox />, false, { label: this.tg("name") })}</div>
      </div>
    );
  }
}
