import { observer } from "mobx-react";

import SelectWithSearch, { ValueType } from "@eman/emankit/SelectWithSearch";

import BindingElement from "./index";
import { checkGroup, SelectBoxProps } from "./SelectBox";
import { OptionType, GroupedOptionsType } from "@eman/emankit";

@observer
export default class MultiSelectBox<
  KValue extends string | number = number,
  TOptionType extends OptionType<KValue> = OptionType<KValue>
> extends BindingElement<SelectBoxProps<KValue, TOptionType>, any> {
  protected handleValueChanged = (values: ValueType<TOptionType, true>) => {
    if (values) {
      this.setValue(values.map(item => item.value));
    } else {
      this.setValue([]);
    }
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const {
      allowEmpty,
      options,
      emptyRow,
      skipDirty,
      menuPosition,
      onValueChanged,
      formatValue,
      parseValue,
      tabIndex,
      ...rest
    } = this.props;
    const items: TOptionType[] = [];

    if (options) {
      options.forEach(option => {
        if (checkGroup(option)) {
          const opt = (option as unknown) as GroupedOptionsType<KValue, TOptionType>;

          // @TODO Add real support for groups at SelectWithSearch component
          opt.options.forEach(item => {
            items.push((item as unknown) as TOptionType);
          });
        } else {
          items.push((option as unknown) as TOptionType);
        }
      });
    }

    const selectedValues = items.filter(optionItem => {
      return !!(this.value || []).find((valueItem: number | string) => valueItem === optionItem.value);
    });

    if (allowEmpty) {
      items.unshift({
        value: "",
        label: emptyRow || "",
      } as any); // Only one way how to add empty row
    }

    return (
      <SelectWithSearch<KValue, TOptionType, true>
        onChange={this.handleValueChanged}
        value={selectedValues || []}
        {...rest}
        options={items}
        placeholder={emptyRow}
        isMulti={true}
        menuPosition={menuPosition}
        tabIndex={tabIndex as any}
      />
    );
  }
}
