import React from "react";
import Avatar, { AvatarProps } from "@eman/emankit/Avatar";
import { lazyInject } from "../../inversify.config";
import TYPES from "../../inversify.types";
import AvatarService from "@service/Avatar";
import { observer } from "mobx-react";
import User from "@model/User";

export interface AvatarComponentProps extends Omit<AvatarProps, "url"> {
  user: User;
}

@observer
export default class AvatarComponent extends React.Component<AvatarComponentProps> {
  @lazyInject(TYPES.Avatar)
  avatarService: AvatarService;

  render() {
    const { user, ...rest } = this.props;
    const uri = this.avatarService.getUrl(user.id!, user.last_avatar_updated_at) || "";

    return <Avatar {...rest} uri={uri} />;
  }
}
