import { Transform, Type, Exclude } from "@eman/class-transformer";
import BaseModel from "@model/BaseModel";

import { computed, observable } from "mobx";
import JobTitle from "./JobTitle";
import JobTitleWorkingTimeRatio from "./JobTitleWorkingTimeRatio";
import Rate from "./Rate";

export default class Capacity extends BaseModel {
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from?: Date;
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to?: Date;
  @observable monday = 0;
  @observable tuesday = 0;
  @observable wednesday = 0;
  @observable thursday = 0;
  @observable friday = 0;
  @observable saturday = 0;
  @observable sunday = 0;

  @observable contract_id: number;
  @observable job_title_id: number;
  @observable rate_id?: number;

  @observable free_rate_hours: number;
  @observable job_title_working_time_ratio_id: number;
  @observable job_title_working_time_ratio: JobTitleWorkingTimeRatio;

  @Type(() => JobTitle)
  @observable
  job_title: JobTitle;

  @Type(() => Rate)
  @observable
  rate: Rate;

  @Exclude()
  @observable
  workdays = 8;

  @computed
  get totalCapacity(): number {
    return this.monday + this.tuesday + this.wednesday + this.thursday + this.friday + this.saturday + this.sunday;
  }
}
