import { observer } from "mobx-react";

import EditModal from "@component/EditModal";

import { lazyInject, TYPES } from "../../../../inversify.config";
import WorkingTimeRatiosForm from "./Form";
import WorkingTimeRatiosEditVM from "@vm/Edit/WorkingTimeRatios";
import WorkingTimeRatio from "@model/WorkingTimeRatio";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import React from "react";
import WorkingTimeRatiosListVM from "@vm/List/WorkingTimeRatios";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import { RATIO_SPLIT } from "../../../../config";

@observer
export default class WorkingTimeRatiosEditModal extends EditModal<WorkingTimeRatio> {
  modelName = "working_time_ratio";
  prefix = "employee.contract";

  get hasDelete() {
    return false;
  }

  @lazyInject(TYPES.WorkingTimeRatiosEdit)
  vm: WorkingTimeRatiosEditVM;

  @lazyInject(TYPES.WorkingTimeRatiosList)
  workingTimeRatiosListVM: WorkingTimeRatiosListVM;

  setIdFromParams() {
    const params = this.props.match.params;
    this.vm.initVM(params.contract_id, params.user_id, this.getId());
  }

  componentWillUnmount(): void {
    this.vm.cleanUp();
    this.workingTimeRatiosListVM.cleanUp();
  }

  async fetchWorkingTimeRatiosList() {
    await this.workingTimeRatiosListVM.fetchList(false);
  }

  componentDidMount(): void {
    const params = this.props.match.params;
    this.workingTimeRatiosListVM.setId(params.user_id, params.contract_id);
    this.fetchWorkingTimeRatiosList();

    super.componentDidMount();
  }

  onSave = async () => {
    const queryParams = this.router.getQuery() || {};

    let status;

    if (queryParams[RATIO_SPLIT] === "true") {
      status = await this.vm.create();
    } else {
      status = await this.vm.update();
    }

    if (status) {
      this.vm.entity.clearErrors();
      EventBus.trigger(SHOW_TOAST, this.successMessage());
      this.onClose();
    }

    return status;
  };

  buttons() {
    const editButton = (
      <Button variant={ButtonVariant.Primary} onClick={this.onSave} disabled={this.vm.allowedToSave}>
        {this.locs.tg("form.edit")}
      </Button>
    );

    if (this.hasDelete) {
      return (
        <div className="row">
          <div className="col-6" style={{ textAlign: "left" }}>
            <Button variant={ButtonVariant.Dangerous} onClick={this.onDelete}>
              {this.locs.tg("form.delete")}
            </Button>
          </div>
          <div className="col-6">{editButton}</div>
        </div>
      );
    } else {
      return editButton;
    }
  }

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.show_employees_contracts(params.user_id, params.contract_id));
  };

  renderModalBody() {
    return (
      <>
        {!this.workingTimeRatiosListVM.currentlyFetching && (
          <WorkingTimeRatiosForm
            entity={this.vm.entity}
            allowedToSave={this.vm.allowedToSave}
            activeRatioDateFrom={this.workingTimeRatiosListVM.activeRatioDateFrom}
          />
        )}
      </>
    );
  }
}
