import Notification from "./Notification";
import { observable } from "mobx";

export enum ExportType {
  ALLOCATION = "allocations",
}

export default class Export extends Notification {
  @observable lines: number;
  name?: string;
  offset: number;
  export_type: string;
}
