import { observer } from "mobx-react";
import { ValueType } from "react-select/src/types";
import SelectWithSearch from "@eman/emankit/SelectWithSearch";
import BindingElement from "./index";
import { OptionProps, SingleValueProps } from "react-select";
import { OptionType, GroupedOptionsType } from "@eman/emankit";

import "./styles.scss";
import { ComponentType } from "react";
import { checkGroup, SelectBoxProps } from "./SelectBox";

export interface SelectWithSearchBoxProps<KValue extends string | number, TOptionType extends OptionType<KValue>>
  extends SelectBoxProps<KValue, TOptionType> {
  singleValueRender?: ComponentType<SingleValueProps<TOptionType>>;
  optionsRender?: ComponentType<OptionProps<TOptionType, false>>;
}

@observer
export default class SelectWithSearchBox<
  KValue extends string | number = number,
  TOptionType extends OptionType<KValue> = OptionType<KValue>
> extends BindingElement<SelectWithSearchBoxProps<KValue, TOptionType>, any> {
  protected handleValueChanged = (value: ValueType<TOptionType, false>) => {
    this.setValue((!value || value?.value) == "" ? null : value?.value);
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const {
      allowEmpty,
      options,
      emptyRow,
      menuPosition,
      skipDirty,
      onValueChanged,
      formatValue,
      parseValue,
      tabIndex,
      ...rest
    } = this.props;

    const items: TOptionType[] = [];

    if (options) {
      options.forEach(option => {
        if (checkGroup(option)) {
          const opt = option as GroupedOptionsType<KValue, TOptionType>;

          // @TODO Add real support for groups at SelectWithSearch component
          opt.options.forEach(item => {
            items.push(item);
          });
        } else {
          items.push(option as any);
        }
      });
    }

    const selectedValue = items.find(item => {
      return item.value === this.value;
    });

    if (allowEmpty) {
      items.unshift({
        value: "",
        label: emptyRow || "",
      } as any);
    }

    return (
      <SelectWithSearch<KValue, TOptionType, false>
        onChange={this.handleValueChanged}
        value={selectedValue || null}
        {...rest}
        options={items}
        placeholder={emptyRow}
        isMulti={false}
        menuPosition={menuPosition}
        tabIndex={tabIndex as any}
        isDisabled={this.props.disabled}
      />
    );
  }
}
