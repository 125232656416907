import { toJS } from "mobx";

import FilterBar from "@eman/emankit/FilterBar";
import { JobTitleState } from "@model/JobTitle";
import ViewHelpers from "@util/ViewHelpers";
import { OptionType } from "@eman/emankit";
import { EvidenceStateStatus } from "@model/EvidenceState";
import LocalizationService from "@service/Localization";
import JobPosition from "@model/JobPosition";
import OrganizationStructure from "@model/OrganizationStructure";
import EnumVM from "@service/Enum";
import { UserStateByContract } from "@model/User";

interface Props {
  onChange: (values: FilterValues, visible: string[], reset: boolean) => void;
  userOptions?: OptionType<string>[];
  jobPositionOptions?: OptionType<number>[];
  organizationUnitOptions?: OptionType<number>[];
  projectOptions?: OptionType<number>[];
  jobPositionsOptions: JobPosition[];
  organizationsOptions: OrganizationStructure[];
  selectedItems?: FilterValues;
  visibleFilters?: string[];
  locs: LocalizationService;
  enums: EnumVM;
  onResetView?: () => void;
}

const EmployeeFilter = ({
  projectOptions,
  organizationUnitOptions,
  jobPositionOptions,
  userOptions,
  selectedItems,
  visibleFilters,
  jobPositionsOptions,
  organizationsOptions,
  locs,
  enums,
  onChange,
  onResetView,
}: Props) => {
  const filters: FilterData[] = [
    {
      id: "user_id",
      label: locs.tm("employee", 5),
      values: userOptions,
    },
    {
      id: "user_first_name",
      label: locs.ta("user", "first_name"),
      type: "search",
    },
    {
      id: "user_last_name",
      label: locs.ta("user", "last_name"),
      type: "search",
    },
    {
      id: "contract_state",
      label: locs.ta("employee", "user_state_contract"),
      values: Object.values(UserStateByContract).map(state => ({ label: locs.tg(`employee.state.${state}`), value: state })),
    },
    {
      id: "evidence_state_evidence_status",
      label: locs.ta("employee", "evidence_state"),
      values: Object.values(EvidenceStateStatus).map(state => ({ label: locs.tg(`employee.state.${state}`), value: state })),
    },
    {
      id: "job_title_job_position_id",
      label: locs.tm("job_position"),
      values: jobPositionOptions,
    },
    {
      id: "organization_unit_id",
      label: locs.tm("organization_unit"),
      values: organizationUnitOptions,
      hasChildren: true,
    },
    {
      id: "allocation_project_id",
      label: locs.tm("project"),
      values: projectOptions,
    },
    {
      id: "allocation_service",
      label: locs.ta("allocation", "service"),
      values: ViewHelpers.booleanOptions(locs),
      single: true,
    },
    {
      id: "branch_office_name",
      label: locs.tm("branch_office"),
      type: "search",
    },
    {
      id: "contract_enumeration_user_type_id",
      label: locs.ta("contract", "contractor"),
      values: enums.valuesForSelect("user_types"),
    },
    {
      id: "job_title_state",
      label: locs.ta("employee", "job_title_state"),
      values: Object.values(JobTitleState).map(state => ({
        label: locs.tg(`employee.contract.job_title.state.${state}`),
        value: state,
      })),
    },
    {
      id: "job_title_working_time_ratio_primary_job_title_job_position_id",
      label: locs.ta("user", "primary_job_title"),
      values: jobPositionsOptions.map(jobPosition => ({
        value: jobPosition.id?.toString(),
        label: jobPosition.name.toString(),
      })),
    },
    {
      id: "job_title_working_time_ratio_primary_job_title_organization_structure_id",
      label: locs.ta("user", "primary_organization_unit"),
      values: organizationsOptions.map(organization => ({
        value: organization.id?.toString(),
        label: organization.name.toString(),
      })),
    },
  ];

  return (
    <FilterBar
      floatingPosition
      localization={locs.filters}
      onFilterChange={onChange}
      filters={filters}
      selected={toJS(selectedItems)}
      visible={visibleFilters}
      onResetView={onResetView}
    />
  );
};

export default EmployeeFilter;
