import { inject, injectable } from "inversify";

import PaymentInfo from "@model/PaymentInfo";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PaymentInfoListVM extends AssociatedListViewModel<PaymentInfo, AssociatedRepository<PaymentInfo>> {
  constructor(@inject(TYPES.PaymentInfoRepository) repository: AssociatedRepository<PaymentInfo>) {
    super(repository);
    this.scroll = false;
    this.save = false;
  }
}
