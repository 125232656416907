import { observer } from "mobx-react";
import { Router } from "react-router";
import { Route } from "react-router-dom";

// Ui components
import Layout from "@component/Layout";
import Footer from "@component/Footer";
import ProgressIndicator from "@eman/emankit/ProgressIndicator";

// Base components
import BaseRouter from "./Base";

// Others
import { UserRightsPages } from "@model/Rights";
import { uriHelper } from "../config";

// Views
import AnnouncementCreate from "@view/Announcement/Create";
import Login from "@view/Login";
import Announcements from "./Announcements";
import Employees from "./Employees";
import Employer from "./Employer";
import Enumerations from "./Enumerations";
import Exports from "./Exports";
import Roles from "./Roles";
import Allocations from "./Allocations";

const appRoutes: IAppRoute[] = [
  {
    path: uriHelper.announcements_uri(),
    exact: false,
    title: "announcement.list",
    component: Announcements,
  },
  {
    path: uriHelper.employees_uri(),
    exact: false,
    title: "employee.list",
    component: Employees,
  },
  {
    path: uriHelper.employer_uri(),
    exact: false,
    title: "employer.show",
    component: Employer,
  },
  {
    path: uriHelper.enumeration_uri(),
    exact: false,
    title: "enumeration.list",
    component: Enumerations,
  },
  {
    path: uriHelper.roles_uri(),
    exact: false,
    title: "role.list",
    component: Roles,
  },
  {
    path: uriHelper.exports_uri(),
    exact: false,
    title: "export.list",
    component: Exports,
  },
  {
    path: uriHelper.allocations_uri(),
    exact: false,
    title: "allocation.list",
    component: Allocations,
  },
];

@observer
export default class RootRouter extends BaseRouter {
  constructor(props: any) {
    super(props);

    if (this.user.isLoggedIn && !this.user.allowToPage(UserRightsPages.EMPLOYEES)) {
      appRoutes.splice(0, 0, {
        path: "/",
        exact: true,
        redirect: () => uriHelper.show_employees_personal_data(this.user.entity.id),
      });
    } else {
      appRoutes.splice(0, 0, {
        path: "/",
        exact: true,
        redirect: () => uriHelper.employees(),
      });
    }
  }

  render() {
    if (!this.locs.isLoaded || (this.user.isLoggedIn && !this.enums.isLoaded)) {
      return <ProgressIndicator />;
    }

    let userSection = null;
    if (this.user.isLoggedIn) {
      userSection = (
        <>
          <Route component={AnnouncementCreate} />
          {this.pageRouteRender(appRoutes)}
        </>
      );
    } else {
      // Login page
      userSection = <Route component={Login} />;
    }

    return (
      <Router history={this.router.history}>
        <Layout>
          {/* User or not user section */}
          {userSection}
          <Footer />
        </Layout>
      </Router>
    );
  }
}
