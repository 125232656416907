import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import JobPosition from "@model/JobPosition";
import JobPositionEditVM from "@vm/Edit/JobPosition";
import JobPositionForm from "./Form";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class JobPositionEditModal extends EditModal<JobPosition> {
  modelName = "job_position";
  prefix = "employer";

  @lazyInject(TYPES.JobPositionEdit)
  vm: JobPositionEditVM;

  successMessage(): string {
    return this.tg("updated");
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employer_job_positions());
  };

  renderModalBody() {
    return <JobPositionForm entity={this.vm.entity} organizationStructureOptions={this.vm.availableOrganizationStructures} />;
  }
}
