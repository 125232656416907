import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";
import Panel from "@eman/emankit/Panel";

import ResourceComponent from "@component/ResourceComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import RateList from "./List";

@observer
export default class Rates extends ResourceComponent<PropsFromRouter> {
  modelName = "rate";
  prefix = "employee.contract";

  onAddClick = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.new_employees_contracts_rates(params.user_id, params.contract_id));
  };

  render() {
    let button;
    if (this.user.allowToObject(UserRightsObjects.RATE, UserRightsOperations.CREATE)) {
      button = (
        <Button variant={ButtonVariant.Active} onClick={this.onAddClick}>
          {this.tg("add")}
        </Button>
      );
    }

    return (
      <Panel>
        <HeaderRow title={this.tg("title")} level={2}>
          {button}
        </HeaderRow>
        <RateList match={this.props.match} />
      </Panel>
    );
  }
}
