import { injectable } from "inversify";

import PaymentInfo from "@model/PaymentInfo";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class PaymentInfoRepository extends BaseAssociatedRepository<PaymentInfo> {
  constructor() {
    super(PaymentInfo, "/users/$ID/payment_infos", "payment_info");
  }
}
