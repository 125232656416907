import CreateModal from "@component/CreateModal";
import { observer } from "mobx-react";

import Allocation from "@model/Allocation";
import AllocationCreateVM from "@vm/Create/Allocation";
import Form from "./Form";

import { lazyInject, TYPES } from "../../inversify.config";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import { computed } from "mobx";

@observer
export default class AllocationCreateModal extends CreateModal<Allocation> {
  modelName = "allocation";

  @lazyInject(TYPES.AllocationCreate)
  vm: AllocationCreateVM;

  componentDidMount() {
    super.componentDidMount();
    this.suffix = `create.${this.vm.entity.is_work ? "work" : "absence"}`;
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.allocations());
  };

  @computed
  get canCreateBeforeToday(): boolean {
    return this.user.allowToObject(UserRightsObjects.TIME_LIMITS, UserRightsOperations.IGNORE_TODAYS_DEPENDENT_VALIDATIONS);
  }

  renderModalBody() {
    return (
      <>
        <Form entity={this.vm.entity} vm={this.vm.allocationFormVM} canCreateEditBeforeToday={this.canCreateBeforeToday} />
      </>
    );
  }
}
