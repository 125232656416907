import { inject, injectable } from "inversify";
import { action, when } from "mobx";

import Rate, { RateState } from "@model/Rate";
import RateItem from "@model/RateItem";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import ContractsVM from "@vm/Other/Contracts";
import RateListVM from "@vm/List/Rate";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class RateCreateVM extends AssociatedCreateViewModel<Rate, AssociatedRepository<Rate>> {
  constructor(
    @inject(TYPES.RateRepository) repository: AssociatedRepository<Rate>,
    @inject(TYPES.Contracts) public contractsVM: ContractsVM,
    @inject(TYPES.RateList) private rateListVM: RateListVM
  ) {
    super(Rate, repository);
  }

  async create(): Promise<number | undefined> {
    this.entity.contract_id = this.contractsVM.contractId!;
    return super.create();
  }

  @action
  async resetEntityAndErrors() {
    super.resetEntityAndErrors();

    await when(() => this.contractsVM.contractId !== undefined);

    this.rateListVM.setParentId(this.parentId);

    const filterActive: FilterValues = {
      state: {
        operator: "in",
        values: [RateState.ACTIVE],
      },
      contract_id: {
        operator: "in",
        values: [this.contractsVM.contractId],
      },
    };

    this.rateListVM.setFilters(filterActive, undefined, true);

    await this.rateListVM.fetchList(false, false);

    if (this.rateListVM.list.length) {
      // TODO: After BE will fix filtering by state === 'active' remove filtering below
      const activeRate = this.rateListVM.list.filter(item => item.state === RateState.ACTIVE)[0];
      if (activeRate) {
        this.entity.enumeration_working_time_id = activeRate.enumeration_working_time_id;
        this.entity.enumeration_rate_reason_id = activeRate.enumeration_rate_reason_id;
      }
    }
  }

  @action.bound
  addItem() {
    this.entity.rate_items.push(new RateItem());
  }

  @action.bound
  removeItem(item: RateItem) {
    item.markedForDestroy = true;
    this.entity.rate_items = this.entity.rate_items.filter(rateItem => !rateItem.markedForDestroy);
  }

  get items(): RateItem[] {
    return this.entity.rate_items.filter(item => !item.markedForDestroy || item.errorCount);
  }
}
