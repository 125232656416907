import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import Skill from "@model/Skill";
import { SelectBox } from "@util/Form";

@observer
export default class SkillForm extends BaseForm<Skill> {
  modelName = "skill";
  prefix = "employee";

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-6">
          {this.generateFormField(
            "enumeration_skill_type_id",
            <SelectBox options={this.enums.valuesForSelect("skill_types")} emptyRow={this.emptyRow} />,
            true
          )}
        </div>
        <div className="col-md-6">
          {this.generateFormField(
            "enumeration_skill_level_id",
            <SelectBox options={this.enums.valuesForSelect("skill_levels")} emptyRow={this.emptyRow} />,
            true
          )}
        </div>
      </div>
    );
  }
}
