import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import School from "@model/School";
import SchoolShowVM from "@vm/Show/School";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class SchoolShowModal extends ShowModal<School> {
  modelName = "school";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.SchoolShow)
  vm: SchoolShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} vm={this.vm.schoolFormVM} />;
  }
}
