import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import Note, { NOTE_RIGHT_TYPES } from "@model/Note";
import { CheckBox, SelectBox, TextAreaBox } from "@util/Form";
import { OptionType } from "@eman/emankit";

@observer
export default class NoteForm extends BaseForm<Note> {
  modelName = "note";
  prefix = "employee";

  makeRightTypeOptions = (): OptionType<string>[] => {
    return Object.values(NOTE_RIGHT_TYPES).map(type => ({
      value: type,
      label: this.locs.tg(`${this.prefix}.${this.modelName}.right_type.${type}`),
    }));
  };

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-6">
          {this.generateFormField("right_type", <SelectBox<string> options={this.makeRightTypeOptions()} />, true)}
        </div>
        <div className="col-md-6">{this.generateFormField("archived", <CheckBox />, true)}</div>
        <div className="col-md-12">{this.generateFormField("description", <TextAreaBox />, true)}</div>
      </div>
    );
  }
}
