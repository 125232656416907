import WorkingTimeRatio from "@model/WorkingTimeRatio";
import { injectable } from "inversify";
import BaseAssociatedRepository from "./BaseAssociatedRepository";

@injectable()
export default class UserTimeRatioRepository extends BaseAssociatedRepository<WorkingTimeRatio> {
  constructor() {
    super(WorkingTimeRatio, `users/$ID/working_time_ratios`, "working_time_ratio");
  }
}
