import BaseModel from "@model/BaseModel";
import { observable } from "mobx";
import { Type } from "@eman/class-transformer";

export default class Balance extends BaseModel {
  @observable value: number;
  @observable user_id: number;
  @observable is_work: boolean | null;

  @Type(() => Date)
  @observable
  date: Date;
}
