import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import Vacation from "@model/Vacation";
import { SelectBox, TextBox } from "@util/Form";
import ViewHelpers from "@util/ViewHelpers";

@observer
export default class VacationForm extends BaseForm<Vacation> {
  modelName = "vacation";
  prefix = "employee";

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-6">
          {this.generateFormField("year", <SelectBox options={ViewHelpers.yearSelectOptions()} />, true, {
            label: this.ta("year"),
            disabled: true,
          })}
        </div>
        <div className="col-md-6">{this.generateFormField("number_of_hours", <TextBox type="number" />, true)}</div>
        <div className="col-md-6">{this.generateFormField("previous_remaining_hours", <TextBox type="number" />, true)}</div>
      </div>
    );
  }
}
