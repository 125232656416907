import { ChangeEvent } from "react";

import Select from "@eman/emankit/Select";
import { InputVariant } from "@eman/emankit/Input";
import LocalizationService from "@service/Localization";

export enum EmployeeAllocationsOrder {
  USER_LAST_NAME_ASC = "user_last_name+asc",
  USER_LAST_NAME_DESC = "user_last_name+desc",
  JOB_TITLE_ASC = "job_title_job_position_name+asc",
  JOB_TITLE_NAME_DESC = "job_title_job_position_name+desc",
  BALANCE_SUM_ASC = "balance_sum+asc",
  BALANCE_SUM_DESC = "balance_sum+desc",
}

interface Props {
  locs: LocalizationService;
  onChange: (orderOptions: OrderOptions) => void;
}

const EmployeeSort = ({ locs, onChange }: Props) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const [field, direction] = id.split("+") as [OrderOptions["field"], OrderOptions["direction"]];
    onChange({ field, direction });
  };

  const tg = (id: string): string => {
    return locs.tg(`allocation.calendar.${id}`);
  };

  return (
    <div className="employee-calendar-sort">
      <Select onChange={handleChange} variant={InputVariant.White}>
        {Object.values(EmployeeAllocationsOrder).map(id => {
          const [field, direction] = id.split("+");

          return (
            <option key={id} value={id}>
              {`${tg("sort." + field)} - ${tg("direction." + direction)}`}
            </option>
          );
        })}
      </Select>
    </div>
  );
};

export default EmployeeSort;
