import ResourceComponent from "@component/ResourceComponent";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateProps<TModel extends models.IBase, TCreateModel extends ViewModel.Create<TModel>> {}

export default abstract class Create<
  TModel extends models.IBase,
  TViewModel extends ViewModel.Create<TModel>,
  OtherProps = {},
  TState = {}
> extends ResourceComponent<CreateProps<TModel, TViewModel> & OtherProps, TState> {
  suffix = "create";

  abstract vm: TViewModel;

  constructor(props: CreateProps<TModel, TViewModel> & OtherProps) {
    super(props);
  }

  componentWillMount() {
    this.vm.resetEntityAndErrors();
  }
}
