import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import HealthInsurance from "@model/HealthInsurance";
import HealthInsuranceCreateVM from "@vm/Create/HealthInsurance";

import HealthInsuranceForm from "./Form";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class HealthInsuranceCreate extends CreateModal<HealthInsurance> {
  modelName = "health_insurance";
  prefix = "employee";

  @lazyInject(TYPES.HealthInsuranceCreate)
  vm: HealthInsuranceCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_health_insurances(this.props.match.params.user_id));
  };

  renderModalBody() {
    return (
      <div style={{ minHeight: 370 }}>
        <HealthInsuranceForm entity={this.vm.entity} />
      </div>
    );
  }
}
