import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";
import Panel from "@eman/emankit/Panel";

import ResourceComponent from "@component/ResourceComponent";
import JobTitlesCheckVM from "@vm/Other/JobTitlesCheck";
import TYPES from "src/inversify.types";
import { lazyInject } from "src/inversify.config";
import WorkingTimeRatiosList from "./List";

@observer
export default class WorkingTimeRatios extends ResourceComponent<PropsFromRouter> {
  modelName = "working_time_ratios";
  prefix = "employee.contract";

  @lazyInject(TYPES.JobTitlesCheck)
  vm: JobTitlesCheckVM;

  componentDidMount() {
    const params = this.props.match.params;
    this.vm.init(params.user_id, params.contract_id);
  }

  onAddClick = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.new_employees_contracts_job_titles(params.user_id, params.contract_id));
  };

  renderButton() {
    if (!this.vm.canChangeJobTitle) {
      return null;
    }

    return (
      <Button variant={ButtonVariant.Active} onClick={this.onAddClick}>
        {this.tg("add")}
      </Button>
    );
  }

  render() {
    return (
      <Panel>
        <HeaderRow title={this.tg("title")} level={2} />
        <WorkingTimeRatiosList match={this.props.match} />
      </Panel>
    );
  }
}
