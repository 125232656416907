import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import { confirmYesNo } from "@eman/emankit/ConfirmAlert";
import EmployeeCard from "@eman/emankit/EmployeeCard";
import Modal from "@eman/emankit/Modal";
import Toolbar from "@eman/emankit/Toolbar";
import Uploader from "@eman/emankit/Uploader";

import BaseShow from "@component/Show";
import { JobTitleState } from "@model/JobTitle";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import User from "@model/User";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import ViewHelpers from "@util/ViewHelpers";
import UserShowVM from "@vm/Show/User";

import { lazyInject, TYPES } from "../../inversify.config";

export interface EmployeeShowProps {
  managePhoto?: boolean;
}

export interface EmployeeShowState {
  modalOpen?: boolean;
}

@observer
export default class EmployeeShow extends BaseShow<User, UserShowVM, EmployeeShowProps, EmployeeShowState> {
  modelName = "user";

  state = {
    modalOpen: false,
  };

  get nameSpace(): string {
    return "employee";
  }

  @lazyInject(TYPES.UserShow)
  vm: UserShowVM;

  componentWillUnmount() {
    super.componentWillUnmount();
    this.vm.dispose();
  }

  shortInfo = (): string => {
    const contract = this.vm.entity.main_contract;

    if (contract) {
      this.enums.assignObjectEnum(contract);
      const contractType = contract.contract_type ? contract.contract_type.code : "";
      const jobTitles = contract.job_titles
        .filter(item => item.state === JobTitleState.ACTIVE)
        .map(item => item.job_position.name);
      return `${jobTitles.length ? jobTitles.join(", ").concat(", ") : ""}${contractType} | ${contract.company.name}, ${
        contract.branch_office?.name ?? ""
      }`;
    }

    return "";
  };

  onFileSelect = async (files: File[]) => {
    const status = await this.vm.uploadAvatar(files);

    if (status) {
      this.toggleModal();
      EventBus.trigger(SHOW_TOAST, this.tg("header.avatar.changed"));
    }
  };

  onDeleteClick = async () => {
    confirmYesNo(
      this.tg("header.avatar.delete_confirm"),
      async () => {
        const status = await this.vm.deleteAvatar();

        if (status) {
          EventBus.trigger(SHOW_TOAST, this.tg("header.avatar.deleted"));
        }
      },
      { confirmButtonType: ButtonVariant.SecondaryBlue, cancelButtonType: ButtonVariant.Primary }
    );
  };

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  renderCardContent = (): React.ReactNode => {
    if (!this.props.managePhoto || !this.user.allowToObject(UserRightsObjects.USER, UserRightsOperations.EDIT)) {
      return null;
    }

    return (
      <Toolbar>
        <Button small={true} onClick={this.toggleModal}>
          {this.tg("header.avatar.change")}
        </Button>
        {!this.vm.entity.avatar_deleted && (
          <Button small={true} onClick={this.onDeleteClick} variant={ButtonVariant.Dangerous}>
            {this.tg("header.avatar.delete")}
          </Button>
        )}
      </Toolbar>
    );
  };

  render() {
    const { entity } = this.vm;
    const { children } = this.props;

    if (!entity || !entity.id) {
      return <div style={{ height: 50 }} />;
    }

    // tslint:disable-next-line: no-nested-template-literals
    const name = `${entity.first_name}${entity.middle_name ? ` ${entity.middle_name}` : ""} ${entity.last_name}`;

    return (
      <>
        <div className="row mb-20">
          <div className={children ? "col-md-8" : "col-12"}>
            <EmployeeCard
              imageUrl={this.vm.avatar}
              withImage={true}
              degreeBefore1={entity.degree_before1}
              degreeBefore2={entity.degree_before2}
              degreeAfter={entity.degree_after}
              name={name}
              shortInfo={this.shortInfo()}
              badge={entity.state ? this.tg(`state.${entity.state}`) : undefined}
              badgeColor={entity.state ? ViewHelpers.userStateToColor(entity.state) : undefined}>
              {this.renderCardContent()}
            </EmployeeCard>
          </div>
          {children && (
            <div className="col-md-4" style={{ display: "flex", justifyContent: "flex-end" }}>
              {children}
            </div>
          )}
        </div>
        <Modal open={this.state.modalOpen} onClose={this.toggleModal}>
          <Uploader
            localization={this.locs.uploader}
            allowedTypes={["image/png", "image/jpeg", "image/jpg"]}
            showFiles={true}
            onDrop={this.onFileSelect}
            multiple={false}
            files={[]}
          />
        </Modal>
      </>
    );
  }
}
