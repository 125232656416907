import { Transform, Type } from "@eman/class-transformer";
import BaseModel from "@model/BaseModel";
import { enumValue } from "@util/Enumerable";
import { observable } from "mobx";

export default class Dependent extends BaseModel {
  @observable name: string;
  @observable identification: string;
  @observable household: boolean;
  @observable enumeration_relationship_id: number;
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  birth_date: Date;

  @enumValue("relationships", "enumeration_relationship_id")
  @observable
  relationship: models.IEnumType;
}
