import JobTitleFormVM from "@vm/Form/JobTitle";
import { inject, injectable } from "inversify";

import JobTitle from "@model/JobTitle";
import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class JobTitleEditVM extends AssociatedEditViewModel<JobTitle, AssociatedRepository<JobTitle>> {
  constructor(
    @inject(TYPES.JobTitleRepository) repository: AssociatedRepository<JobTitle>,
    @inject(TYPES.JobTitleForm) public jobTitleFormVM: JobTitleFormVM
  ) {
    super(JobTitle, repository);
  }

  setEntity(entity: JobTitle) {
    super.setEntity(entity);
    this.jobTitleFormVM.setEntity(entity);
  }
}
