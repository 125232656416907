import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import Certification from "@model/Certification";
import CertificationShowVM from "@vm/Show/Certification";
import { lazyInject, TYPES } from "../../../inversify.config";

import CertificationForm from "./Form";

@observer
export default class CertificationShowModal extends ShowModal<Certification> {
  modelName = "certification";
  prefix = "employee";

  @lazyInject(TYPES.CertificationShow)
  vm: CertificationShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_certifications(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <>
        <CertificationForm entity={this.vm.entity} readonly={true} />
      </>
    );
  }
}
