import { inject, injectable } from "inversify";

import Vacation from "@model/Vacation";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class VacationShowVM extends AssociatedShowViewModel<Vacation, AssociatedRepository<Vacation>> {
  constructor(@inject(TYPES.VacationRepository) repository: AssociatedRepository<Vacation>) {
    super(Vacation, repository);
  }
}
