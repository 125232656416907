import { toJS } from "mobx";
import React from "react";

import DataBody, { AlignType, ColumnType, SortOrder } from "@eman/emankit/DataTable/DataBody";
import { FilterBarProps } from "@eman/emankit/FilterBar";
import Pagination, { PaginationProps } from "@eman/emankit/Pagination";

import ResourceComponent from "@component/ResourceComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import { ReactSmartScroller } from "react-smart-scroller";
import "./style.scss";

/**
 * Abstract base list for small list
 */
export default abstract class SmallList<
  TModel extends models.IBase,
  TViewModel extends ViewModel.List<TModel>,
  TProps = {},
  TState = {}
> extends ResourceComponent<TProps, TState> {
  autoInitList = true;
  abstract vm: TViewModel;

  abstract renderRow: (item: TModel, column: string) => React.ReactNode;
  abstract rows(): ColumnType[];

  componentDidMount(): void {
    this.vm.loading = false;
    if (this.autoInitList) {
      this.initVM();
    }
  }

  initVM() {
    this.vm.init();
  }

  /**
   * Overload in child if you need click action
   * @param item
   */
  onClick = (item: TModel): void => {
    // nothing to do
  };

  /**
   * Helper method for generate router link based on rights
   */
  objectEditOrShowLink = (methodName: string, params: Array<string | number>, queryParams?: any) => {
    if (this.user.allowToObject(this.modelName as UserRightsObjects, UserRightsOperations.EDIT)) {
      const method = `edit_${methodName}`;
      this.router.pageLink(this.uriHelper[method](...params), queryParams);
    } else if (this.user.allowToObject(this.modelName as UserRightsObjects, UserRightsOperations.SHOW)) {
      const method = `show_${methodName}`;
      this.router.pageLink(this.uriHelper[method](...params));
    }
  };

  /**
   * Overload in child if you need filters
   */
  filterProps = (): FilterData[] => {
    return [];
  };

  onSortChange = (sort: SortOrder): void => {
    this.vm.setOrder({ field: sort.key, direction: sort.sort });
  };

  createField(
    id: string,
    {
      label,
      nosort = false,
      align = AlignType.Left,
      alwaysShow = false,
      hideLabel = false,
      childrenOpener = false,
      width,
      childrenSelector,
      className,
    }: {
      label?: string;
      nosort?: boolean;
      align?: AlignType;
      alwaysShow?: boolean;
      hideLabel?: boolean;
      width?: number;
      childrenOpener?: boolean;
      childrenSelector?: string;
      className?: string;
    }
  ) {
    return {
      id,
      label: label || this.ta(id),
      nosort,
      alwaysShow,
      hideLabel,
      align,
      width,
      childrenOpener,
      childrenSelector,
      className,
    };
  }

  /**
   * More items to render above the table?
   */
  otherRender(): React.ReactNode {
    return null;
  }

  onFilterChange = (values: FilterValues, visible: string[], reset: boolean) => {
    if (reset) {
      this.vm.setSearchValue(undefined);
    }

    this.vm.setFilters(values, visible, undefined, true);
  };

  filterBarProps(): FilterBarProps {
    return {
      filters: this.filterProps(),
      localization: this.locs.filters,
      selected: toJS(this.vm.selectedItems),
      visible: this.vm.visibleFilters,
      onFilterChange: this.onFilterChange,
    };
  }

  paginationProps(): PaginationProps {
    return {
      pageSize: this.vm.pagination.pageSize,
      page: this.vm.pagination.page,
      totalRecords: this.vm.total,
      onPageChange: this.vm.setPageAndPageSize,
      hideNext: true,
      hidePrevious: true,
      hidePageSize: true,
    };
  }

  /**
   * Render table with pagination.
   */
  render(): React.ReactNode {
    if (!this.vm) {
      return null;
    }

    const rows = this.rows();

    return (
      <React.Fragment>
        {this.otherRender()}
        <div className={`emankit__data_table_container`}>
          <ReactSmartScroller
            thumb={
              <div
                style={{
                  width: 100,
                  height: 10,
                  backgroundColor: "#1f3b80",
                }}
              />
            }>
            <DataBody
              columns={rows}
              selectedColumns={rows.map((value: ColumnType) => value.id)}
              data={this.vm.list}
              loading={this.vm.currentlyFetching}
              sortBy={
                this.vm.order
                  ? {
                      key: this.vm.order.field,
                      sort: this.vm.order.direction,
                    }
                  : undefined
              }
              value={this.renderRow}
              onClick={this.onClick}
              onSortChange={this.onSortChange}
              filterBarProps={this.filterBarProps()}
              withSidebar={true}
            />
          </ReactSmartScroller>
        </div>
        <br />
        <Pagination {...this.paginationProps()} />
      </React.Fragment>
    );
  }
}
