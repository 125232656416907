import RateItem from "@model/RateItem";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

export default class RateItemRepository extends BaseAssociatedRepository<RateItem> {
  constructor() {
    super(RateItem, "users/$ID/rates/$RATE_ID/rate_items", "rate_item");
  }

  setId(id: string | number, rateId: number) {
    this.id = id;
    this.uri = this.uriBackup.replace("$ID", id.toString()).replace("$RATE_ID", rateId.toString());
  }
}
