import { inject, injectable } from "inversify";

import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
import Training from "@model/Training";
import EventBus, { SCROLL_TOP } from "@util/EventBus";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class TrainingListVM extends AssociatedListViewModel<Training, AssociatedRepositoryWithConfirm<Training>> {
  static defaults = {
    order: {
      field: "created_at",
      direction: "desc",
    },
    columns: ["url", "training_type", "supervisor_confirmation", "user_confirmation", "created_at", "supervisor", "user"],
  };

  constructor(@inject(TYPES.TrainingRepository) repository: AssociatedRepositoryWithConfirm<Training>) {
    super(repository);
  }

  async confirmTraining(id: number) {
    this.currentlyFetching = true;

    const response = await this.repository.confirmTraining(id, this.parentId);

    if (!response.status && response.errors && response.errors.constructor === Object) {
      this.entity.setErrors(response.errors);
      EventBus.trigger(SCROLL_TOP);
    }

    this.currentlyFetching = false;

    return response.status;
  }
}
