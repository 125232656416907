import { observer } from "mobx-react";
import React from "react";

import Input from "@eman/emankit/Input";

import BindingElement, { BindingProps } from "./index";

@observer
export default class PasswordBox extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setValue(e.target.value);
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const { skipDirty, onValueChanged, formatValue, parseValue, ...others } = this.props;

    return <Input {...others} type="password" onChange={this.handleValueChanged} value={this.value || ""} />;
  }
}
