import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import { IconType } from "@eman/emankit/Icon";

import BaseList from "@component/BaseList";
import BranchOffice from "@model/BranchOffice";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import BranchOfficeListVM from "@vm/List/BranchOffice";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class BranchOfficeList extends BaseList<BranchOffice, BranchOfficeListVM, PropsFromRouter> {
  searchable = false;
  modelName = "branch_office";
  prefix = "employer.organization_structure";

  @lazyInject(TYPES.BranchOfficeList)
  vm: BranchOfficeListVM;

  initVM() {
    this.vm.init(this.props.match.params.organization_structure_id);
  }

  onAddClick = () => {
    this.router.pageLink(
      this.uriHelper.new_employer_organization_structures_branch_offices(this.props.match.params.organization_structure_id)
    );
  };

  headerProps() {
    return {
      text: this.tg("title"),
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.BRANCH_OFFICE, UserRightsOperations.CREATE)) {
          return (
            <Button icon={IconType.Plus} variant={ButtonVariant.Primary} onClick={this.onAddClick}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "name",
        label: this.ta("name"),
        type: "search",
      },
    ];

    return {
      filters,
    };
  }

  dataProps() {
    const columns = [this.createField("name", {})];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: BranchOffice) => {
        this.objectEditOrShowLink("employer_organization_structures_branch_offices", [
          this.props.match.params.organization_structure_id,
          item.id,
        ]);
      },
    };
  }
}
