import { inject, injectable, postConstruct } from "inversify";
import { action, computed, runInAction } from "mobx";

import User from "@model/User";
import EventBus, { AVATAR_UPLOADED, ENTITY_CREATED, ENTITY_DELETED, ENTITY_UPDATE, IDisposable } from "@util/EventBus";
import AvatarService from "@service/Avatar";
import ShowViewModel from "./ShowViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;
void inject;

@injectable()
export default class UserShowVM extends ShowViewModel<User, IUserRepository<User>> {
  private readonly eventHandlers: IDisposable[] = [];

  constructor(
    @inject(TYPES.UserRepository) repository: IUserRepository<User>,
    @inject(TYPES.Avatar) private avatarService: AvatarService
  ) {
    super(User, repository);
  }

  @postConstruct()
  listenEventBus() {
    this.eventHandlers.push(EventBus.on(ENTITY_CREATED, this.entityCallback));
    this.eventHandlers.push(EventBus.on(ENTITY_UPDATE, this.entityCallback));
    this.eventHandlers.push(EventBus.on(ENTITY_DELETED, this.entityCallback));
  }

  dispose() {
    // Don't dispose this, this class is singleton.
    // this.eventHandlers.forEach(x => x.dispose());
  }

  /**
   * User header contains information about User entity and main contract with job position
   * @param params
   */
  entityCallback = (params: any) => {
    if (this.id < 0) {
      return; // New record
    }

    if ([this.repository.classModelName, "contract", "job_title"].indexOf(params?.identificator) !== -1) {
      this.fetchItem(false);
    }
  };

  setEntity(entity: User) {
    super.setEntity(entity);
  }

  @computed
  get avatar(): string | undefined {
    return this.avatarService.getUrl(this.entity.id!, this.entity.last_avatar_updated_at);
  }

  @action
  async uploadAvatar(files: File[]): Promise<boolean> {
    await this.avatarService.uploadAvatar(this.entity.id!, files);
    await this.reloadAvatar();
    return true;
  }

  @action
  async deleteAvatar(): Promise<boolean> {
    await this.avatarService.deleteAvatar(this.entity.id!);
    await this.reloadAvatar();
    return true;
  }

  async reloadAvatar(): Promise<boolean> {
    const item = await this.repository.show(this.entity.id!, false);

    runInAction(() => {
      this.entity.last_avatar_updated_at = item.entity?.last_avatar_updated_at || new Date();
      this.entity.avatar_deleted = item.entity?.avatar_deleted || false;
    });

    EventBus.trigger(AVATAR_UPLOADED, { id: this.entity.id! });

    return true;
  }
}
