import { inject, injectable } from "inversify";

import Address from "@model/Address";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class AddressCreateVM extends AssociatedCreateViewModel<Address, AssociatedRepository<Address>> {
  constructor(@inject(TYPES.AddressRepository) repository: AssociatedRepository<Address>) {
    super(Address, repository);
  }
}
