import ContractsVM from "@vm/Other/Contracts";
import { inject, injectable } from "inversify";
import { computed, IReactionDisposer, reaction } from "mobx";

import Contract from "@model/Contract";
import EvidenceState, { EvidenceStateStatus } from "@model/EvidenceState";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
import User from "@service/CurrentUser";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class EvidenceStateListVM
  extends AssociatedListViewModel<EvidenceState, AssociatedRepository<EvidenceState>>
  implements ViewModel.WithReactions {
  static defaults = {
    order: {
      field: "valid_from",
      direction: "desc",
    },
    columns: [
      "in_state",
      "valid_from",
      "valid_to",
      "enumeration_evidence_state_reason_id",
      "description",
      "expected_to",
      "state",
    ],
  };

  private contractChange: IReactionDisposer;

  constructor(
    @inject(TYPES.EvidenceStateRepository) repository: AssociatedRepository<EvidenceState>,
    @inject(TYPES.Contracts) private contractsVM: ContractsVM,
    @inject(TYPES.User) private user: User
  ) {
    super(repository);
    this.scroll = false;
    this.save = false;
  }

  async init(parentId?: number) {
    this.turnOnReactions();
    this.setFilters({}, undefined, true);

    return super.init(parentId);
  }

  @computed
  get canChange(): boolean {
    if (!this.user.allowToObject(UserRightsObjects.EVIDENCE_STATE, UserRightsOperations.CREATE)) {
      return false;
    }

    const states = this.list.map(state => state.evidence_status);
    return states.includes(EvidenceStateStatus.ACTIVED);
  }

  /**
   * React on contract change - we need filter list by current contract id
   */
  turnOnReactions() {
    this.contractChange = reaction(
      () => this.contractsVM.currentContract,
      (currentContract: Contract | undefined) => {
        if (currentContract && this.selectedItems.contract_id && currentContract.id !== this.selectedItems.contract_id.values) {
          this.selectedItems.contract_id = {
            operator: "in" as FilterOperator,
            values: this.contractsVM.contractId,
          };
          this.fetchList();
        }
      }
    );
  }

  turnOffReactions() {
    this.contractChange();
  }

  /**
   * Contract_id is pernament filter
   */
  // tslint:disable-next-line: bool-param-default
  setFilters(filters: FilterValues, visible?: string[], skipFetching?: boolean, save?: boolean) {
    const filtersWithContract: FilterValues = {
      ...filters,
      contract_id: {
        operator: "in",
        values: this.contractsVM.contractId,
      },
    };

    return super.setFilters(filtersWithContract, visible, skipFetching, save);
  }
}
