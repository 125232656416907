import subYears from "date-fns/subYears";
import { computed, observable } from "mobx";
import { inject, injectable } from "inversify";
import UserShowVM from "@vm/Show/User";
import FormViewModel from "@vm/Form/FormViewModel";

import TYPES from "../../inversify.types";
import PersonalDocument from "@model/PersonalDocument";
import { addYears } from "date-fns";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class PersonalDocumentFormVM extends FormViewModel<PersonalDocument> {
  constructor(@inject(TYPES.UserShow) private userShowVM: UserShowVM) {
    super();
  }

  @observable public fromBirth = false;

  @computed
  get fromDate(): Date {
    if (this.fromBirth) {
      return this.userShowVM.entity?.personal_detail?.birth_date || subYears(new Date(), 100);
    }
    return subYears(new Date(), 10);
  }

  @computed
  get toDate(): Date {
    if (this.fromBirth) {
      return this.userShowVM.entity?.personal_detail?.birth_date || subYears(new Date(), 100);
    }
    return addYears(new Date(), 10);
  }
}
