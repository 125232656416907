import "reflect-metadata";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unsafe-return */

import { createBrowserHistory } from "history";
import { Container, inject, injectable, interfaces } from "inversify";

// Types
import TYPES from "./inversify.types";
void TYPES;
void inject;

// Services
import AvatarService from "@service/Avatar";
import Breadcrumb from "@service/Breadcrumb";
import CurrentUserService from "@service/CurrentUser";
import Enum from "@service/Enum";
import Localization from "@service/Localization";
import NotificationService from "@service/Notification";
import Router from "@service/Router";
import Websocket from "@service/Websocket";

// Models
import Address from "@model/Address";
import Allocation from "@model/Allocation";
import AllocationItem from "@model/AllocationItem";
import AllocationItemGroup from "@model/AllocationItemGroup";
import AllocationUser from "@model/AllocationUser";
import Announcement from "@model/Announcement";
import Balance from "@model/Balance";
import BalanceGroup from "@model/BalanceGroup";
import BranchOffice from "@model/BranchOffice";
import Capacity from "@model/Capacity";
import ChangesHistory from "@model/ChangesHistory";
import Company from "@model/Company";
import Contact from "@model/Contact";
import Contract from "@model/Contract";
import CurrentUser from "@model/CurrentUser";
import Dependent from "@model/Dependent";
import Employee from "@model/Employee";
import EvidenceState from "@model/EvidenceState";
import Export from "@model/Export";
import HealthInsurance from "@model/HealthInsurance";
import JobPosition from "@model/JobPosition";
import JobTitle from "@model/JobTitle";
import Notification from "@model/Notification";
import OrganizationUnit from "@model/OrganizationUnit";
import PaymentInfo from "@model/PaymentInfo";
import PersonalDocument from "@model/PersonalDocument";
import PreviousEmployment from "@model/PreviousEmployment";
import Project from "@model/Project";
import Rate from "@model/Rate";
import RateItem from "@model/RateItem";
import Role from "@model/Role";
import School from "@model/School";
import User from "@model/User";
import Note from "@model/Note";
import Training from "@model/Training";
import Vacation from "@model/Vacation";

// Repositories
import AddressRepository from "@repository/Address";
import AllocationRepository from "@repository/Allocation";
import AllocationItemRepository from "@repository/AllocationItem";
import AllocationItemGroupRepository from "@repository/AllocationItemGroup";
import AllocationUserRepository from "@repository/AllocationUser";
import AnnouncementRepository from "@repository/Announcement";
import BalanceRepository from "@repository/Balance";
import BalanceGroupRepository from "@repository/BalanceGroup";
import BranchOfficeRepository from "@repository/BranchOffice";
import CapacityRepository from "@repository/Capacity";
import CompanyRepository from "@repository/Company";
import ContactRepository from "@repository/Contact";
import ContractRepository from "@repository/Contract";
import CurrentUserRepository from "@repository/CurrentUser";
import DependentRepository from "@repository/Dependent";
import EmployeeRepository from "@repository/Employee";
import EnumRepository from "@repository/Enum";
import EvidenceStateRepository from "@repository/EvidenceState";
import ExportRepository from "@repository/Export";
import HealthInsuranceRepository from "@repository/HealthInsurance";
import JobPositionRepository from "@repository/JobPosition";
import JobTitleRepository from "@repository/JobTitle";
import NotificationRepository from "@repository/Notification";
import OrganizationStructureRepository from "@repository/OrganizationStructure";
import OrganizationUnitRepository from "@repository/OrganizationUnit";
import ChangesHistoryRepository from "@repository/ChangesHistory";
import PaymentInfoRepository from "@repository/PaymentInfo";
import PersonalDocumentRepository from "@repository/PersonalDocument";
import PreviousEmploymentRepository from "@repository/PreviousEmployment";
import ProjectRepository from "@repository/Project";
import RateRepository from "@repository/Rate";
import RateItemRepository from "@repository/RateItem";
import RoleRepository from "@repository/Role";
import SchoolRepository from "@repository/School";
import UserRepository from "@repository/User";
import NoteRepository from "@repository/Note";
import TrainingRepository from "@repository/Training";
import VacationRepository from "@repository/Vacation";
import UserTimeRatioRepository from "@repository/UserTimeRatioRepository";

// VMs

// Items VMs
import AllocationUserItemsVM from "@vm/Items/AllocationUser";

// List VMs
import AddressListVM from "@vm/List/Address";
import AnnouncementListVM from "@vm/List/Announcement";
import AllocationListVM from "@vm/List/Allocation";
import AllocationItemListVM from "@vm/List/AllocationItem";
import AllocationItemGroupListVM from "@vm/List/AllocationItemGroup";
import BalanceListVM from "@vm/List/Balance";
import BalanceGroupListVM from "@vm/List/BalanceGroup";
import BranchOfficeListVM from "@vm/List/BranchOffice";
import CapacityListVM from "@vm/List/Capacity";
import ContactListVM from "@vm/List/Contact";
import ContractListVM from "@vm/List/Contract";
import DependentListVM from "@vm/List/Dependent";
import EmployeeListVM from "@vm/List/Employee";
import EnumListVM from "@vm/List/Enum";
import EnumContainerListVM from "@vm/List/EnumContainer";
import EvidenceStateListVM from "@vm/List/EvidenceState";
import ExportListVM from "@vm/List/Export";
import HealthInsuranceListVM from "@vm/List/HealthInsurance";
import JobPositionListVM from "@vm/List/JobPosition";
import JobTitleListVM from "@vm/List/JobTitle";
import OrganizationStructureListVM from "@vm/List/OrganizationStructure";
import PaymentInfoListVM from "@vm/List/PaymentInfo";
import PersonalDocumentListVM from "@vm/List/PersonalDocument";
import PreviousEmploymentListVM from "@vm/List/PreviousEmployment";
import ProjectListVM from "@vm/List/Project";
import RateListVM from "@vm/List/Rate";
import RoleListVM from "@vm/List/Role";
import SchoolListVM from "@vm/List/School";
import UserListVM from "@vm/List/User";
import NoteListVM from "@vm/List/Note";
import TrainingListVM from "@vm/List/Training";
import VacationListVM from "@vm/List/Vacation";
import WorkingTimeRatiosListVM from "@vm/List/WorkingTimeRatios";

// Create VMs
import AddressCreateVM from "@vm/Create/Address";
import AllocationCreateVM from "@vm/Create/Allocation";
import AnnouncementCreateVM from "@vm/Create/Announcement";
import BranchOfficeCreateVM from "@vm/Create/BranchOffice";
import CapacityCreateVM from "@vm/Create/Capacity";
import CompanyCreateVM from "@vm/Create/Company";
import ContactCreateVM from "@vm/Create/Contact";
import ContractCreateVM from "@vm/Create/Contract";
import DependentCreateVM from "@vm/Create/Dependent";
import EnumCreateVM from "@vm/Create/Enum";
import ExportCreateVM from "@vm/Create/Export";
import EvidenceStateCreateVM from "@vm/Create/EvidenceState";
import HealthInsuranceCreateVM from "@vm/Create/HealthInsurance";
import JobPositionCreateVM from "@vm/Create/JobPosition";
import JobTitleCreateVM from "@vm/Create/JobTitle";
import OrganizationStructureCreateVM from "@vm/Create/OrganizationStructure";
import OrganizationUnitCreateVM from "@vm/Create/OrganizationUnit";
import PaymentCreateVM from "@vm/Create/PaymentInfo";
import PersonalDocumentCreateVM from "@vm/Create/PersonalDocument";
import PreviousEmploymentCreateVM from "@vm/Create/PreviousEmployment";
import RateCreateVM from "@vm/Create/Rate";
import RoleCreateVM from "@vm/Create/Role";
import SchoolCreateVM from "@vm/Create/School";
import UserCreateVM from "@vm/Create/User";
import NoteCreateVM from "@vm/Create/Note";
import TrainingCreateVM from "@vm/Create/Training";

// Edit List VMs
import RateItemsEditListVM from "@vm/EditList/RateItems";

// Edit VMs
import AddressEditVM from "@vm/Edit/Address";
import AllocationEditVM from "@vm/Edit/Allocation";
import BranchOfficeEditVM from "@vm/Edit/BranchOffice";
import CapacityEditVM from "@vm/Edit/Capacity";
import ContactEditVM from "@vm/Edit/Contact";
import ContractEditVM from "@vm/Edit/Contract";
import DependentEditVM from "@vm/Edit/Dependent";
import EnumEditVM from "@vm/Edit/Enum";
import HealthInsuranceEditVM from "@vm/Edit/HealthInsurance";
import JobPositionEditVM from "@vm/Edit/JobPosition";
import JobTitleEditVM from "@vm/Edit/JobTitle";
import OrganizationStructureEditVM from "@vm/Edit/OrganizationStructure";
import PaymentInfoEditVM from "@vm/Edit/PaymentInfo";
import PersonalDocumentEditVM from "@vm/Edit/PersonalDocument";
import PreviousEmploymentEditVM from "@vm/Edit/PreviousEmployment";
import RateEditVM from "@vm/Edit/Rate";
import RoleEditVM from "@vm/Edit/Role";
import SchoolEditVM from "@vm/Edit/School";
import UserEditVM from "@vm/Edit/User";
import NoteEditVM from "@vm/Edit/Note";
import TrainingEditVM from "@vm/Edit/Training";
import WorkingTimeRatiosEditVM from "@vm/Edit/WorkingTimeRatios";

// Show VMs
import AddressShowVM from "@vm/Show/Address";
import AnnouncementShowVM from "@vm/Show/Announcement";
import BranchOfficeShowVM from "@vm/Show/BranchOffice";
import CapacityShowVM from "@vm/Show/Capacity";
import ContactShowVM from "@vm/Show/Contact";
import DependentShowVM from "@vm/Show/Dependent";
import EnumContainerShowVM from "@vm/Show/EnumContainer";
import EvidenceStateShowVM from "@vm/Show/EvidenceState";
import HealthInsuranceShowVM from "@vm/Show/HealthInsurance";
import JobPositionShowVM from "@vm/Show/JobPosition";
import JobTitleShowVM from "@vm/Show/JobTitle";
import OrganizationStructureShowVM from "@vm/Show/OrganizationStructure";
import PaymentInfoShowVM from "@vm/Show/PaymentInfo";
import PersonalDocumentShowVM from "@vm/Show/PersonalDocument";
import PreviousEmploymentShowVM from "@vm/Show/PreviousEmployment";
import RateShowVM from "@vm/Show/Rate";
import SchoolShowVM from "@vm/Show/School";
import UserShowVM from "@vm/Show/User";
import NoteShowVM from "@vm/Show/Note";
import TrainingShowVM from "@vm/Show/Training";
import VacationShowVM from "@vm/Show/Vacation";

// Form VMs
import AllocationFormVM from "@vm/Form/Allocation";
import CapacityFormVM from "@vm/Form/Capacity";
import ContractFormVM from "@vm/Form/Contract";
import JobTitleFormVM from "@vm/Form/JobTitle";
import PersonalDocumentFormVM from "@vm/Form/PersonalDocument";
import PreviousEmploymentFormVM from "@vm/Form/PreviousEmployment";
import RoleFormVM from "@vm/Form/Role";
import SchoolFormVM from "@vm/Form/School";

// Other VMs
import AttachmentsVM from "@vm/Other/Attachments";
import ContractsVM from "@vm/Other/Contracts";
import MyAllocationsVM from "@vm/Other/MyAllocations";
import JobTitlesCheckVM from "@vm/Other/JobTitlesCheck";

const container = new Container();

// Uri helpers
import UriHelper from "@util/UriHelper";
import { uriHelper } from "./config";
import WorkingTimeRatioRepository from "@repository/WorkingTimeRatio";
import EmployeeAllocationsVM from "@vm/Other/EmployeeAllocations";
import AllocationJobTitle from "@model/AllocationJobTitle";
import AllocationJobTitleRepository from "@repository/AllocationJobTitle";
import WorkingTimeRatio from "@model/WorkingTimeRatio";
import JobTitlesRepository from "@repository/JobTitles";
import ExportFormVM from "@vm/Form/Export";
import VacationEditVM from "@vm/Edit/Vacation";
import Certification from "@model/Certification";
import CertificationRepository from "@repository/Certification";
import Skill from "@model/Skill";
import SkillRepository from "@repository/Skill";
import PerformanceManagementRepository from "@repository/PerformanceManagement";
import PerformanceManagement from "@model/PerformanceManagement";
import CertificationCreateVM from "@vm/Create/Certification";
import PerformanceManagementCreateVM from "@vm/Create/PerformanceManagement";
import SkillCreateVM from "@vm/Create/Skill";
import CertificationListVM from "@vm/List/Certification";
import PerformanceManagementListVM from "@vm/List/PerformanceManagement";
import SkillListVM from "@vm/List/Skill";
import CertificationShowVM from "@vm/Show/Certification";
import PerformanceManagementShowVM from "@vm/Show/PerformanceManagement";
import SkillShowVM from "@vm/Show/Skill";
import CertificationEditVM from "@vm/Edit/Certification";
import PerformanceManagementEditVM from "@vm/Edit/PerformanceManagement";
import SkillEditVM from "@vm/Edit/Skill";

// Generate router first place
const history = createBrowserHistory();
const router = new Router(history);

/**
 * !!! SORT Repositories, Services a VMs alphabetically, it prevents to merge conflicts !!!
 */

// Repositories
container.bind<Repository<Address>>(TYPES.AddressRepository).to(AddressRepository).inSingletonScope();
container.bind<Repository<Allocation>>(TYPES.AllocationRepository).to(AllocationRepository).inSingletonScope();
container.bind<Repository<AllocationItem>>(TYPES.AllocationItemRepository).to(AllocationItemRepository).inSingletonScope();
container
  .bind<Repository<AllocationItemGroup>>(TYPES.AllocationItemGroupRepository)
  .to(AllocationItemGroupRepository)
  .inSingletonScope();
container
  .bind<Repository<AllocationJobTitle>>(TYPES.AllocationJobTitleRepository)
  .to(AllocationJobTitleRepository)
  .inSingletonScope();
container.bind<Repository<AllocationUser>>(TYPES.AllocationUserRepository).to(AllocationUserRepository).inSingletonScope();
container.bind<Repository<Announcement>>(TYPES.AnnouncementRepository).to(AnnouncementRepository).inSingletonScope();
container.bind<Repository<Balance>>(TYPES.BalanceRepository).to(BalanceRepository).inSingletonScope();
container.bind<Repository<BalanceGroup>>(TYPES.BalanceGroupRepository).to(BalanceGroupRepository).inSingletonScope();
container.bind<Repository<BranchOffice>>(TYPES.BranchOfficeRepository).to(BranchOfficeRepository).inSingletonScope();
container.bind<Repository<Capacity>>(TYPES.CapacityRepository).to(CapacityRepository).inSingletonScope();
container.bind<Repository<Certification>>(TYPES.CertificationRepository).to(CertificationRepository).inSingletonScope();
container.bind<Repository<ChangesHistory>>(TYPES.ChangesHistoryRepository).to(ChangesHistoryRepository).inSingletonScope();
container.bind<Repository<Company>>(TYPES.CompanyRepository).to(CompanyRepository).inSingletonScope();
container.bind<Repository<Contact>>(TYPES.ContactRepository).to(ContactRepository).inSingletonScope();
container.bind<Repository<Contract>>(TYPES.ContractRepository).to(ContractRepository).inSingletonScope();
container.bind<ICurrentUserRepository<CurrentUser>>(TYPES.CurrentUserRepository).to(CurrentUserRepository).inSingletonScope();
container.bind<Repository<Dependent>>(TYPES.DependentRepository).to(DependentRepository).inSingletonScope();
container.bind<Repository<Employee>>(TYPES.EmployeeRepository).to(EmployeeRepository).inSingletonScope();
container.bind<IEnumRepository<models.IEnumType>>(TYPES.EnumRepository).to(EnumRepository).inSingletonScope();
container.bind<Repository<EvidenceState>>(TYPES.EvidenceStateRepository).to(EvidenceStateRepository).inSingletonScope();
container.bind<Repository<Export>>(TYPES.ExportRepository).to(ExportRepository).inSingletonScope();
container.bind<Repository<HealthInsurance>>(TYPES.HealthInsuranceRepository).to(HealthInsuranceRepository).inSingletonScope();
container.bind<Repository<JobPosition>>(TYPES.JobPositionRepository).to(JobPositionRepository).inSingletonScope();
container.bind<Repository<JobTitle>>(TYPES.JobTitleRepository).to(JobTitleRepository).inSingletonScope();
container.bind<Repository<JobTitle>>(TYPES.JobTitlesRepository).to(JobTitlesRepository).inSingletonScope();
container.bind<Repository<Notification>>(TYPES.NotificationRepository).to(NotificationRepository).inSingletonScope();
container
  .bind<Repository<models.OrganizationStructure>>(TYPES.OrganizationStructureRepository)
  .to(OrganizationStructureRepository)
  .inSingletonScope();
container.bind<Repository<OrganizationUnit>>(TYPES.OrganizationUnitRepository).to(OrganizationUnitRepository).inSingletonScope();
container.bind<Repository<PaymentInfo>>(TYPES.PaymentInfoRepository).to(PaymentInfoRepository).inSingletonScope();
container.bind<Repository<PersonalDocument>>(TYPES.PersonalDocumentRepository).to(PersonalDocumentRepository).inSingletonScope();
container
  .bind<Repository<PreviousEmployment>>(TYPES.PreviousEmploymentRepository)
  .to(PreviousEmploymentRepository)
  .inSingletonScope();
container.bind<Repository<Project>>(TYPES.ProjectRepository).to(ProjectRepository).inSingletonScope();
container.bind<Repository<Rate>>(TYPES.RateRepository).to(RateRepository).inSingletonScope();
container.bind<Repository<RateItem>>(TYPES.RateItemRepository).to(RateItemRepository).inSingletonScope();
container.bind<Repository<Role>>(TYPES.RoleRepository).to(RoleRepository).inSingletonScope();
container.bind<Repository<School>>(TYPES.SchoolRepository).to(SchoolRepository).inSingletonScope();
container.bind<Repository<User>>(TYPES.UserRepository).to(UserRepository).inSingletonScope();
container.bind<Repository<Note>>(TYPES.NoteRepository).to(NoteRepository).inSingletonScope();
container
  .bind<Repository<PerformanceManagement>>(TYPES.PerformanceManagementRepository)
  .to(PerformanceManagementRepository)
  .inSingletonScope();
container.bind<Repository<Skill>>(TYPES.SkillRepository).to(SkillRepository).inSingletonScope();
container.bind<Repository<Training>>(TYPES.TrainingRepository).to(TrainingRepository).inSingletonScope();
container.bind(TYPES.WorkingTimeRatioRepository).to(WorkingTimeRatioRepository).inSingletonScope();
container.bind<Repository<WorkingTimeRatio>>(TYPES.UserTimeRatioRepository).to(UserTimeRatioRepository).inSingletonScope();
container.bind<Repository<Vacation>>(TYPES.VacationRepository).to(VacationRepository).inSingletonScope();

// Services
container.bind<AvatarService>(TYPES.Avatar).to(AvatarService).inSingletonScope();
container.bind<Breadcrumb>(TYPES.Breadcrumb).to(Breadcrumb).inSingletonScope();
container.bind<CurrentUserService>(TYPES.User).to(CurrentUserService).inSingletonScope();
container.bind<Enum>(TYPES.Enum).to(Enum).inSingletonScope();
container.bind<Services.Localization>(TYPES.Localization).to(Localization).inSingletonScope();
container.bind<NotificationService>(TYPES.Notification).to(NotificationService).inSingletonScope();
container.bind<Router>(TYPES.Router).toConstantValue(router);
container.bind<Websocket>(TYPES.Websocket).to(Websocket).inSingletonScope();

// VMs
// Create VMs
container.bind<AddressCreateVM>(TYPES.AddressCreate).to(AddressCreateVM);
container.bind<AllocationCreateVM>(TYPES.AllocationCreate).to(AllocationCreateVM);
container.bind<AnnouncementCreateVM>(TYPES.AnnouncementCreate).to(AnnouncementCreateVM);
container.bind<BranchOfficeCreateVM>(TYPES.BranchOfficeCreate).to(BranchOfficeCreateVM);
container.bind<CapacityCreateVM>(TYPES.CapacityCreate).to(CapacityCreateVM);
container.bind<CertificationCreateVM>(TYPES.CertificationCreate).to(CertificationCreateVM);
container.bind<CompanyCreateVM>(TYPES.CompanyCreate).to(CompanyCreateVM);
container.bind<ContactCreateVM>(TYPES.ContactCreate).to(ContactCreateVM);
container.bind<ContractCreateVM>(TYPES.ContractCreate).to(ContractCreateVM);
container.bind<DependentCreateVM>(TYPES.DependentCreate).to(DependentCreateVM);
container.bind<EnumCreateVM>(TYPES.EnumCreate).to(EnumCreateVM);
container.bind<ExportCreateVM>(TYPES.ExportCreate).to(ExportCreateVM);
container.bind<EvidenceStateCreateVM>(TYPES.EvidenceStateCreate).to(EvidenceStateCreateVM);
container.bind<HealthInsuranceCreateVM>(TYPES.HealthInsuranceCreate).to(HealthInsuranceCreateVM);
container.bind<JobPositionCreateVM>(TYPES.JobPositionCreate).to(JobPositionCreateVM);
container.bind<JobTitleCreateVM>(TYPES.JobTitleCreate).to(JobTitleCreateVM);
container.bind<OrganizationStructureCreateVM>(TYPES.OrganizationStructureCreate).to(OrganizationStructureCreateVM);
container.bind<OrganizationUnitCreateVM>(TYPES.OrganizationUnitCreate).to(OrganizationUnitCreateVM);
container.bind<PaymentCreateVM>(TYPES.PaymentInfoCreate).to(PaymentCreateVM);
container.bind<PersonalDocumentCreateVM>(TYPES.PersonalDocumentCreate).to(PersonalDocumentCreateVM);
container.bind<PreviousEmploymentCreateVM>(TYPES.PreviousEmploymentCreate).to(PreviousEmploymentCreateVM);
container.bind<RateCreateVM>(TYPES.RateCreate).to(RateCreateVM);
container.bind<RoleCreateVM>(TYPES.RoleCreate).to(RoleCreateVM);
container.bind<SchoolCreateVM>(TYPES.SchoolCreate).to(SchoolCreateVM);
container.bind<UserCreateVM>(TYPES.UserCreate).to(UserCreateVM);
container.bind<NoteCreateVM>(TYPES.NoteCreate).to(NoteCreateVM);
container.bind<PerformanceManagementCreateVM>(TYPES.PerformanceManagementCreate).to(PerformanceManagementCreateVM);
container.bind<SkillCreateVM>(TYPES.SkillCreate).to(SkillCreateVM);
container.bind<TrainingCreateVM>(TYPES.TrainingCreate).to(TrainingCreateVM);

// List VMs
container.bind<AddressListVM>(TYPES.AddressList).to(AddressListVM);
container.bind<AnnouncementListVM>(TYPES.AnnouncementList).to(AnnouncementListVM);
container.bind<AllocationListVM>(TYPES.AllocationList).to(AllocationListVM);
container.bind<AllocationItemListVM>(TYPES.AllocationItemList).to(AllocationItemListVM);
container.bind<AllocationItemGroupListVM>(TYPES.AllocationItemGroupList).to(AllocationItemGroupListVM);
container.bind<BalanceListVM>(TYPES.BalanceList).to(BalanceListVM);
container.bind<BalanceGroupListVM>(TYPES.BalanceGroupList).to(BalanceGroupListVM);
container.bind<BranchOfficeListVM>(TYPES.BranchOfficeList).to(BranchOfficeListVM);
container.bind<CapacityListVM>(TYPES.CapacityList).to(CapacityListVM);
container.bind<CertificationListVM>(TYPES.CertificationList).to(CertificationListVM);
container.bind<ContactListVM>(TYPES.ContactList).to(ContactListVM);
container.bind<ContractListVM>(TYPES.ContractList).to(ContractListVM);
container.bind<DependentListVM>(TYPES.DependentList).to(DependentListVM);
container.bind<EnumListVM>(TYPES.EnumList).to(EnumListVM);
container.bind<EnumContainerListVM>(TYPES.EnumContainerList).to(EnumContainerListVM);
container.bind<EvidenceStateListVM>(TYPES.EvidenceStateList).to(EvidenceStateListVM);
container.bind<ExportListVM>(TYPES.ExportList).to(ExportListVM);
container.bind<HealthInsuranceListVM>(TYPES.HealthInsuranceList).to(HealthInsuranceListVM);
container.bind<JobPositionListVM>(TYPES.JobPositionList).to(JobPositionListVM);
container.bind<JobTitleListVM>(TYPES.JobTitleList).to(JobTitleListVM);
container.bind<OrganizationStructureListVM>(TYPES.OrganizationStructureList).to(OrganizationStructureListVM);
container.bind<PaymentInfoListVM>(TYPES.PaymentInfoList).to(PaymentInfoListVM);
container.bind<PersonalDocumentListVM>(TYPES.PersonalDocumentList).to(PersonalDocumentListVM);
container.bind<PreviousEmploymentListVM>(TYPES.PreviousEmploymentList).to(PreviousEmploymentListVM);
container.bind<ProjectListVM>(TYPES.ProjectList).to(ProjectListVM);
container.bind<RateListVM>(TYPES.RateList).to(RateListVM);
container.bind<RoleListVM>(TYPES.RoleList).to(RoleListVM);
container.bind<UserListVM>(TYPES.UserList).to(UserListVM);
container.bind<SchoolListVM>(TYPES.SchoolList).to(SchoolListVM);
container.bind<EmployeeListVM>(TYPES.EmployeeList).to(EmployeeListVM);
container.bind<NoteListVM>(TYPES.NoteList).to(NoteListVM);
container.bind<PerformanceManagementListVM>(TYPES.PerformanceManagementList).to(PerformanceManagementListVM);
container.bind<SkillListVM>(TYPES.SkillList).to(SkillListVM);
container.bind<TrainingListVM>(TYPES.TrainingList).to(TrainingListVM);
container.bind<VacationListVM>(TYPES.VacationList).to(VacationListVM);
container.bind<WorkingTimeRatiosListVM>(TYPES.WorkingTimeRatiosList).to(WorkingTimeRatiosListVM);
// Items VMs
container.bind<AllocationUserItemsVM>(TYPES.AllocationUserItems).to(AllocationUserItemsVM);

// Show VMs
container.bind<AddressShowVM>(TYPES.AddressShow).to(AddressShowVM);
container.bind<AnnouncementShowVM>(TYPES.AnnouncementShow).to(AnnouncementShowVM);
container.bind<BranchOfficeShowVM>(TYPES.BranchOfficeShow).to(BranchOfficeShowVM);
container.bind<CapacityShowVM>(TYPES.CapacityShow).to(CapacityShowVM);
container.bind<CertificationShowVM>(TYPES.CertificationShow).to(CertificationShowVM);
container.bind<ContactShowVM>(TYPES.ContactShow).to(ContactShowVM);
container.bind<DependentShowVM>(TYPES.DependentShow).to(DependentShowVM);
container.bind<EnumContainerShowVM>(TYPES.EnumContainerShow).to(EnumContainerShowVM);
container.bind<EvidenceStateShowVM>(TYPES.EvidenceStateShow).to(EvidenceStateShowVM);
container.bind<HealthInsuranceShowVM>(TYPES.HealthInsuranceShow).to(HealthInsuranceShowVM);
container.bind<JobTitleShowVM>(TYPES.JobTitleShow).to(JobTitleShowVM);
container.bind<JobPositionShowVM>(TYPES.JobPositionShow).to(JobPositionShowVM);
container.bind<OrganizationStructureShowVM>(TYPES.OrganizationStructureShow).to(OrganizationStructureShowVM);
container.bind<PaymentInfoShowVM>(TYPES.PaymentInfoShow).to(PaymentInfoShowVM);
container.bind<PersonalDocumentShowVM>(TYPES.PersonalDocumentShow).to(PersonalDocumentShowVM);
container.bind<PreviousEmploymentShowVM>(TYPES.PreviousEmploymentShow).to(PreviousEmploymentShowVM);
container.bind<RateShowVM>(TYPES.RateShow).to(RateShowVM);
container.bind<SchoolShowVM>(TYPES.SchoolShow).to(SchoolShowVM);
container.bind<UserShowVM>(TYPES.UserShow).to(UserShowVM).inSingletonScope();
container.bind<NoteShowVM>(TYPES.NoteShow).to(NoteShowVM);
container.bind<PerformanceManagementShowVM>(TYPES.PerformanceManagementShow).to(PerformanceManagementShowVM);
container.bind<SkillShowVM>(TYPES.SkillShow).to(SkillShowVM);
container.bind<TrainingShowVM>(TYPES.TrainingShow).to(TrainingShowVM);
container.bind<VacationShowVM>(TYPES.VacationShow).to(VacationShowVM);

// Edit VMs
container.bind<AddressEditVM>(TYPES.AddressEdit).to(AddressEditVM);
container.bind<AllocationEditVM>(TYPES.AllocationEdit).to(AllocationEditVM);
container.bind<BranchOfficeEditVM>(TYPES.BranchOfficeEdit).to(BranchOfficeEditVM);
container.bind<CapacityEditVM>(TYPES.CapacityEdit).to(CapacityEditVM);
container.bind<CertificationEditVM>(TYPES.CertificationEdit).to(CertificationEditVM);
container.bind<ContactEditVM>(TYPES.ContactEdit).to(ContactEditVM);
container.bind<ContractEditVM>(TYPES.ContractEdit).to(ContractEditVM);
container.bind<DependentEditVM>(TYPES.DependentEdit).to(DependentEditVM);
container.bind<EnumEditVM>(TYPES.EnumEdit).to(EnumEditVM);
container.bind<HealthInsuranceEditVM>(TYPES.HealthInsuranceEdit).to(HealthInsuranceEditVM);
container.bind<JobPositionEditVM>(TYPES.JobPositionEdit).to(JobPositionEditVM);
container.bind<JobTitleEditVM>(TYPES.JobTitleEdit).to(JobTitleEditVM);
container.bind<OrganizationStructureEditVM>(TYPES.OrganizationStructureEdit).to(OrganizationStructureEditVM);
container.bind<PaymentInfoEditVM>(TYPES.PaymentInfoEdit).to(PaymentInfoEditVM);
container.bind<PersonalDocumentEditVM>(TYPES.PersonalDocumentEdit).to(PersonalDocumentEditVM);
container.bind<PreviousEmploymentEditVM>(TYPES.PreviousEmploymentEdit).to(PreviousEmploymentEditVM);
container.bind<RateEditVM>(TYPES.RateEdit).to(RateEditVM);
container.bind<RoleEditVM>(TYPES.RoleEdit).to(RoleEditVM);
container.bind<SchoolEditVM>(TYPES.SchoolEdit).to(SchoolEditVM);
container.bind<UserEditVM>(TYPES.UserEdit).to(UserEditVM);
container.bind<NoteEditVM>(TYPES.NoteEdit).to(NoteEditVM);
container.bind<PerformanceManagementEditVM>(TYPES.PerformanceManagementEdit).to(PerformanceManagementEditVM);
container.bind<SkillEditVM>(TYPES.SkillEdit).to(SkillEditVM);
container.bind<TrainingEditVM>(TYPES.TrainingEdit).to(TrainingEditVM);
container.bind<VacationEditVM>(TYPES.VacationEdit).to(VacationEditVM);
container.bind<WorkingTimeRatiosEditVM>(TYPES.WorkingTimeRatiosEdit).to(WorkingTimeRatiosEditVM);
// EditList VMs
container.bind<RateItemsEditListVM>(TYPES.RateItemEditList).to(RateItemsEditListVM);

// Form VMs
container.bind<AllocationFormVM>(TYPES.AllocationForm).to(AllocationFormVM);
container.bind<CapacityFormVM>(TYPES.CapacityForm).to(CapacityFormVM);
container.bind<ContractFormVM>(TYPES.ContractForm).to(ContractFormVM);
container.bind<ExportFormVM>(TYPES.ExportForm).to(ExportFormVM);
container.bind<JobTitleFormVM>(TYPES.JobTitleForm).to(JobTitleFormVM);
container.bind<PreviousEmploymentFormVM>(TYPES.PreviousEmploymentForm).to(PreviousEmploymentFormVM);
container.bind<PersonalDocumentFormVM>(TYPES.PersonalDocumentForm).to(PersonalDocumentFormVM);
container.bind<RoleFormVM>(TYPES.RoleForm).to(RoleFormVM);
container.bind<SchoolFormVM>(TYPES.SchoolForm).to(SchoolFormVM);

// Other VMs
container
  .bind<interfaces.Factory<AttachmentsVM<models.IWithAttachments>>>(TYPES.Attachments)
  .toFactory<AttachmentsVM<models.IWithAttachments>>(() => {
    return (repository: any) => {
      return new AttachmentsVM(repository);
    };
  });
container.bind<ContractsVM>(TYPES.Contracts).to(ContractsVM).inSingletonScope();
container.bind<MyAllocationsVM>(TYPES.MyAllocations).to(MyAllocationsVM).inSingletonScope();
container.bind<EmployeeAllocationsVM>(TYPES.EmployeeAllocations).to(EmployeeAllocationsVM).inSingletonScope();
container.bind<JobTitlesCheckVM>(TYPES.JobTitlesCheck).to(JobTitlesCheckVM).inSingletonScope();

// Others (uri etc..
container.bind<UriHelper>(TYPES.UriHelper).toConstantValue(uriHelper);

// Check this out https://github.com/inversify/InversifyJS/issues/1026
// and https://github.com/inversify/InversifyJS/issues/1050
const INJECTION = Symbol.for("INJECTION");

function lazyInject(identifier?: any) {
  return (target: any, name: string): any => ({
    configurable: true,
    enumerable: true,
    get: () => {
      // Caching resources in metadata...
      if (!Reflect.hasMetadata(INJECTION, target, name)) {
        Reflect.defineMetadata(INJECTION, container.get(identifier), target, name);
      }
      return Reflect.getMetadata(INJECTION, target, name);
    },
    set: () => {
      return void 0;
    },
  });
}

export { container, lazyInject, TYPES, inject, injectable };
