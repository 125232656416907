import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import BranchOffice from "@model/BranchOffice";
import BranchOfficeEditVM from "@vm/Edit/BranchOffice";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class BranchOfficeEditModal extends EditModal<BranchOffice> {
  modelName = "branch_office";
  prefix = "employer.organization_structure";

  @lazyInject(TYPES.BranchOfficeEdit)
  vm: BranchOfficeEditVM;

  onClose = () => {
    this.router.pageLink(
      this.uriHelper.employer_organization_structures_branch_offices(this.props.match.params.organization_structure_id)
    );
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.organization_structure_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} />;
  }
}
