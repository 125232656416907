import { observer } from "mobx-react";
import { singular } from "pluralize-me";

import BaseList from "@component/BaseList";
import EnumContainer from "@model/EnumContainer";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import EnumerationContainerListVM from "@vm/List/EnumContainer";
import EnumContainerListVM from "@vm/List/EnumContainer";
import React from "react";

import { lazyInject, TYPES } from "../../inversify.config";

@observer
export default class EnumerationList extends BaseList<EnumContainer, EnumContainerListVM> {
  modelName = "enumeration_container";
  searchable = false;

  @lazyInject(TYPES.EnumContainerList)
  vm: EnumerationContainerListVM;

  renderRow = (item: EnumContainer, column: string): React.ReactNode => {
    if (column === "name") {
      return this.locs.tm(singular(item.name), 5);
    } else {
      return item[column];
    }
  };

  filterProps() {
    return {
      hideColumnSettings: true,
      hideFilterSettings: true,
    };
  }

  headerProps() {
    return {
      text: this.tg("title"),
    };
  }

  dataProps() {
    const columns = [this.createField("id", { nosort: true }), this.createField("name", { nosort: true })];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: EnumContainer) => {
        if (this.user.allowToObject(UserRightsObjects.ENUMERATION, UserRightsOperations.EDIT)) {
          this.router.pageLink(this.uriHelper.show_enumeration(item.id));
        }
      },
    };
  }

  paginationProps() {
    const paginationProps = super.paginationProps() as any;
    paginationProps.hidePageSize = true;

    return paginationProps;
  }
}
