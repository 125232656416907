import { observer } from "mobx-react";
import React from "react";

import Panel from "@eman/emankit/Panel";

import ChangeFooter from "@component/ChangeFooter";
import Edit from "@component/Edit";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import User from "@model/User";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import UserEditVM from "@vm/Edit/User";

import { lazyInject, TYPES } from "../../../inversify.config";
import UserForm from "../Form";
import Header from "./Header";
import PaymentInfos from "./PaymentInfos";
import PersonalDocuments from "./PersonalDocuments";
import PreviousEmployments from "./PreviousEmployments";
import Schools from "./Schools";

@observer
export default class PersonalInformation extends Edit<User, UserEditVM> {
  modelName = "user";

  @lazyInject(TYPES.UserEdit)
  vm: UserEditVM;

  updateUser = async () => {
    const response = await this.vm.update();

    if (response) {
      EventBus.trigger(SHOW_TOAST, this.locs.tg("employee.personal_information.updated"));
    }
  };

  resetChanges = () => {
    this.vm.fetchItem(false);
  };

  render() {
    const entity = this.vm.entity;

    return (
      <>
        <Header />
        <Panel>
          <UserForm
            entity={this.vm.entity}
            roleOptions={this.vm.roleOptions}
            readonly={!this.user.allowToObject(UserRightsObjects.USER, UserRightsOperations.EDIT)}
          />
          <ChangeFooter
            errorCount={entity.errorCount}
            changes={entity.changes}
            onSaveClick={this.updateUser}
            onResetClick={this.resetChanges}
          />
        </Panel>

        {this.user.allowToObject(UserRightsObjects.SCHOOL, UserRightsOperations.LIST) && (
          <Panel>
            <Schools vm={this.vm} match={this.props.match} />
          </Panel>
        )}

        {this.user.allowToObject(UserRightsObjects.PAYMENT_INFO, UserRightsOperations.LIST) && (
          <Panel>
            <PaymentInfos vm={this.vm} match={this.props.match} />
          </Panel>
        )}

        {this.user.allowToObject(UserRightsObjects.PREVIOUS_EMPLOYMENT, UserRightsOperations.LIST) && (
          <Panel>
            <PreviousEmployments vm={this.vm} match={this.props.match} />
          </Panel>
        )}

        {this.user.allowToObject(UserRightsObjects.PERSONAL_DOCUMENT, UserRightsOperations.LIST) && (
          <Panel>
            <PersonalDocuments vm={this.vm} match={this.props.match} />
          </Panel>
        )}
      </>
    );
  }
}
