import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import PaymentInfo from "@model/PaymentInfo";
import PaymentInfoCreateVM from "@vm/Create/PaymentInfo";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class PaymentInfoCreateModal extends CreateModal<PaymentInfo> {
  modelName = "payment_info";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.PaymentInfoCreate)
  vm: PaymentInfoCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  renderModalBody() {
    return (
      <div style={{ minHeight: 300 }}>
        <Form entity={this.vm.entity} />
      </div>
    );
  }
}
