import { inject, injectable } from "inversify";

import JobTitle from "@model/JobTitle";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
import EventBus, { CONTRACT_UPDATED, IDisposable } from "@util/EventBus";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class JobTitleListVM extends AssociatedListViewModel<JobTitle, AssociatedRepository<JobTitle>> {
  static defaults = {
    order: {
      field: "valid_from",
      direction: "desc",
    },
    columns: [
      "job_position",
      "organization_structure",
      "supervisor",
      "manager",
      "primary",
      "reason",
      "valid_from",
      "valid_to",
      "state",
    ],
  };

  // Current values from params
  private employeeId: number;
  private contractId?: number;

  private readonly eventHandlers: IDisposable[] = [];

  constructor(@inject(TYPES.JobTitleRepository) repository: AssociatedRepository<JobTitle>) {
    super(repository);
    this.scroll = false;
    this.save = false;
  }

  async init() {
    this.eventHandlers.push(EventBus.on(CONTRACT_UPDATED, this.fetchList));
  }

  async setId(employeeId: number, contractId?: number) {
    if (this.employeeId == employeeId && this.contractId == contractId) {
      return;
    }

    // Save values
    this.employeeId = employeeId;
    this.contractId = contractId;

    // Set repository and reload later
    this.repository.setId(employeeId);
    this.setFilters({}, undefined, false, false);
  }

  /**
   * Its called from view on componentWillUmount to prevent memory leak
   */
  dispose() {
    this.eventHandlers.forEach(x => x.dispose());
  }

  /**
   * Contract_id is pernament filter
   */
  // tslint:disable-next-line: bool-param-default
  setFilters(filters: FilterValues, visible?: string[], skipFetching?: boolean, save?: boolean) {
    const filtersWithContract: FilterValues = {
      ...filters,
      contract_id: {
        operator: "in",
        values: this.contractId,
      },
    };

    return super.setFilters(filtersWithContract, visible, skipFetching, save);
  }
}
