import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import Announcement from "@model/Announcement";
import { DatePickerBox, SelectBox, TextAreaBox } from "@util/Form";

export interface AnnounementFormProps {
  disabled?: boolean;
}

@observer
export default class AnnouncementForm extends BaseForm<Announcement, AnnounementFormProps> {
  modelName = "announcement";

  render(): any {
    const { disabled } = this.props;

    return (
      <div className="row">
        <div className="col-md-6">
          {this.generateFormField(
            "enumeration_announcement_type_id",
            <SelectBox options={this.enums.valuesForSelect("announcement_types")} emptyRow={this.locs.tg("form.select_value")} />,
            true,
            { disabled }
          )}
        </div>
        <div className="col-md-6">{this.generateFormField("valid_from", <DatePickerBox />, true, { disabled })}</div>
        <div className="col-md-12">{this.generateFormField("description", <TextAreaBox />, true, { disabled })}</div>
      </div>
    );
  }
}
