import BaseModel from "@model/BaseModel";
import { observable } from "mobx";

const NINE_AM_IN_MINUTES = 540;

export default class AllocationValidFromTime extends BaseModel {
  @observable monday = NINE_AM_IN_MINUTES;
  @observable tuesday = NINE_AM_IN_MINUTES;
  @observable wednesday = NINE_AM_IN_MINUTES;
  @observable thursday = NINE_AM_IN_MINUTES;
  @observable friday = NINE_AM_IN_MINUTES;
  @observable saturday = NINE_AM_IN_MINUTES;
  @observable sunday = NINE_AM_IN_MINUTES;
}
