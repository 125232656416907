import Contact from "@model/Contact";

import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";

import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class ContactCreateVM extends AssociatedCreateViewModel<Contact, AssociatedRepository<Contact>> {
  constructor(@inject(TYPES.ContactRepository) repository: AssociatedRepository<Contact>) {
    super(Contact, repository);
  }
}
