import { Type } from "@eman/class-transformer";
import BaseModel from "@model/BaseModel";
import { observable } from "mobx";
import User from "./User";

export default class Project extends BaseModel implements models.IGroupedModel {
  @observable name: string;
  @observable description: string;
  @observable ext_id: number;
  @observable due_date: Date;
  @observable meta?: { group: { name: string; id: number; unit: string } };
  @observable closed: boolean;

  @Type(() => User)
  @observable
  project_manager?: User;
}
