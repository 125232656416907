import Breadcrumbs from "@component/Breadcrumbs";
import { UserRightsObjects, UserRightsPages } from "@model/Rights";
import JobPositionCreateModal from "@view/Employer/JobPosition/Create";
import JobPositionEditModal from "@view/Employer/JobPosition/Edit";
import JobPositionList from "@view/Employer/JobPosition/List";
import JobPositionShowModal from "@view/Employer/JobPosition/Show";

// Config
import { EMPLOYER_SUBPAGES, PAGES, uriHelper } from "../../config";

import BaseRouter from "../Base";
import OrganizationStructure from "./OrganizationStructure";

export const employerRoutes: IAppRoute[] = [
  {
    path: uriHelper.employer_uri(),
    exact: true,
    redirect: () => uriHelper.employer_organization_structures_uri(),
  },
  {
    path: uriHelper.employer_organization_structures_uri(),
    exact: false,
    title: `${PAGES.EMPLOYER}.${EMPLOYER_SUBPAGES.ORGANIZATION_STRUCTURE}.list`,
    component: OrganizationStructure,
  },
  {
    path: uriHelper.employer_job_positions_uri(),
    exact: false,
    title: `${PAGES.EMPLOYER}.${EMPLOYER_SUBPAGES.JOB_POSITION}.list`,
    component: JobPositionList,
  },
  {
    path: uriHelper.new_employer_job_positions_uri(),
    exact: true,
    modalComponent: JobPositionCreateModal,
  },
  {
    path: uriHelper.edit_employer_job_positions_uri(),
    exact: true,
    modalComponent: JobPositionEditModal,
  },
  {
    path: uriHelper.show_employer_job_positions_uri(),
    exact: true,
    modalComponent: JobPositionShowModal,
  },
];

export default class Employer extends BaseRouter {
  render() {
    const sidebarLinks = [];

    if (this.user.allowToPage(UserRightsPages.ORGANIZATION_STRUCTURE)) {
      sidebarLinks.push({
        path: this.uriHelper.employer_organization_structures(),
        title: "employer.organization_structure.list",
      });

      sidebarLinks.push(
        {
          path: this.uriHelper.visualize_employer_organization_structures(),
          title: "employer.organization_structure.visualize",
          isChild: true,
        },
        {
          path: this.uriHelper.org_chart_employer_organization_structures(),
          title: "employer.organization_structure.org_chart",
          isChild: true,
        }
      );
    }

    if (this.user.allowToObject(UserRightsObjects.JOB_POSITION)) {
      sidebarLinks.push({
        path: this.uriHelper.employer_job_positions(),
        title: "employer.job_position.list",
      });
    }

    return (
      <>
        <div className="emankit__sidebar">{this.sidebarLinks(sidebarLinks)}</div>
        <div className="emankit__layout__content emankit__layout__content--hasSidebar">
          <Breadcrumbs />
          {this.pageRouteRender(employerRoutes)}
        </div>
      </>
    );
  }
}
