import { inject, injectable } from "inversify";

import BranchOffice from "@model/BranchOffice";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class BranchOfficeListVM extends AssociatedListViewModel<BranchOffice, AssociatedRepository<BranchOffice>> {
  static defaults = {
    columns: ["name"],
  };

  constructor(@inject(TYPES.BranchOfficeRepository) repository: AssociatedRepository<BranchOffice>) {
    super(repository);
  }
}
