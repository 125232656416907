import { OptionType } from "@eman/emankit";
import BranchOffice from "@model/BranchOffice";
import BranchOfficeRepository from "@repository/BranchOffice";
import { computed } from "mobx";
import OptionsVM from "./Options";

export default class BranchOfficeOptionsVM extends OptionsVM<BranchOffice> {
  protected repository: BranchOfficeRepository;

  async fetchItems(filters?: FilterValues) {
    this.isInitialized = false;

    const data = await this.repository.fullList();

    this.items = data.list;
    this.isInitialized = true;
  }

  @computed
  get selectOptions(): OptionType<number>[] {
    return (this.items || []).map(item => {
      return {
        value: item.id as number,
        label: this.nameProperties.map(propertyName => item[propertyName]).join(" "),
      };
    });
  }
}
