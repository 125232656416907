import { injectable } from "inversify";

import BaseRepository from "@repository/BaseRepository";
import ChangesHistory from "@model/ChangesHistory";

@injectable()
export default class ChangesHistoryRepository extends BaseRepository<ChangesHistory> {
  constructor() {
    super(ChangesHistory, "changes_history", "changes_history");
  }
}
