import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import Rate from "@model/Rate";
import RateShowVM from "@vm/Show/Rate";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class RateShowModal extends ShowModal<Rate> {
  modelName = "rate";

  @lazyInject(TYPES.RateShow)
  vm: RateShowVM;

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.show_employees_contracts(params.user_id, params.contract_id));
  };

  doNothing = () => {
    //
  };

  renderModalBody() {
    return (
      <Form
        entity={this.vm.entity}
        onAddItem={this.doNothing}
        onRemoveItem={this.doNothing}
        items={this.vm.items}
        readonly={true}
      />
    );
  }
}
