import format from "date-fns/format";
import parse from "date-fns/parse";

export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";

export const parseDate = (date: string, dateFormat?: string, baseDate?: Date): Date => {
  return parse(date, dateFormat || DEFAULT_DATE_FORMAT, baseDate || new Date());
};

export const formatDate = (date: string | Date, dateFormat?: string) => {
  let parsedDate: Date;
  if (typeof date === "string") {
    parsedDate = parseDate(date);
  } else {
    parsedDate = date;
  }

  return format(parsedDate, dateFormat || DEFAULT_DATE_FORMAT);
};
