import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import OrganizationStructure, { OrganizationStructureType } from "@model/OrganizationStructure";
import OrganizationStructureShowVM from "@vm/Show/OrganizationStructure";

import { ORGANIZATION_STRUCTURE_TYPE_PARAM } from "../../../config";

import { lazyInject, TYPES } from "../../../inversify.config";

import Form from "./Form";

@observer
export default class OrganizationStructureShowModal extends ShowModal<OrganizationStructure> {
  prefix = "employer";

  get modelName(): string {
    if (this.getType() === OrganizationStructureType.COMPANY) {
      return "company";
    } else {
      return "organization_unit";
    }
  }

  @lazyInject(TYPES.OrganizationStructureShow)
  vm: OrganizationStructureShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employer_organization_structures());
  };

  getType(): OrganizationStructureType {
    const queryParams = this.router.getQuery() || {};

    return queryParams[ORGANIZATION_STRUCTURE_TYPE_PARAM] as OrganizationStructureType;
  }

  setIdFromParams() {
    this.vm.init(this.getId(), this.getType());
  }

  renderModalBody() {
    return <Form type={this.getType()} entity={this.vm.entity} possibleParents={this.vm.possibleParents} readonly={true} />;
  }
}
