import { inject, injectable } from "inversify";

import Rate from "@model/Rate";
import RateItem from "@model/RateItem";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class RateShowVM extends AssociatedShowViewModel<Rate, AssociatedRepository<Rate>> {
  constructor(@inject(TYPES.RateRepository) repository: AssociatedRepository<Rate>) {
    super(Rate, repository);
  }

  get items(): RateItem[] {
    return this.entity.rate_items;
  }
}
