import format from "date-fns/format";
import { inject, injectable } from "inversify";
import { action, computed, observable } from "mobx";

import CurrentUser from "@model/CurrentUser";

import EventBus, { LOCALE_CHANGE } from "../Utils/EventBus";

import TYPES from "../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

import { parseDate } from "@util/DateFormats";
import LocaleProvider from "@util/LocaleProvider";

import UIKitLocaleProvider from "@eman/emankit/LocaleProvider";

/**
 * General localization service.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
@injectable()
export default class LocalizationService implements Services.Localization {
  @observable locs: LocalizeContainer = {};
  @observable private locCache: { [key: string]: string } = {};
  private loadingLocalization = false;

  // Repositories
  @inject(TYPES.CurrentUserRepository)
  private repository: ICurrentUserRepository<CurrentUser>;

  constructor() {
    EventBus.on(LOCALE_CHANGE, this.changeLocale);
  }

  async fetchLocalizations() {
    if (!this.loadingLocalization) {
      this.loadingLocalization = true;
      const locs: LocalizeContainer = await this.repository.fetchLocalization();
      this.setLocs(locs);
    }
  }

  @computed
  get isLoaded(): boolean {
    return this.locs && Object.keys(this.locs).length !== 0;
  }

  @action
  setLocs(locs: LocalizeContainer) {
    this.locs = locs;
    this.locCache = {};

    // Set UI KIT basic data.
    UIKitLocaleProvider.changeLanguage(LocaleProvider.locale, locs.general.uikit);
  }

  changeLocale = (): void => {
    this.loadingLocalization = false;
    this.fetchLocalizations();
  };

  /**
   * Translate general phrase.
   *
   * @example
   *   translateGeneral('buttons.new') // Returns 'Nový'
   *
   * @param {string} code
   * @returns {string}
   * @memberof GeneralLocalization
   */
  translateGeneral(code: string): string {
    if (!this.isLoaded) {
      this.fetchLocalizations();
      return "";
    }

    if (this.locCache[code]) {
      return this.locCache[code];
    }

    const arr = code.split(".");
    if (arr.length > 0) {
      let scope = this.locs.general;

      for (const key of arr) {
        if (scope.hasOwnProperty(key)) {
          scope = scope[key];
        } else {
          // tslint:disable-next-line:no-console
          console.warn(`[Localization] Problem with key ${code} missing ${key}.`);
          scope = code;
          break;
        }
      }

      this.locCache[code] = scope;
      return scope;
    } else {
      return this.locs.general[code];
    }
  }

  /**
   * Alias for {@see translateGeneral}.
   *
   * @param code
   */
  tg(code: string): string {
    return this.translateGeneral(code);
  }

  /**
   * Return select prompt text.
   *
   * @returns Prompt translated text
   *
   */
  get prompt(): string {
    return this.locs.general.buttons.prompt;
  }

  /**
   * Translate model name.
   *
   * @example
   *    translateModel('company', 5) // Returns "Společnosti"
   *    translateModel('company') // Returns "Společnost"
   *
   * @param name Model name
   * @param size Number of it
   */
  translateModel(name: string, size = 1): string {
    if (!this.isLoaded) {
      this.fetchLocalizations();
      return "";
    }

    let key = "other";

    if (size === 0) {
      key = "zero";
    } else if (size === 1) {
      key = "one";
    }

    let value = this.locs.models.models[name];
    if (value && value.hasOwnProperty(key)) {
      value = value[key];
    } else {
      value = name;
    }

    return value;
  }

  /**
   * Alias for {@see translateModel}.
   *
   * @param name
   * @param size
   */
  tm(name: string, size = 1): string {
    return this.translateModel(name, size);
  }

  /**
   * Translate single attribute for model.
   *
   * @example
   *    translateAttribute('company', 'ic') // Returns "ič"
   *
   * @param {string} model
   * @param {string} name
   * @returns {string}
   * @memberof GeneralLocalization
   */
  translateAttribute(model: string, name: string): string {
    if (!this.isLoaded) {
      this.fetchLocalizations();
      return "";
    }

    let translation;

    if (this.locs.models.attributes[model]) {
      translation = this.locs.models.attributes[model][name];
    }

    if (!translation && this.locs.attributes.hasOwnProperty(name)) {
      translation = this.locs.attributes[name];
    }

    return translation || name;
  }

  /**
   * Alias for {@see translateAttribute}.
   *
   * @param {string} model
   * @param {string} name
   * @returns {string}
   * @memberof GeneralLocalization
   */
  ta(model: string, name: string): string {
    return this.translateAttribute(model, name);
  }

  /**
   * Return uploader string localizations.
   */
  get uploader(): any {
    return this.locs.general.uploader;
  }

  /**
   * Return filters string localizations.
   */
  get filters(): any {
    return this.tg("uikit.datatable.filters");
  }

  /**
   * Return date in proper format according to localization.
   *
   * @param {Date | string} date
   * @returns {string}
   * @memberof LocalizationService
   */
  formatDate(date?: string | Date): string {
    let parsedDate: Date;

    if (!date) {
      return "";
    } else if (typeof date === "string") {
      parsedDate = parseDate(date);
    } else {
      parsedDate = date;
    }

    return format(parsedDate, this.tg("uikit.date_picker.format"));
  }

  /**
   * Return time in proper format according to localization.
   *
   * @param {Date | string} time
   * @returns {string}
   * @memberof LocalizationService
   */
  formatTime(time: string | Date): string {
    let parsedTime: Date;
    if (typeof time === "string") {
      parsedTime = parseDate(time);
    } else {
      parsedTime = time;
    }

    return parsedTime.toLocaleTimeString(this.tg("language_code"), { hour: "2-digit", minute: "2-digit" });
  }

  formatDateTime(date?: string | Date): string {
    return date ? `${this.formatDate(date)} ${this.formatTime(date)}` : "";
  }

  /**
   * Return number in proper format according to localization.
   *
   * @param {number} num
   * @returns {string}
   * @memberof LocalizationService
   */
  formatNumber(num: number): string {
    if (Number(num) !== num) {
      return "";
    }

    return num.toLocaleString(this.tg("language_code"));
  }

  /**
   * Return amount in proper format according to localization.
   *
   * @param {number} amount
   * @returns {string}
   * @memberof LocalizationService
   */
  formatAmount(amount: number | undefined): string {
    return amount !== undefined ? `${this.formatNumber(amount)} ${this.tg("currency")}` : "";
  }
}
