import { inject, injectable } from "inversify";

import Skill from "@model/Skill";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class SkillShowVM extends AssociatedShowViewModel<Skill, AssociatedRepository<Skill>> {
  constructor(@inject(TYPES.SkillRepository) repository: AssociatedRepository<Skill>) {
    super(Skill, repository);
  }
}
