import { injectable } from "inversify";

import HealthInsurance from "@model/HealthInsurance";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class HealthInsuranceRepository extends BaseAssociatedRepository<HealthInsurance> {
  constructor() {
    super(HealthInsurance, "users/$ID/health_insurances", "health_insurance");
  }
}
