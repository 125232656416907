import { useContext, useEffect } from "react";

import { EmployeeContext } from "../../../Router/Employees/Employee";

/**
 * (Un)Register ContractHeader component inside employee context
 */
const header = () => {
  const contextValue = useContext(EmployeeContext);

  // didMount
  useEffect(() => {
    contextValue.changeManagePhoto(true);
  }, []);

  // willUnmount
  useEffect(() => {
    return () => {
      contextValue.changeManagePhoto(false);
    };
  }, []);

  return null;
};

export default header;
