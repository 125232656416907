import { inject, injectable } from "inversify";

import AllocationUser from "@model/AllocationUser";
import TYPES from "../../inversify.types";
import ItemsViewModel from "@vm/Items/ItemsViewModel";
import { UserState } from "@model/User";

@injectable()
export default class AllocationUserItemsVM extends ItemsViewModel<AllocationUser, Repository<AllocationUser>> {
  static defaults: ItemsSettings = {
    order: {
      direction: "asc",
      field: "user_last_name",
    },
    filters: {
      user_state: { values: [UserState.ACTIVE, UserState.PROBATION, UserState.DISMISSAL, UserState.CREATED], operator: "in" },
    },
    visibleFilters: ["user_first_name", "user_last_name", "user_state", "job_title_job_position_id", "organization_unit_id"],
  };

  constructor(@inject(TYPES.AllocationUserRepository) repository: Repository<AllocationUser>) {
    super(repository);
  }
}
