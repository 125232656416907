import { inject, injectable } from "inversify";

import Role from "@model/Role";
import EditViewModel from "@vm/Edit/EditViewModel";
import RoleFormVM from "@vm/Form/Role";
import TYPES from "../../inversify.types";
import { computed, observable, toJS } from "mobx";
import { cloneDeep } from "lodash";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class RoleEditVM extends EditViewModel<Role, Repository<Role>> {
  @observable originalEntity: Role;

  constructor(@inject(TYPES.RoleRepository) repository: Repository<Role>, @inject(TYPES.RoleForm) public roleFormVM: RoleFormVM) {
    super(Role, repository);
  }

  setEntity(entity: Role) {
    super.setEntity(entity);
    this.roleFormVM.setEntity(entity);
    this.originalEntity = cloneDeep(toJS(this.entity));
  }

  @computed
  get hasRealChanges() {
    const entity = { ...this.entity.rights, isDirty: {}, oldValues: {} };
    const otherAttributes = this.originalEntity?.admin === this.entity.admin && this.originalEntity?.name === this.entity.name;
    return !otherAttributes || JSON.stringify(entity)?.length !== JSON.stringify(this.originalEntity?.rights)?.length;
  }
}
