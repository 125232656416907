import { observable } from "mobx";

import BaseModel from "@model/BaseModel";
import { enumValue } from "@util/Enumerable";

export default class Certification extends BaseModel {
  @observable user_id: string;
  @observable name: string;
  @observable enumeration_certificate_issuer_id: number;
  @observable date_from: Date;
  @observable date_to: Date;
  @observable credential_id: string;
  @observable credential_url: string;

  @enumValue("certificate_issuers", "enumeration_certificate_issuer_id")
  @observable
  certificate_issuer: models.IEnumType;
}
