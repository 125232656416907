import EvidenceState, { EvidenceStateStatus } from "@model/EvidenceState";

import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import ContractsVM from "@vm/Other/Contracts";

import { inject, injectable } from "inversify";
import { computed, IReactionDisposer, reaction, observable, action } from "mobx";
import TYPES from "../../inversify.types";
import Enum from "@service/Enum";
import EnumType from "@model/EnumType";
import addDays from "date-fns/addDays";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class EvidenceStateCreateVM extends AssociatedCreateViewModel<EvidenceState, AssociatedRepository<EvidenceState>> {
  private contractsVM: ContractsVM;
  @observable private lastEvidenceState?: EvidenceState;

  @computed
  get lastStatus(): EvidenceStateStatus | undefined {
    return this.lastEvidenceState?.evidence_status;
  }

  private reactionDisposers: IReactionDisposer[] = [];

  @observable
  selectedReason: EnumType;

  constructor(
    @inject(TYPES.EvidenceStateRepository) repository: AssociatedRepository<EvidenceState>,
    @inject(TYPES.Contracts) contractsVM: ContractsVM,
    @inject(TYPES.Enum) private enums: Enum
  ) {
    super(EvidenceState, repository);
    this.contractsVM = contractsVM;
  }

  get availableFrom() {
    return this.lastEvidenceState?.valid_from ? addDays(this.lastEvidenceState?.valid_from, 1) : undefined;
  }

  get availableExpectedTo() {
    return this.availableFrom ? addDays(this.availableFrom, 1) : undefined;
  }

  async init() {
    this.lastEvidenceState = (
      await this.repository.fetchList({
        order: { field: "valid_from", direction: "desc" },
        pagination: { page: 0, pageSize: 1 },
      })
    ).list[0];
    this.turnOnReactions();
  }

  async create(): Promise<number | undefined> {
    this.entity.contract_id = this.contractsVM.contractId!;
    return super.create();
  }

  turnOnReactions(): void {
    this.reactionDisposers.push(
      reaction(
        () => this.entity.enumeration_evidence_state_reason_id,
        evidenceStateReasonId => this.onEvidenceStateReasonChange(evidenceStateReasonId)
      )
    );
  }

  turnOffReactions(): void {
    this.reactionDisposers.forEach(disposer => disposer());
    this.reactionDisposers = [];
  }

  @action
  onEvidenceStateReasonChange = async (evidenceStateReasonId: number) => {
    this.selectedReason = this.enums.value("evidence_state_reasons", evidenceStateReasonId) as EnumType;
  };
}
