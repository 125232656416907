import { inject, injectable } from "inversify";
import { observable, runInAction, computed } from "mobx";

import OrganizationStructure from "@model/OrganizationStructure";
import ListViewModel from "@vm/List/ListViewModel";
import OptionsVM from "@vm/Other/Options";

import TYPES from "../../inversify.types";
import OrganizationUnit from "@model/OrganizationUnit";
import ChangesHistory from "@model/ChangesHistory";
import ViewHelpers from "@util/ViewHelpers";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class OrganizationStructureListVM extends ListViewModel<
  OrganizationStructure,
  IOrganizationStructureRepository<OrganizationStructure>
> {
  static defaults = {
    order: {
      field: "lft",
      direction: "asc",
    },
    columns: ["name", "parent", "type"],
    visibleFilters: ["name"],
  };

  @observable orgChartData?: OrgChart;
  @observable onlySpecialist = false;
  @observable openedChilds: number[] = [];

  private parentOptionsVM: OptionsVM<OrganizationStructure>;
  public organizationUnitOptionsVM: OptionsVM<OrganizationUnit>;
  @observable
  public changesHistory: ChangesHistory[];

  constructor(
    @inject(TYPES.OrganizationStructureRepository) repository: IOrganizationStructureRepository<OrganizationStructure>,
    @inject(TYPES.OrganizationUnitRepository) public organizationUnitRepository: Repository<OrganizationUnit>,
    @inject(TYPES.ChangesHistoryRepository) public changesHistoryRepository: Repository<ChangesHistory>
  ) {
    super(repository);
    this.organizationUnitOptionsVM = new OptionsVM(this.organizationUnitRepository, "name");
    this.parentOptionsVM = new OptionsVM(repository, "name", {
      filters: {
        children_count: { operator: "gt", values: 0 },
      },
    });
  }

  async reloadHistory() {
    const response = await this.changesHistoryRepository.fetchList({
      order: { field: "created_at", direction: "desc" },
      pagination: { page: 0, pageSize: 5 },
    });

    this.changesHistory = response.list;
  }

  async init(...rest: any) {
    await this.reloadHistory();

    return this.fetchList();
  }

  get parentOptions() {
    return this.parentOptionsVM.selectOptions;
  }

  @computed
  get organizationUnitOptions() {
    return ViewHelpers.createSubOrganizationOptions(this.organizationUnitOptionsVM.items);
  }

  @computed
  get orgChart() {
    if (this.orgChartData && this.orgChartData.job_titles && this.onlySpecialist) {
      return { ...this.orgChartData, job_titles: this.orgChartData.job_titles.filter(item => item.specialist === true) };
    }

    return this.orgChartData;
  }

  @computed
  get lastEdit(): Date | undefined {
    if (this.orgChartData) {
      return new Date(this.orgChartData.last_edit);
    }

    return undefined;
  }

  entityCallback = (params: any) => {
    if (params?.identificator === "organization_unit" || params.identificator === "company") {
      this.fetchList(false, false);
      this.reloadHistory();
    }
  };

  getOrganizationUnitChild(id: number) {
    return this.organizationUnitOptionsVM.items.find(item => item.id === id);
  }

  async fetchOrgChart() {
    if (this.orgChartData) {
      return;
    }

    const chart = await this.repository.fetchOrgChart();

    runInAction(() => {
      this.orgChartData = chart;
    });
  }
}
