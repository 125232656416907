import { inject, injectable } from "inversify";
import { computed } from "mobx";

import Allocation, { AllocationEvent, AllocationStatus } from "@model/Allocation";
import AllocationUser from "@model/AllocationUser";
import { JobTitleState } from "@model/JobTitle";
import Project from "@model/Project";
import ListViewModel from "@vm/List/ListViewModel";

import TYPES from "../../inversify.types";
import OptionsVM from "@vm/Other/Options";
import AllocationRepository from "@repository/Allocation";
import OrganizationUnit from "@model/OrganizationUnit";
import { OptionType } from "@eman/emankit";
import ViewHelpers from "@util/ViewHelpers";

@injectable()
export default class AllocationListVM extends ListViewModel<Allocation, AllocationRepository> {
  static defaults: ListSettings = {
    order: {
      field: "valid_from",
      direction: "asc",
    },
    filters: {
      enumeration_allocation_status: {
        values: AllocationStatus.REQUEST,
        operator: "in",
      },
      job_title_state: {
        operator: "in",
        values: [JobTitleState.ACTIVE, JobTitleState.IN_PREPARATION],
      },
    },
    visibleFilters: ["enumeration_allocation_status", "enumeration_allocation_type_id", "user_state"],
  };

  private allocationUserOptionsVM: OptionsVM<AllocationUser>;
  private organizationUnitOptionsVM: OptionsVM<OrganizationUnit>;
  private projectOptionsVM: OptionsVM<Project>;

  constructor(
    @inject(TYPES.AllocationRepository) repository: AllocationRepository,
    @inject(TYPES.AllocationUserRepository) allocationUserRepository: Repository<AllocationUser>,
    @inject(TYPES.OrganizationUnitRepository) organizationUnitRepository: Repository<OrganizationUnit>,
    @inject(TYPES.ProjectRepository) projectRepository: Repository<Project>
  ) {
    super(repository);
    this.allocationUserOptionsVM = new OptionsVM<AllocationUser>(allocationUserRepository, "user" as any, {
      order: {
        field: "user_last_name",
        direction: "asc",
      },
    });
    this.organizationUnitOptionsVM = new OptionsVM(organizationUnitRepository, "name");
    this.projectOptionsVM = new OptionsVM<Project>(projectRepository, "name");
  }

  @computed
  get userOptions(): OptionType<number>[] {
    return this.allocationUserOptionsVM.items.map(item => ({
      label: `${item.user.first_name} ${item.user.last_name}`,
      value: item.user.id!,
    }));
  }

  @computed
  get organizationUnitOptions() {
    return ViewHelpers.createSubOrganizationOptions(this.organizationUnitOptionsVM.items);
  }

  @computed
  get projectOptions() {
    return this.projectOptionsVM.selectOptions;
  }

  async performEvent(allocationId: number, event: AllocationEvent) {
    return this.repository.performEvent(allocationId, event);
  }
}
