import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { SynchronizedHistory } from "mobx-react-router";

import PageHeader from "@eman/emankit/Header";
import { HeaderMenuProps } from "@eman/emankit/Header/HeaderMenu";

import BaseComponent from "@component/BaseComponent";
import AppMenu from "@component/Menu";
import { UserRightsObjects, UserRightsOperations, UserRightsPages } from "@model/Rights";
import { PEOPLEMAN_WIKI_URL, REDMINE_HELPDESK_URL } from "src/config";
import { IconType } from "@eman/emankit/Icon";

export interface HeaderState {
  active?: string;
}

@observer
export default class Header extends BaseComponent<{}, HeaderState> {
  unregisterSubscriber?: UnregisterCallback;

  state = {
    active: undefined,
  };

  componentDidMount() {
    const history = this.router.history as SynchronizedHistory;

    this.unregisterSubscriber = history.subscribe((location, action) => {
      const paths = location.pathname.split("/");
      if (paths.length) {
        this.setState({ active: paths[1] });
      } else {
        this.setState({ active: undefined });
      }
    });
  }

  componentWillUnmount() {
    if (this.unregisterSubscriber) {
      this.unregisterSubscriber();
    }
    this.unregisterSubscriber = undefined;
  }

  logoClick = () => {
    if (!this.user.allowToPage(UserRightsPages.EMPLOYEES)) {
      this.router.pageLink(this.uriHelper.show_employees(this.user.entity.id!));
    } else {
      this.router.pageLink(this.uriHelper.employees());
    }
  };

  onCtaClick = () => {
    this.router.toggleModal();
  };

  menuClick = (path: string) => {
    if (path === "logout") {
      this.user.logoutUser();
    } else if (path === "helpdesk") {
      window.open(REDMINE_HELPDESK_URL);
    } else if (path === "wiki") {
      window.open(PEOPLEMAN_WIKI_URL);
    } else {
      this.router.pageLink(path);
    }
  };

  render() {
    if (!this.router) {
      return null;
    }

    let profile: HeaderMenuProps | undefined;
    let info: HeaderMenuProps | undefined;

    if (this.user.isLoggedIn) {
      const profileMenuItems: any = [];
      profile = {
        items: profileMenuItems,
        username: `${this.user.entity.first_name} ${this.user.entity.last_name}`,
        active: this.state.active,
        onChange: this.menuClick,
        imageUrl: this.user.avatar,
        className: "with-arrow",
      };

      profileMenuItems.push({
        id: this.uriHelper.show_employees(this.user.entity.id),
        label: `${this.user.entity.first_name} ${this.user.entity.last_name}`,
      });

      if (this.user.allowToPage(UserRightsPages.ENUMERATION)) {
        profileMenuItems.push({
          id: this.uriHelper.enumeration(),
          label: this.locs.tg("enumeration_container.title"),
        });
      }

      if (this.user.allowToPage(UserRightsPages.ROLE)) {
        profileMenuItems.push({
          id: this.uriHelper.roles(),
          label: this.locs.tg("role.title"),
        });
      }

      profileMenuItems.push({
        id: "logout",
        label: "Odhlásit se",
      });

      const infoMenuItems: any = [];
      info = {
        items: infoMenuItems,
        onChange: this.menuClick,
        icon: { icon: IconType.Info, width: 32, height: 32 },
      };
      infoMenuItems.push({
        id: "wiki",
        label: "Wiki",
      });

      infoMenuItems.push({
        id: "helpdesk",
        label: "Helpdesk",
      });
    }

    let cta;
    if (this.user.allowToObject(UserRightsObjects.ANNOUNCEMENT, UserRightsOperations.CREATE)) {
      cta = {
        button: this.locs.tg("cta.button"),
        onClick: this.onCtaClick,
      };
    }

    return (
      <PageHeader
        info={info}
        profile={profile}
        cta={cta}
        locationListener={this.router.history.listen}
        logoClick={this.logoClick}>
        <AppMenu />
      </PageHeader>
    );
  }
}
