import { Type } from "@eman/class-transformer";
import BaseModel from "@model/BaseModel";
import Rights from "@model/Rights";
import { observable } from "mobx";

export default class Role extends BaseModel {
  @observable admin: boolean;
  @observable name: string;
  @observable order: number;

  @Type(() => Rights)
  @observable
  rights: Rights = new Rights();

  isDirtyRelations(): string[] {
    return ["rights"];
  }
}
