import isEqual from "lodash/isEqual";
import upperFirst from "lodash/upperFirst";
import React from "react";
import { vokativ } from "vokativ";

import Badge from "@eman/emankit/Badge";
import { ButtonVariant } from "@eman/emankit/Button";
import { BaseColor } from "@eman/emankit/Colors";
import { IconType } from "@eman/emankit/Icon";

import { AllocationEvent, AllocationStatus } from "@model/Allocation";
import AllocationValue from "@model/AllocationValue";
import { AnnouncementEvent, AnnouncementStatus } from "@model/Announcement";
import { ContractState } from "@model/Contract";
import { JobTitleState } from "@model/JobTitle";
import User, { UserAllocationState, UserState } from "@model/User";
import LocaleProvider from "@util/LocaleProvider";
import { GenericFormField } from "@util/Form";
import JobTitleWorkingTimeRatio from "@model/JobTitleWorkingTimeRatio";
import WorkingTimeRatio from "@model/WorkingTimeRatio";
import { OptionType } from "@eman/emankit";
import { toJS } from "mobx";
import OrganizationUnit from "@model/OrganizationUnit";

export interface JobOptionType extends OptionType<number> {
  state: JobTitleState;
}

export interface UserAllocationStateOptionType extends OptionType<number> {
  label: string;
  value: number;
  allocation_state: UserAllocationState;
}

class ViewHelpers {
  booleanOptions(locs: Services.Localization): OptionType<number>[] {
    return [
      {
        value: 1,
        label: locs.tg("bool.yes"),
      },
      {
        value: 0,
        label: locs.tg("bool.no"),
      },
    ];
  }

  booleanStringify(locs: Services.Localization, value: boolean | number): string {
    return locs.tg(`bool.${!!value ? "yes" : "no"}`) /* eslint-disable-line no-extra-boolean-cast */;
  }

  booleanToBadge(locs: Services.Localization, value: boolean | number, reverse = false): React.ReactNode {
    return <Badge color={!!value !== reverse ? BaseColor.Green : BaseColor.Red}>{this.booleanStringify(locs, value)}</Badge>;
  }

  booleanToBadgeSmooth(locs: Services.Localization, value: boolean | number): React.ReactNode {
    return <Badge color={value ? BaseColor.LightBlue : BaseColor.Gray}>{this.booleanStringify(locs, value)}</Badge>;
  }

  /**
   * Generate enum item badge.
   * @param item Enumeration item
   */
  enumItemBadge(item?: models.IEnumType): React.ReactNode {
    if (item) {
      return <Badge color={item.options.color}>{item.name}</Badge>;
    } else {
      return null;
    }
  }

  /**
   * Return name of enum for given enum id column of item
   * @param item
   * @param columnEnumIdName
   */
  enumName(item: models.IBase, columnEnumIdName: string): string {
    const columnEnumName = columnEnumIdName.replace("enumeration_", "").replace("_id", "");

    return item[columnEnumName] ? item[columnEnumName].name : "";
  }

  /**
   * Return user name in proper format
   * @param user
   */
  userName(user: User, withDegrees = true): string {
    let name = `${user.first_name} ${user.last_name}`;

    if (user.degree_after && withDegrees) {
      name += `, ${user.degree_after}`;
    }

    if (user.degree_before2 && withDegrees) {
      name = `${user.degree_before2} ${name}`;
    }

    if (user.degree_before1 && !user.degree_before2 && withDegrees) {
      name = `${user.degree_before1} ${name}`;
    } else if (user.degree_before1 && user.degree_before2 && withDegrees) {
      name = `${user.degree_before1}, ${name}`;
    }

    return name;
  }

  allocationStateToColor = (status: AllocationStatus) => {
    return {
      [AllocationStatus.APPROVED]: BaseColor.Green,
      [AllocationStatus.REQUEST]: BaseColor.LightBlue,
      [AllocationStatus.CANCELED]: BaseColor.Gray,
      [AllocationStatus.PROBLEM]: BaseColor.Turquoise,
      [AllocationStatus.REJECTED]: BaseColor.Red,
      [AllocationStatus.WARNING]: BaseColor.Orange,
    }[status];
  };

  allocationEventToButtonType(event: AllocationEvent) {
    return {
      [AllocationEvent.APPROVE]: ButtonVariant.Approve,
      [AllocationEvent.REOPEN]: ButtonVariant.Primary,
      [AllocationEvent.PROBLEM]: ButtonVariant.Dangerous,
      [AllocationEvent.CANCEL]: ButtonVariant.SecondaryBlue,
      [AllocationEvent.REJECT]: ButtonVariant.Reject,
      [AllocationEvent.WARNING]: ButtonVariant.SecondaryWhite,
    }[event];
  }

  allocationEventToIcon(event: AllocationEvent) {
    switch (event) {
      case AllocationEvent.REJECT:
        return IconType.Cross;

      case AllocationEvent.APPROVE:
        return IconType.Success;

      default:
        return undefined;
    }
  }

  allocationValue = (itemValue: AllocationValue, locs: Services.Localization) => {
    let value;

    if (itemValue.total_amount) {
      value = locs.tg("allocation.manage.total_amount").replace("%{COUNT}", `${itemValue.total_amount}`);
    } else if (itemValue.week_amount) {
      const groupedByHours = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].reduce(
        (acc: any, day, index) => {
          if (itemValue.week_amount && itemValue.week_amount[`${day}`] > 0) {
            const dayValue = itemValue.week_amount[`${day}`];
            if (acc.hasOwnProperty(dayValue)) {
              acc[dayValue].push(index);
            } else {
              acc[dayValue] = [index];
            }
          }
          return acc;
        },
        {}
      );

      value = Object.keys(groupedByHours)
        .map(hours => {
          const days = groupedByHours[hours];

          if (isEqual(days, [1, 2, 3, 4, 5])) {
            return locs.tg("allocation.manage.hour_per_day").replace("%{COUNT}", hours);
          } else {
            return `${hours}h / ${days.map((day: number) => locs.tg("uikit.date_picker.strings.weekdaysLong")[day]).join(", ")}`;
          }
        })
        .join(", ");
    }

    return value;
  };

  announcementStateToColor = (state: AnnouncementStatus) => {
    return {
      [AnnouncementStatus.WAITING]: BaseColor.Orange,
      [AnnouncementStatus.PROCCESSED]: BaseColor.Green,
      [AnnouncementStatus.REJECTED]: BaseColor.Red,
      [AnnouncementStatus.CANCELED]: BaseColor.Gray,
    }[state];
  };

  announcementEventToButtonType = (event: AnnouncementEvent) => {
    return {
      [AnnouncementEvent.PROCESS]: ButtonVariant.Approve,
      [AnnouncementEvent.REJECT]: ButtonVariant.Reject,
      [AnnouncementEvent.CANCEL]: ButtonVariant.Dangerous,
    }[event];
  };

  announcementEventToIcon = (event: AnnouncementEvent) => {
    let icon: IconType | undefined;

    if (event === "processed") {
      icon = IconType.Success;
    } else if (event === "rejected") {
      icon = IconType.Cross;
    }

    return icon;
  };

  contractStateToColor = (state: ContractState) => {
    return {
      [ContractState.CREATED]: BaseColor.LightBlue,
      [ContractState.DISMISSAL]: BaseColor.Red,
      [ContractState.ACTIVE]: BaseColor.Green,
      [ContractState.CLOSED]: BaseColor.Gray,
    }[state];
  };

  jobTitleStateToColor = (state: JobTitleState) => {
    return {
      [JobTitleState.ACTIVE]: BaseColor.Green,
      [JobTitleState.IN_PREPARATION]: BaseColor.LightBlue,
      [JobTitleState.CLOSED]: BaseColor.Gray,
    }[state];
  };

  userStateToColor = (state: UserState) => {
    return {
      [UserState.CREATED]: BaseColor.LightBlue,
      [UserState.PROBATION]: BaseColor.Orange,
      [UserState.ACTIVE]: BaseColor.Green,
      [UserState.DISMISSAL]: BaseColor.Red,
      [UserState.CLOSED]: BaseColor.Gray,
    }[state];
  };

  fileSize(sizeInBytes: number): string {
    let unit = "B";
    let size: number = sizeInBytes;

    if (size >= 1024 * 1024) {
      unit = "MB";
      size = Math.ceil((size / (1024 * 1024)) * 100) / 100;
    } else if (size >= 1024) {
      unit = "KB";
      size = Math.ceil((size / 1024) * 100) / 100;
    }

    return `${size} ${unit}`;
  }

  nameInVocative(name: string) {
    if (LocaleProvider.locale === "cs") {
      return upperFirst(vokativ(name));
    }
    return name;
  }

  // tslint:disable-next-line: bool-param-default
  generateFormField(component: any, property: string, formField: React.ReactNode, required?: boolean, label?: string) {
    return (
      <GenericFormField
        key={property}
        target={component.props.entity}
        property={property}
        label={label || component.ta(property)}
        required={required}>
        {formField}
      </GenericFormField>
    );
  }

  decimalHoursToTime(decimalHours: number): string {
    const hours = Math.trunc(decimalHours);
    const minutesFraction = decimalHours - hours;
    return `${hours}:${(minutesFraction * 60).toString().padStart(2, "0")}`;
  }

  jopOption(option: WorkingTimeRatio, jobOption: JobTitleWorkingTimeRatio): JobOptionType {
    const organizationName = jobOption.job_title.job_position.organization_structures.find(
      item => item.id === jobOption.job_title.organization_structure_id
    )?.name;
    const positionName = jobOption.job_title.job_position?.name;

    return {
      value: jobOption.id!,
      label: `${organizationName || ""} ${organizationName ? "- " : ""}${positionName} ${jobOption.ratio * 100}%`,
      state: option.state,
    };
  }

  jopOptionFiltering(options: WorkingTimeRatio[]) {
    const allowedRatios: JobOptionType[] = [];

    options.forEach(option => {
      option.job_title_working_time_ratios.forEach(jobOption => {
        if (jobOption.ratio !== 0) {
          allowedRatios.push(this.jopOption(option, jobOption));
        }
      });
    });

    return allowedRatios;
  }

  cleanFilters(options: any[], filters: FilterValues, value: string) {
    const check = Object.values(toJS(filters[value]?.values || {})).length === options.length;
    if (check) {
      delete filters[value];
    }
    return filters;
  }

  formatNameWithProjectName(item: any) {
    return `${item.meta.group.name}${item.meta.group.unit ? ` (${item.meta.group.unit})` : ""}`;
  }

  createSubOrganizationOptions(organizations: OrganizationUnit[]): OptionType[] {
    const arr: OptionType[] = [];
    organizations
      .filter(item => !!item.children_count)
      .forEach(item => {
        const option = { value: item.id!, label: item?.name, parent: undefined };
        arr.splice(arr.map(e => e.parent).indexOf(item.id), 0, option);

        item.children.forEach(child => {
          const childOption = { value: child.id!, label: child?.name, parent: child.parent_id };

          arr.splice(arr.map(e => child.parent_id).indexOf(child.id!), 0, childOption);
        });
      });

    const last_element = arr.pop();

    if (last_element) arr.unshift(last_element);

    return arr;
  }

  yearSelectOptions(): OptionType[] {
    const max = new Date().getFullYear();
    const min = max - 9;
    const years: number[] = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years.map(year => {
      return { value: year, label: `${year}` };
    });
  }

  renderUrlButton(locs: Services.Localization, url?: string) {
    if (!url) {
      return "";
    }

    const urlRegex = /^((?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_+.~#?&/=]*|)$/g;
    if (urlRegex.test(url)) {
      return (
        <a className="emankit__button" target="_blank" rel="noreferrer" href={url} style={{ padding: "10px 20px" }}>
          <span>{locs.tg("list_buttons.address")}</span>
        </a>
      );
    }

    return locs.tg("list_buttons.invalid_url");
  }
}

export default new ViewHelpers();
