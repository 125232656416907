import Contract from "@view/Employee/Contract";
import ContractCreate from "@view/Employee/Contract/Create";
import ContractEdit from "@view/Employee/Contract/Edit";
import CapacityCreateModal from "@view/Employee/Contract/Capacity/Create";
import CapacityEditModal from "@view/Employee/Contract/Capacity/Edit";
import CapacityShowModal from "@view/Employee/Contract/Capacity/Show";
import JobTitleCreateModal from "@view/Employee/Contract/JobTitle/Create";
import JobTitleEditModal from "@view/Employee/Contract/JobTitle/Edit";
import JobTitleShowModal from "@view/Employee/Contract/JobTitle/Show";
import RateCreateModal from "@view/Employee/Contract/Rate/Create";
import RateEditModal from "@view/Employee/Contract/Rate/Edit";
import RateShowModal from "@view/Employee/Contract/Rate/Show";
import ContractsVM from "@vm/Other/Contracts";

import { EMPLOYEE_SUBPAGES, PAGES, uriHelper } from "../../../config";
import { lazyInject, TYPES } from "../../../inversify.config";
import BaseRouter from "../../Base";
import WorkingTimeRatiosEditModal from "@view/Employee/Contract/WorkingTimeRatios/Edit";

export const contractRoutes: IAppRoute[] = [
  {
    path: uriHelper.employees_contracts_uri(),
    exact: true,
    component: Contract,
  },
  {
    path: uriHelper.new_employees_contracts_uri(),
    exact: true,
    title: `${PAGES.EMPLOYEE}.${EMPLOYEE_SUBPAGES.CONTRACT}.create`,
    component: ContractCreate,
  },
  {
    path: uriHelper.show_employees_contracts_uri(),
    exact: false,
    component: ContractEdit,
  },
  {
    path: uriHelper.new_employees_contracts_job_titles_uri(),
    exact: true,
    modalComponent: JobTitleCreateModal,
  },
  {
    path: uriHelper.edit_employees_contracts_job_titles_uri(),
    exact: true,
    modalComponent: JobTitleEditModal,
  },
  {
    path: uriHelper.show_employees_contracts_job_titles_uri(),
    exact: true,
    modalComponent: JobTitleShowModal,
  },
  {
    path: uriHelper.edit_employees_contracts_working_time_ratios_uri(),
    exact: true,
    modalComponent: WorkingTimeRatiosEditModal,
  },
  {
    path: uriHelper.new_employees_contracts_rates_uri(),
    exact: true,
    modalComponent: RateCreateModal,
  },
  {
    path: uriHelper.edit_employees_contracts_rates_uri(),
    exact: true,
    modalComponent: RateEditModal,
  },
  {
    path: uriHelper.show_employees_contracts_rates_uri(),
    exact: true,
    modalComponent: RateShowModal,
  },
  {
    path: uriHelper.new_employees_contracts_capacities_uri(),
    exact: true,
    modalComponent: CapacityCreateModal,
  },
  {
    path: uriHelper.edit_employees_contracts_capacities_uri(),
    exact: true,
    modalComponent: CapacityEditModal,
  },
  {
    path: uriHelper.show_employees_contracts_capacities_uri(),
    exact: true,
    modalComponent: CapacityShowModal,
  },
];

export default class Contracts extends BaseRouter<PropsFromRouter> {
  @lazyInject(TYPES.Contracts)
  vm: ContractsVM;

  componentDidMount() {
    this.vm.init(this.props.match.params.user_id);
  }

  componentWillUnmount(): void {
    this.vm.clean();
  }

  render() {
    return this.pageRouteRender(contractRoutes);
  }
}
