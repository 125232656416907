import { inject, injectable } from "inversify";

import EnumType from "@model/EnumType";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class EnumListVM extends AssociatedListViewModel<EnumType, IEnumRepository<EnumType>> {
  static defaults = {
    order: {
      field: "order",
      direction: "asc",
    },
    columns: ["name", "order", "code"],
  };

  constructor(@inject(TYPES.EnumRepository) repository: IEnumRepository<EnumType>) {
    super(repository);
  }
}
