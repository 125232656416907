import { observer } from "mobx-react";

import BaseComponent from "@component/BaseComponent";
import BreadcrumbService, { Crumb } from "@service/Breadcrumb";

import { lazyInject, TYPES } from "../../inversify.config";

export interface BreadcrumbProps {
  children: React.ReactNode;
  crumb: Crumb;
}

export interface BreadcrumbState {
  key?: string;
  crumb: Crumb;
}

@observer
export default class Breadcrumb extends BaseComponent<BreadcrumbProps, BreadcrumbState> {
  static getDerivedStateFromProps(props: Readonly<BreadcrumbProps>, prevState: BreadcrumbState) {
    if (props.crumb.pathname !== prevState.crumb.pathname) {
      return {
        crumb: props.crumb,
      };
    }
    return null;
  }

  @lazyInject(TYPES.Breadcrumb)
  breadcrumb: BreadcrumbService;

  constructor(props: BreadcrumbProps) {
    super(props);
    this.state = {
      key: undefined,
      crumb: props.crumb,
    };
  }

  componentDidMount(): void {
    const key = this.breadcrumb.addCrumb(this.props.crumb);
    this.setState({ key });
  }

  componentDidUpdate(prevProps: Readonly<BreadcrumbProps>, prevState: Readonly<BreadcrumbState>, snapshot?: any): void {
    if (this.state.key) {
      this.breadcrumb.updateCrumb(this.state.key, this.state.crumb);
    } else {
      const key = this.breadcrumb.addCrumb(this.props.crumb);
      this.setState({ key });
    }
  }

  componentWillUnmount(): void {
    const key = this.state.key;

    if (key) {
      this.breadcrumb.removeCrumb(key);
    }
  }

  render() {
    return this.props.children;
  }
}
