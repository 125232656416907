import React from "react";

import BaseComponent from "@component/BaseComponent";
import Button from "@eman/emankit/Button";
import { IconType } from "@eman/emankit/Icon";
import Panel from "@eman/emankit/Panel";

import { block } from "@eman/emankit/config";

import "./styles.scss";

export interface LoginProps {
  onLogin: (e: any) => void;
}

class Login extends BaseComponent<LoginProps> {
  render() {
    const { onLogin } = this.props;
    const b = block("login");

    return (
      <Panel className={b()}>
        <div className={b("company")}>
          <div className={b("logo")} />
          <div className={b("company-label")}>
            Peopl<b>eman</b>
          </div>
        </div>
        <Button icon={IconType.Google} onClick={onLogin}>
          {this.locs.tg("login.button")}
        </Button>
      </Panel>
    );
  }
}

export default Login;
