import { observer } from "mobx-react";
import React from "react";

import Select from "@eman/emankit/Select";

import BindingElement, { BindingProps } from "./index";
import { OptionType, GroupedOptionsType } from "@eman/emankit";
import { MenuPosition } from "react-select";

export interface SelectBoxProps<T extends string | number, K extends OptionType<T>> extends BindingProps<any> {
  options: Array<K | GroupedOptionsType<T, K>>;
  allowEmpty?: boolean;
  emptyRow?: string;
  menuPosition?: MenuPosition;
}

export const checkGroup = (item: any) => item.options !== undefined;

@observer
export default class SelectBox<
  KValue extends string | number = number,
  TOptionType extends OptionType<KValue> = OptionType<KValue>
> extends BindingElement<SelectBoxProps<KValue, TOptionType>, any> {
  static defaultProps = {
    emptyRow: "",
  };

  protected handleValueChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value !== "" ? e.target.value : null;
    this.setValue(value);
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const { allowEmpty, options, emptyRow, skipDirty, onValueChanged, formatValue, parseValue, ...rest } = this.props;
    let items: React.ReactNode[] = [];

    if (this.props.options) {
      items = this.props.options.map(item => {
        if (checkGroup(item)) {
          const opt = item as GroupedOptionsType<KValue, TOptionType>;

          return (
            <optgroup label={item.label} key={`select_group_${item.label}`}>
              {opt.options.map(subItem => (
                <option value={subItem.value} key={`select_value_${subItem.value}`}>
                  {subItem.label}
                </option>
              ))}
            </optgroup>
          );
        } else {
          const opt = item as TOptionType;

          return (
            <option value={opt.value} key={`select_value_${opt.value}`}>
              {opt.label}
            </option>
          );
        }
      });
    }

    if (!(!allowEmpty && this.value)) {
      items.unshift(
        <option value="" key="empty_row" disabled={!allowEmpty}>
          {emptyRow}
        </option>
      );
    }

    return (
      <Select onChange={this.handleValueChanged} value={this.value || ""} {...rest}>
        {items}
      </Select>
    );
  }
}
