import AvatarComponent from "@component/Avatar";
import BaseComponent from "@component/BaseComponent";
import User from "@model/User";
import { noop } from "lodash";
import { REDMINE_URL } from "src/config";

interface UserSidebarProps {
  user: User;
  onClick?: (...params: any) => void;
  hideOpener?: boolean;
  eisUrl?: boolean;
}

export default class UserSidebar extends BaseComponent<UserSidebarProps> {
  render() {
    const { user, onClick, hideOpener, eisUrl } = this.props;

    const link =
      eisUrl && user.employee_number
        ? () => window.open(`${REDMINE_URL}/users/internal?internal_id=${user.employee_number}`)
        : () => this.router.pageLink(this.uriHelper.show_employees(user.id!));

    return (
      <div className={`user-sidebar${hideOpener ? " hide-arrow" : ""}`} onClick={(!hideOpener && onClick) || noop}>
        <AvatarComponent user={user} size={30} />
        <a className="name" onClick={link}>
          {user.fullName}
        </a>
      </div>
    );
  }
}
