import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import JobTitle from "@model/JobTitle";
import JobTitleCreateVM from "@vm/Create/JobTitle";

import { lazyInject, TYPES } from "../../../../inversify.config";
import JobTitleForm from "./Form";
import { computed } from "mobx";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";

@observer
export default class JobTitleCreateModal extends CreateModal<JobTitle> {
  modelName = "job_title";
  prefix = "employee.contract";

  @lazyInject(TYPES.JobTitleCreate)
  vm: JobTitleCreateVM;

  componentDidMount() {
    const params = this.props.match.params;
    this.vm.jobTitleFormVM.isCreate = true;
    super.componentDidMount();
    this.vm.init(params.user_id, params.contract_id);
  }

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.show_employees_contracts(params.user_id, params.contract_id));
  };

  @computed
  get canCreateBeforeToday(): boolean {
    return this.user.allowToObject(UserRightsObjects.TIME_LIMITS, UserRightsOperations.IGNORE_TODAYS_DEPENDENT_VALIDATIONS);
  }

  renderModalBody() {
    return <JobTitleForm entity={this.vm.entity} vm={this.vm.jobTitleFormVM} canCreateBeforeToday={this.canCreateBeforeToday} />;
  }
}
