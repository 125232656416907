import { injectable } from "inversify";

import JobTitle from "@model/JobTitle";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class JobTitleRepository extends BaseAssociatedRepository<JobTitle> {
  constructor() {
    super(JobTitle, "users/$ID/job_titles", "job_title");
  }
}
