import InputContainer from "@eman/emankit/InputContainer";
import AnnouncementForm from "@view/Announcement/Form";
import { LocationDescriptorObject } from "history";
import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import Modal from "@eman/emankit/Modal";
import Uploader from "@eman/emankit/Uploader";

import Create from "@component/Create";
import Announcement from "@model/Announcement";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import Attachments from "@view/Common/Attachments";
import AnnouncementCreateVM from "@vm/Create/Announcement";

import { lazyInject, TYPES } from "../../inversify.config";

interface LocationState {
  showModal?: boolean;
}

export interface AnnouncementToEmployerModalProps {
  location: LocationDescriptorObject<LocationState>;
}

@observer
export default class AnnouncementCreate extends Create<Announcement, AnnouncementCreateVM, AnnouncementToEmployerModalProps> {
  modelName = "announcement";

  @lazyInject(TYPES.AnnouncementCreate)
  vm: AnnouncementCreateVM;

  buttons = () => {
    return (
      <div style={{ textAlign: "right" }}>
        <Button className="mr-12" onClick={this.closeModal} variant={ButtonVariant.SecondaryBlue}>
          {this.locs.tg("form.cancel")}
        </Button>
        <Button onClick={this.onSaveClick} variant={ButtonVariant.Primary}>
          {this.locs.tg("form.sent")}
        </Button>
      </div>
    );
  };

  onSaveClick = async () => {
    const id = await this.vm.create();

    if (id) {
      EventBus.trigger(SHOW_TOAST, this.tg("sent"));
      this.closeModal();
    }
  };

  closeModal = () => {
    this.vm.resetEntityAndErrors();
    this.router.toggleModal();
  };

  onFileSelect = async (files: File[]) => {
    this.vm.onDropFile(files);
  };

  render() {
    let open = false;
    if (this.props.location.state?.showModal) {
      open = this.props.location.state.showModal;
    }

    const entity = this.vm.entity;

    return (
      <Modal open={open} onClose={this.closeModal} style={{ width: 800 }} buttons={this.buttons} header={this.tg("title")}>
        <AnnouncementForm entity={entity} />
        {this.user.allowToObject(UserRightsObjects.DOCUMENT, UserRightsOperations.UPLOAD) && (
          <InputContainer label={this.ta("attachments")} format="column">
            <Uploader
              localization={this.locs.uploader}
              allowedTypes={[]}
              showFiles={true}
              onDrop={this.onFileSelect}
              multiple={false}
              files={[]}
            />
          </InputContainer>
        )}
        <Attachments vm={this.vm} />
      </Modal>
    );
  }
}
