import { injectable } from "inversify";

import AllocationItem from "@model/AllocationItem";
import BaseRepository from "@repository/BaseRepository";

@injectable()
export default class AllocationItemRepository extends BaseRepository<AllocationItem> {
  constructor() {
    super(AllocationItem, "allocation_items", "allocation_item");
  }
}
