import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import Training from "@model/Training";
import { SelectBox, TextBox, TextAreaBox, GenericFormField } from "@util/Form";
import { OptionType } from "@eman/emankit";
import { computed } from "mobx";

export interface TrainingFormProps {
  possibleUsers?: OptionType<number>[];
}

@observer
export default class TrainingForm extends BaseForm<Training, TrainingFormProps> {
  modelName = "training";
  prefix = "employee";

  @computed get currentSupervisor(): models.IEnumType | undefined {
    return this.enums.value("training_types", this.props.entity.enumeration_training_type_id);
  }

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-6">
          {this.generateFormField(
            "enumeration_training_type_id",
            <SelectBox options={this.enums.valuesForSelect("training_types")} />,
            true,
            {
              label: this.ta("training_type"),
              disabled: !!this.props.entity.user_confirmation && !!this.props.entity.supervisor_confirmation,
            }
          )}
        </div>
        {this.props.possibleUsers && (
          <>
            <div className="col-md-6">
              {this.generateFormField("user_id", <SelectBox options={this.props.possibleUsers} menuPosition={"fixed"} />, true, {
                disabled: true,
                label: this.ta("user"),
              })}
            </div>
            {this.currentSupervisor && (
              <div className="col-md-6">
                <GenericFormField
                  target={this.currentSupervisor}
                  property="supervisor_id"
                  label={this.ta("supervisor")}
                  disabled={true}>
                  <SelectBox options={this.props.possibleUsers} />
                </GenericFormField>
              </div>
            )}
          </>
        )}
        <div className="col-md-6">
          {this.generateFormField(
            "user_confirmation",
            <TextBox
              formatValue={value => {
                return value ? this.locs.formatDate(value) : this.ta("unconfirmed");
              }}
            />,
            false,
            { disabled: true }
          )}
        </div>
        <div className="col-md-6">
          {this.generateFormField(
            "supervisor_confirmation",
            <TextBox
              formatValue={value => {
                return value ? this.locs.formatDate(value) : this.ta("unconfirmed");
              }}
            />,
            false,
            { disabled: true }
          )}
        </div>
        <div className="col-md-12">{this.generateFormField("url", <TextAreaBox />, false)}</div>
      </div>
    );
  }
}
