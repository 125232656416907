import { inject, injectable } from "inversify";

import Capacity from "@model/Capacity";
import CapacityForm from "@vm/Form/Capacity";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class CapacityShowVM extends AssociatedShowViewModel<Capacity, AssociatedRepository<Capacity>> {
  constructor(
    @inject(TYPES.CapacityRepository) repository: AssociatedRepository<Capacity>,
    @inject(TYPES.CapacityForm) public capacityFormVM: CapacityForm
  ) {
    super(Capacity, repository);
  }

  setParentId(parentId: number) {
    super.setParentId(parentId);

    this.capacityFormVM.setParentId(parentId);
  }

  setEntity(entity: Capacity) {
    super.setEntity(entity);
    this.capacityFormVM.setEntity(entity);
  }
}
