import { inject, injectable } from "inversify";
import { action, computed, observable } from "mobx";

import EnumType from "@model/EnumType";
import EventBus, { LOCALE_CHANGE } from "@util/EventBus";

import { getEnumAttributes } from "@util/Enumerable";

import TYPES from "../inversify.types";
import { OptionType } from "@eman/emankit";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

/**
 * General enum view.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
@injectable()
export default class EnumVM implements Services.Enum {
  /**
   * Fetched enums.
   *
   * @type {EnumContainer}
   * @memberof EnumGeneralVM
   */
  @observable enums: EnumContainer = {};

  /**
   * Indicator of currently fetching enums, there is multiple sources for fetching enums.
   *
   * @type {boolean}
   * @memberof EnumGeneralVM
   */
  currentlyFetching = false;

  /**
   * Inline enum cache.
   *
   * @private
   * @type {({ [name: string]: { [key: string]: models.IEnumType | undefined }})}
   * @memberof EnumGeneralVM
   */
  private enumCache: { [name: string]: { [key: string]: models.IEnumType | undefined } };
  private enumArrayCache: { [name: string]: { [key: string]: models.IEnumType[] | undefined } };

  private repository: IEnumRepository<EnumType>;

  constructor(@inject(TYPES.EnumRepository) repository: IEnumRepository<EnumType>) {
    this.repository = repository;
    EventBus.on(LOCALE_CHANGE, this.resetEnums);
    this.resetEnums();
  }

  resetEnums = () => {
    this.setEnums({});
    this.enumCache = {};
    this.enumArrayCache = {};
    this.fetchEnums();
  };

  async fetchEnums() {
    if (!this.currentlyFetching) {
      this.currentlyFetching = true;
      const enums: EnumContainer = await this.repository.fetchCurrent();
      this.setEnums(enums);
      this.currentlyFetching = false;
    }
  }

  @computed
  get isLoaded(): boolean {
    return this.enums !== undefined && Object.keys(this.enums).length !== 0;
  }

  @action
  setEnums(enums: EnumContainer) {
    this.enums = enums;
  }

  /**
   * Return array of values for enum.
   *
   * @param name Enum name
   */
  values(name: string): models.IEnumType[] {
    if (!this.isLoaded) {
      this.fetchEnums();
      return [];
    } else {
      return this.enums[name];
    }
  }

  sortByOrder(l: models.IEnumType, r: models.IEnumType) {
    if (l.order && !r.order) {
      return -1;
    } else if (!l.order && r.order) {
      return 1;
    } else if (l.order && r.order) {
      return l.order - r.order;
    } else {
      return 0;
    }
  }

  /**
   * Values by code
   * @param name  Enum name
   * @param withCode Enum name with code
   * @param filterFnc
   */
  valuesForSelectByCode(name: string, withCode = false, filterFnc?: (item: models.IEnumType) => boolean): OptionType<string>[] {
    return this.values(name)
      .sort(this.sortByOrder)
      .reduce((result: OptionType<string>[], value: models.IEnumType) => {
        const id = value.code;

        /* eslint-disable-next-line sonarjs/no-redundant-boolean */
        if (filterFnc ? filterFnc(value) : true && !value.deleted) {
          result.push({
            value: id,
            label: withCode ? `${value.code} - ${value.name}` : value.name,
          });
        }

        return result;
      }, []);
  }

  /**
   * Values for select (same as filter values).
   *
   * @param name
   * @param code
   */
  // tslint:disable-next-line: cognitive-complexity
  valuesForSelect(
    name: string,
    // tslint:disable-next-line: bool-param-default
    withCode?: boolean,
    filterFnc?: (item: models.IEnumType) => boolean
  ): OptionType<number>[] {
    return this.values(name)
      .sort(this.sortByOrder)
      .reduce((result: OptionType<number>[], value: models.IEnumType) => {
        const id = value.id;

        /* eslint-disable-next-line sonarjs/no-redundant-boolean */
        if (filterFnc ? filterFnc(value) : true && !value.deleted) {
          result.push({
            value: id,
            label: withCode ? `${value.code} - ${value.name}` : value.name,
          });
        }

        return result;
      }, []);
  }

  /**
   * Return enum value.
   *
   * @param name Enum name
   * @param code Enum code
   */
  value(name: string, code: string | number, byCode?: boolean): models.IEnumType | undefined {
    if (!this.enumCache[name]) {
      this.enumCache[name] = {};
    }

    const key = code;

    if (this.enumCache[name] && this.enumCache[name][key]) {
      return this.enumCache[name][key];
    }

    const values = this.values(name) || [];

    if (values.length === 0) {
      return undefined;
    }

    let selectedItem: any;

    if (byCode) {
      values.forEach(item => {
        if (item.code == code) {
          selectedItem = item;
        }
      });
    } else {
      values.forEach(item => {
        if (item.id == code) {
          selectedItem = item;
        }
      });
    }

    if (selectedItem) {
      this.enumCache[name][key] = selectedItem;
      return selectedItem;
    } else {
      return undefined;
    }
  }

  /**
   * Return array of enum values.
   *
   * @param name Enum name
   * @param code Enum code
   */
  valueForArray(name: string, code: number[]): models.IEnumType[] | undefined {
    if (!this.enumArrayCache[name]) {
      this.enumArrayCache[name] = {};
    }

    const key = code.join("");

    if (this.enumArrayCache[name] && this.enumArrayCache[name][key]) {
      return this.enumArrayCache[name][key];
    }

    const values = this.values(name) || [];

    if (values.length === 0) {
      return undefined;
    }

    const selectedItems: models.IEnumType[] = [];
    values.forEach(item => {
      if (code.indexOf(item.id) > -1) {
        selectedItems.push(item);
      }
    });

    if (selectedItems) {
      this.enumArrayCache[name][key] = selectedItems;
      return selectedItems;
    } else {
      return undefined;
    }
  }

  resetObjectEnums(item: models.IBase) {
    item.__alreadyAssignedEnums = false;

    const attributes = getEnumAttributes(item);

    for (const target in attributes) {
      if (attributes.hasOwnProperty(target)) {
        item[target] = undefined;
      }
    }

    this.assignObjectEnum(item);
  }

  /**
   * Find and set model item data to all attributes which is annotated.
   *
   * @param item Base model or anything
   */
  assignObjectEnum(item: models.IBase) {
    if (item.__alreadyAssignedEnums) {
      return; // Skip already assigned enums, it is faster than checking item[target] for each one
    }

    const attributes = getEnumAttributes(item);
    if (!attributes) {
      return;
    }

    for (const target in attributes) {
      if (attributes.hasOwnProperty(target)) {
        const enumItem = attributes[target];
        const source = item[enumItem.source];

        if (source && !item[target]) {
          let value: models.IEnumType | models.IEnumType[] | undefined;

          if (enumItem.array) {
            value = this.valueForArray(enumItem.name, source);
          } else {
            value = this.value(enumItem.name, source);
          }

          if (value) {
            item[target] = value;
          } else {
            throw new Error(`Cannot find ${enumItem.name} for ${item} in enumerations!`);
          }
        }
      }
    }

    item.__alreadyAssignedEnums = true;
  }
}
