import ApiClient, { AxiosConfig } from "@util/ApiClient";
import { injectable } from "inversify";

import Announcement, { AnnouncementEvent } from "@model/Announcement";
import BaseRepositoryWithAttachments from "@repository/BaseRepositoryWithAttachments";
import EventBus, { ENTITY_UPDATE } from "@util/EventBus";

@injectable()
export default class AnnouncementRepository extends BaseRepositoryWithAttachments<Announcement> {
  constructor() {
    super(Announcement, "announcements", "announcement");
  }

  performEvent(announcementId: number, event: AnnouncementEvent, refusalReason?: string): Promise<ApiResponse<Announcement>> {
    const config: AxiosConfig = {
      url: `${this.uri}/${announcementId}/event/${event}`,
      method: "PUT",
      id: "PERFORM_ANNOUNCEMENT_EVENT",
      data: {
        refusal_reason: refusalReason,
      },
    };

    return ApiClient.fetchResponse(config).then(response => {
      EventBus.trigger(ENTITY_UPDATE, {
        identificator: this.classModelName,
        id: announcementId,
      });

      return response;
    });
  }
}
