import { inject, injectable } from "inversify";

import RateItem from "@model/RateItem";
import EditListViewModel from "@vm/EditList/EditListViewModel";
import { observable } from "mobx";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class RateItemsEditListVM extends EditListViewModel<RateItem, AssociatedRepository<RateItem>> {
  @observable userId: number;
  @observable rateId: number;

  constructor(@inject(TYPES.RateItemRepository) repository: AssociatedRepository<RateItem>) {
    super(RateItem, repository);
    this.scroll = false;
  }

  setParentId(userId: number, rateId: number) {
    this.repository.setId(userId, rateId);
    this.userId = userId;
    this.rateId = rateId;
    this.init();
  }
}
