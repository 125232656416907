import { inject, injectable } from "inversify";

import Contact from "@model/Contact";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class ContactListVM extends AssociatedListViewModel<Contact, AssociatedRepository<Contact>> {
  static defaults = {
    order: {
      field: "id",
      direction: "asc",
    },
    columns: ["contact_type", "value", "work", "public", "valid_from", "valid_to", "description"],
    visibleFilters: ["contact_type"],
  };

  constructor(@inject(TYPES.ContactRepository) repository: AssociatedRepository<Contact>) {
    super(repository);
    this.scroll = false;
  }
}
