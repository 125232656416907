import { observer } from "mobx-react";
import { singular } from "pluralize-me";

import Button, { ButtonVariant } from "@eman/emankit/Button";

import BaseList from "@component/BaseList";
import EnumType from "@model/EnumType";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import EnumListVM from "@vm/List/Enum";

export interface EnumListProps {
  vm: EnumListVM;
}

export const STATIC_ENUM_LIST = ["evidence_state_statuses", "allocation_statuses", "announcement_statuses", "user_types"];

@observer
export default class EnumList extends BaseList<EnumType, EnumListVM, EnumListProps> {
  searchable = false;
  vm: EnumListVM;

  get modelName() {
    return `enumeration_${this.vm.parentId}`;
  }

  constructor(props: EnumListProps) {
    super(props);

    this.vm = props.vm;
  }

  ta = (attribute: string): string => {
    return this.locs.ta("enumeration", attribute);
  };

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_enumeration_enum(this.vm.parentId));
  };

  headerProps() {
    return {
      text: this.locs.tm(singular(this.vm.parentId as any), 5),
      buttons: () => {
        // @ts-ignore
        if (STATIC_ENUM_LIST.indexOf(this.vm.parentId) >= 0) {
          return;
        }

        if (this.user.allowToObject(UserRightsObjects.ENUMERATION, UserRightsOperations.CREATE)) {
          return (
            <Button variant={ButtonVariant.Primary} onClick={this.onAddClick}>
              {this.locs.tg("enumeration_container.enumeration.add")}
            </Button>
          );
        }
        return null;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "name",
        type: "search",
        label: this.ta("name"),
      },
      {
        id: "order",
        type: "search",
        label: this.ta("order"),
      },
      {
        id: "code",
        type: "search",
        label: this.ta("code"),
      },
    ];

    return {
      filters,
      hideReset: true,
      hideFilterSettings: true,
      hideColumnSettings: true,
    };
  }

  renderRow = (item: EnumType, column: string) => {
    return item[column];
  };

  dataProps() {
    const columns = [this.createField("name", {}), this.createField("order", {}), this.createField("code", {})];

    let onClick;

    // This is a hack for not editable enumerations
    // @ts-ignore
    if (STATIC_ENUM_LIST.indexOf(this.vm.parentId) < 0) {
      onClick = (item: EnumType) => {
        if (this.user.allowToObject(UserRightsObjects.ENUMERATION, UserRightsOperations.EDIT)) {
          this.router.pageLink(this.uriHelper.edit_enumeration_enum(this.vm.parentId, item.id));
        }
      };
    }

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick,
    };
  }
}
