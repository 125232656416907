import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import EnumType from "@model/EnumType";
import EnumCreateVM from "@vm/Create/Enum";

import { lazyInject, TYPES } from "../../../inversify.config";
import Form from "./Form";

@observer
export default class EnumCreate extends CreateModal<EnumType> {
  modelName = "enumeration";
  prefix = "enumeration_container";

  @lazyInject(TYPES.EnumCreate)
  vm: EnumCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.enumeration_id);
    super.componentDidMount();
  }

  get autoReplyOptions() {
    return this.vm.entity.auto_reply;
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.show_enumeration(this.props.match.params.enumeration_id));
  };

  renderModalBody() {
    return (
      <Form
        entity={this.vm.entity}
        enumerationContainerName={`${this.vm.parentId}`}
        autoReplyOptions={this.autoReplyOptions}
        possibleJobPositions={this.vm.possibleJobPositions}
        possibleUsers={this.vm.possibleUsers}
        enums={this.vm.enums}
      />
    );
  }
}
