import HealthInsurance from "@model/HealthInsurance";

import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";

import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class HealthInsuranceCreateVM extends AssociatedCreateViewModel<
  HealthInsurance,
  AssociatedRepository<HealthInsurance>
> {
  constructor(@inject(TYPES.HealthInsuranceRepository) repository: AssociatedRepository<HealthInsurance>) {
    super(HealthInsurance, repository);
  }
}
