import RoleCreate from "@view/Role/Create";
import RoleEdit from "@view/Role/Edit";
import RoleList from "@view/Role/List";

import { uriHelper } from "../config";

import BaseRouter from "./Base";

export const rolesRoutes: IAppRoute[] = [
  {
    path: uriHelper.roles_uri(),
    exact: true,
    component: RoleList,
  },
  {
    path: uriHelper.edit_roles_uri(),
    component: RoleEdit,
    title: "role.edit",
  },
  {
    path: uriHelper.new_roles_uri(),
    component: RoleCreate,
    title: "role.create",
  },
];

export default class Roles extends BaseRouter {
  render() {
    return this.pageRouteRender(rolesRoutes);
  }
}
