import Dependent from "@model/Dependent";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";
import { injectable } from "inversify";

@injectable()
export default class DependentRepository extends BaseAssociatedRepository<Dependent> {
  constructor() {
    super(Dependent, "users/$ID/dependents", "dependent");
  }
}
