import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import Capacity from "@model/Capacity";
import CapacityEditVM from "@vm/Edit/Capacity";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import React from "react";
import { confirmYesNo } from "@eman/emankit/ConfirmAlert";
import EventBus, { JOB_TITLES_UPDATED, SHOW_TOAST } from "@util/EventBus";

import "./style.scss";

@observer
export default class CapacityEditModal extends EditModal<Capacity> {
  modelName = "capacity";
  prefix = "employee";

  @lazyInject(TYPES.CapacityEdit)
  vm: CapacityEditVM;

  componentDidMount(): void {
    const params = this.props.match.params;
    this.vm.workingTimeRatiosListVM.scroll = false;
    this.vm.workingTimeRatiosListVM.setId(params.user_id, params.contract_id);
    this.vm.setParentId(params.user_id);
    super.componentDidMount();
  }

  buttons() {
    const editButton = (
      <Button variant={ButtonVariant.Primary} onClick={this.onSave} disabled={!this.vm.allowedToSave}>
        {this.locs.tg("form.update")}
      </Button>
    );

    if (this.hasDelete) {
      return (
        <div className="row">
          <div className="col-6" style={{ textAlign: "left" }}>
            <Button variant={ButtonVariant.Dangerous} onClick={this.onDelete}>
              {this.locs.tg("form.delete")}
            </Button>
          </div>
          <div className="col-6">{editButton}</div>
        </div>
      );
    } else {
      return editButton;
    }
  }

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.show_employees_contracts(params.user_id, params.contract_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  onReset = () => {
    this.vm.resetEntityAndErrors();
  };

  onDelete = async () => {
    confirmYesNo(
      <>
        <p className="warning-message">{this.locs.tg("form.delete_confirm_capacity.delete_capacity")}</p>
        {this.locs.tg("form.delete_confirm_capacity.begin")}
        <br />
        {this.locs.tg("form.delete_confirm_capacity.end")}
      </>,
      async () => {
        const status = await this.vm.destroy();

        if (status) {
          EventBus.trigger(SHOW_TOAST, this.locs.tg("form.deleted"));
          this.onClose();
        }

        return status;
      },
      { confirmButtonType: ButtonVariant.SecondaryBlue, cancelButtonType: ButtonVariant.Primary }
    );
  };

  onSave = async () => {
    let status;
    confirmYesNo(
      <>
        <p className="warning-message">
          {this.locs.tg("form.edit_confirm_capacity.edit_capacity")}
          <br />
          {`${this.locs.tg("form.edit_confirm_capacity.from")} ${this.locs.formatDate(this.vm.entity.valid_from)} ${
            this.vm.entity.valid_to ? `${this.locs.tg("form.edit_confirm_capacity.to")}` : ""
          } ${this.locs.formatDate(this.vm.entity.valid_to)}`}
        </p>
        {this.locs.tg("form.edit_confirm_capacity.begin")}
        <br />
        {this.locs.tg("form.edit_confirm_capacity.end")}
      </>,
      async () => {
        const response = await this.vm.update();
        if (response) {
          this.vm.entity.clearErrors();
          EventBus.trigger(JOB_TITLES_UPDATED);
          EventBus.trigger(SHOW_TOAST, this.successMessage());
          this.onClose();
        }

        status = response;
      },
      { confirmButtonType: ButtonVariant.SecondaryBlue, cancelButtonType: ButtonVariant.Primary }
    );
    return status || false;
  };

  renderModalBody() {
    // Check #PPM-704 - there is an issue
    // - buttons() is not rendering (sometimes! that is the weirdest part)
    // - this void makes an observer for totalCapacity and it works like charm
    // @ts-ignore
    void this.vm.entity?.totalCapacity;

    return (
      <Form
        entity={this.vm.entity}
        vm={this.vm.capacityFormVM}
        allowedRatiosTitles={this.vm.workingTimeRatiosListVM.allowedRatiosTitlesEdit}
      />
    );
  }
}
