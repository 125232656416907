import { Exclude, Transform, Type } from "@eman/class-transformer";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";
import { enumValue } from "@util/Enumerable";

export default class PersonalDetail extends BaseModel {
  @observable dependents_count?: number;
  @observable birth_number: string;
  @observable birth_place: string;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  birth_date: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  first_contract_from: Date;

  @observable enumeration_education_level_id: string;
  @observable enumeration_gender_id: string;
  @observable enumeration_marital_status_id: string;

  // Enums
  @Exclude()
  @enumValue("genders", "enumeration_gender_id")
  @observable
  gender: models.IEnumType;

  @Exclude()
  @enumValue("marital_statuses", "enumeration_marital_status_id")
  @observable
  marital_status: models.IEnumType;

  @Exclude()
  @enumValue("education_levels", "enumeration_education_level_id")
  @observable
  education_level: models.IEnumType;
}
