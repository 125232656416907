import { addYears, subYears } from "date-fns";
import { computed, action } from "mobx";
import { inject, injectable } from "inversify";
import EnumService from "@service/Enum";
import EnumType from "@model/EnumType";
import School from "@model/School";
import UserShowVM from "@vm/Show/User";
import FormViewModel from "@vm/Form/FormViewModel";

import TYPES from "../../inversify.types";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class SchoolFormVM extends FormViewModel<School> {
  constructor(
    @inject(TYPES.Enum) private enums: EnumService,
    @inject(TYPES.EnumRepository) private repository: IEnumRepository<EnumType>,
    @inject(TYPES.UserShow) private userShowVM: UserShowVM
  ) {
    super();
    this.repository.setId("schools");
  }

  @computed
  get fromDate() {
    return this.userShowVM.entity?.personal_detail?.birth_date || subYears(new Date(), 100);
  }

  @computed
  get toDate() {
    const birth_date = this.userShowVM.entity?.personal_detail?.birth_date;

    return birth_date ? addYears(birth_date, 10) : subYears(new Date(), 100);
  }

  @computed
  get schoolOptions() {
    return this.enums.valuesForSelect("schools");
  }

  @action
  createNewSchool = async (name: string) => {
    const newItem = new EnumType();
    newItem.name = name;
    newItem.code = String(new Date().getTime());
    const result = await this.repository.create(newItem);
    if (result.entity) {
      await this.enums.fetchEnums();
      // FIXME: need fix in component AutoCreate when creating new option, will be send id of created option
      setTimeout(() => {
        this.entity.enumeration_school_id = result.entity!.id;
      }, 1000);
    }

    /* eslint-disable-next-line no-extra-boolean-cast */
    return !!result ? Promise.resolve() : Promise.reject();
  };
}
