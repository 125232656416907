import { observable } from "mobx";
import BaseModel from "@model/BaseModel";
import User from "./User";
import { Exclude, Type } from "@eman/class-transformer";

export default class Training extends BaseModel {
  @observable user_id: number;

  @Type(() => User)
  @observable
  supervisor: User;

  @Type(() => User)
  @observable
  user: User;

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  @observable
  user_confirmation?: Date;

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  @observable
  supervisor_confirmation?: Date;

  @observable enumeration_training_type_id: number;
  @observable url?: string;
}
