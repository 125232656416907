import { injectable } from "inversify";

import Contact from "@model/Contact";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class ContactRepository extends BaseAssociatedRepository<Contact> {
  constructor() {
    super(Contact, "users/$ID/contacts", "contact");
  }
}
