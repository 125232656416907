import BaseModel from "@model/BaseModel";
import CreateViewModel from "@vm/Create/CreateViewModel";
import { action, observable } from "mobx";

export default class AssociatedCreateViewModel<TModel extends BaseModel, TRepository extends AssociatedRepository<TModel>>
  extends CreateViewModel<TModel, TRepository>
  implements ViewModel.Associated {
  @observable parentId: number;

  @action
  setParentId(parentId: number) {
    this.parentId = parentId;
    this.repository.setId(parentId);
  }

  async create(): Promise<number | undefined> {
    if (this.parentId === undefined) {
      throw new Error("You have to set parentID first to use this associated VM");
    }

    return super.create();
  }
}
