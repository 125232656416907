import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import { confirmYesNo } from "@eman/emankit/ConfirmAlert";
import Toolbar from "@eman/emankit/Toolbar";

import EditModal from "@component/EditModal";
import Allocation, { AllocationEvent } from "@model/Allocation";
import { StateRightsOperations, UserRightsObjects, UserRightsOperations } from "@model/Rights";
import EventBus, { SHOW_TOAST } from "@util/EventBus";
import ViewHelpers from "@util/ViewHelpers";
import AllocationEditVM from "@vm/Edit/Allocation";
import AllocationLog from "./AllocationLog";
import Form from "./Form";

import { lazyInject } from "../../inversify.config";
import TYPES from "../../inversify.types";
import { computed } from "mobx";

@observer
export default class AllocationEditModal extends EditModal<Allocation> {
  modelName = "allocation";

  @lazyInject(TYPES.AllocationEdit)
  vm: AllocationEditVM;

  title(): string {
    return this.locs.tg(`allocation.${this.editable ? "edit" : "show"}.title`);
  }

  get editable() {
    return this.vm.entity.is_editable && this.user.allowToAllocation(this.vm.entity, UserRightsOperations.EDIT);
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.allocations());
  };

  onEventClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const event = e.currentTarget.getAttribute("data-event")! as AllocationEvent;

    const confirmMessage: React.ReactNode = this.locs.tg(`${this.modelName}.event_confirm`);
    confirmYesNo(confirmMessage, () => {
      this.performEvent(event);
    });
  };

  performEvent = async (event: AllocationEvent) => {
    const status = await this.vm.performEvent(event);

    if (status) {
      EventBus.trigger(SHOW_TOAST, this.locs.tg(`${this.modelName}.status_changed`));
    }
  };

  @computed
  get canEditBeforeToday(): boolean {
    return this.user.allowToObject(UserRightsObjects.TIME_LIMITS, UserRightsOperations.IGNORE_TODAYS_DEPENDENT_VALIDATIONS);
  }

  buttons = () => {
    const { entity } = this.vm;

    return (
      <div className="row">
        <div className={entity.is_editable ? "col-md-8" : "col-12"} style={{ display: "flex", alignItems: "center" }}>
          <Toolbar>
            {this.user.allowToAllocation(entity, StateRightsOperations.STATUS_CHANGE) &&
              entity.available_events
                .filter(event => this.user.allowToAllocation(entity, StateRightsOperations.STATUS_CHANGE, event))
                .sort((a, b) => {
                  if (a === AllocationEvent.APPROVE) {
                    return -1;
                  } else if (b === AllocationEvent.APPROVE) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map(event => (
                  <Button
                    data-id={entity.id}
                    data-event={event}
                    key={`action-${event}`}
                    onClick={this.onEventClick}
                    variant={ViewHelpers.allocationEventToButtonType(event)}
                    icon={ViewHelpers.allocationEventToIcon(event)}>
                    {this.locs.tg(`${this.modelName}.event.${event}`)}
                  </Button>
                ))}
          </Toolbar>
        </div>
        {this.editable && (
          <div className="col-md-4" style={{ textAlign: "right" }}>
            <Button variant={ButtonVariant.Primary} onClick={this.onSave}>
              {this.locs.tg("form.edit")}
            </Button>
          </div>
        )}
      </div>
    );
  };

  renderModalBody() {
    return (
      <>
        <Form
          entity={this.vm.entity}
          vm={this.vm.allocationFormVM}
          readonly={!this.editable}
          canCreateEditBeforeToday={this.canEditBeforeToday}
        />
        <AllocationLog entity={this.vm.entity} vm={this.vm.allocationFormVM} />
      </>
    );
  }
}
