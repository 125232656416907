import BaseList from "@component/BaseList";

import Badge from "@eman/emankit/Badge";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import { BaseColor } from "@eman/emankit/Colors/";
import HealthInsurance, { HealthInsuranceState } from "@model/HealthInsurance";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import HealthInsuranceListVM from "@vm/List/HealthInsurance";
import { observer } from "mobx-react";
import React from "react";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class HealthInsuranceList extends BaseList<HealthInsurance, HealthInsuranceListVM, PropsFromRouter> {
  modelName = "health_insurance";
  prefix = "employee";
  searchable = false;

  @lazyInject(TYPES.HealthInsuranceList)
  vm: HealthInsuranceListVM;

  initVM() {
    this.vm.init(this.props.match.params.user_id);
  }

  renderStateBadge = (state: HealthInsuranceState) => {
    let color: BaseColor;

    switch (state) {
      case HealthInsuranceState.ACTIVE:
        color = BaseColor.Green;
        break;

      case HealthInsuranceState.IN_PREPARATION:
        color = BaseColor.LightBlue;
        break;

      case HealthInsuranceState.CLOSED:
      default:
        color = BaseColor.Gray;
        break;
    }

    return <Badge color={color}>{this.tg(`state.${state}`)}</Badge>;
  };

  renderRow = (item: HealthInsurance, column: string): React.ReactNode => {
    switch (column) {
      case "enumeration_health_insurance_office_id":
        return item.health_insurance_office ? item.health_insurance_office.name : "";
      case "state":
        return this.renderStateBadge(item.state);
      case "valid_from":
        return this.locs.formatDate(item.valid_from);
      case "valid_to":
        return this.locs.formatDate(item.valid_to);
      default:
        return item[column];
    }
  };

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_employees_health_insurances(this.props.match.params.user_id));
  };

  headerProps() {
    return {
      text: this.tg("title"),
      level: 2,
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.HEALTH_INSURANCE, UserRightsOperations.CREATE)) {
          return (
            <Button variant={ButtonVariant.Primary} key="new_insurance" onClick={this.onAddClick}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "enumeration_health_insurance_office_id",
        label: this.ta("enumeration_health_insurance_office_id"),
        values: this.enums.valuesForSelect("health_insurance_offices"),
      },
      {
        id: "insurance_number",
        label: this.ta("insurance_number"),
        type: "search",
      },
      {
        id: "valid_from",
        label: this.ta("valid_from"),
        type: "date_range",
      },
      {
        id: "valid_to",
        label: this.ta("valid_to"),
        type: "date_range",
      },
    ];

    return {
      filters,
      hideReset: true,
      hideFilterSettings: true,
      hideColumnSettings: true,
    };
  }

  dataProps() {
    const columns = [
      this.createField("enumeration_health_insurance_office_id", {}),
      this.createField("insurance_number", {}),
      this.createField("valid_from", { width: 100 }),
      this.createField("valid_to", { width: 100 }),
      this.createField("state", { width: 100, nosort: true }),
    ];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: HealthInsurance) => {
        this.objectEditOrShowLink("employees_health_insurances", [this.props.match.params.user_id, item.id]);
      },
    };
  }
}
