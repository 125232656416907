import { observer } from "mobx-react";
import React from "react";

import Footer from "@eman/emankit/Footer";

import BaseComponent from "@component/BaseComponent";

@observer
export default class PageFooter extends BaseComponent {
  render() {
    const text = (
      <span>{`Copyright eMan a.s. ${new Date().getUTCFullYear()}  ${process.env.APP_VERSION} (${process.env.DEPLOYED_AT})`}</span>
    );

    return <Footer text={text} />;
  }
}
