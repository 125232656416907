import ResourceComponent from "@component/ResourceComponent";
import Allocation from "@model/Allocation";
import { observer } from "mobx-react";
import LogRow from "@view/Allocation/AllocationLog/LogRow";
import Avatar from "@component/Avatar";
import ViewHelpers from "@util/ViewHelpers";
import AllocationFormVM from "@vm/Form/Allocation";

export interface AllocationLogProps {
  entity: Allocation;
  vm: AllocationFormVM;
}

@observer
export default class AllocationLog extends ResourceComponent<AllocationLogProps> {
  modelName = "allocation_history";
  prefix = "allocation";

  render() {
    const entity = this.props.entity;

    if (!entity.id) {
      return null;
    }

    const histories = entity.allocation_histories;

    return (
      <>
        <div className="emankit__input_container">
          <label>{this.tg("title")}</label>
        </div>
        <table className="mt-12" style={{ width: "100%" }}>
          <tbody>
            {histories.map(history => (
              <LogRow entity={history} key={history.id} vm={this.props.vm} />
            ))}
            <tr>
              <td style={{ width: 130, verticalAlign: "top", lineHeight: "20px" }}>
                {this.locs.formatDateTime(entity.created_at)}
              </td>
              <td style={{ width: 25, verticalAlign: "top" }}>
                <Avatar user={entity.author} size={20} />
              </td>
              <td style={{ width: 130, verticalAlign: "top", lineHeight: "20px" }}>
                {ViewHelpers.userName(entity.author, false)}
              </td>
              <td>{this.tg("created")}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}
