import { injectable } from "inversify";

import Role from "@model/Role";
import BaseRepository from "@repository/BaseRepository";
import ApiClient, { AxiosConfig } from "@util/ApiClient";

@injectable()
export default class RoleRepository extends BaseRepository<Role> {
  constructor() {
    super(Role, "roles", "role");
  }

  fetchRights() {
    const config: AxiosConfig = {
      id: "FETCH_RIGHTS",
      url: "/enumerations/rights",
      method: "get",
    };

    return ApiClient.fetchData(config);
  }
}
