import React from "react";
import { observer } from "mobx-react";
import SmallList from "@component/SmallList";
import { lazyInject, TYPES } from "../../../../inversify.config";
import WorkingTimeRatio from "@model/WorkingTimeRatio";
import WorkingTimeRatiosListVM from "@vm/List/WorkingTimeRatios";
import Button from "@eman/emankit/Button";
import Badge from "@eman/emankit/Badge";
import ViewHelpers from "@util/ViewHelpers";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import { RATIO_SPLIT } from "../../../../config";

import "./style.scss";

function extractPositionNamesAndRatios(item: WorkingTimeRatio): { position: string; ratio: number; organization: string }[] {
  return item.job_title_working_time_ratios.map(workingTimeRatio => {
    const position = workingTimeRatio.job_title.job_position.name;
    const ratio = workingTimeRatio.ratio;
    const organization = workingTimeRatio.job_title.organization_structure.name;
    return {
      position,
      ratio,
      organization,
    };
  });
}

@observer
export default class WorkingTimeRatiosList extends SmallList<WorkingTimeRatio, WorkingTimeRatiosListVM, PropsFromRouter> {
  modelName = "job_title";
  prefix = "employee.contract";

  @lazyInject(TYPES.WorkingTimeRatiosList)
  vm: WorkingTimeRatiosListVM;

  initVM() {
    this.vm.init();

    // React to URL change (if changed setId will ensure that this is called only once)
    this.setIdFromParams();
  }

  componentDidUpdate() {
    // React to URL change (if changed setId will ensure that this is called only once)
    this.setIdFromParams();
  }

  setIdFromParams = () => {
    const params = this.props.match.params;
    const employeeId = parseInt(params.user_id, 10);
    const contractId = parseInt(params.contract_id, 10);
    this.vm.setId(employeeId, contractId);
  };

  componentWillUnmount() {
    this.vm.dispose();
  }

  onEditClick = (item: WorkingTimeRatio, isSplit: boolean) => {
    const params = this.props.match.params;
    this.objectEditOrShowLink(
      "employees_contracts_working_time_ratios",
      [params.user_id, params.contract_id, item.id],
      isSplit && { [RATIO_SPLIT]: true }
    );
  };

  renderRow = (item: WorkingTimeRatio, column: string) => {
    switch (column) {
      case "valid_from":
        return this.locs.formatDate(item.valid_from);

      case "valid_to":
        return item.valid_to ? this.locs.formatDate(item.valid_to) : "";

      case "ratios":
        const positionNamesAndRatios = extractPositionNamesAndRatios(item);
        return positionNamesAndRatios.map(({ position, ratio, organization }, key) => {
          return (
            <div className="ratios-row" key={key}>
              <span>
                <strong>{position}</strong>
                &nbsp; ({(ratio * 100).toFixed(2)}%)
              </span>
              {` - `}
              <span>
                <strong>{organization}</strong>
              </span>
              <br />
            </div>
          );
        });

      case "state": {
        return <Badge color={ViewHelpers.jobTitleStateToColor(item.state)}>{this.tg(`state.${item.state}`)}</Badge>;
      }
      case "actions": {
        if (
          (item.inPreparation || item.isClosed || (item.isActive && item.valid_from?.getTime() !== item.valid_to?.getTime())) &&
          this.user.allowToObject(UserRightsObjects.WORKING_TIME_RATIO, UserRightsOperations.EDIT) &&
          this.user.allowToObject(UserRightsObjects.WORKING_TIME_RATIO, UserRightsOperations.SHOW) &&
          this.user.allowToObject(UserRightsObjects.WORKING_TIME_RATIO, UserRightsOperations.CREATE)
        ) {
          return (
            <>
              {item.isActive && (
                <Button small={true} onClick={() => this.onEditClick(item, item.isActive)} className="button-margin">
                  {this.locs.tg("split")}
                </Button>
              )}
              {this.user.allowToObject(UserRightsObjects.TIME_LIMITS, UserRightsOperations.IGNORE_TODAYS_DEPENDENT_VALIDATIONS) &&
                (item.isClosed || item.isActive) && (
                  <Button small={true} onClick={() => this.onEditClick(item, false)}>
                    {this.locs.tg(`${"edit"}`)}
                  </Button>
                )}
              {item.inPreparation && (
                <Button small={true} onClick={() => this.onEditClick(item, false)}>
                  {this.locs.tg(`${"edit"}`)}
                </Button>
              )}
            </>
          );
        }
        return "";
      }

      default:
        return "";
    }
  };

  rows() {
    return [
      this.createField("valid_from", { nosort: true }),
      this.createField("valid_to", { nosort: true }),
      this.createField("ratios", { nosort: true }),
      this.createField("state", { nosort: true }),
      this.createField("actions", { nosort: true, hideLabel: true }),
    ];
  }
}
