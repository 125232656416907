import BaseModel from "@model/BaseModel";
import { observable } from "mobx";
import AllocationValue from "@model/AllocationValue";
import User from "@model/User";
import { AllocationRecalculate, AllocationStatus } from "@model/Allocation";
import { Type } from "@eman/class-transformer";

export class AllocationHistoryDetail {
  @Type(() => Date)
  @observable
  valid_to?: [Date, Date];

  @Type(() => Date)
  @observable
  valid_from?: [Date, Date];

  @Type(() => AllocationValue)
  @observable
  value?: [AllocationValue, AllocationValue];

  @observable enumeration_allocation_status?: [AllocationStatus, AllocationStatus];
  @observable enumeration_allocation_type_id?: [number, number];
  @observable note?: [string, string];
  @observable user_id?: [number, number];
  @observable project_id?: [number, number];
  @observable recalculate: [AllocationRecalculate, AllocationRecalculate];
  @observable updated_at: [Date, Date];
  @observable updated_by_id?: [number, number];
}

export default class AllocationHistory extends BaseModel {
  @Type(() => AllocationHistoryDetail)
  @observable
  detail: AllocationHistoryDetail;
  @observable author_id: number;

  @Type(() => User)
  @observable
  author: User;
}
