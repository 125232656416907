import { injectable } from "inversify";

import Employee from "@model/Employee";
import BaseRepository from "@repository/BaseRepository";

@injectable()
export default class EmployeeRepository extends BaseRepository<Employee> {
  constructor() {
    super(Employee, "employees", "employee");
  }
}
