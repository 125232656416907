const ATTRIBUTES_KEY = Symbol("enum:attributes");

export interface EnumReflectAttributes {
  [key: string]: EnumReflectAttribute;
}

export interface EnumReflectAttribute {
  name: string;
  source: string;
  secondSource: string;
  array: boolean;
}

export function getEnumAttributes(target: any): EnumReflectAttributes | undefined {
  const attributes = Reflect.getMetadata(ATTRIBUTES_KEY, target);

  if (attributes) {
    return Object.keys(attributes).reduce((copy, key) => {
      copy[key] = { ...attributes[key] };

      return copy;
    }, {});
  } else {
    return undefined;
  }
}

export function getEnumAttribute(
  target: any,
  attribute: string
): { targetAttribute: string; sourceData: EnumReflectAttribute } | undefined {
  const attributes = Reflect.getMetadata(ATTRIBUTES_KEY, target);

  if (attributes) {
    const targetAttribute = Object.keys(attributes).find(key => attributes[key].source === attribute);
    if (targetAttribute) {
      return { targetAttribute, sourceData: attributes[targetAttribute] };
    }
  }

  return undefined;
}

export function setEnumAttributes(target: any, attributes: any): void {
  Reflect.defineMetadata(ATTRIBUTES_KEY, { ...attributes }, target);
}

export function addEnumAttribute(target: any, name: string, options: any): void {
  let attributes = getEnumAttributes(target);

  if (!attributes) {
    attributes = {};
  }
  attributes[name] = { ...options };

  setEnumAttributes(target, attributes);
}

export function enumValue(name: string, source: string, array = false) {
  return (target: any, propertyName: string, propertyDescriptor?: PropertyDescriptor) => {
    addEnumAttribute(target, propertyName, { name, source, array });
  };
}

export function enumCompositeValue(name: string, source: string, secondSource: string) {
  return (target: any, propertyName: string, propertyDescriptor?: PropertyDescriptor) => {
    addEnumAttribute(target, propertyName, { name, source, secondSource });
  };
}
