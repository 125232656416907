import { injectable } from "inversify";

import BaseRepository from "@repository/BaseRepository";
import AllocationUser from "@model/AllocationUser";

@injectable()
export default class AllocationUserRepository extends BaseRepository<AllocationUser> {
  constructor() {
    super(AllocationUser, "allocation_users", "allocation_user");
  }
}
