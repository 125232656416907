import { computed, observable } from "mobx";
import { Transform, Type } from "@eman/class-transformer";
import { enumValue } from "@util/Enumerable";
import BaseModel from "@model/BaseModel";
import AllocationHistory from "@model/AllocationHistory";
import AllocationValidFromTime from "@model/AllocationValidFromTime";
import AllocationValue from "@model/AllocationValue";
import Project from "@model/Project";
import User from "@model/User";
import JobTitle from "./JobTitle";

export enum AllocationStatus {
  APPROVED = "approved",
  REQUEST = "request",
  WARNING = "warning",
  PROBLEM = "problem",
  REJECTED = "rejected",
  CANCELED = "canceled",
}

export enum AllocationEvent {
  APPROVE = "approve",
  REOPEN = "reopen",
  REJECT = "reject",
  CANCEL = "cancel",
  WARNING = "warning",
  PROBLEM = "problem",
}

export enum AllocationRecalculate {
  WAITING = "waiting",
  DONE = "done",
  IN_PROGRESS = "in_progress",
}

export default class Allocation extends BaseModel {
  @observable enumeration_allocation_type_id: number;
  @observable enumeration_allocation_status: AllocationStatus;
  @observable user_id: number;
  @observable author_id: number;
  @observable note: string;
  @observable project_id: number;

  @observable is_work: boolean;
  @observable recalculate: AllocationRecalculate;

  @observable job_title_working_time_ratio_id: number;

  // Parent group (for project)
  @observable project_parent_id?: number;

  @Type(() => Project)
  @observable
  project: Project;

  @Type(() => User)
  @observable
  author: User;

  @Type(() => User)
  @observable
  user: User;

  @Type(() => AllocationValidFromTime)
  @observable
  valid_from_time?: AllocationValidFromTime = new AllocationValidFromTime();

  @Type(() => AllocationValue)
  @observable
  value: AllocationValue = new AllocationValue();

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;

  @observable
  available_events: AllocationEvent[] = [];

  @observable
  @Type(() => AllocationHistory)
  allocation_histories: AllocationHistory[] = [];

  @enumValue("allocation_types", "enumeration_allocation_type_id")
  @observable
  allocation_type: models.IEnumType;

  @observable is_editable: boolean;

  @observable service: boolean;

  isDirtyRelations(): string[] {
    return ["value"];
  }

  @observable
  job_title: JobTitle;

  @Type(() => Number)
  @observable
  job_title_id: number;

  @observable
  capacity_id: number;

  @observable organization_structure_id: number;

  @observable event: AllocationEvent;

  @computed
  get isApproved(): boolean {
    return this.enumeration_allocation_status === AllocationStatus.APPROVED;
  }

  @computed
  get isCanceled(): boolean {
    return this.enumeration_allocation_status === AllocationStatus.CANCELED;
  }

  @computed
  get isProblem(): boolean {
    return this.enumeration_allocation_status === AllocationStatus.PROBLEM;
  }

  @computed
  get isRejected(): boolean {
    return this.enumeration_allocation_status === AllocationStatus.REJECTED;
  }

  @computed
  get isRequested(): boolean {
    return this.enumeration_allocation_status === AllocationStatus.REQUEST;
  }

  @computed
  get isWarning(): boolean {
    return this.enumeration_allocation_status === AllocationStatus.WARNING;
  }
}
