import { observer } from "mobx-react";
import React from "react";

import Columns from "@eman/emankit/Columns";

import BaseForm from "@component/BaseForm";
import Address from "@model/Address";
import { DatePickerBox, SelectBox, TextBox } from "@util/Form";

@observer
export default class AddressForm extends BaseForm<Address> {
  modelName = "address";

  renderFormBody() {
    const { entity } = this.props;

    return (
      <Columns>
        {this.generateFormField(
          "enumeration_address_type_id",
          <SelectBox options={this.enums.valuesForSelect("address_types")} emptyRow={this.locs.tg("form.select_value")} />,
          true
        )}
        {this.generateFormField("street", <TextBox />, true)}
        {this.generateFormField("house_number", <TextBox />, !entity.street_number)}
        {this.generateFormField("street_number", <TextBox />, !entity.house_number)}
        {this.generateFormField("city", <TextBox />, true)}
        {this.generateFormField("zip", <TextBox />, true)}
        {this.generateFormField("evidence_number", <TextBox />)}
        {this.generateFormField("city_part", <TextBox />)}
        {this.generateFormField("valid_from", <DatePickerBox />)}
        {this.generateFormField("valid_to", <DatePickerBox />)}
        {this.generateFormField("description", <TextBox />)}
      </Columns>
    );
  }
}
