import { OptionType } from "@eman/emankit";
import ViewHelpers from "@util/ViewHelpers";
import { injectable } from "inversify";
import { computed } from "mobx";
import OptionsVM from "./Options";

@injectable()
export default class GroupedOptionsVM<TModel extends models.IGroupedModel> extends OptionsVM<TModel> {
  async fetchItems(filters?: FilterValues) {
    this.isInitialized = false;
    const data = await this.repository.fetchList({
      order: this.order as OrderOptions,
      pagination: this.pagination,
      filters,
      loading: false,
    });

    this.items = data.list;
    this.isInitialized = true;
  }

  @computed
  get selectOptions(): OptionType<number>[] {
    return this.items.map(this.itemMapper);
  }

  @computed
  get parentOptions(): OptionType<number>[] {
    // Map group items
    const ids: number[] = [];
    const options: OptionType<number>[] = [];

    this.items
      .filter(item => item.closed === false)
      .forEach(item => {
        const group = item.meta?.group;
        if (group?.id && ids.indexOf(group?.id) < 0 && this.parentOptionHardCodedFilter(group?.name, group?.unit)) {
          ids.push(group.id);
          options.push({
            value: group.id,
            label: ViewHelpers.formatNameWithProjectName(item),
          });
        }
      });

    return options;
  }

  // TODO: Move additional filtering into form administration (Not yet implemented)
  parentOptionHardCodedFilter(name: string, unit: string): boolean {
    const FILTERED_NAMES = [
      "Template",
      "Meeting Room Management",
      "PKI Certificate Authority",
      "Single Sign-on",
      "_CONTRACTORS",
      "Helpdesk",
      "Projektová CV",
      "_Future People &Overtime",
    ];
    const FILTERED_UNITS = ["Personalistika", "Office"];

    for (const value of FILTERED_NAMES) {
      if (name?.includes(value)) return false;
    }

    for (const value of FILTERED_UNITS) {
      if (unit?.includes(value)) return false;
    }

    return true;
  }

  childOptions(parentId: number): OptionType<number>[] {
    return this.items.filter(item => item.meta?.group?.id === parentId).map(this.itemMapper);
  }

  itemMapper = (item: TModel): OptionType<number> => ({
    value: item.id as number,
    label: this.nameProperties.map(propertyName => item[propertyName]).join(" "),
  });
}
