import axios from "axios";
import { action } from "mobx";
import { inject, injectable } from "inversify";
import TYPES from "../inversify.types";
import User from "@model/User";

@injectable()
export default class AvatarService {
  private repository: IUserRepository<User>;

  constructor(@inject(TYPES.UserRepository) repository: IUserRepository<User>) {
    this.repository = repository;
  }

  @action
  async uploadAvatar(userId: number, files: File[]): Promise<boolean> {
    return this.repository.uploadAvatar(userId, files[0]).then(response => response.status);
  }

  @action
  async deleteAvatar(userId: number): Promise<boolean> {
    return this.repository.deleteAvatar(userId).then(response => response.status);
  }

  getUrl(userId: number, lastUpdatedAt = new Date()) {
    return `${axios.defaults.baseURL}/users/${userId}/avatar?time=${lastUpdatedAt.getTime()}`;
  }
}
