import { inject, injectable } from "inversify";

import Address from "@model/Address";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class AddressListVM extends AssociatedListViewModel<Address, AssociatedRepository<Address>> {
  static defaults = {
    order: {
      field: "id",
      direction: "asc",
    },
    columns: [
      "enumeration_address_type_id",
      "street",
      "house_number",
      "street_number",
      "city",
      "zip",
      "city_part",
      "description",
    ],
    visibleFilters: ["enumeration_address_type_id"],
  };

  constructor(@inject(TYPES.AddressRepository) repository: AssociatedRepository<Address>) {
    super(repository);
    this.scroll = false;
  }
}
