import { observer } from "mobx-react";
import React from "react";
import BaseForm from "@component/BaseForm";
import WorkingTimeRatio from "@model/WorkingTimeRatio";
import WorkingTimeRatioSlider, { StatePatch } from "@component/WorkingTimeRatioSlider";
import { HoursInWorkWeek } from "@model/Rate";
import { JobTitleState } from "@model/JobTitle";
import { DatePickerBox } from "@util/Form";
import { runInAction } from "mobx";
import { RATIO_SPLIT } from "src/config";
import { add } from "date-fns";

@observer
export default class WorkingTimeRatiosForm extends BaseForm<
  WorkingTimeRatio,
  { allowedToSave?: boolean; activeRatioDateFrom: Date }
> {
  modelName = "working_time_ratio";
  prefix = "employee.contract";

  leftColumnFields = (): React.ReactNode => {
    const queryParams = this.router.getQuery() || {};
    const isSplit = queryParams[RATIO_SPLIT] === "true";
    const selectedDate = this.props.entity.valid_from;
    const fromDateLimit = this.props.activeRatioDateFrom;

    let dateFrom = selectedDate;
    if (isSplit) {
      dateFrom = selectedDate > fromDateLimit ? selectedDate : fromDateLimit;
    } else {
      dateFrom = selectedDate || add(new Date(), { days: 1 });
    }

    const timeZoneOffsetInHours = -(dateFrom.getTimezoneOffset() / 60);

    dateFrom.setHours(dateFrom.getHours() + timeZoneOffsetInHours);

    runInAction(() => {
      this.props.entity.valid_from = dateFrom;
    });

    return [
      this.generateFormField(
        "valid_from",
        <DatePickerBox
          dateFrom={dateFrom}
          disabledDays={{
            before: isSplit ? fromDateLimit : dateFrom,
            after: this.props.entity.valid_to,
          }}
          disabledInput={!isSplit}
        />,
        true
      ),
    ];
  };

  rightColumnFields = (): React.ReactNode => {
    const now = new Date();
    const timeZoneOffsetInHours = -(now.getTimezoneOffset() / 60);

    const validFrom = this.props.entity.valid_from;

    const dateFrom = validFrom > now ? validFrom : now;
    dateFrom.setHours(dateFrom.getHours() + timeZoneOffsetInHours);

    return [
      this.generateFormField(
        "valid_to",
        <DatePickerBox
          dateFrom={dateFrom}
          disabledDays={{ after: this.props.entity.valid_to }}
          dateTo={this.props.entity.valid_to || undefined}
          disabledInput={
            [JobTitleState.IN_PREPARATION, JobTitleState.CLOSED, JobTitleState.ACTIVE].indexOf(this.props.entity.state) !== -1
          }
        />
      ),
    ];
  };

  onRatiosUpdate = (patch: StatePatch): void => {
    this.props.entity.job_title_working_time_ratios.forEach(ratio => {
      ratio.ratio = patch[ratio.id!];
    });
  };

  renderFormBody() {
    return (
      <>
        <div className="row">
          <div className="col-md-6 col-sm-12">{this.leftColumnFields()}</div>
          <div className="col-md-6 col-sm-12">{this.rightColumnFields()}</div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="working-time-ratio-slider-group emankit__input_container">
              <label>{this.tg("ratio")}</label>
              <WorkingTimeRatioSlider
                availableWorkingHours={HoursInWorkWeek}
                ratios={this.props.entity.job_title_working_time_ratios}
                onChange={this.onRatiosUpdate}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
