import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import HeaderRow from "@eman/emankit/HeaderRow";

import ResourceComponent from "@component/ResourceComponent";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import UserEditVM from "@vm/Edit/User";
import SchoolsList from "./List";

export interface SchoolsProps extends PropsFromRouter {
  vm: UserEditVM;
}

@observer
export default class Schools extends ResourceComponent<SchoolsProps> {
  suffix = "school";
  modelName = "employee.personal_information";

  onAddClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.router.pageLink(this.uriHelper.new_employees_personal_data_schools(this.props.vm.id));
  };

  render() {
    let button;
    if (this.user.allowToObject(UserRightsObjects.SCHOOL, UserRightsOperations.CREATE)) {
      button = (
        <Button variant={ButtonVariant.Active} onClick={this.onAddClick}>
          {this.tg("add")}
        </Button>
      );
    }

    return (
      <>
        <HeaderRow title={this.tg("title")} level={2}>
          {button}
        </HeaderRow>
        <SchoolsList userId={this.props.match.params.user_id} />
      </>
    );
  }
}
