import { observer } from "mobx-react";
import React from "react";

import CheckBoxInput from "@eman/emankit/Input/CheckBox";

import BindingElement, { BindingProps } from "./index";

@observer
export default class CheckBox extends BindingElement<BindingProps<any>, any> {
  protected handleValueChanged = () => {
    this.setValue(!this.value);
  };

  // tslint:disable-next-line:member-ordering
  render() {
    const { skipDirty, ...others } = this.props;

    return <CheckBoxInput value={this.value} onChange={this.handleValueChanged} {...others} />;
  }
}
