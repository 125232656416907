import BaseModel from "@model/BaseModel";
import { observable } from "mobx";
import { Type } from "@eman/class-transformer";
import JobPosition from "./JobPosition";
import Capacity from "./Capacity";
import OrganizationStructure from "./OrganizationStructure";

export default class AllocationJobTitle extends BaseModel {
  @Type(() => Number)
  @observable
  job_position_id: number;

  @Type(() => Number)
  @observable
  user_id: number;

  @Type(() => JobPosition)
  job_position: JobPosition;

  @Type(() => OrganizationStructure)
  organization_structure: OrganizationStructure;

  @Type(() => Capacity)
  capacities: Capacity[];
}
