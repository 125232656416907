import { Transform, Type } from "@eman/class-transformer";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";

export default class PreviousEmployment extends BaseModel {
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;

  @observable company_name: string;
  @observable note: string;

  @observable job_position_name: string;
}
