import { inject, injectable } from "inversify";

import School from "@model/School";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class SchoolListVM extends AssociatedListViewModel<School, AssociatedRepository<School>> {
  constructor(@inject(TYPES.SchoolRepository) repository: AssociatedRepository<School>) {
    super(repository);
    this.scroll = false;
    this.save = false;
  }
}
