import { observer } from "mobx-react";

import LoginForm from "@component/Login";

import BaseComponent from "../../Components/BaseComponent";

@observer
export default class Login extends BaseComponent {
  handleLogin = (e: any) => {
    e.preventDefault();
    return this.user.loginUser();
  };

  render() {
    return (
      <div className="centered-content">
        <LoginForm onLogin={this.handleLogin} />
      </div>
    );
  }
}
