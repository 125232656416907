import { Exclude, Type } from "@eman/class-transformer";
import { observable } from "mobx";
import JobTitle, { JobTitleState } from "./JobTitle";

// JobTitleWorkingTimeRatio is used without extending baseModel, because of workaround with exclude and expose overloading from class transformer
export default class JobTitleWorkingTimeRatio {
  @observable job_title_id: number;
  @observable ratio: number;

  @observable id?: number;

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  @observable
  created_at: Date;

  @Exclude({ toPlainOnly: true })
  @Type(() => Date)
  @observable
  updated_at: Date;

  @Exclude({ toPlainOnly: true })
  @Type(() => JobTitle)
  @observable
  job_title: JobTitle;

  state: JobTitleState;
}
