import { observer } from "mobx-react";
import React from "react";

import Columns from "@eman/emankit/Columns";

import BaseForm from "@component/BaseForm";
import BranchOffice from "@model/BranchOffice";
import { TextBox } from "@util/Form";

@observer
export default class BranchOfficeForm extends BaseForm<BranchOffice> {
  modelName = "branch_office";

  renderFormBody() {
    return <Columns>{this.generateFormField("name", <TextBox />, true)}</Columns>;
  }
}
