import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import Training from "@model/Training";
import TrainingEditVM from "@vm/Edit/Training";

import TrainingForm from "./Form";
import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class TrainingEdit extends EditModal<Training> {
  modelName = "training";
  prefix = "employee";

  @lazyInject(TYPES.TrainingEdit)
  vm: TrainingEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_trainings(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <>
        <TrainingForm entity={this.vm.entity} possibleUsers={this.vm.possibleUsers} />
      </>
    );
  }
}
