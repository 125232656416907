import { observer } from "mobx-react";

import BaseForm from "@component/BaseForm";
import PersonalDocument, { PersonalDocumentType } from "@model/PersonalDocument";
import { DatePickerBox, SelectBox, TextBox, TextAreaBox } from "@util/Form";
import PersonalDocumentFormVM from "@vm/Form/PersonalDocument";

export interface PersonalDocumentFormProps {
  vm: PersonalDocumentFormVM;
}
@observer
export default class PersonalDocumentForm extends BaseForm<PersonalDocument, PersonalDocumentFormProps> {
  modelName = "personal_document";
  prefix = "employee.personal_information";

  handleTypeChanged = (value: PersonalDocumentType): void => {
    if (
      [PersonalDocumentType.IDENTITY_CARD, PersonalDocumentType.PASSPORT, PersonalDocumentType.DRIVING_LICENSE].indexOf(value) !==
      -1
    ) {
      this.props.vm.fromBirth = false;
    } else {
      this.props.vm.fromBirth = true;
    }
  };

  renderFormBody() {
    return (
      <div className="row">
        <div className="col-md-6">
          {this.generateFormField(
            "enumeration_personal_document_type_id",
            <SelectBox
              options={this.enums.valuesForSelect("personal_document_types")}
              emptyRow={this.emptyRow}
              onValueChanged={this.handleTypeChanged}
            />,
            true
          )}
        </div>
        <div className="col-md-6">{this.generateFormField("number", <TextBox />, true)}</div>
        <div className="col-md-6">{this.generateFormField("issuer", <TextBox />, true)}</div>
        <div className="col-md-6">
          {this.generateFormField(
            "issuer_state_id",
            <SelectBox options={this.enums.valuesForSelect("states")} emptyRow={this.emptyRow} />,
            true
          )}
        </div>
        <div className="col-md-6">
          {this.generateFormField("valid_from", <DatePickerBox fromMonth={this.props.vm.fromDate} />)}
        </div>
        <div className="col-md-6">{this.generateFormField("valid_to", <DatePickerBox fromMonth={this.props.vm.fromDate} />)}</div>
        <div className="col-md-12">{this.generateFormField("note", <TextAreaBox />)}</div>
      </div>
    );
  }
}
