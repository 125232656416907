import React from "react";

import ResourceComponent from "@component/ResourceComponent";

// Just pass props to component
export default abstract class Edit<
  TModel extends models.IBase,
  TViewModel extends ViewModel.Edit<TModel>,
  OtherProps = {},
  OtherState = {}
> extends ResourceComponent<PropsFromRouter & OtherProps, OtherState> {
  suffix = "edit";

  abstract vm: TViewModel;

  get entity(): TModel {
    return this.vm.entity;
  }

  constructor(props: PropsFromRouter & OtherProps) {
    super(props);
  }

  componentDidMount(): void {
    this.setIdFromParams();
  }

  componentDidUpdate() {
    this.setIdFromParams();
  }

  /**
   * Method get ID from params and update view model context, this is because you can "click" in notifications area
   * to link to edit on page where you are... If you want to load additional data use ViewModel or override this
   * in subclasses.
   */
  setIdFromParams() {
    const params = this.props.match.params;
    // tslint:disable-next-line: no-string-literal
    const key = `${this.vm.repository["modelName"]}_id`; // TODO: This hack I personaly dont like it... but it will works for sub-show / list vm either..
    this.vm.init(params[key]);
  }

  componentWillUnmount() {
    this.vm.cleanUp();
  }

  createFakeRow(text: string, children: React.ReactNode): React.ReactNode {
    return (
      <div key={text} className="row form-group mnd__input_container">
        <div className="col-sm-2 col-form-label">{text}</div>
        <div className="col-sm-10 form-fake-input-div">{children}</div>
      </div>
    );
  }

  render(): React.ReactNode {
    throw new Error("Subclass must overrides this!");
  }
}
