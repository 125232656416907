import { injectable } from "inversify";

import Project from "@model/Project";
import BaseRepository from "@repository/BaseRepository";

@injectable()
export default class ProjectRepository extends BaseRepository<Project> {
  constructor() {
    super(Project, "projects", "project");
  }
}
