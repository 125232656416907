import { injectable } from "inversify";

import BaseRepository from "@repository/BaseRepository";
import BalanceGroup from "@model/BalanceGroup";

@injectable()
export default class BalanceGroupRepository extends BaseRepository<BalanceGroup> {
  constructor() {
    super(BalanceGroup, "balance_groups", "balance_group");
  }
}
