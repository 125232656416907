// Polyfills
import "react-app-polyfill/ie11";

// Import DI
import { container, TYPES } from "./inversify.config";

import Raven from "raven-js";
import React from "react";
import ReactDOM from "react-dom";
import EventBus, { SCROLL_TOP } from "./Utils/EventBus";

// Default app container
import Router from "./Router/index";

// Import theme from UIkit
import "@eman/emankit/index.css";
import "src/Libs/diagram.css";

// Start raven
// filter error with 401 status
if (process.env.SENTRY_DSN && process.env.CI_COMMIT_SHA) {
  Raven.config(process.env.SENTRY_DSN, {
    release: process.env.CI_COMMIT_SHA,
    ignoreErrors: [/Request failed with status code 401/],
  }).install();
}

// Bind scroll top event
EventBus.on(SCROLL_TOP, () => {
  window.scrollTo(0, 0);
});

// Try to fetch default values from containers
container.get<Services.Localization>(TYPES.Localization).fetchLocalizations();
container.get<Services.CurrentUser>(TYPES.User).fetchUser();

// Debug only
window["container"] = container;
window["types"] = TYPES;

// TODO: If we use inject in Router we have problem, that compiler fails on required arguments.
ReactDOM.render(
  <React.Fragment>
    <Router />
  </React.Fragment>,
  document.getElementById("root") as HTMLElement
);
