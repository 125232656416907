import { inject, injectable } from "inversify";

import HealthInsurance from "@model/HealthInsurance";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class HealthInsuranceListVM extends AssociatedListViewModel<
  HealthInsurance,
  AssociatedRepository<HealthInsurance>
> {
  static defaults = {
    order: {
      field: "valid_from",
      direction: "desc",
    },
    columns: ["enumeration_health_insurance_office_id", "insurance_number", "valid_from", "valid_to", "state"],
  };

  constructor(@inject(TYPES.HealthInsuranceRepository) repository: AssociatedRepository<HealthInsurance>) {
    super(repository);
  }
}
