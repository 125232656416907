import { inject, injectable, unmanaged } from "inversify";
import { action, observable } from "mobx";

import EnumVM from "@service/Enum";
import EventBus, { SCROLL_TOP } from "@util/EventBus";
import ViewModel from "../ViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

/**
 * Abstract parent of all Show view models.
 *
 * @author Jan Strnadek <jan.strnadek@eman.cz>
 * @version 0.1
 */
@injectable()
export default abstract class ShowViewModel<TModel extends models.IBase, TRepository extends Repository<TModel>>
  extends ViewModel<TModel, TRepository>
  implements ViewModel.Show<TModel> {
  /**
   * Entity id to fetch.
   */
  @observable id: number;

  @inject(TYPES.Enum)
  enums: EnumVM;

  /**
   * Constructor.
   */
  constructor(@unmanaged() public model: new () => TModel, @unmanaged() repository: TRepository) {
    super(repository);
    this.entity = new this.model();
  }

  /**
   * (Re)Initializer.
   * @param id Entity id for fetch
   */
  init(id: number) {
    if (id && this.id !== id) {
      this.setId(id);
    }
  }

  /**
   * Set entity and auto assign enums attributes
   * @param entity
   */
  @action
  setEntity(entity: TModel) {
    super.setEntity(entity);
    this.enums.assignObjectEnum(this.entity);
  }

  /**
   * Cleanup view model data, override this for cleanup not-using arrays. View models are initialized
   * in instances in viewModelStore in MobX, so no automatic cleanup..
   */
  cleanUp() {
    this.resetId();
    this.setEntity(new this.model());
    this.cleanUpAssociatedVMS();
  }

  /**
   * ID object.
   *
   * @param id Object id from params
   */
  @action
  setId(id: number): Promise<number> {
    this.id = id;
    return this.fetchItem();
  }

  @action
  resetId() {
    this.id = -1;
  }

  /**
   * Destroy item.
   *
   * @returns
   * @memberof ShowViewModel
   */
  @action
  async destroy(): Promise<boolean> {
    const response = await this.repository.destroy(this.entity.id!);
    return response.status;
  }

  /**
   * Fetch single item from server.
   *
   * @param scrollTop Rather scroll view to top?
   */
  async fetchItem(scrollTop = true): Promise<number> {
    this.currentlyFetching = true;

    const response = await this.repository.show(this.id, this.loading);

    if (response.status && response.entity) {
      this.setEntity(response.entity);
    }

    if (scrollTop && this.scroll) {
      EventBus.trigger(SCROLL_TOP);
    }

    // Create or update associated VMs
    this.createOrUpdateAssociatedVMS();

    this.currentlyFetching = false;

    return this.id;
  }

  /**
   * Override this method when you have other associated VMs.
   *
   * @memberof ShowViewModel
   */
  @action
  createOrUpdateAssociatedVMS() {
    // Nothing to do here
  }

  /**
   * Override this to cleanup / reset filters / destroy associated VMs.
   *
   * @memberof ShowViewModel
   */
  @action
  cleanUpAssociatedVMS() {
    // Nothing to do here
  }
}
