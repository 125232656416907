import { Exclude, Expose, Transform, Type } from "@eman/class-transformer";
import { enumValue } from "@util/Enumerable";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";
import RateItem from "@model/RateItem";
import SupergrossRate from "@model/SupergrossRate";

export enum RateState {
  IN_PREPARATION = "in_preparation",
  ACTIVE = "active",
  ARCHIVED = "archived",
}

export const HoursInWorkWeek = 40;

export default class Rate extends BaseModel {
  @observable amount: number;
  @observable description: string;
  @Type(() => Number)
  @observable
  rate_h: number;
  @Type(() => Number)
  @observable
  free_rate_hours: number;
  @Type(() => Number)
  @observable
  rate_md: number;
  @Type(() => Number)
  @observable
  rate_month: number;

  @observable state: RateState;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;

  @observable enumeration_rate_reason_id: number;

  @observable enumeration_working_time_id: number;

  @observable contract_id: number;

  @Type(() => RateItem)
  @Exclude({ toPlainOnly: true })
  @observable
  rate_items: RateItem[] = [new RateItem()];

  @Type(() => SupergrossRate)
  @Exclude({ toPlainOnly: true })
  @observable
  supergross_rate: SupergrossRate;

  @Expose({ groups: ["create"] })
  get rate_items_attributes() {
    return this.rate_items;
  }

  @enumValue("working_times", "enumeration_working_time_id")
  @observable
  working_time: models.IEnumType;

  @enumValue("rate_reasons", "enumeration_rate_reason_id")
  @observable
  rate_reason: models.IEnumType;
}
