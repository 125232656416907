import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import Address from "@model/Address";
import AddressShowVM from "@vm/Show/Address";

import { lazyInject, TYPES } from "../../../../inversify.config";
import Form from "./Form";

@observer
export default class AddressShowModal extends ShowModal<Address> {
  modelName = "address";
  prefix = "employee.contact_information";

  @lazyInject(TYPES.AddressShow)
  vm: AddressShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_contact_informations(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} readonly={true} />;
  }
}
