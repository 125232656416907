import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import Dependent from "@model/Dependent";
import DependentEditVM from "@vm/Edit/Dependent";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class DependentEditModal extends EditModal<Dependent> {
  modelName = "dependent";
  prefix = "employee";

  @lazyInject(TYPES.DependentEdit)
  vm: DependentEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_dependents(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} hasCurrentHPPContract={this.vm.hasCurrentHPPContract} />;
  }
}
