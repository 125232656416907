import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import EvidenceState from "@model/EvidenceState";
import EvidenceStateCreateVM from "@vm/Create/EvidenceState";

import EvidenceStateForm from "./Form";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class EvidenceStateCreate extends CreateModal<EvidenceState> {
  modelName = "evidence_state";
  prefix = "employee.contract";

  @lazyInject(TYPES.EvidenceStateCreate)
  vm: EvidenceStateCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    this.vm.init();
    super.componentDidMount();
  }

  onClose = () => {
    const params = this.props.match.params;
    this.router.pageLink(this.uriHelper.employees_evidence_states(params.user_id));
  };

  title(): string {
    return this.tg("title");
  }

  renderModalBody() {
    return <EvidenceStateForm entity={this.vm.entity} vm={this.vm} currentStatus={this.vm.lastStatus} />;
  }
}
