import BaseModel from "@model/BaseModel";
import { computed, observable } from "mobx";
import OrganizationUnit from "./OrganizationUnit";

export enum OrganizationStructureType {
  COMPANY = "Company",
  ORGANIZATION_UNIT = "OrganizationUnit",
}

export default class OrganizationStructure extends BaseModel implements models.OrganizationStructure {
  @observable parent_id: number;
  @observable created_at: Date;
  @observable updated_at: Date;
  @observable type: OrganizationStructureType;
  @observable name: string;
  @observable description?: string;
  @observable lft: number;
  @observable rgt: number;
  @observable depth: number;
  @observable children_count: number;
  @observable parent?: OrganizationStructure;
  @observable children_exist?: boolean;
  @observable children: OrganizationUnit[];

  @computed get company(): boolean {
    return this.type === "Company";
  }
}
