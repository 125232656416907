import { inject, injectable } from "inversify";

import EditViewModel from "@vm/Edit/EditViewModel";
import AllocationForm from "@vm/Form/Allocation";
import AllocationRepository from "@repository/Allocation";
import Allocation, { AllocationEvent } from "@model/Allocation";

import TYPES from "../../inversify.types";
import { action } from "mobx";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class AllocationEditVM extends EditViewModel<Allocation, AllocationRepository> {
  constructor(
    @inject(TYPES.AllocationRepository) repository: AllocationRepository,
    @inject(TYPES.AllocationForm) public allocationFormVM: AllocationForm
  ) {
    super(Allocation, repository);
  }

  setEntity(entity: Allocation) {
    super.setEntity(entity);
    this.allocationFormVM.setEntity(entity);
  }

  @action.bound
  async performEvent(event: AllocationEvent) {
    this.entity.event = event;

    const status = this.update();

    if (status) {
      this.fetchItem(false);
    }

    return status;
  }
}
