import Announcement, { AnnouncementEvent } from "@model/Announcement";
import AnnouncementRepository from "@repository/Announcement";
import ListViewModel from "@vm/List/ListViewModel";
import { inject, injectable } from "inversify";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class AnnouncementListVM extends ListViewModel<Announcement, AnnouncementRepository> {
  static defaults = {
    order: {
      field: "id",
      direction: "desc",
    },
    visibleFilters: ["enumeration_announcement_status"],
  };

  constructor(@inject(TYPES.AnnouncementRepository) repository: AnnouncementRepository) {
    super(repository);
    this.scroll = false;
  }

  async performEvent(id: number, event: AnnouncementEvent, refusalReason?: string) {
    return await this.repository.performEvent(id, event, refusalReason);
  }
}
