import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import PersonalDocument from "@model/PersonalDocument";
import PersonalDocumentEditVM from "@vm/Edit/PersonalDocument";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class PersonalDocumentEditModal extends EditModal<PersonalDocument> {
  modelName = "personal_document";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.PersonalDocumentEdit)
  vm: PersonalDocumentEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} vm={this.vm.personalDocumentFormVM} />;
  }
}
