import { CalendarNavigation } from "@eman/emankit/Calendar";
import { ChangeEvent, useEffect, useState } from "react";
import Button from "@eman/emankit/Button";
import Select from "@eman/emankit/Select";
import { IconType } from "@eman/emankit/Icon";
import { addInterval, subInterval, AllocationInterval, getStartDate, FirstDayOfWeek, createDaySelection } from "@util/Calendar";
import { sub } from "date-fns";
import EventBus, { DATE_CHANGED } from "@util/EventBus";
import LocalizationService from "@service/Localization";
import { setTimeout } from "timers";

interface AllocationCalendarNavigationProps {
  defaultStartDate: Date;
  defaultInterval: AllocationInterval;
  tooltip: string;
  firstDayOfWeek?: FirstDayOfWeek;
  onChange: (startDate: Date, endDate: Date, interval: AllocationInterval) => void;
  locs: LocalizationService;
  clearGroups: () => void;
  isMyAllocations?: boolean;
}

const AllocationCalendarNavigation = ({
  defaultStartDate,
  defaultInterval,
  onChange,
  tooltip,
  firstDayOfWeek = 1,
  locs,
  clearGroups,
  isMyAllocations,
}: AllocationCalendarNavigationProps) => {
  const [interval, setInterval] = useState(defaultInterval);
  const [startDate, setStartDate] = useState(defaultStartDate);

  const formatDateValue = (date: Date) => {
    return date.getTime();
  };

  const parseDateValue = (timestamp: number) => {
    const date = new Date();
    date.setTime(timestamp);

    return date;
  };

  useEffect(() => {
    setStartDate(getStartDate(startDate, interval, firstDayOfWeek));
    if (!isMyAllocations) {
      onTodayClick();
    }
  }, [interval]);

  useEffect(() => {
    const endDate = sub(addInterval(startDate, interval), { days: 1 });
    onChange(startDate, endDate, interval);
  }, [startDate]);

  const onIntervalChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const interval = e.target.value as AllocationInterval;
    /* eslint-disable-next-line @typescript-eslint/no-implied-eval */
    setInterval(interval);
    if (interval === AllocationInterval.YEAR) {
      clearGroups();
    }
    // Keep this trigger for my allocations, !!!
    if (isMyAllocations) {
      EventBus.trigger(DATE_CHANGED);
    }
  };

  const onStartDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const timestamp = parseInt(e.target.value);
    setStartDate(parseDateValue(timestamp));
    EventBus.trigger(DATE_CHANGED);
  };

  const onTodayClick = () => {
    setStartDate(getStartDate(new Date(), interval, firstDayOfWeek));
    // Keep this trigger for my allocations !!!
    // Problem with EventBus trigger
    if (isMyAllocations) {
      EventBus.trigger(DATE_CHANGED);
    }
  };

  const onNextClick = () => {
    setStartDate(addInterval(startDate, interval));

    // Keep this trigger for my allocations !!!
    // Problem with EventBus trigger
    // Keep this Timeout !!!, there is leak in application when using this button methods
    if (isMyAllocations) {
      setTimeout(function () {
        EventBus.trigger(DATE_CHANGED);
      }, 10);
    }
  };

  const onPrevClick = () => {
    setStartDate(subInterval(startDate, interval));

    // Keep this trigger for my allocations !!!
    // Problem with EventBus trigger
    // Keep this Timeout !!!, there is leak in application when using this button methods
    if (isMyAllocations) {
      setTimeout(function () {
        EventBus.trigger(DATE_CHANGED);
      }, 10);
    }

    // Keep this trigger for my allocations !!!
    // Problem with EventBus trigger
  };

  return (
    <CalendarNavigation>
      <Button icon={IconType.Calendar} onClick={onTodayClick} tooltip={tooltip} />
      <Select style={{ minWidth: 160 }} onChange={onStartDateChange} value={formatDateValue(startDate)}>
        {createDaySelection(startDate, interval, firstDayOfWeek, locs).map(item => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
      <Select style={{ minWidth: 120 }} onChange={onIntervalChange} value={interval}>
        {[AllocationInterval.WEEK, AllocationInterval.MONTH, AllocationInterval.YEAR].map(item => (
          <option key={item} value={item}>
            {locs.tg(`allocation.calendar.interval.${item}`)}
          </option>
        ))}
      </Select>
      <Button icon={IconType.ArrowLeft} onClick={onPrevClick} />
      <Button icon={IconType.ArrowRight} onClick={onNextClick} />
    </CalendarNavigation>
  );
};

export default AllocationCalendarNavigation;
