import { inject, injectable } from "inversify";

import Role from "@model/Role";
import ListViewModel from "@vm/List/ListViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class RoleListVM extends ListViewModel<Role, Repository<Role>> {
  static defaults = {
    order: {
      field: "name",
      direction: "asc",
    },
    columns: ["name"],
    visibleFilters: ["name"],
  };

  constructor(@inject(TYPES.RoleRepository) repository: Repository<Role>) {
    super(repository);
  }
}
