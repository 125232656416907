import { observer } from "mobx-react";
import React from "react";

import Input from "@eman/emankit/Input";

import BindingElement, { BindingProps } from "./index";

interface TextFieldProps<T> extends BindingProps<T> {
  disabledDecimal?: boolean;
  min?: number;
}

@observer
export default class TextBox extends BindingElement<TextFieldProps<any>, any> {
  protected handleValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setValue(e.target.value);
  };

  protected formatInput(e: { key?: string; keyCode?: number; preventDefault: () => any }) {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-" || e.key === ",";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  }

  // tslint:disable-next-line:member-ordering
  render() {
    const { skipDirty, formatValue, parseValue, onValueChanged, disabledDecimal, min, ...others } = this.props;

    return (
      <Input
        min={min}
        {...others}
        onChange={this.handleValueChanged}
        onKeyDown={disabledDecimal ? this.formatInput : undefined}
        value={this.value === undefined || this.value === null ? "" : this.value}
      />
    );
  }
}
