import { plainToClass } from "@eman/class-transformer";
import EventBus, { SCROLL_TOP } from "@util/EventBus";
import { inject, injectable } from "inversify";

import EnumContainer from "@model/EnumContainer";
import EnumType from "@model/EnumType";
import ListViewModel from "@vm/List/ListViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class EnumContainerListVM extends ListViewModel<EnumContainer, IEnumRepository<EnumType>> {
  static defaults = {
    columns: ["name", "id"],
  };

  constructor(@inject(TYPES.EnumRepository) repository: IEnumRepository<EnumType>) {
    super(repository);
  }

  fetchList = async (scrollTop = true, save = true) => {
    this.currentlyFetching = true;

    const enums = await this.repository.fetchCurrent(true);

    const list: EnumContainer[] = [];

    for (const key in enums) {
      if (enums.hasOwnProperty(key)) {
        list.push(plainToClass(EnumContainer, { name: key, id: key }));
      }
    }

    this.setListAndTotal(list, list.length);
    this.pagination.pageSize = list.length;

    if (scrollTop && this.scroll) {
      EventBus.trigger(SCROLL_TOP);
    }

    this.currentlyFetching = false;
  };
}
