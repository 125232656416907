import { observer } from "mobx-react";
import React from "react";

import Badge from "@eman/emankit/Badge";
import { BaseColor } from "@eman/emankit/Colors/";
import HeaderRow from "@eman/emankit/HeaderRow";
import Button from "@eman/emankit/Button";

import SmallList from "@component/SmallList";
import EvidenceState, { EvidenceStateStatus } from "@model/EvidenceState";
import EvidenceStateListVM from "@vm/List/EvidenceState";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class EvidenceStateList extends SmallList<EvidenceState, EvidenceStateListVM, PropsFromRouter> {
  modelName = "evidence_state";
  prefix = "employee.contract";

  @lazyInject(TYPES.EvidenceStateList)
  vm: EvidenceStateListVM;

  initVM() {
    const params = this.props.match.params;
    this.vm.init(params.user_id);
  }

  componentWillUnmount() {
    this.vm.turnOffReactions();
  }

  renderStatusBadge = (status: EvidenceStateStatus) => {
    const color: BaseColor = {
      [EvidenceStateStatus.ACTIVED]: BaseColor.Green,
      [EvidenceStateStatus.REMOVED]: BaseColor.LightBlue,
      [EvidenceStateStatus.CLOSED]: BaseColor.Gray,
    }[status];

    return <Badge color={color}>{this.tg(`status.${status}`)}</Badge>;
  };

  renderRow = (item: EvidenceState, column: string): React.ReactNode => {
    switch (column) {
      case "evidence_status":
        return this.renderStatusBadge(item.evidence_status);
      case "enumeration_evidence_state_reason_id":
        return item.evidence_state_reason ? item.evidence_state_reason.name : "";
      case "valid_from":
      case "valid_to":
      case "expected_to":
        return this.locs.formatDate(item[column]);

      default:
        return item[column];
    }
  };

  otherRender = (): React.ReactNode => {
    return (
      <HeaderRow title={this.tg("title")} level={2}>
        {this.vm.canChange && (
          <Button
            onClick={() => this.router.pageLink(this.uriHelper.new_employees_evidence_states(this.props.match.params.user_id))}>
            {this.locs.tg("form.change")}
          </Button>
        )}
      </HeaderRow>
    );
  };

  filterProps = (): FilterData[] => {
    return [
      {
        id: "evidence_status",
        label: this.ta("evidence_status"),
        values: Object.values(EvidenceStateStatus).map(status => ({
          value: status,
          label: this.tg(`evidence_status.${status}`),
        })),
      },
      {
        id: "valid_from",
        label: this.ta("valid_from"),
        type: "date_range",
      },
      {
        id: "valid_to",
        label: this.ta("valid_to"),
        type: "date_range",
      },
      {
        id: "enumeration_evidence_state_reason_id",
        label: this.ta("enumeration_evidence_state_reason_id"),
        values: this.enums.valuesForSelect("evidence_state_reasons"),
      },
      {
        id: "expected_to",
        label: this.ta("expected_to"),
        type: "date_range",
      },
    ];
  };

  rows() {
    return [
      this.createField("valid_from", { width: 100 }),
      this.createField("valid_to", { width: 100 }),
      this.createField("enumeration_evidence_state_reason_id", {}),
      this.createField("description", {}),
      this.createField("expected_to", { width: 100 }),
      this.createField("evidence_status", { width: 100, nosort: true }),
    ];
  }
}
