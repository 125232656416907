import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import Note from "@model/Note";
import NoteShowVM from "@vm/Show/Note";
import { lazyInject, TYPES } from "../../../inversify.config";

import NoteForm from "./Form";

@observer
export default class NoteShowModal extends ShowModal<Note> {
  modelName = "note";
  prefix = "employee";

  @lazyInject(TYPES.NoteShow)
  vm: NoteShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_notes(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <>
        <NoteForm entity={this.vm.entity} readonly={true} />
      </>
    );
  }
}
