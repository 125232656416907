import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import Contact from "@model/Contact";
import ContactEditVM from "@vm/Edit/Contact";

import { lazyInject, TYPES } from "../../../../inversify.config";
import Form from "./Form";

@observer
export default class ContactEditModal extends EditModal<Contact> {
  modelName = "contact";
  prefix = "employee.contact_information";

  @lazyInject(TYPES.ContactEdit)
  vm: ContactEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_contact_informations(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} />;
  }
}
