import { inject, injectable } from "inversify";

import HealthInsurance from "@model/HealthInsurance";
import AssociatedShowViewModel from "@vm/Show/AssociatedShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class HealthInsuranceShowVM extends AssociatedShowViewModel<
  HealthInsurance,
  AssociatedRepository<HealthInsurance>
> {
  constructor(@inject(TYPES.HealthInsuranceRepository) repository: AssociatedRepository<HealthInsurance>) {
    super(HealthInsurance, repository);
  }
}
