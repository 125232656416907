import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";
import addYears from "date-fns/addYears";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import eachYearOfInterval from "date-fns/eachYearOfInterval";
import eachWeekOfInterval from "date-fns/eachWeekOfInterval";
import getWeek from "date-fns/getWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import startOfYear from "date-fns/startOfYear";
import subMonths from "date-fns/subMonths";
import subWeeks from "date-fns/subWeeks";
import subYears from "date-fns/subYears";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { Dictionary, flatMap, groupBy, keyBy, mapValues, max, sortBy, sum } from "lodash";

import BalanceGroup from "@model/BalanceGroup";

import Project from "@model/Project";
import AllocationJobTitle from "@model/AllocationJobTitle";
import AllocationItem from "@model/AllocationItem";
import AllocationUser from "@model/AllocationUser";
import { OptionType } from "@eman/emankit";
import JobPosition from "@model/JobPosition";
import LocalizationService from "@service/Localization";
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";
import OrganizationStructure from "@model/OrganizationStructure";
import User from "@model/User";

export type FirstDayOfWeek = 0 | 2 | 1 | 3 | 4 | 5 | 6 | undefined;

export enum AllocationInterval {
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export const getStartDate = (date: Date, interval: AllocationInterval, firstDayOfWeek: FirstDayOfWeek = 0): Date => {
  switch (interval) {
    case AllocationInterval.WEEK:
      return startOfWeek(date, { weekStartsOn: firstDayOfWeek });

    case AllocationInterval.MONTH:
      return startOfMonth(date);

    case AllocationInterval.YEAR:
      return startOfYear(date);
  }
};

export const addInterval = (date: Date, interval: AllocationInterval): Date => {
  switch (interval) {
    case AllocationInterval.WEEK:
      return addWeeks(date, 1);

    case AllocationInterval.MONTH:
      return addMonths(date, 1);

    case AllocationInterval.YEAR:
      return addYears(date, 1);
  }
};

export const subInterval = (date: Date, interval: AllocationInterval): Date => {
  switch (interval) {
    case AllocationInterval.WEEK:
      return subWeeks(date, 1);

    case AllocationInterval.MONTH:
      return subMonths(date, 1);

    case AllocationInterval.YEAR:
      return subYears(date, 1);
  }
};

export const createDaySelection = (
  startDate: Date,
  interval: AllocationInterval,
  firstDayOfWeek: FirstDayOfWeek = 0,
  locs: LocalizationService
): OptionType<number>[] => {
  switch (interval) {
    case AllocationInterval.WEEK:
      const startWeek = subWeeks(startDate, 10);
      const endWeek = addWeeks(startDate, 10);

      return eachWeekOfInterval(
        {
          start: startWeek,
          end: endWeek,
        },
        { weekStartsOn: firstDayOfWeek }
      ).map(item => ({
        value: item.getTime(),
        label: `${getWeek(item)}. ${locs.tg("allocation.calendar.week")} - ${
          locs.tg("uikit.date_picker.strings.months")[item.getMonth()]
        } ${item.getFullYear()}`,
      }));

    case AllocationInterval.MONTH:
      const startMonth = subMonths(startDate, 12);
      const endMonth = addMonths(startDate, 12);

      return eachMonthOfInterval({ start: startMonth, end: endMonth }).map(item => ({
        value: item.getTime(),
        label: `${locs.tg("uikit.date_picker.strings.months")[item.getMonth()]} ${item.getFullYear()}`,
      }));

    case AllocationInterval.YEAR:
    default:
      const startYear = subYears(startDate, 5);
      const endYear = addYears(startDate, 5);

      return eachYearOfInterval({ start: startYear, end: endYear }).map(item => ({
        value: item.getTime(),
        label: `${item.getFullYear()}`,
      }));
  }
};

export interface BalanceGroupWithDuration extends BalanceGroup {
  duration: number;
}

export const augmentBalances = (balances: BalanceGroup[]): BalanceGroupWithDuration[] => {
  return balances.map(
    group =>
      ({
        ...group,
        duration: differenceInCalendarDays(group.valid_to, group.valid_from) + 1,
      } as BalanceGroupWithDuration)
  );
};

export const dateToIndex = (date: Date): string => {
  return date.toDateString();
};

export const prepareBalances = (balances: BalanceGroup[] = []) => {
  const iteratee = (group: BalanceGroup) => dateToIndex(group.valid_from);
  return keyBy(sortBy(augmentBalances(balances), iteratee), iteratee);
};

const mergeBalances = (balances: BalanceGroupWithDuration[]): BalanceGroupWithDuration => {
  if (balances.length === 1) {
    return balances[0];
  }

  const datesTo: Date[] = [];
  const values: number[] = [];
  const durations: number[] = [];

  balances.forEach(balance => {
    datesTo.push(balance.valid_to);
    values.push(balance.value);
    durations.push(balance.duration);
  });

  return { ...balances[0], valid_to: max(datesTo), value: sum(values), duration: max(durations) } as BalanceGroupWithDuration;
};

export interface AllocationData {
  key: string;
  name: string;
  is_work: boolean;
  project_manager?: User;
  allocations: Dictionary<AllocationItem[]>;
}

export interface NonAllocationData {
  key: string;
  name: string;
  is_work: boolean;
  project_manager?: User;
  allocations: Dictionary<AllocationItem>;
}

export interface JobData {
  id: number;

  type: "jobPosition" | "allocationType";
  jobPosition?: JobPosition;
  organization_structure?: OrganizationStructure;

  balances: {
    [x: string]: BalanceGroupWithDuration;
  };

  allocations: AllocationData[] | NonAllocationData[];
}

export interface SubGroupData {
  userId: number;
  jobPositions: JobData[];
}

export const createSubGroupData = (
  userId: number,
  jobTitles: AllocationJobTitle[],
  projects: Project[],
  balanceGroups: BalanceGroup[],
  allocationItems: AllocationItem[],
  allocationTypes: models.IEnumType[]
) => {
  // globals
  const balanceGroupsByCapacityId = groupBy(balanceGroups, "capacity_id");

  // is_work = true allocations
  const projectsById = keyBy(projects, "id");

  const allocationsByJobTitle = groupBy(allocationItems, allocation => allocation.allocation.job_title_id);

  const dataByPositions: JobData[] = jobTitles.map(jobTitle => {
    const jobTitleAllocations = groupBy(
      allocationsByJobTitle[jobTitle.id!],
      allocationItem => allocationItem.allocation.project_id
    );

    const allocations = Object.entries(jobTitleAllocations).map(([projectId, allocations]) => ({
      key: `project-${projectId}`,
      name: projectsById[projectId]?.name,
      is_work: true,
      project_manager: projectsById[projectId]?.project_manager,
      allocations: groupBy(allocations, allocationItem => dateToIndex(allocationItem.date)),
    }));

    const balances = groupBy(
      flatMap(jobTitle.capacities, capacity => augmentBalances(balanceGroupsByCapacityId[capacity.id!] || [])),
      balance => dateToIndex(balance.valid_from)
    );

    return {
      id: jobTitle.id!,
      type: "jobPosition",
      jobPosition: jobTitle.job_position,
      balances: mapValues(balances, mergeBalances),
      allocations,
      organization_structure: jobTitle.organization_structure,
    };
  });

  // is_work = false allocations
  const allocationsByAllocationType = groupBy(
    allocationItems.filter(item => item.allocation.is_work === false),
    item => item.allocation.enumeration_allocation_type_id
  );

  // Prepare enumerations
  const allocationTypeById = keyBy(allocationTypes, "id");

  if (Object.entries(allocationsByAllocationType).length > 0) {
    const allocations: AllocationData[] = [];

    for (const idString in allocationsByAllocationType) {
      const id = parseInt(idString, 10);
      const type = allocationTypeById[id];
      const items = allocationsByAllocationType[idString];

      allocations.push({
        key: `vacation-${idString}`,
        name: type?.name,
        is_work: false,
        allocations: groupBy(items, item => dateToIndex(item.date)),
      });
    }

    // We don't care about projects, we just have to find allocation items for proper type
    dataByPositions.push({
      id: -1, // Virtual group
      type: "allocationType",
      balances: {},
      allocations,
    });
  }

  return {
    userId,
    jobPositions: dataByPositions,
  };
};

export type SubGroupsData = Record<number, SubGroupData>;

export const isGroupOpen = (groupId: number, openGroups: number[]): boolean => {
  return openGroups.includes(groupId);
};

export const prepareMainGroupData = (
  allocationUsers: AllocationUser[],
  mainBalanceGroups: BalanceGroup[],
  openGroups: number[]
) => {
  const balanceGroupsByUser = groupBy(mainBalanceGroups, group => group.user_id);

  return allocationUsers.map(({ user }) => {
    const open = isGroupOpen(user.id!, openGroups);
    return {
      id: user.id!,
      title: user.fullName,
      user,
      open,
      mainBalances: prepareBalances(balanceGroupsByUser[user.id!]),
    };
  });
};

export type GroupData = ReturnType<typeof prepareMainGroupData>;
export type GroupItem = ArrayElement<GroupData>;

const filterPrefixes = ["user", "allocation"] as const;
type FilterPrefixes = typeof filterPrefixes[number];
export type SplitFilters = {
  [key in FilterPrefixes]: FilterValues;
};

export const splitFilters = (filters: FilterValues = {}): SplitFilters => {
  const res = {};
  filterPrefixes.forEach(prefix => {
    res[prefix] = {};
  });

  Object.entries(filters).forEach(([key, filter]) => {
    const prefix = key.split("_")[0].replace("_", "") as FilterPrefixes;
    if (filterPrefixes.includes(prefix)) {
      res[prefix][key] = filter;
    }
  });
  return res as SplitFilters;
};

/**
 * Balance groups could start before or after UI dateframe, we have to normalize them.
 *
 * @param start UI start Date
 * @param end UI end Date
 * @param sortedArray
 */
export const normalizeBalanceGroups = (start: Date, end: Date, balances: BalanceGroup[]): BalanceGroup[] => {
  const ret: BalanceGroup[] = [];
  const intervalCheck = { start: new Date(start.toDateString()), end: new Date(end.toDateString()) };

  balances.forEach(balance => {
    // Skip balances with intervals outside the
    // defined UI interval
    // Normalizing date values with new Date(date.toDateString()), because of different timezones after classTransformation
    if (
      !areIntervalsOverlapping(
        intervalCheck,
        { start: new Date(balance.valid_from.toDateString()), end: new Date(balance.valid_to.toDateString()) },
        { inclusive: true }
      )
    ) {
      return;
    }

    // If balance starts before set valid_from to start
    if (isBefore(balance.valid_from, start)) {
      balance.valid_from = start;
    }

    // The same for end
    if (isAfter(balance.valid_to, end)) {
      balance.valid_to = end;
    }

    ret.push(balance);
  });

  return ret;
};
