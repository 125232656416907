import { inject, injectable } from "inversify";

import Dependent from "@model/Dependent";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import TYPES from "../../inversify.types";
import { computed } from "mobx";
import ContractsVM from "@vm/Other/Contracts";
import { ContractType } from "@model/Contract";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class DependentCreateVM extends AssociatedCreateViewModel<Dependent, AssociatedRepository<Dependent>> {
  constructor(
    @inject(TYPES.DependentRepository) repository: AssociatedRepository<Dependent>,
    @inject(TYPES.Contracts) private contractsVM: ContractsVM
  ) {
    super(Dependent, repository);
  }

  @computed
  get hasCurrentHPPContract() {
    return this.contractsVM.currentContract?.contract_type?.code === ContractType.HPP;
  }
}
