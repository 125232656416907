import { observer } from "mobx-react";
import React from "react";

import Panel from "@eman/emankit/Panel";

import BaseForm from "@component/BaseForm";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import Role from "@model/Role";
import { CheckBox, CheckBoxArray, GenericFormField, TextBox } from "@util/Form";
import RoleFormVM from "@vm/Form/Role";

export interface RoleFormProps {
  vm: RoleFormVM;
}

@observer
export default class RoleForm extends BaseForm<Role, RoleFormProps> {
  modelName = "role";

  get vm() {
    return this.props.vm;
  }

  componentWillUnmount(): void {
    this.vm.turnOffReactions();
  }

  renderRightSection = (section: UserRightsObjects) => {
    const checkboxes = this.vm.rights.get(section)!.map(right => {
      return <CheckBoxArray id={right} key={right} ownLabel={this.locs.tg(`right.${right}`)} />;
    });

    return (
      <GenericFormField
        target={this.props.entity.rights}
        property={section}
        label={this.locs.tm(section, 5)}
        required={true}
        key={section}>
        {checkboxes}
      </GenericFormField>
    );
  };

  renderFormBody() {
    const rightSections: React.ReactNode[] = [];

    this.vm.rights.forEach((value: UserRightsOperations[], key: UserRightsObjects) => {
      rightSections.push(this.renderRightSection(key));
    });

    return (
      <Panel>
        {this.generateFormField("name", <TextBox />, true)}
        {this.generateFormField("admin", <CheckBox />, true)}
        {rightSections}
      </Panel>
    );
  }
}
