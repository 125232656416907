import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import Contact from "@model/Contact";
import ContactShowVM from "@vm/Show/Contact";

import { lazyInject, TYPES } from "../../../../inversify.config";

import Form from "./Form";

@observer
export default class ContactShowModal extends ShowModal<Contact> {
  modelName = "contact";
  prefix = "employee.contact_information";

  @lazyInject(TYPES.ContactShow)
  vm: ContactShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_contact_informations(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} readonly={true} />;
  }
}
