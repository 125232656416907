import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import Training from "@model/Training";
import TrainingCreateVM from "@vm/Create/Training";

import TrainingForm from "./Form";
import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class TrainingCreate extends CreateModal<Training> {
  modelName = "training";
  prefix = "employee";

  @lazyInject(TYPES.TrainingCreate)
  vm: TrainingCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_trainings(this.props.match.params.user_id));
  };

  renderModalBody() {
    return (
      <>
        <TrainingForm entity={this.vm.entity} possibleUsers={this.vm.possibleUsers} />
      </>
    );
  }
}
