import { inject, injectable } from "inversify";

import Contract from "@model/Contract";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class ContractListVM extends AssociatedListViewModel<Contract, AssociatedRepository<Contract>> {
  static defaults = {
    order: {
      field: "valid_from",
      direction: "desc",
    },
  };

  constructor(@inject(TYPES.ContractRepository) repository: AssociatedRepository<Contract>) {
    super(repository);
  }
}
