import { enumValue } from "@util/Enumerable";
import { observable } from "mobx";

import OrganizationStructure from "@model/OrganizationStructure";

export default class Company extends OrganizationStructure {
  @observable company_number: string;
  @observable vat_number: string;

  @observable social_security_office_id: number;
  @observable legal_form_id: number;

  @observable working_time: string;

  @observable
  @enumValue("social_security_offices", "social_security_office_id")
  social_security_office: models.IEnumType;

  @observable
  @enumValue("legal_forms", "legal_form_id")
  legal_form: models.IEnumType;
}
