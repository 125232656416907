import { observer } from "mobx-react";
import React from "react";
import { NavLink } from "react-router-dom";

import BreadCrumbs from "@eman/emankit/BreadCrumbs";

import BaseComponent from "@component/BaseComponent";
import BreadcrumbService from "@service/Breadcrumb";

import { lazyInject, TYPES } from "../../inversify.config";

@observer
export default class Breadcrumbs extends BaseComponent {
  @lazyInject(TYPES.Breadcrumb)
  breadcrumb: BreadcrumbService;

  render() {
    const crumbs = this.breadcrumb.breadcrumbs;

    const breadCrumbs: React.ReactNode = crumbs.map((crumb, index) => (
      <NavLink
        exact={true}
        activeClassName="active"
        key={`crumb-${index}`}
        to={{
          pathname: crumb.pathname,
        }}>
        {crumb.title}
      </NavLink>
    ));

    // @ts-ignore
    return <BreadCrumbs items={breadCrumbs} />;
  }
}
