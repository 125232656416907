import { inject, injectable } from "inversify";

import BranchOffice from "@model/BranchOffice";
import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class BranchOfficeCreateVM extends AssociatedCreateViewModel<BranchOffice, AssociatedRepository<BranchOffice>> {
  constructor(@inject(TYPES.BranchOfficeRepository) repository: AssociatedRepository<BranchOffice>) {
    super(BranchOffice, repository);
  }
}
