import { plainToClass } from "@eman/class-transformer";
import { inject, injectable } from "inversify";
import { observable } from "mobx";

import EnumContainer from "@model/EnumContainer";
import EnumType from "@model/EnumType";
import EnumListVM from "@vm/List/Enum";
import ShowViewModel from "@vm/Show/ShowViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class EnumContainerShowVM extends ShowViewModel<EnumContainer, IEnumRepository<EnumType>> {
  @observable enumListVM: EnumListVM;

  constructor(
    @inject(TYPES.EnumRepository) repository: IEnumRepository<EnumType>,
    @inject(TYPES.EnumList) enumListVM: EnumListVM
  ) {
    super(EnumContainer, repository);
    this.enumListVM = enumListVM;
  }

  setId(id: number): Promise<number> {
    this.enumListVM.setParentId(id);

    this.entity = plainToClass(EnumContainer, { name: id, id });

    // tslint:disable-next-line: prefer-promise-shorthand
    return new Promise(resolve => resolve(id));
  }
}
