import { inject, injectable } from "inversify";

import Certification from "@model/Certification";
import AssociatedListViewModel from "@vm/List/AssociatedListViewModel";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class CertificationListVM extends AssociatedListViewModel<Certification, AssociatedRepository<Certification>> {
  static defaults = {
    order: {
      field: "created_at",
      direction: "desc",
    },
    columns: ["name", "enumeration_certificate_issuer_id", "date_from", "date_to", "credential_id", "credential_url"],
    visibleFilters: ["date_from", "date_to"],
  };

  constructor(@inject(TYPES.CertificationRepository) repository: AssociatedRepository<Certification>) {
    super(repository);
  }
}
