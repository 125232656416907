import { ReactNode } from "react";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isWeekend from "date-fns/isWeekend";
import LocalizationService from "@service/Localization";
import { getDaysInMonth } from "date-fns";

interface Props {
  interval: Date[];
  leftHeader?: ReactNode;
  locs: LocalizationService;
  isYearInterval?: boolean;
  isMonthInterval?: boolean;
}

const dateFormat = (
  date: Date,
  locs: LocalizationService,
  interval?: Date[],
  isYearInterval?: boolean,
  isMonthInterval?: boolean
): ReactNode => {
  const active = isToday(date);
  const weekend = isWeekend(date);
  const day = date.getDay();
  const month = date.getMonth();
  const year = date.getFullYear();
  const today = new Date();
  const showDayPointer = month === today.getMonth() && year === today.getFullYear();
  const tdWidth = document.getElementById("get-width")?.getBoundingClientRect().width;
  const todayPointerOffset = tdWidth && (tdWidth / getDaysInMonth(new Date())) * new Date().getUTCDate();

  return (
    <td
      key={date.valueOf()}
      className={`date-header${active ? " active" : ""} ${!isYearInterval && weekend ? " weekend" : ""} ${
        isMonthInterval ? " is-month" : ""
      }`}>
      {isYearInterval && showDayPointer && <div className="today-pointer" style={{ marginLeft: `${todayPointerOffset}px` }} />}
      <span className={`day${isMonthInterval ? " day-is-month" : ""} `} id="get-width">
        <span className="date">{!isYearInterval && format(date, "d")}</span>{" "}
        <span className="dayName">
          {!isYearInterval &&
            locs.tg(`uikit.date_picker.strings.${interval?.length === 7 ? "weekdaysLong" : "weekdaysShort"}`)[day]}
          {isYearInterval && locs.tg(`uikit.date_picker.strings.months`)[month]}
        </span>
      </span>
    </td>
  );
};

const AllocationTableHeader = ({ interval, leftHeader, isYearInterval, locs, isMonthInterval }: Props) => {
  return (
    <thead>
      <tr className="header">
        <td className="sidebar">{leftHeader}</td>
        {interval.map(date => dateFormat(date, locs, interval, isYearInterval, isMonthInterval))}
      </tr>
    </thead>
  );
};

export default AllocationTableHeader;
