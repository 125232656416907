import { observer } from "mobx-react";

import SmallList from "@component/SmallList";
import PreviousEmployment from "@model/PreviousEmployment";
import PreviousEmploymentListVM from "@vm/List/PreviousEmployment";

import { lazyInject } from "../../../../inversify.config";
import TYPES from "../../../../inversify.types";

export interface PreviousEmploymentsListProps {
  userId: number;
}

@observer
export default class PreviousEmploymentsList extends SmallList<
  PreviousEmployment,
  PreviousEmploymentListVM,
  PreviousEmploymentsListProps
> {
  @lazyInject(TYPES.PreviousEmploymentList)
  vm: PreviousEmploymentListVM;

  modelName = "previous_employment";

  componentDidMount(): void {
    this.vm.setParentId(this.props.userId);
    super.componentDidMount();
  }

  renderRow = (item: PreviousEmployment, column: string) => {
    switch (column) {
      case "valid_from":
      case "valid_to":
        return this.locs.formatDate(item[column]);

      default:
        return item[column];
    }
  };

  onClick = (item: PreviousEmployment): void => {
    this.objectEditOrShowLink("employees_personal_data_previous_employments", [this.props.userId, item.id!]);
  };

  rows() {
    return [
      this.createField("company_name", {}),
      this.createField("job_position_name", {}),
      this.createField("valid_from", {}),
      this.createField("valid_to", {}),
      this.createField("note", {}),
    ];
  }
}
