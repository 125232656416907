import { observer } from "mobx-react";
import React from "react";

import BaseList from "@component/BaseList";

import { lazyInject, TYPES } from "../../../inversify.config";
import Vacation from "@model/Vacation";
import VacationListVM from "@vm/List/Vacation";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";

@observer
export default class VacationList extends BaseList<Vacation, VacationListVM, PropsFromRouter> {
  modelName = "vacation";
  prefix = "employee";
  searchable = false;
  savedSettingsName = "user_vacation";

  @lazyInject(TYPES.VacationList)
  vm: VacationListVM;

  initVM() {
    this.vm.init(this.props.match.params.user_id);
  }

  renderRow = (item: Vacation, column: string): React.ReactNode => {
    if (column == "year") {
      return item[column];
    } else {
      return `${item[column]}h`;
    }
  };

  headerProps() {
    return {
      text: this.tg("title"),
      level: 2,
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "user_confirmation",
        label: this.ta("user_confirmation"),
        type: "date_range",
      },
      {
        id: "supervisor_confirmation",
        label: this.ta("supervisor_confirmation"),
        type: "date_range",
      },
      {
        id: "created_at",
        label: this.ta("created_at"),
        type: "date_range",
      },
    ];

    return {
      filters,
      hideReset: false,
      hideFilterSettings: false,
      hideColumnSettings: false,
    };
  }

  dataProps() {
    const columns = [
      this.createField("year", {}),
      this.createField("number_of_hours", {}),
      this.createField("previous_remaining_hours", {}),
      this.createField("free_approved", { nosort: true }),
      this.createField("free", { nosort: true }),
      this.createField("exhausted", { nosort: true }),
      this.createField("scheduled", { nosort: true }),
    ];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      ...(this.user.allowToObject(UserRightsObjects.VACATION, UserRightsOperations.EDIT) && {
        onClick: (item: Vacation) => {
          this.objectEditOrShowLink("employees_vacations", [this.props.match.params.user_id, item.id]);
        },
      }),
    };
  }
}
