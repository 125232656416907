import Rate, { RateState } from "@model/Rate";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";
import { injectable } from "inversify";

@injectable()
export default class RateRepository extends BaseAssociatedRepository<Rate> {
  constructor() {
    super(Rate, "/users/$ID/rates", "rate");
  }

  fetchActiveAndInPreparation(contractId: number) {
    return this.fetchList({
      order: {
        field: "created_at",
        direction: "asc",
      },
      pagination: {
        page: 0,
        pageSize: 100,
      },
      filters: {
        state: {
          operator: "in",
          values: [RateState.ACTIVE, RateState.IN_PREPARATION],
        },
        contract_id: {
          operator: "in",
          values: [contractId],
        },
      },
    });
  }
}
