import PersonalInformation from "@view/Employee/PersonalInformation";
import PaymentInfoCreateModal from "@view/Employee/PersonalInformation/PaymentInfos/Create";
import PaymentInfoEditModal from "@view/Employee/PersonalInformation/PaymentInfos/Edit";
import PaymentInfoShowModal from "@view/Employee/PersonalInformation/PaymentInfos/Show";
import PersonalDocumentCreateModal from "@view/Employee/PersonalInformation/PersonalDocuments/Create";
import PersonalDocumentEditModal from "@view/Employee/PersonalInformation/PersonalDocuments/Edit";
import PersonalDocumentShowModal from "@view/Employee/PersonalInformation/PersonalDocuments/Show";
import PreviousEmploymentCreateModal from "@view/Employee/PersonalInformation/PreviousEmployments/Create";
import PreviousEmploymentEditModal from "@view/Employee/PersonalInformation/PreviousEmployments/Edit";
import PreviousEmploymentShowModal from "@view/Employee/PersonalInformation/PreviousEmployments/Show";
import SchoolCreateModal from "@view/Employee/PersonalInformation/Schools/Create";
import SchoolEditModal from "@view/Employee/PersonalInformation/Schools/Edit";
import SchoolShowModal from "@view/Employee/PersonalInformation/Schools/Show";

import { uriHelper } from "../../../config";
import BaseRouter from "../../Base";

export const personalInformations: IAppRoute[] = [
  {
    path: uriHelper.employees_personal_data_uri(),
    exact: false,
    component: PersonalInformation,
  },
  {
    path: uriHelper.new_employees_personal_data_payment_infos_uri(),
    exact: true,
    modalComponent: PaymentInfoCreateModal,
  },
  {
    path: uriHelper.edit_employees_personal_data_payment_infos_uri(),
    exact: true,
    modalComponent: PaymentInfoEditModal,
  },
  {
    path: uriHelper.show_employees_personal_data_payment_infos_uri(),
    exact: true,
    modalComponent: PaymentInfoShowModal,
  },
  {
    path: uriHelper.new_employees_personal_data_personal_documents_uri(),
    exact: true,
    modalComponent: PersonalDocumentCreateModal,
  },
  {
    path: uriHelper.edit_employees_personal_data_personal_documents_uri(),
    exact: true,
    modalComponent: PersonalDocumentEditModal,
  },
  {
    path: uriHelper.show_employees_personal_data_personal_documents_uri(),
    exact: true,
    modalComponent: PersonalDocumentShowModal,
  },
  {
    path: uriHelper.new_employees_personal_data_previous_employments_uri(),
    exact: true,
    modalComponent: PreviousEmploymentCreateModal,
  },
  {
    path: uriHelper.edit_employees_personal_data_previous_employments_uri(),
    exact: true,
    modalComponent: PreviousEmploymentEditModal,
  },
  {
    path: uriHelper.show_employees_personal_data_previous_employments_uri(),
    exact: true,
    modalComponent: PreviousEmploymentShowModal,
  },
  {
    path: uriHelper.new_employees_personal_data_schools_uri(),
    exact: true,
    modalComponent: SchoolCreateModal,
  },
  {
    path: uriHelper.edit_employees_personal_data_schools_uri(),
    exact: true,
    modalComponent: SchoolEditModal,
  },
  {
    path: uriHelper.show_employees_personal_data_schools_uri(),
    exact: true,
    modalComponent: SchoolShowModal,
  },
];

export default class Personalnformations extends BaseRouter {
  render() {
    return this.pageRouteRender(personalInformations);
  }
}
