import downloadjs from "downloadjs";
import { inject, injectable } from "inversify";
import { computed } from "mobx";

import Announcement, { AnnouncementEvent } from "@model/Announcement";
import Document from "@model/Document";
import AnnouncementRepository from "@repository/Announcement";
import ShowViewModel from "@vm/Show/ShowViewModel";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class AnnouncementShowVM
  extends ShowViewModel<Announcement, AnnouncementRepository>
  implements ViewModel.WithDocuments {
  constructor(@inject(TYPES.AnnouncementRepository) repository: AnnouncementRepository) {
    super(Announcement, repository);
  }

  @computed
  get documents(): Document[] {
    return this.entity.documents;
  }

  async download(documentId: number) {
    const response = await this.repository.downloadAttachment(this.id, documentId);

    if (response) {
      downloadjs(response);
    }
  }

  async performEvent(id: number, event: AnnouncementEvent, refusalReason?: string) {
    const response = await this.repository.performEvent(id, event, refusalReason);

    if (response.status) {
      this.fetchItem(false);
    }

    return response;
  }
}
