import { inject, injectable } from "inversify";

import Contract from "@model/Contract";
import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";
import ContractFormVM from "@vm/Form/Contract";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class ContractEditVM extends AssociatedEditViewModel<Contract, AssociatedRepository<Contract>> {
  constructor(
    @inject(TYPES.ContractRepository) repository: AssociatedRepository<Contract>,
    @inject(TYPES.ContractForm) public contractFormVM: ContractFormVM
  ) {
    super(Contract, repository);
  }

  setEntity(entity: Contract) {
    super.setEntity(entity);
    this.contractFormVM.setEntity(entity);
  }

  setParentId(parentId: number) {
    super.setParentId(parentId);
    this.contractFormVM.setParentId(parentId);
  }
}
