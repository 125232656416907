import AllocationItem from "@model/AllocationItem";
import { AllocationData, NonAllocationData } from "@util/Calendar";
import { toJS } from "mobx";
import AllocationRowItem from "./item";
import LocalizationService from "@service/Localization";
import ProjectManager from "./ProjectManager";

interface Props {
  data: AllocationData | NonAllocationData;
  interval: Date[];
  onAllocationClick: (item: AllocationItem) => void;
  locs?: LocalizationService;
}

const AllocationRow = ({ data, interval, onAllocationClick, locs }: Props) => {
  const { key, allocations, name } = data;
  const cells = [];
  const locService = locs;

  for (const date of interval) {
    const time = date.toDateString();
    if (allocations[time]) {
      // True allocations
      const allocation = allocations[time];

      // Not work allocations
      const nonAllocations = allocations[time] as AllocationItem;

      // toJS required when switching intervals, keep it here !!!
      const allocationValues = Object.values(toJS(allocation));

      // Check needed for not work allocation rendering
      let allocationCheck;

      if (nonAllocations.allocation?.is_work === false) {
        allocationCheck = <AllocationRowItem allocation={nonAllocations} onClick={() => onAllocationClick(nonAllocations)} />;
      } else {
        allocationCheck = allocationValues.map((item, index) => {
          // We need extra margin in cell items on multiple values
          if (allocationValues.length > 1 && index > 0) {
            return (
              <div className="cells-margin" key={index}>
                <AllocationRowItem allocation={item} onClick={() => onAllocationClick(item)} />
              </div>
            );
          } else {
            // else is it normal AllocationRowItem
            return <AllocationRowItem allocation={item} onClick={() => onAllocationClick(item)} key={index} />;
          }
        });
      }
      cells.push(
        <td key={time} className={`table-col ${allocationValues.length === 1 ? "cell-allign-top" : ""}`}>
          {allocationCheck}
        </td>
      );
    } else {
      cells.push(<td key={time} className="table-col"></td>);
    }
  }

  return (
    <tr key={key} className="project-allocation table-row">
      <td className="sidebar table-col">
        {name && <ProjectManager projectManager={data.project_manager} locs={locService} deal={name} />}
      </td>
      {cells}
    </tr>
  );
};

export default AllocationRow;
