import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import Address from "@model/Address";
import AddressEditVM from "@vm/Edit/Address";

import { lazyInject, TYPES } from "../../../../inversify.config";
import Form from "./Form";

@observer
export default class AddressEditModal extends EditModal<Address> {
  modelName = "address";
  prefix = "employee.contact_information";

  @lazyInject(TYPES.AddressEdit)
  vm: AddressEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_contact_informations(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} />;
  }
}
