import { Exclude } from "@eman/class-transformer";
import OrganizationStructure from "@model/OrganizationStructure";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";

export default class JobPosition extends BaseModel {
  @observable name: string;
  @observable description: string;
  @observable manager: boolean;
  @observable specialist: boolean;

  @observable organization_structure_ids: number[];
  @Exclude({ toPlainOnly: true })
  @observable
  organization_structures: OrganizationStructure[] = [];
}
