import { observer } from "mobx-react";
import React from "react";

import BaseList from "@component/BaseList";
import Button, { ButtonVariant } from "@eman/emankit/Button";
import Note, { NOTE_RIGHT_TYPES } from "@model/Note";
import NoteListVM from "@vm/List/Note";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import ViewHelpers from "@util/ViewHelpers";

import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class NoteList extends BaseList<Note, NoteListVM, PropsFromRouter> {
  modelName = "note";
  prefix = "employee";
  searchable = false;
  savedSettingsName = "user_note";

  @lazyInject(TYPES.NoteList)
  vm: NoteListVM;

  initVM() {
    this.vm.init(this.props.match.params.user_id);
  }

  renderRightType(type: NOTE_RIGHT_TYPES) {
    return this.tg(`right_type.${type}`);
  }

  renderRow = (item: Note, column: string): React.ReactNode => {
    switch (column) {
      case "author":
        return item.authorFullName;
      case "right_type":
        return this.renderRightType(item.right_type);
      case "created_at":
        return this.locs.formatDate(item.created_at);
      case "archived":
        return ViewHelpers.booleanToBadgeSmooth(this.locs, item.archived);
      default:
        return item[column];
    }
  };

  onAddClick = () => {
    this.router.pageLink(this.uriHelper.new_employees_notes(this.props.match.params.user_id));
  };

  headerProps() {
    return {
      text: this.tg("title"),
      level: 2,
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.NOTE, UserRightsOperations.CREATE)) {
          return (
            <Button variant={ButtonVariant.Primary} key="new_note" onClick={this.onAddClick}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  filterProps() {
    const filters: FilterData[] = [
      {
        id: "archived",
        label: this.ta("archived"),
        values: ViewHelpers.booleanOptions(this.locs),
        single: true,
      },
      {
        id: "description",
        label: this.ta("description"),
        type: "search",
      },
      {
        id: "created_at",
        label: this.ta("created_at"),
        type: "date_range",
      },
    ];

    return {
      filters,
      hideReset: false,
      hideFilterSettings: false,
      hideColumnSettings: false,
    };
  }

  dataProps() {
    const columns = [
      this.createField("description", {}),
      this.createField("author", { nosort: true }),
      this.createField("right_type", {}),
      this.createField("created_at", { width: 100 }),
      this.createField("archived", {}),
    ];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: Note) => {
        this.objectEditOrShowLink("employees_notes", [this.props.match.params.user_id, item.id]);
      },
    };
  }
}
