import { Subscription } from "@rails/actioncable";
import download from "downloadjs";
import { inject, injectable } from "inversify";
import { action, observable } from "mobx";

import ExportRepository from "@repository/Export";
import Notification from "@model/Notification";
import EventBus, { LOGIN_USER, LOGOUT_USER, REFRESH_NOTIFICATION, SHOW_NOTIFICATION } from "@util/EventBus";
import CurrentUser from "@service/CurrentUser";
import WebSocket from "@service/Websocket";

import TYPES from "../inversify.types";

// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class NotificationService {
  @observable notifications: Notification[] = [];

  private subscription?: Subscription;

  @inject(TYPES.NotificationRepository)
  private repository: Repository<Notification>;

  constructor(
    @inject(TYPES.Websocket) private websocket: WebSocket,
    @inject(TYPES.User) private currentUser: CurrentUser,
    @inject(TYPES.ExportRepository) private exportRepository: ExportRepository
  ) {
    EventBus.on(LOGIN_USER, this.subscribe);
    EventBus.on(LOGOUT_USER, this.unsubscribe);
    EventBus.on(LOGOUT_USER, this.clearNotifications);
    EventBus.on(SHOW_NOTIFICATION, this.fetchNotifications);
  }

  /**
   * Websocket receive data.
   */
  received = (data: any) => {
    if (data.refresh_notification) {
      this.fetchNotifications();
      EventBus.trigger(REFRESH_NOTIFICATION);
    }
  };

  @action.bound
  subscribe() {
    if (!this.subscription) {
      this.subscription = this.websocket.subscribe("NotificationChannel", this.received);
    }

    this.fetchNotifications();
  }

  @action.bound
  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  @action.bound
  clearNotifications() {
    this.notifications = [];
  }

  @action.bound
  async fetchNotifications() {
    if (this.currentUser.entity.authenticated) {
      const list = await this.repository.fetchList({
        order: { field: "id", direction: "asc" },
        pagination: { page: 0, pageSize: 2000 },
        loading: false,
        save: false,
        ignoreErrors: true,
      });

      this.notifications = list.list;
      if (this.notifications.length && this.notifications[0].status === "done") {
        this.timeoutNotification(this.notifications[0].id!);
      }
    }
  }

  async timeoutNotification(id: number) {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    await delay(3000);
    this.closeNotification(id);
  }

  async closeNotification(id: number) {
    await this.repository.destroy(id);
    this.fetchNotifications();
  }

  async onItemClick(id: number) {
    const notification = this.notifications.find(item => item.id === id);

    if (notification && notification.succeed) {
      let response;

      switch (notification.type) {
        case "ExportJob":
        case "ExportReportJob":
          response = await this.exportRepository.fileDownload(notification.id!);
          break;
      }

      if (response) {
        const { data, fileName } = response;
        download(data, fileName || "filename");
      }

      this.closeNotification(id);
    }
  }
}
