import { inject, injectable } from "inversify";
import { computed } from "mobx";

import ForeignPersonalDetail from "@model/ForeignPersonalDetail";
import Role from "@model/Role";
import User from "@model/User";
import EditViewModel from "@vm/Edit/EditViewModel";
import OptionsVM from "@vm/Other/Options";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class UserEditVM extends EditViewModel<User, Repository<User>> {
  roleOptionsVM: OptionsVM<Role>;

  constructor(
    @inject(TYPES.UserRepository) repository: Repository<User>,
    @inject(TYPES.RoleRepository) roleRepository: Repository<Role>
  ) {
    super(User, repository);
    this.roleOptionsVM = new OptionsVM<Role>(roleRepository, "name", { order: { direction: "asc", field: "order" } });
  }

  @computed
  get roleOptions() {
    return this.roleOptionsVM.selectOptions;
  }

  async update(): Promise<boolean> {
    const citizenship = this.enums.value("citizenships", this.entity.enumeration_citizenship_id);
    if (citizenship && citizenship.eurozone) {
      this.entity.foreign_personal_detail = new ForeignPersonalDetail();
    }

    return super.update();
  }
}
