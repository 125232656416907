import { OptionType } from "@eman/emankit";
import AllocationUser from "@model/AllocationUser";
import Training from "@model/Training";

import AssociatedEditViewModel from "@vm/Edit/AssociatedEditViewModel";
import OptionsVM from "@vm/Other/Options";
import { inject, injectable } from "inversify";
import { computed, observable } from "mobx";

import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class TrainingEditVM extends AssociatedEditViewModel<Training, AssociatedRepository<Training>> {
  @observable
  private userOptionsVM: OptionsVM<AllocationUser>;

  constructor(
    @inject(TYPES.TrainingRepository) repository: AssociatedRepository<Training>,
    @inject(TYPES.AllocationUserRepository) private allocationUserRepository: Repository<AllocationUser>
  ) {
    super(Training, repository);
    this.userOptionsVM = new OptionsVM(this.allocationUserRepository, "user_last_name" as any);
  }

  @computed
  get possibleUsers(): OptionType<number>[] {
    return this.userOptionsVM?.items.map((item: AllocationUser) => ({
      label: `${item.user.first_name} ${item.user.last_name}`,
      value: item.user.id!,
    }));
  }
}
