import { observer } from "mobx-react";
import React from "react";

import Button, { ButtonVariant } from "@eman/emankit/Button";
import { FilterBarProps } from "@eman/emankit/FilterBar";

import BaseList from "@component/BaseList";
import Address from "@model/Address";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";
import AddressListVM from "@vm/List/Address";
import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class AddressList extends BaseList<Address, AddressListVM, PropsFromRouter> {
  modelName = "address";
  prefix = "employee.contact_information";
  searchable = false;
  persistQueryString = false;

  @lazyInject(TYPES.AddressList)
  vm: AddressListVM;

  initVM() {
    this.vm.init(this.props.match.params.user_id);
  }

  filterProps(): Omit<FilterBarProps, "localization"> {
    const filters: FilterData[] = [
      {
        id: "enumeration_address_type_id",
        label: this.ta("enumeration_address_type_id"),
        values: this.enums.valuesForSelect("address_types"),
      },
      {
        id: "street",
        label: this.ta("street"),
        type: "search",
      },
      {
        id: "city",
        label: this.ta("city"),
        type: "search",
      },
      {
        id: "zip",
        label: this.ta("zip"),
        type: "search",
      },
      {
        id: "house_number",
        label: this.ta("house_number"),
        type: "search",
      },
      {
        id: "street_number",
        label: this.ta("street_number"),
        type: "search",
      },
      {
        id: "city_part",
        label: this.ta("city_part"),
        type: "search",
      },
      {
        id: "evidence_number",
        label: this.ta("evidence_number"),
        type: "search",
      },
    ];

    return {
      filters,
    };
  }

  renderRow = (item: Address, column: string): React.ReactNode => {
    if (column === "enumeration_address_type_id") {
      return item.address_type ? item.address_type.name : "";
    } else if (column === "valid_from" || column === "valid_to") {
      return this.locs.formatDate(item[column]);
    } else {
      return item[column];
    }
  };

  onCreateClick = () => {
    this.router.pageLink(this.uriHelper.new_employees_contact_informations_addresses(this.props.match.params.user_id));
  };

  headerProps() {
    return {
      text: this.tg("title"),
      level: 2,
      buttons: () => {
        if (this.user.allowToObject(UserRightsObjects.ADDRESS, UserRightsOperations.CREATE)) {
          return (
            <Button variant={ButtonVariant.Primary} onClick={this.onCreateClick}>
              {this.tg("add")}
            </Button>
          );
        }

        return null;
      },
    };
  }

  dataProps() {
    const columns = [
      this.createField("street", {}),
      this.createField("city", {}),
      this.createField("zip", {}),
      this.createField("enumeration_address_type_id", {}),
      this.createField("house_number", {}),
      this.createField("street_number", {}),
      this.createField("evidence_number", {}),
      this.createField("city_part", {}),
      this.createField("valid_from", {}),
      this.createField("valid_to", {}),
      this.createField("description", { nosort: true }),
    ];

    return {
      columns,
      value: this.renderRow,
      fixedHeader: false,
      onClick: (item: Address) => {
        this.objectEditOrShowLink("employees_contact_informations_addresses", [this.props.match.params.user_id, item.id]);
      },
    };
  }
}
