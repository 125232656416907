import { observer } from "mobx-react";

import Tabs, { Tab } from "@eman/emankit/Tabs";

import BaseComponent from "@component/BaseComponent";
import EmployeeCalendar from "@view/Allocation/Tabs/EmployeeCalendar";
import MyAllocationsCalendar from "@view/Allocation/Tabs/MyAllocationCalendar";
import ManageAlocations from "@view/Allocation/Tabs/ManageAlocations";
import { UserRightsObjects } from "@model/Rights";

@observer
export default class AllocationTabs extends BaseComponent {
  tabs() {
    const tabs: Tab[] = [];

    // @TODO This rights are weird but without them this cannot work for now
    if (
      this.user.allowToObject(UserRightsObjects.ALLOCATION_WORKING) &&
      this.user.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING)
    ) {
      tabs.push({
        name: this.locs.tg("allocation.calendar_types.my_allocation_calendar"),
        children: <MyAllocationsCalendar />,
      });
    }

    if (
      this.user.allowToObject(UserRightsObjects.ALLOCATION_WORKING) &&
      this.user.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING)
    ) {
      tabs.push({
        name: this.locs.tg("allocation.calendar_types.employee_calendar"),
        children: <EmployeeCalendar />,
      });
    }

    if (
      this.user.allowToObject(UserRightsObjects.ALLOCATION_WORKING) &&
      this.user.allowToObject(UserRightsObjects.ALLOCATION_NONWORKING)
    ) {
      tabs.push({
        name: this.locs.tg("allocation.manage.title"),
        children: <ManageAlocations />,
      });
    }

    return tabs;
  }

  render() {
    const tabs = this.tabs();

    if (tabs.length === 1) {
      return tabs[0].children;
    }

    return <Tabs tabs={this.tabs()} />;
  }
}
