import ViewHelpers from "@util/ViewHelpers";
import { observer } from "mobx-react";
import React from "react";

import Badge from "@eman/emankit/Badge";
import { BaseColor } from "@eman/emankit/Colors";
import { AlignType } from "@eman/emankit/DataTable/DataBody";

import SmallList from "@component/SmallList";
import Rate, { RateState } from "@model/Rate";
import RateListVM from "@vm/List/Rate";
import ContractsVM from "@vm/Other/Contracts";
import { lazyInject, TYPES } from "../../../../inversify.config";
import { UserRightsObjects, UserRightsOperations } from "@model/Rights";

@observer
export default class RateList extends SmallList<Rate, RateListVM, PropsFromRouter> {
  modelName = "rate";
  prefix = "employee.contract";
  searchable = false;

  @lazyInject(TYPES.RateList)
  vm: RateListVM;

  @lazyInject(TYPES.Contracts)
  contractsVM: ContractsVM;

  initVM() {
    this.vm.init();

    // React to URL change (if changed setId will ensure that this is called only once)
    this.setIdFromParams();
  }

  componentDidUpdate() {
    // React to URL change (if changed setId will ensure that this is called only once)
    this.setIdFromParams();
  }

  setIdFromParams = () => {
    const params = this.props.match.params;
    const employeeId = parseInt(params.user_id, 10);
    const contractId = parseInt(params.contract_id, 10);
    this.vm.setId(employeeId, contractId);
  };

  componentWillUnmount() {
    this.vm.dispose();
  }

  stateBadge = (state: RateState) => {
    let color;

    switch (state) {
      case RateState.IN_PREPARATION:
        color = BaseColor.LightBlue;
        break;

      case RateState.ACTIVE:
        color = BaseColor.Green;
        break;

      default:
        color = BaseColor.Gray;
        break;
    }

    return <Badge color={color}>{this.tg(`state.${state}`)}</Badge>;
  };

  filterProps = (): FilterData[] => {
    return [
      {
        id: "valid_from",
        label: this.ta("valid_from"),
        type: "date_range",
      },
      {
        id: "valid_to",
        label: this.ta("valid_to"),
        type: "date_range",
      },
      {
        id: "state",
        label: this.ta("state"),
        values: Object.values(RateState).map(state => ({ value: state, label: this.tg(`state.${state}`) })),
      },
      {
        id: "enumeration_rate_reason_id",
        label: this.ta("enumeration_rate_reason_id"),
        values: this.enums.valuesForSelect("rate_reasons"),
      },
      {
        id: "rate_h",
        label: this.ta("rate_h"),
        type: "range",
      },
      {
        id: "rate_md",
        label: this.ta("rate_md"),
        type: "range",
      },
      {
        id: "rate_month",
        label: this.ta("rate_month"),
        type: "range",
      },
    ];
  };

  renderRow = (item: Rate, column: string) => {
    switch (column) {
      case "valid_from":
      case "valid_to":
        return this.locs.formatDate(item[column]);

      case "rate_h":
      case "rate_md":
      case "rate_month":
        if (!this.contractsVM.currentContract?.contract_type?.supergross) {
          return this.locs.formatNumber(item[column]);
        } else {
          return (
            <>
              <div>
                {this.tg("rate_base")} {this.locs.formatNumber(item[column])}
              </div>
              <div>
                {this.tg("rate_supergross")} {this.locs.formatNumber(item.supergross_rate[`${column}_super`])}
              </div>
            </>
          );
        }

      case "enumeration_rate_reason_id":
      case "enumeration_working_time_id":
        return ViewHelpers.enumName(item, column);

      case "state":
        return this.stateBadge(item.state);

      case "rate_items":
        return (
          <>
            {item.rate_items.map(subitem => {
              this.enums.assignObjectEnum(subitem);
              return (
                <div key={`item-${subitem.id}`}>
                  {subitem.rate_item_type ? subitem.rate_item_type.name : ""} {this.locs.formatNumber(subitem.amount)}
                </div>
              );
            })}
          </>
        );

      default:
        return item[column];
    }
  };

  onClick = (entity: Rate) => {
    const params = this.props.match.params;
    {
      this.user.allowToObject(UserRightsObjects.RATE, UserRightsOperations.EDIT) &&
        this.user.allowToObject(UserRightsObjects.RATE, UserRightsOperations.SHOW) &&
        this.objectEditOrShowLink("employees_contracts_rates", [params.user_id, params.contract_id, entity.id]);
    }
  };

  rows() {
    return [
      this.createField("valid_from", {}),
      this.createField("valid_to", {}),
      this.createField("rate_items", { nosort: true, width: 230, align: AlignType.Right }),
      this.createField("enumeration_working_time_id", {}),
      this.createField("rate_h", { align: AlignType.Right }),
      this.createField("rate_md", { align: AlignType.Right }),
      this.createField("rate_month", { align: AlignType.Right }),
      this.createField("enumeration_rate_reason_id", {}),
      this.createField("state", { nosort: true }),
    ];
  }
}
