import { inject, injectable, postConstruct } from "inversify";
import { computed, when } from "mobx";

import ForeignPersonalDetail from "@model/ForeignPersonalDetail";
import Role from "@model/Role";
import User from "@model/User";
import Enum from "@service/Enum";
import CreateViewModel from "@vm/Create/CreateViewModel";
import OptionsVM from "@vm/Other/Options";
import TYPES from "../../inversify.types";
import { UserRoles } from "./Role";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class UserCreateVM extends CreateViewModel<User, Repository<User>> {
  roleOptionsVM: OptionsVM<Role>;

  constructor(
    @inject(TYPES.UserRepository) repository: Repository<User>,
    @inject(TYPES.RoleRepository) roleRepository: Repository<Role>,
    @inject(TYPES.Enum) private enums: Enum
  ) {
    super(User, repository);
    this.roleOptionsVM = new OptionsVM<Role>(roleRepository, "name", { order: { direction: "asc", field: "order" } });
  }

  @postConstruct()
  async prefillDefaults() {
    await when(() => this.roleOptionsVM.isInitialized);
    this.selectDefaultRole();
  }

  resetEntityAndErrors() {
    super.resetEntityAndErrors();
    this.selectDefaultRole();
  }

  selectDefaultRole() {
    if (this.roleOptionsVM.items.length) {
      const roleId = this.roleOptionsVM.items?.find(role => role.name === UserRoles.EMPLOYEE)?.id;
      if (roleId) {
        this.entity.role_id = roleId;
      }
    }
  }

  async create(): Promise<number | undefined> {
    const citizenship = this.enums.value("citizenships", this.entity.enumeration_citizenship_id);
    if (citizenship && citizenship.eurozone) {
      this.entity.foreign_personal_detail = new ForeignPersonalDetail();
    }

    return super.create();
  }

  @computed
  get roleOptions() {
    return this.roleOptionsVM.selectOptions;
  }
}
