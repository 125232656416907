import { Transform, Type } from "@eman/class-transformer";
import { enumValue } from "@util/Enumerable";
import { observable } from "mobx";

import BaseModel from "@model/BaseModel";

export enum HealthInsuranceState {
  ACTIVE = "active",
  IN_PREPARATION = "in_preparation",
  CLOSED = "closed",
}

export default class HealthInsurance extends BaseModel {
  @observable enumeration_health_insurance_office_id: number;
  @observable insurance_number: string;
  @observable user_id: number;

  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_from: Date;
  @Type(() => Date)
  @Transform(value => BaseModel.formatDate(value), { toPlainOnly: true })
  @observable
  valid_to: Date;
  @observable state: HealthInsuranceState;

  @observable
  @enumValue("health_insurance_offices", "enumeration_health_insurance_office_id")
  health_insurance_office: models.IEnumType;
}
