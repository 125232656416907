import { injectable } from "inversify";

import PersonalDocument from "@model/PersonalDocument";
import BaseAssociatedRepository from "@repository/BaseAssociatedRepository";

@injectable()
export default class PersonalDocumentRepository extends BaseAssociatedRepository<PersonalDocument> {
  constructor() {
    super(PersonalDocument, "users/$ID/personal_documents", "personal_document");
  }
}
