import { observer } from "mobx-react";

import ShowModal from "@component/ShowModal";
import PaymentInfo from "@model/PaymentInfo";
import PaymentInfoShowVM from "@vm/Show/PaymentInfo";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../../inversify.config";

@observer
export default class PaymentInfoShowModal extends ShowModal<PaymentInfo> {
  modelName = "payment_info";
  prefix = "employee.personal_information";

  @lazyInject(TYPES.PaymentInfoShow)
  vm: PaymentInfoShowVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_personal_data(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return <Form entity={this.vm.entity} readonly={true} />;
  }
}
