import CreateModal from "@component/CreateModal";
import { observer } from "mobx-react";

import OrganizationStructure, { OrganizationStructureType } from "@model/OrganizationStructure";
import { GenericFormField, SelectBox } from "@util/Form";
import OrganizationStructureCreateVM from "@vm/Create/OrganizationStructure";
import Form from "./Form";

import { lazyInject, TYPES } from "../../../inversify.config";
import { OptionType } from "@eman/emankit";
import Button, { ButtonVariant } from "@eman/emankit/Button";
@observer
export default class OrganizationStructureCreateModal extends CreateModal<OrganizationStructure> {
  modelName = "organization_structure";
  prefix = "employer";

  @lazyInject(TYPES.OrganizationStructureCreate)
  vm: OrganizationStructureCreateVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employer_organization_structures());
  };

  buttons() {
    return (
      <>
        <Button className="mr-12" onClick={this.onReset}>
          {this.locs.tg("form.reset")}
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={this.onSave} disabled={!this.vm.type}>
          {this.locs.tg("form.create")}
        </Button>
      </>
    );
  }

  renderModalBody() {
    const options: OptionType<string>[] = Object.values(OrganizationStructureType).map(type => ({
      value: type,
      label: this.tg(`type.${type}`),
    }));

    return (
      <>
        <div className="mb-20 row">
          <div className="col-md-6">
            <GenericFormField target={this.vm} property="type" label={this.tg("type.select")}>
              <SelectBox<string>
                options={options}
                onValueChanged={this.vm.resetEntityAndErrors}
                emptyRow={this.locs.tg("form.select_value")}
              />
            </GenericFormField>
          </div>
        </div>
        <Form type={this.vm.type} entity={this.vm.entity} possibleParents={this.vm.possibleParents} />
      </>
    );
  }
}
