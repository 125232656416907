import { action, observable } from "mobx";

import BaseModel from "@model/BaseModel";
import EditViewModel from "@vm/Edit/EditViewModel";

export default class AssociatedEditViewModel<
  TModel extends BaseModel,
  TRepository extends AssociatedRepository<TModel>
> extends EditViewModel<TModel, TRepository> {
  @observable parentId: string | number;

  init(id: number, parentId?: number) {
    if (this.parentId === undefined && !parentId) {
      throw new Error("You have to set parentID first to use this associated VM");
    }

    if (parentId) {
      this.setParentId(parentId);
    }

    super.init(id);
  }

  @action
  setParentId(parentId: number) {
    this.parentId = parentId;
    this.repository.setId(parentId);
  }
}
