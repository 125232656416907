import { observer } from "mobx-react";

import CreateModal from "@component/CreateModal";
import Skill from "@model/Skill";
import SkillCreateVM from "@vm/Create/Skill";

import SkillForm from "./Form";
import { lazyInject, TYPES } from "../../../inversify.config";

@observer
export default class SkillCreate extends CreateModal<Skill> {
  modelName = "skill";
  prefix = "employee";

  @lazyInject(TYPES.SkillCreate)
  vm: SkillCreateVM;

  componentDidMount(): void {
    this.vm.setParentId(this.props.match.params.user_id);
    super.componentDidMount();
  }

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_skills(this.props.match.params.user_id));
  };

  renderModalBody() {
    return (
      <>
        <SkillForm entity={this.vm.entity} />
      </>
    );
  }
}
