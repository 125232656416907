import { OptionType } from "@eman/emankit";
import AllocationUser from "@model/AllocationUser";
import Training from "@model/Training";
import Enum from "@service/Enum";

import AssociatedCreateViewModel from "@vm/Create/AssociatedCreateViewModel";
import OptionsVM from "@vm/Other/Options";

import { inject, injectable } from "inversify";
import { observable, computed, action, IReactionDisposer, reaction } from "mobx";
import TYPES from "../../inversify.types";
// There is error in babel
// remove this after https://github.com/babel/babel/issues/9838
// will be fixed
void TYPES;
void inject;

@injectable()
export default class TrainingCreateVM
  extends AssociatedCreateViewModel<Training, AssociatedRepository<Training>>
  implements ViewModel.WithReactions {
  enums: Enum;
  @observable
  private userOptionsVM: OptionsVM<AllocationUser>;

  private reactionDisposers: IReactionDisposer[] = [];

  constructor(
    @inject(TYPES.TrainingRepository) repository: AssociatedRepository<Training>,
    @inject(TYPES.AllocationUserRepository) private allocationUserRepository: Repository<AllocationUser>,
    @inject(TYPES.Enum) enums: Enum
  ) {
    super(Training, repository);
    this.enums = enums;

    this.userOptionsVM = new OptionsVM(this.allocationUserRepository, "user_last_name" as any);
    this.turnOnReactions();
  }

  turnOnReactions(): void {
    this.reactionDisposers.push(
      reaction(
        () => this.entity.enumeration_training_type_id,
        enumeration_training_type_id => this.onTrainingTypeChange(enumeration_training_type_id)
      )
    );
  }

  turnOffReactions(): void {
    this.reactionDisposers.forEach(disposer => disposer());
    this.reactionDisposers = [];
  }

  @action
  resetEntityAndErrors() {
    this.entity = new this.model();
    this.entity.user_id = this.parentId;
  }

  @action
  onTrainingTypeChange = async (enumeration_training_type_id: number) => {
    const url: string | undefined = this.enums.value("training_types", enumeration_training_type_id)?.url;
    this.entity.url = url;
  };

  @computed
  get possibleUsers(): OptionType<number>[] {
    return this.userOptionsVM?.items.map((item: AllocationUser) => ({
      label: `${item.user.first_name} ${item.user.last_name}`,
      value: item.user.id!,
    }));
  }
}
