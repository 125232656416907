import { observer } from "mobx-react";

import EditModal from "@component/EditModal";
import HealthInsurance from "@model/HealthInsurance";
import HealthInsuranceEditVM from "@vm/Edit/HealthInsurance";

import { lazyInject, TYPES } from "../../../inversify.config";

import Form from "./Form";

@observer
export default class HealthInsuranceEdit extends EditModal<HealthInsurance> {
  modelName = "health_insurance";
  prefix = "employee";

  @lazyInject(TYPES.HealthInsuranceEdit)
  vm: HealthInsuranceEditVM;

  onClose = () => {
    this.router.pageLink(this.uriHelper.employees_health_insurances(this.props.match.params.user_id));
  };

  setIdFromParams() {
    this.vm.init(this.getId(), this.props.match.params.user_id);
  }

  renderModalBody() {
    return (
      <div style={{ minHeight: 370 }}>
        <Form entity={this.vm.entity} />
      </div>
    );
  }
}
