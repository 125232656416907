import { ReactNode } from "react";
import { BalanceGroupWithDuration } from "@util/Calendar";
import _, { Dictionary } from "lodash";
import BalanceRowItem from "./item";

interface Props {
  interval: Date[];
  balances: Dictionary<BalanceGroupWithDuration>;
  sidebarRenderer: () => ReactNode;
  className?: string;
  hideSidebar?: boolean;
}

const BalanceRow = ({ interval, balances, sidebarRenderer, className, hideSidebar }: Props) => {
  const cells = [];
  let skip = 0;
  for (const day of interval) {
    const balance = balances[day.toDateString()];
    if (balance) {
      const value = balance.value;

      const cell = (
        <td className="table-col" data-value={value}>
          <BalanceRowItem balance={balance} />
        </td>
      );

      for (let i = 0; i < balance.duration; i++) {
        cells.push(cell);
      }

      skip = balance.duration - 1;
    } else {
      if (skip === 0) {
        cells.push(<td className="table-col"></td>);
      } else {
        skip--;
      }
    }
  }

  return (
    <tr className={`${className} table-row`}>
      <td className="sidebar table-col">{!hideSidebar && sidebarRenderer()}</td>
      {cells}
    </tr>
  );
};

export default BalanceRow;
