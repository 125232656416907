import { observer } from "mobx-react";

import AutoCompleteInput from "@eman/emankit/AutoCompleteInput";

import BindingElement, { BindingProps } from "./index";
import { OptionType } from "@eman/emankit";

@observer
export default class AutocompleteBox extends BindingElement<BindingProps<any>, any> {
  // TODO: Check values
  protected handleValueChanged = (value: any) => {
    this.setValue(this.parseValue(value));
  };

  // In autocomplete with FormUtils.SelectOption behaviour is different, we have to provide
  // designated method which returns SelectOption for current target. Unfortunattely those
  // are not properties but computable getters and setter, so `get` from `mobx` does not work.
  protected get value() {
    const { target, property } = this.props as any;
    return target[property];
  }

  private suggestionValue(suggestion: any): string {
    if (typeof suggestion === "string") {
      return suggestion;
    } else {
      return suggestion.name as string;
    }
  }

  private parseValue(value: any): string {
    if (typeof value === "string") {
      return value;
    } else {
      return value.name as string;
    }
  }

  // tslint:disable-next-line:member-ordering
  render() {
    let onInputChange: any;
    if (this.props.submitAnyUserInput) {
      onInputChange = this.handleValueChanged;
    }

    return (
      <AutoCompleteInput<OptionType<string>>
        inputProps={{ placeholder: this.props.placeholder, iconStop: this.props.iconStop, disabled: this.props.disabled }}
        searchSuggestions={this.props.searchSuggestions}
        onSelected={this.handleValueChanged}
        value={this.value}
        suggestions={[]}
        getInitialSuggestions={this.props.getInitialSuggestions}
        getSuggestionValue={this.suggestionValue}
        onInputChange={onInputChange}
        renderSuggestionsOnFocus={this.props.renderSuggestionsOnFocus}
      />
    );
  }
}
